import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoChevronUp } from "react-icons/io5";
import enLogo from "../../assets/countries/en.svg";
import frLogo from "../../assets/countries/fr.svg";
import ptLogo from "../../assets/countries/pt.svg";
import {
  ChevronContainer,
  Country,
  CountryFields,
  PickerContainer,
} from "./style";

const LanguagePicker: React.FC = () => {
  const { t, i18n } = useTranslation();
  const actualLanguage = t("languages.actualInitials");
  const [open, setOpen] = useState(false);
  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setOpen(false);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const languages = [
    {
      image: enLogo,
      value: "en",
    },
    {
      image: frLogo,
      value: "fr",
    },
    {
      image: ptLogo,
      value: "pt",
    },
  ];

  const findActualImage = () => {
    return languages.find(
      (item) => item.value === t("languages.actualInitials")
    )?.image;
  };

  return (
    <PickerContainer>
      <Country
        onClick={toggleOpen}
        onBlur={() => {
          setOpen(false);
        }}
      >
        <img draggable={false} src={findActualImage()} alt="COUNTRY LOGO" />
      </Country>
      <CountryFields opened={open}>
        {languages
          .filter((item) => item.value !== actualLanguage)
          .map((language, index) => (
            <Country
              key={index}
              onMouseDown={() => handleChangeLanguage(language.value)}
            >
              <img draggable={false} src={language.image} alt="COUNTRY LOGO" />
            </Country>
          ))}
      </CountryFields>
      <ChevronContainer isOpen={open} onClick={toggleOpen}>
        {<IoChevronUp />}
      </ChevronContainer>
      {/* {open ? <IoChevronUp /> : <IoChevronDown />} */}
    </PickerContainer>
  );
};

export default LanguagePicker;
