import { messages as pt } from "./pt";
import { messages as en } from "./en";
import { messages as fr } from "./fr";

const messages = {
	...pt,
	...en,
	...fr,
};
export { messages };
