import { css } from "styled-components";

export const fadeInAnimation = css`
  -webkit-animation: fadeInFromNone 500ms ease-out;
  -moz-animation: fadeInFromNone 500ms ease-out;
  -o-animation: fadeInFromNone 500ms ease-out;
  animation: fadeInFromNone 500ms ease-out;

  @-webkit-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const fadeOutAnimation = css`
  -webkit-animation: fadeOut 500ms ease-out;
  -moz-animation: fadeOut 500ms ease-out;
  -o-animation: fadeOut 500ms ease-out;
  animation: fadeOut 500ms ease-out;

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }

  @-o-keyframes fadeOut {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
    }
  }
`;
