import { createRef } from "react";
import { useTranslation } from "react-i18next";
import { RelatedPublicationsProps } from "../../../../../api/types";
import { useViewport } from "../../../../../hooks/Viewport/useViewport";
import { getSymptomsIcon } from "../../../../../utils/getSymptomsIcon";
import { getSymptomsSvg } from "../../../../../utils/getSymptomSvg";
import HorizontalScrollView from "../../../../HorizontalScroll";
import ScrollButtons from "../../../../ScrollButtons";
import StudyCard from "../StudyCard";
import {
  Header,
  HeaderContent,
  SymptomName,
  SymptomSection,
  SymptomsIcon,
  SymptomSubtitle,
  SymptomTitleContainer,
} from "./style";

interface SymptomSectionViewProps {
  symptom: RelatedPublicationsProps;
  isContraindication: boolean;
}

const SymptomSectionView = ({
  symptom,
  isContraindication,
}: SymptomSectionViewProps) => {
  const { name, studies, categoryId } = symptom;

  const { t } = useTranslation();

  const { isUnderMobile } = useViewport();

  const sliderRef = createRef<HTMLDivElement>();

  return (
    <SymptomSection>
      <Header>
        <HeaderContent>
          <SymptomsIcon>
            {/* {getSymptomsIcon(symptom.categoryId)} */}
            {isContraindication
              ? getSymptomsSvg(symptom.categoryId, "red")
              : getSymptomsIcon(symptom.categoryId)}
          </SymptomsIcon>
          <SymptomTitleContainer>
            {/* TODO traduzir nome dos suboutcomes na API */}
            <SymptomName>{name}</SymptomName>
            <SymptomSubtitle>{`${studies.length} ${
              studies.length > 1 ? t("common.studies") : t("common.study")
            }`}</SymptomSubtitle>
          </SymptomTitleContainer>
        </HeaderContent>
        {!isUnderMobile && <ScrollButtons ref={sliderRef} length={413} />}
      </Header>
      <HorizontalScrollView
        gap={!isUnderMobile ? 27 : 10}
        disableScroll
        ref={sliderRef}
      >
        {studies.map((study) => {
          return <StudyCard key={study.id} study={study} />;
        })}
        {/* {studies.length < 3 &&
          [...Array(3 - studies.length)].map((_) => <SkeletonStudyCard />)} */}
      </HorizontalScrollView>
    </SymptomSection>
  );
};

export default SymptomSectionView;
