import React from "react";
import { ReactComponent as CapsuleIcon } from "../../../assets/content/capsule.svg";
import { ReactComponent as StarIcon } from "../../../assets/content/star.svg";
import fallbackImage from "../../../assets/jpg/fallbackImage.jpg";

import {
  AlertContainer,
  CapsuleContainer,
  Container,
  Footer,
  Header,
  IconContainer,
  Product,
  ProductContainer,
  StarContainer,
} from "./styles";

import { useTranslation } from "react-i18next";
import { IoAlertOutline } from "react-icons/io5";
import { ProductProps } from "../../../api/types";
import StandardTooltip from "../../../components/Tooltip";
import { getCurrencyFormat } from "../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../utils/getImageCompletePath";
import handleImportance from "../../../utils/getImportance";

interface InfoComumProps {
  onClicked: (id: number) => void;
  selectedCardId: number;
  clickedCard: number;
  product: ProductProps;
  amountProducts: number;
}
const CardsProductsPack: React.FC<InfoComumProps> = ({
  clickedCard,
  selectedCardId,
  onClicked,
  product,
  amountProducts,
}) => {
  const {
    brand,
    capsuleAmount,
    currency,
    hasPrecaution,
    hasSynergy,
    healthGoals,
    imageUrl,
    isInPack,
    name,
    price,
    score,
    symptoms,
    alerts,
  } = product;

  const { t } = useTranslation();

  function click() {
    onClicked(selectedCardId);
  }

  return (
    <Container
      clicked={clickedCard === selectedCardId}
      elements={amountProducts}
      onClick={click}
    >
      <div className="triangulo"></div>

      <Header>
        <IconContainer>
          <CapsuleContainer>
            <CapsuleIcon />
            <label>{capsuleAmount}</label>
          </CapsuleContainer>
          {isInPack && (
            <StarContainer id={`product-is-in-top-ten-${product.id}`}>
              <StarIcon />
            </StarContainer>
          )}
          {isInPack && (
            <StandardTooltip
              anchor={`product-is-in-top-ten-${product.id}`}
              color="blue"
              text={t("hovers.product_is_recommended")}
              place="bottom"
            />
          )}
          {hasPrecaution && (
            <AlertContainer
              id={`product-has-alert-${product.id}`}
              importance={handleImportance(product.alerts)}
            >
              <IoAlertOutline color="white" />
            </AlertContainer>
          )}
          {hasPrecaution && (
            <StandardTooltip
              anchor={`product-has-alert-${product.id}`}
              color="orange"
              text={t("hovers.product_has_alert")}
              place="bottom"
            />
          )}
        </IconContainer>
      </Header>
      <ProductContainer>
        <Product
          src={getImageCompletePath("product", imageUrl)}
          alt="product"
          onError={(e) => (e.currentTarget.src = fallbackImage)}
        />
      </ProductContainer>
      <Footer value={Math.round(score)}>
        <label>{brand}</label>
        <b>{name}</b>
        <span>
          {t("screens.recommendedPack.your_compatibility")}{" "}
          <span>{`${Math.round(score)}/100`}</span>
        </span>
        <div>
          <label>{getCurrencyFormat(currency, price)}</label>
        </div>
      </Footer>
    </Container>
  );
};
export default CardsProductsPack;
