import styled from "styled-components";

export const ContainerCardsSection = styled.section`
  margin-top: 52px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 1366px) {
    margin-top: 16px;
  }

  @media (max-width: 428px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TitileSectionCards = styled.h3`
  font-size: 18px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.black};

  span {
    color: ${({ theme }) => theme.colors.blueLight};
  }

  @media (max-width: 428px) {
    width: 70%;
    text-align: center;
  }
`;

export const WrapperCardsBests = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  @media (max-width: 428px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    /* margin-left: 2rem; */
  }
`;

export const ContainerUserNameTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 34px;
`;

export const UserName = styled.h2`
  width: 200px;
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: 428px) {
    text-align: center;
    margin-bottom: 24px;
  }
`;

export const HeaderLabel = styled.div`
  width: 75%;
  height: 72px;
  background-color: #fff;
  border-radius: 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;
  margin: 0 6em 0 0.1rem;

  @media (max-width: 428px) {
    width: 100%;
    height: 155px;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    margin: 0 2rem;
    & img {
      width: 250px;
    }
  }
`;

export const ScientificPublications = styled.div`
  width: 100%;
  font-size: 16px;

  display: flex;

  > h3 {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black};

    span {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.blueLight};
    }
  }

  @media (max-width: 428px) {
    font-size: 18px;
    padding: 16px 20px;

    & > h3 {
      text-align: center;
    }
  }
`;

export const ButtonPriceContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  > label {
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    // line-height: 39px;
  }

  > div {
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 100%;
  max-height: 381px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    height: 100%;
    max-height: none;
  }
`;

export const BannerBackground = styled.div`
  height: 100%;
  pointer-events: none;
  position: absolute;

  > svg {
    height: 100%;
    width: auto;
    opacity: 0.6;
  }
`;

export const BannerLayer = styled.div`
  display: flex;
  background: linear-gradient(265.7deg, #2effaf 3.92%, #0049d6 81.28%);

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  align-items: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const SubtitleActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const Subtitle = styled.h5`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};

  > span {
    color: ${({ theme }) => theme.colors.blueLight};
    margin-left: 6px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 1rem;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }

  > label {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};

    @media screen and (max-width: 1024px) {
      margin: 2rem 0 1rem;
    }

    > span {
      display: none;

      @media screen and (max-width: 1024px) {
        display: inline-block;
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.selected};
        font-weight: 400;
      }
    }
  }
`;

export const SeeAll = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.selected};
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;
