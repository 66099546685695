import React from "react";
import { Container } from "./style";

// type NutrientCardProps = Pick<
//   NutrientProps,
//   | "name"
//   | "healthGoals"
//   | "symptoms"
//   | "image"
//   | "score"
//   | "isInTop"
//   | "hasPrecaution"
//   | "hasSynergy"
// >;

interface NutrientDetailsProps {
  onClick: () => void;
  label: string;
  color?: "green" | "blue" | "red";
  width?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

const PrimaryButton: React.FC<NutrientDetailsProps> = ({
  onClick,
  label,
  color,
  width,
  disabled = false,
  type,
}) => {
  return (
    <Container
      onClick={onClick}
      color={color}
      width={width}
      disabled={disabled}
      type={type}
    >
      <label onClick={onClick}>{`${label}`}</label>
    </Container>
  );
};
export default PrimaryButton;
