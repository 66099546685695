import styled, { css } from "styled-components";

interface ContainerProps {
  elements?: number;
}

const iconBox = css`
  height: 27.5px;
  width: 27.5px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 15px;
    width: 15px;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: ${({ elements }) =>
    elements ? 100 / elements - 2 + "%" : 300 + "px"};
  min-width: 185px;
  height: 396px;
  padding: 35px 10px;
  margin: 0 auto 8px;
  max-width: 420px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  transition: all 0.5s;

  animation: animationCards 0.3s ease-in-out;

  @keyframes animationCards {
    0% {
      // transform: translate(150px, 150px);
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      // transform: translate(0, 0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 1024px) {
    min-width: 14rem;

    justify-content: space-between;

    padding: 1rem 0.5rem;
  }
`;

export const Product = styled.img`
  height: 120px;
  width: auto;
  image-rendering: auto;
  max-width: 120px;
  max-height: 120px;
  height: auto;
`;

export const ProductContainer = styled.div`
  width: 100%;
  padding: 8px;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  // border: 2px solid red;

  > b {
    // border: 2px solid blue;
    text-align: center;
    margin-top: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    color: ${({ theme }) => theme.colors.gray};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray};
  }
  span {
    margin-top: 8px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    align-self: center;

    font-weight: 400;
    font-size: 20px;

    font-weight: bold;
    color: #21e7c5;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 8px;
  width: 100%;
  flex-flow: wrap;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const CapsuleContainer = styled.div`
  width: 44px;
  height: 27.5px;
  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 4px;
  /* padding: 6px 4px 6px 4px; */
  > svg {
    height: 18px;
    width: 18px;
  }

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    // line-height: 22px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;

// export const AlertContainer = styled.div`
//   ${iconBox}
//   ${({ theme }) => theme.redGradient}
// `;

export const AlertContainer = styled.div.attrs(
  (props: { importance: string }) => props
)`
  ${iconBox}
  ${({ theme, importance }) => importance === 'hasAvoid' ? theme.redGradient : importance === 'hasContraindications' ? theme.orangeGradient : theme.yellowGradient }
  /* ${({ theme }) => theme.redGradient} */
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  > label,
  b {
    margin-left: 5px;
    font-size: 15px;
  }
`;
