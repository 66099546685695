import React, { createRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  CompositionType,
  ExcipientType,
  OtherIngredientType,
} from "../../../../api/types";
import { ReactComponent as PillsIcon } from "../../../../assets/delivery-methods/pill-filled.svg";
import ViewMoreButton from "../../../../components/Button/ViewMore";
import CardSimple from "../../../../components/Cards/Simple";
import HorizontalScrollView from "../../../../components/HorizontalScroll";
import ScrollButtons from "../../../../components/ScrollButtons";
import StandardTooltip from "../../../../components/Tooltip";
import { getExcipientIcon } from "../../../../utils/getExcipientIcon";
import OtherIngredients from "../../components/OtherIngredients";
import { ContainerScrollBotton, ExcipientCard, GapDiv } from "../../style";
import ComponentCard from "./components/ComponentCard";
import { ModalComposition } from "./components/ModalComposition";
import {
  CompositionLabel,
  CompositionLabelContainer,
  ContainerCompositionCards,
} from "./style";

type CompositionProps = {
  composition: CompositionType[];
  otherIngredients: OtherIngredientType[];
  excipients: ExcipientType[];
  executeScroll: () => void;
  deliveryMethod: string;
};

const Composition: React.FC<CompositionProps> = ({
  composition,
  otherIngredients,
  excipients,
  executeScroll,
  deliveryMethod,
}) => {
  const { t } = useTranslation();
  const sliderRef = createRef<HTMLDivElement>();

  const [viewMore, setViewMore] = useState(false);

  const theme = useTheme();

  const toggleViewMore = () => {
    if (viewMore) {
      executeScroll();
    }
    setViewMore(!viewMore);
  };

  const otherIngredientsRef = useRef<HTMLDivElement>(null);
  const executeOtherIngredientsScroll = () => {
    if (otherIngredientsRef && otherIngredientsRef.current) {
      otherIngredientsRef.current.scrollIntoView();
    }
  };

  const [openCompositionModal, setOpenCompositionModal] = useState(false);
  const [selectedComposition, setSelectedComposition] =
    useState<CompositionType>();

  const handleOpenCompositionModal = (composition: CompositionType) => {
    setSelectedComposition(composition);
    setOpenCompositionModal(true);
  };

  const handleCloseCompositionModal = () => {
    // setSelectedComposition();
    setOpenCompositionModal(false);
  };

  return (
    <div>
      <CompositionLabelContainer>
        <PillsIcon />
        {/* // TODO implementar depois uma fução para Delivery Method */}
        <CompositionLabel
          dangerouslySetInnerHTML={{
            __html: t("screens.productDetail.for_each_capsule"),
          }}
        ></CompositionLabel>
      </CompositionLabelContainer>

      <ContainerCompositionCards>
        {composition
          .filter((component) => component.fieldsOfAction.length > 0) // REMOVER ESSE FILTRO DEPOIS QUE CORRIGIR A API
          .map((component, index) => {
            return (
              <ComponentCard
                key={index}
                component={component}
                viewMore={viewMore}
                index={index}
                onComposition={() => handleOpenCompositionModal(component)}
              />
            );
          })}
      </ContainerCompositionCards>

      {composition.length > 8 && (
        <ViewMoreButton
          isOpen={viewMore}
          onClick={toggleViewMore}
          horizontalAlign
        />
      )}

      <div ref={otherIngredientsRef} />
      {otherIngredients.length > 0 && (
        <OtherIngredients
          otherIngredients={otherIngredients}
          executeScroll={executeOtherIngredientsScroll}
        />
      )}

      {excipients.length > 0 && (
        <CardSimple
          noPaddingLeftRight
          noBackground
          hiddenWidth
          title={t("screens.productDetail.excipients")}
        >
          <GapDiv>
            <ContainerScrollBotton>
              <ScrollButtons ref={sliderRef} length={413} />
            </ContainerScrollBotton>

            <HorizontalScrollView ref={sliderRef}>
              {excipients.map((excipient, index) => {
                return (
                  <div key={index}>
                    <ExcipientCard
                      excipientAllergies={excipient?.allergies.length > 0}
                      excipientIntolerances={excipient?.intolerances.length > 0}
                      color1={
                        excipient.allergies.length > 0
                          ? "#F68E61"
                          : excipient.intolerances.length > 0
                          ? "#FFC26D"
                          : "#3e7be9"
                      }
                      color2={
                        excipient.allergies.length > 0
                          ? "#FF6737"
                          : excipient.intolerances.length > 0
                          ? "#FFB253"
                          : "#00f8ff"
                      }
                      key={index}
                      importance={excipient.importance}
                    >
                      <div>
                        {getExcipientIcon(
                          excipient.iconName,
                          excipient.allergies.length > 0
                            ? "red"
                            : excipient.intolerances.length > 0
                            ? "orange"
                            : "blue"
                        )}
                      </div>
                      <label id={`${excipient.id}`}>{excipient.name}</label>
                    </ExcipientCard>
                    <StandardTooltip
                      anchor={`${excipient.id}`}
                      text={`${excipient.name}`}
                      color="gray"
                    />
                  </div>
                );
              })}
            </HorizontalScrollView>
          </GapDiv>
        </CardSimple>
      )}

      <ModalComposition
        id="modal"
        isOpen={openCompositionModal}
        onModalClose={handleCloseCompositionModal}
        component={selectedComposition!}
        allergies={selectedComposition?.allergies!}
        intolerances={selectedComposition?.intolerances!}
      />
    </div>
  );
};

export default Composition;
