import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, Container, Progress, TopContent } from "./style";

// import { Container } from './styles';

type ProgressCardType = {
  icon: React.ReactNode;
  progress: number;
  title: string;
  height?: number;
};

const ProgressCard: React.FC<ProgressCardType> = ({
  icon,
  progress,
  title,
  height,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TopContent>
        {icon}
        <label>{t(`healthGoals.${title}`)}</label>
      </TopContent>
      <Progress height={height}>
        <Bar progress={progress} />
      </Progress>
    </Container>
  );
};

export default ProgressCard;
