import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { NutritionType } from '../../../../../../../../api/types';
import { ReactComponent as ImgCard } from '../../../../../../../../assets/nutrition/img-nutrition-card.svg';
import { ReactComponent as ForkAndKnifeImg } from "../../../../../../../../assets/banners/fork-and-knife.svg";

import { Container, DietInformation, Header, Icon, ImgContainer, ProgressContainer, Text, WrapperHeader } from './style';

interface NutritionProps {
  nutrition: NutritionType[]
}

export function NutritionCard({ nutrition }: NutritionProps) {
  return (
    <>
      {
        nutrition.map((element, index) => (
          <Container>
            <ImgContainer>
              <ImgCard />
            </ImgContainer>

            <Header>
              <DietInformation
                score={element.score}
                backgroundType={element.type}
                key={index}
              >
                <WrapperHeader>
                  <ProgressContainer>
                    <CircularProgressbarWithChildren
                      value={element.score}
                      strokeWidth={4}
                      styles={buildStyles({
                        trailColor: "rgba(255, 255, 255, 0.22)",
                        pathColor: "white",
                      })}
                    >
                      <ForkAndKnifeImg/>
                    </CircularProgressbarWithChildren>
                  </ProgressContainer>

                  <Text>
                    {
                      element.text
                    }
                  </Text>
                </WrapperHeader>
              </DietInformation>

            </Header>
          </ Container>
        ))
      }
    </>
  )
}