import { TFunction } from "react-i18next";
import { IoAlert } from "react-icons/io5";
import { AlertsType } from "../../../../api/types";
import AlertCardMobile from "../../../../components/Cards/AlertCardMobile";
import getContraindicationPhrasesFormatted from "../../../../utils/getContraindicationPhrasesFormatted";
import { getDrugInteractionsIcon } from "../../../../utils/getDrugInteractionIcon";
import getDrugInteractionPhrasesFormatted from "../../../../utils/getDrugInteractionPhrasesFormatted";
import getInnerHtmlInsideDiv from "../../../../utils/getInnerHtmlInsideDiv";
import { getSymptomsSvg } from "../../../../utils/getSymptomSvg";
import { ExcipientAlertIcon } from "../../style";

interface ProductAlertsProps {
  alerts: AlertsType;
  t: TFunction<"translation", undefined>;
}

const getProductAlertsMobile = ({ alerts, t }: ProductAlertsProps) => {
  const {
    componentsAllergy,
    componentsIntolerance,
    contraindications,
    drugInteraction,
    excipientAllergy,
    excipientIntolerance,
  } = alerts;

  const drugInteractionsAlerts = getDrugInteractionPhrasesFormatted(
    drugInteraction,
    t
  ).map((alert, index) => {
    return (
      <AlertCardMobile
        icon={getDrugInteractionsIcon(alert.design)}
        subtitle={t("common.drug_interaction")}
        title={alert.drugName}
        topIcon={<IoAlert />}
        key={`drug-interaction-alert-index-${index}`}
        anchor={`drug-interaction-alert-${index}`}
        tooltipText={alert.phrase}
        color="red"
        tooltipChildren={
          <div
            dangerouslySetInnerHTML={{
              __html: getInnerHtmlInsideDiv(alert.phrase),
            }}
          ></div>
        }
      />
    );
  });

  const excipientsAlerts = excipientIntolerance.map((alert, index) => {
    return (
      <AlertCardMobile
        icon={
          <ExcipientAlertIcon>
            {/* {getExcipientIcon(alert.icon, "white")} */}
          </ExcipientAlertIcon>
        }
        subtitle={t("common.excipient")}
        title={alert.excipientName}
        topIcon={<IoAlert />}
        key={`excipient-alert-index-${index}`}
        anchor={`excipient-alert-${index}`}
        color="yellow"
      />
    );
  });

  const contraindicationsAlerts = getContraindicationPhrasesFormatted(
    contraindications,
    t
  ).map((alert, index) => {
    return (
      <AlertCardMobile
        icon={getSymptomsSvg(alert.categoryId, "white")}
        subtitle={t("common.contraindication")}
        title={alert.name}
        topIcon={<IoAlert />}
        key={`contraindication-alert-index-${index}`}
        anchor={`contraindication-alert-${index}`}
        tooltipText={t("hovers.nutrient_contraindicated", {
          nutrientName: alert.nutrientName,
          symptomName: alert.name,
        })}
        color="orange"
        tooltipChildren={
          <div
            dangerouslySetInnerHTML={{
              __html: getInnerHtmlInsideDiv(
                t("hovers.nutrient_contraindicated", {
                  nutrientName: alert.nutrientName,
                  symptomName: alert.name,
                })
              ),
            }}
          ></div>
        }
      />
    );
  });

  return [
    ...drugInteractionsAlerts,
    ...contraindicationsAlerts,
    ...excipientsAlerts,
  ];
};

export default getProductAlertsMobile;
