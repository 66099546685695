import styled from "styled-components";

export const ContraindicationAlert = styled.span`

`;

export const WrapperTop = styled.div`
  width: 540px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  /* padding: 16px; */
`
export const WrapperAlert = styled.div<{isContraindication: boolean}>`
  width: 550px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 16px 0;

  > div {
    width: 24px;
    height: 24px;

    svg {
      ${({isContraindication}) => isContraindication ? "width: 4px; height: 15px;" : "width: 15px; height: 15px;"}
      
    }
  }

  span {
    font-size: 12px;
  }
`

export const Container = styled.div<{ viewMore: boolean; index: number, isContraindication: boolean }>`
  min-width: 565px;
  /* height: 75px; */
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 8px; */
  /* padding: 16px; */
  border-radius: 8px;
  /* box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3); */
  box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.15);
  margin-bottom: 1rem;
  padding: 16px;

  ${({ viewMore, index }) =>
    !viewMore &&
    index > 3 &&
    `
    display: none;
    opacity: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin-top: 0;
    `} 

  /* padding: 24px;
  border-radius: 8px;
  border: 1px solid #e6e7ec;
  display: flex;
  flex-direction: column;
  width: 100%;

  background: #fff; */
`;

export const TopContent = styled.div<{isContraindication: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  //   border: 2px solid red;
  //   height: 36px;
  //   > svg {
  //     height: 50px;
  //     width: 50px;
  //     // border: 2px solid orange;
  //   }

  //   > * {
  //     border: 2px solid orange;
  //   }

  svg {
    width: 50px;
    height: 50px;
    border: 2px solid ${({ isContraindication, theme }) => isContraindication ? "#FF6737" : theme.colors.blue};
    /* border: 2px solid ${({ theme }) => theme.colors.blue}; */
    border-radius: 50%;
    /* padding: 8px; */  

    & path, #Elipse_7902, #Elipse_7533 {
      fill: ${({ isContraindication }) => isContraindication && "url(#linear-orange)"};
    }
  }

  /* > img {
    height: 38px;
    width: auto;
  } */

  > label {
      width: max-content;
      max-width: 170px;
      /* height: 22px; */
      font-size: 16px;
      font-weight: bold;
      margin-left: 12px;
      color: ${({ theme }) => theme.colors.black};
    }
`;

export const Progress = styled.div<{ height?: number }>`
  height: ${({ height }) => height ?? `15`}px;
  width: 55%;
  /* width: 100%; */
  border-radius: 50px;
  background-color: #f5f7fe;
  /* margin-top: 13px; */
`;

export const Bar = styled.div<{
  progress: number;
  isContraindication?: boolean;
}>`
  background: ${({ progress, isContraindication }) =>
    progress < 0 || isContraindication
      ? `linear-gradient(228.69deg, #F68E61 24.77%, #FF6737 86.3%)`
      : `linear-gradient(269.62deg, #2effaf 18.05%, #00aaff 103.31%)`};
  height: 100%;
  width: ${({ progress }) => progress}%;
  border-radius: inherit;
`;






// 'svg',
// {
//   src: `${variants[category as keyof typeof variants]}`,
//   style: {
//     width: "50px",
//     height: "50px",
//     border: `2px solid blue`,
//     borderRadius: "50%",
//   }
// }