import { useContext, useEffect, useState } from 'react';
import { CompositionType, RelatedPublicationsProps } from '../../../../../../../../api/types';
import { StudiesContext } from "../../../../../../../../context/studies";
import { Container, Content} from './style';
import Loading from '../../../../../../../../components/Loading';
import SymptomSectionView from '../../../../../../../../components/Modal/RelatedPublications/components/SymptomSection';

type ComponentCardProps = {
  component: CompositionType;
};

export function PublicationsTab({ component }: ComponentCardProps) {
  const [orderedList, setOrderedList] = useState<RelatedPublicationsProps[]>(
    []
  );

  const {
    studiesId,
  } = component;

  const {
    setStudiesID,
    setSuboutcomeIdScoreOrderList,
    relatedPublications,
    loading,
    suboutcomeIdScoreOrderList,
    contraindicationSuboutcomeIndex,
  } = useContext(StudiesContext);

  useEffect(() => {
    setStudiesID(studiesId);
    setSuboutcomeIdScoreOrderList(component?.fieldsOfAction.map((elem) => elem.id));
    if (relatedPublications.length === 0) return;
    if (suboutcomeIdScoreOrderList.length === 0) return;

    suboutcomeIdScoreOrderList.forEach((suboutcomeId) => {
      const suboutcome = relatedPublications.filter(
        (suboutcome) => suboutcome.id === suboutcomeId
      )[0];

      if (suboutcome) {
        setOrderedList((oldList) => [...oldList, suboutcome]);
      }
    });

    return () => {
      setOrderedList([]);
    };
  }, [relatedPublications, suboutcomeIdScoreOrderList]);

  return (
    <>
      <Container>
        <Content>
          {loading ? (
            <Loading />
          ) : (
            orderedList.map((symptomCategory, index) => {
              return (
                <SymptomSectionView
                  key={symptomCategory.id}
                  symptom={symptomCategory}
                  isContraindication={contraindicationSuboutcomeIndex.includes(
                    index
                  )}
                />
              );
            })
          )}
        </Content>
      </Container>
    </>
  )
}