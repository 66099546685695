const replaceAccentedCharacters = (str: string): string => {
  let replace = {
    á: "a",
    à: "a",
    ã: "a",
    â: "a",
    é: "e",
    è: "e",
    ê: "e",
    í: "i",
    ì: "i",
    î: "i",
    ó: "o",
    ò: "o",
    õ: "o",
    ô: "o",
    ú: "u",
    ù: "u",
    û: "u",
    ç: "c",
  };
  return str.replace(/[\u00E0-\u00FC]/g, function (match: string) {
    return replace[match as keyof typeof replace];
  });
};

export default replaceAccentedCharacters;
