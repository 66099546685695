import styled from "styled-components";
interface CardPacksRecommendationContainerProps {
  isUnderTablet: boolean;
  sidebarOpen: boolean;
}

export const RecommendedPacksContainer = styled.div`
  width: 100%;

  .pack-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 2rem 0 1.6875rem;

    > div {
      > h3 {
        font-size: 20px;
      }
    }

    span {
      color: ${({ theme }) => theme.colors.blueLight};
    }

    .dosage {
      margin: 2rem 0;
      /* display: flex; */
      justify-content: flex-start;
      align-items: center;

      p {
        font-size: 18px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }

  @media screen and (max-width: 428px) {
    h3 {
      font-weight: 700;
      font-size: 24px;
      color: ${({ theme }) => theme.colors.black};
      margin-bottom: 0.5rem;
      span {
        color: ${({ theme }) => theme.colors.blueLight};
      }
    }
    @media screen and (max-width: 428px) {
      h3 {
        font-weight: 700;
        font-size: 18px;
      }
      .dosage {
        p {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
    }
  }

  .products-pack-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    @media screen and (max-width: 428px) {
      flex-direction: column;
      height: 100%;
    }
  }
`;

export const LabelButton = styled.div`
  justify-content: space-between;

  :last-child {
    > select {
      width: 100%;
    }
  }

  width: 120px;
  height: 42px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 50px;
  padding: 1rem;
`;

export const CardPacksRecommendationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 44px;
  margin: 0 auto;

  @media screen and (max-width: 428px) {
    gap: 11px;
    justify-content: center;
  }

  @media screen and (max-width: 375px) {
    gap: 8px;
    justify-content: center;
  }
`;

export const SelectAndButtoContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  > select {
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-radius: 50px;
    padding: 0.5rem 2rem 0.5rem 1rem;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray};
    height: 45px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23545976' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'></polyline></svg>");
    background-repeat: no-repeat;
    background-position: right 0.8rem center;
    background-size: 1rem auto;
  }
`;

export const AddProductButton = styled.button<{ added: boolean }>`
  width: 100%;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.selected};
  padding: 4px;
  justify-content: center;
  align-items: center;
  display: flex;

  > label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    cursor: pointer;
  }

  > svg {
    margin-left: 6px;
    margin-bottom: 1px;
    fill: white;
  }
  transition: all 400ms;

  ${({ added }) =>
    added &&
    `
    background-color: #23C6A8;

    > label {
      color: #1E9980;
    }

    > svg {
      fill: #1E9980;
      transition: all 160ms;
    }
  `}
`;

export const FooterPackMobilePage = styled.footer`
  width: 100%;
  background: #fff;

  padding: 0.8rem 1rem;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  position: fixed;
  bottom: 0;
  left: 0;

  box-shadow: 0px -9px 16px #d3d6df;

  > div {
    // border: 2px solid blue;
    > p {
      // border: 2px solid green;
      margin-bottom: 8px;
      font-weight: bold;
    }
  }
`;
