import React from "react";
import { ReactComponent as StarIcon } from "../../../assets/content/star.svg";
import fallbackImage from "../../../assets/jpg/fallbackImage.jpg";

import {
  AlertContainer,
  CompatibilityText,
  Container,
  ContentCard,
  FooterCard,
  IconContainer,
  Product,
  ProductContainer,
  StarContainer,
  WrapperContentCard,
} from "./styles";

import { useTranslation } from "react-i18next";
import { IoAlertOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { ProductProps } from "../../../api/types";
import StandardTooltip from "../../../components/Tooltip";
import { getCurrencyFormat } from "../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../utils/getImageCompletePath";

interface InfoComumProps {
  onClicked: (id: number) => void;
  selectedCardId: number;
  clickedCard: number;
  product: ProductProps;
  amountProducts: number;
}
const CardsProductsPack: React.FC<InfoComumProps> = ({
  clickedCard,
  selectedCardId,
  onClicked,
  product,
  amountProducts,
}) => {
  const navigation = useNavigate();

  const { t } = useTranslation();

  const {
    brand,
    capsuleAmount,
    currency,
    hasPrecaution,
    hasSynergy,
    healthGoals,
    imageUrl,
    isInPack,
    name,
    price,
    score,
    symptoms,
  } = product;

  return (
    <Container
      clicked={clickedCard === selectedCardId}
      elements={amountProducts}
      onClick={() => {
        navigation("/product-details", {
          state: product,
        });
      }}
    >
      <WrapperContentCard>
        <h1>{name}</h1>
        <ContentCard>
          <IconContainer>
            {isInPack && (
              <StarContainer id={`product-is-in-top-ten-${product.id}`}>
                <StarIcon />
              </StarContainer>
            )}
            {isInPack && (
              <StandardTooltip
                anchor={`product-is-in-top-ten-${product.id}`}
                color="blue"
                text={t("hovers.product_is_recommended")}
                place="bottom"
                clickable
              />
            )}
            {hasPrecaution && (
              <AlertContainer id={`product-has-alert-${product.id}`}>
                <IoAlertOutline color="white" />
              </AlertContainer>
            )}
            {hasPrecaution && (
              <StandardTooltip
                anchor={`product-has-alert-${product.id}`}
                color="orange"
                text={t("hovers.product_has_alert")}
                place="bottom"
                clickable
              />
            )}
          </IconContainer>
          <CompatibilityText>
            {"Votré compatibilité"} <span>{`${Math.round(score)}/100`}</span>
          </CompatibilityText>
        </ContentCard>

        <FooterCard>
          <span>{t("screens.recommendedPack.learn_more")}</span>
          <label>{getCurrencyFormat(currency, price)}</label>
        </FooterCard>
      </WrapperContentCard>
      <ProductContainer>
        <Product
          src={getImageCompletePath("product", imageUrl)}
          alt="product"
          onError={(e) => (e.currentTarget.src = fallbackImage)}
        />
      </ProductContainer>
    </Container>
  );
};
export default CardsProductsPack;
