import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { IoChevronForwardSharp, IoClose } from "react-icons/io5";
import { ReactComponent as ButtonScrollUp } from "../../assets/icons/svg/buttonScrollUp.svg";
import { ReactComponent as IconFilter } from "../../assets/filter-icon.svg";
import InfoProduct from "../../components/Cards/InfoProduct";
import Loading from "../../components/Loading";
import Page from "../../components/Page";
import { FilterContext } from "../../context/filter";
import { HeaderContainer, HeaderLabel } from "../../styles/commomStyles";

import {
  Container,
  ContainerArrowUp,
  FilterButton,
  FilterTag,
  FilteredContainer,
  LoadingContainer,
  SearchContainer,
  SearchInput,
  SubHeader,
} from "./style";

import {
  ContainerPaginate,
  PaginateWrapper,
  RoundEffect,
} from "../../components/Paginate/style";
import { PaginationContext } from "../../context/pagination";
import { useLocation, useNavigate } from "react-router";

export default function Recommendation() {
  const {
    avatarSymptoms,
    categorySymptoms,
    handleOpenSidebar,
    categorySymptomsIndexSelected,
    setCategorySymptomsIndexSelected,
    avatarSymptomsIndexSelected,
    setAvatarSymptomsIndexsSelected,
    healthGoals,
    healthGoalsIndexSelected,
    setHealthGoalsIndexSelected,
    clearFilters,
    loadingFilter,
    productsSearchInput,
    setProductsSearchInput,
    filteredProducts,
  } = useContext(FilterContext);

  const [scrollPosition, setScrollPosition] = useState(0);

  const { t } = useTranslation();

  const {
    currentItens,
    prevPage,
    nextPage,
    items,
  } = useContext(PaginationContext)

  // useEffect(() => {
  //   // window.scrollTo(0, 0);
  // }, []);

  const location = useLocation();
  const navigate = useNavigate()

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const searchInput = useRef<HTMLInputElement>(null);

  const handleScrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  const RenderHeader = () => (
    <HeaderContainer>
      <HeaderLabel
        dangerouslySetInnerHTML={{
          __html: t("screens.products.best_products_from_a_base", {
            count: filteredProducts.length,
          }),
        }}
      ></HeaderLabel>
    </HeaderContainer>
  );

  return (
    <Page screen="products" header={() => <RenderHeader />}>
      <ContainerArrowUp
        onClick={handleScrollUp}
        scrollPosition={scrollPosition}
      >
        <ButtonScrollUp />
      </ContainerArrowUp>
      <SubHeader>
        <label>
          <b>{t("screens.products.find_the_best")} </b>
          <br />
          {t("screens.products.select_below")}
        </label>
        <SearchContainer onClick={() => searchInput?.current?.focus()}>
          <AiOutlineSearch />
          <SearchInput
            ref={searchInput}
            value={productsSearchInput}
            onChange={(e) => {
              setProductsSearchInput(e.target.value);
            }}
            placeholder={t("screens.products.search_product")}
            name="searchInput"
          />
        </SearchContainer>
      </SubHeader>

      <FilteredContainer>
        <FilterButton onClick={handleOpenSidebar}>
          <IconFilter className="icon-filter" />
          <span>{t("screens.products.filter")}</span>
          <IoChevronForwardSharp size={15} />
        </FilterButton>

        {avatarSymptomsIndexSelected.map((value) => {
          return (
            <FilterTag className="filterTag">
              <span>{avatarSymptoms[value].name}</span>
              <IoClose
                size={15}
                fill={"#545976"}
                className="icon-close"
                onClick={() => {
                  setAvatarSymptomsIndexsSelected((prev) =>
                    prev.filter((elem) => elem !== value)
                  );
                }}
              />
            </FilterTag>
          );
        })}

        {categorySymptomsIndexSelected.map((value) => {
          return (
            <FilterTag className="filterTag">
              <span>{categorySymptoms[value].name}</span>
              <IoClose
                size={15}
                fill={"#545976"}
                className="icon-close"
                onClick={() => {
                  setCategorySymptomsIndexSelected((prev) =>
                    prev.filter((elem) => elem !== value)
                  );
                }}
              />
            </FilterTag>
          );
        })}

        {healthGoalsIndexSelected.map((value) => {
          return (
            <FilterTag className="filterTag">
              <span>{t(`healthGoals.${Object.keys(healthGoals)[value]}`)}</span>
              <IoClose
                size={15}
                fill={"#545976"}
                className="icon-close"
                onClick={() => {
                  setHealthGoalsIndexSelected((prev) =>
                    prev.filter((elem) => elem !== value)
                  );
                }}
              />
            </FilterTag>
          );
        })}
      </FilteredContainer>

      {!loadingFilter && filteredProducts ? (
        <>
          <Container>
            {currentItens
              .map((product, key) => (
                <InfoProduct product={{ ...product }} key={key} />
              ))}
          </Container>

          <ContainerPaginate>
            <PaginateWrapper>
              <RoundEffect onClick={prevPage}>&laquo;</RoundEffect>
              {items}
              <RoundEffect onClick={nextPage}>&raquo;</RoundEffect>
            </PaginateWrapper>
          </ContainerPaginate>
        </>
      ) : (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
    </Page>
  );
}
