import styled from "styled-components";

export const LabelButton = styled.div`
  justify-content: space-between;

  // border: 2px solid green;

  :last-child {
    // margin-left: 1rem;
    > select {
      width: 100%;
    }
  }
  display: flex;
  height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 50px;
  padding: 1rem;
  width: 120px;

  align-items: center;

  button {
    display: flex;
    align-items: center;
  }
`;

export const ToogleNumber = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-align: center;
  margin: 0;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.gray};

  // ::-webkit-inner-spin-button,
  // ::-webkit-outer-spin-button {
  //   -webkit-appearance: none;
  //   margin: 0;
  // }
`;
