import React from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoAlertCircle } from "react-icons/io5";
import {
  Background,
  Container,
  Icon,
  TopLeftIcon,
  TopRightIcon,
} from "./style";

type SymphonCardType = {
  warning?: boolean;
  icon: React.ReactNode;
  warnIcon?: boolean;
  title: string;
  progress: number;
  onClick?: () => void;
};
const Symptom: React.FC<SymphonCardType> = ({
  icon,
  progress,
  title,
  warnIcon,
  warning,
  onClick,
}) => {
  return (
    <Container warn={warning} onClick={onClick} clickable={!!onClick}>
      <div>
        {!!onClick && (
          <TopLeftIcon>
            <AiOutlinePlusCircle color="white" />
          </TopLeftIcon>
        )}
        <Icon>
          <Background>
            <CircularProgressbarWithChildren
              value={progress > 0 ? progress : progress * -1}
              strokeWidth={4}
              styles={buildStyles({
                trailColor: "rgba(0, 0, 0, 0.2)",
                pathColor: "white",
              })}
            >
              {icon}
            </CircularProgressbarWithChildren>
          </Background>
        </Icon>
        <TopRightIcon>
          {warnIcon && <IoAlertCircle color="white" />}
        </TopRightIcon>
      </div>
      <div>
        <label>{title}</label>
      </div>
    </Container>
  );
};

export default Symptom;
