import styled from "styled-components";

export const PickerContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  //   border: 2px solid red;
  position: relative;

  > svg {
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }
`;

export const CountryFields = styled.div<{ opened: boolean }>`
  flex-direction: column;
  position: absolute;
  display: flex;
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  top: 60px;
  background: white;
  ${({ theme }) => theme.shadow}
  //   padding: 4px;

//   border: 2px solid blue;
  gap: 4px;

  //   height: 52px;
  //   width: 52px;

  transition: all 0.3s;
  border-radius: 100px;
`;

export const Country = styled.button`
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  height: 50px;
  width: 50px;
  display: flex;
  border-radius: 100px;
  transition: opacity 0.2s ease-in;

  //   border: 2px solid green;

  &:hover {
    opacity: 0.7;
  }

  > img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1024px) {
    padding: 0.1rem;
    width: 34px;
    height: 34px;
  }
`;

export const ChevronContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: -16px;
  //   transform: translate(0, 0);
  //   border: 2px solid red;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    transition: transform 180ms ease-in-out;
    -webkit-transition: -webkit-transform 180ms ease-in-out;
    -ms-transition: -ms-transform 180ms ease-in-out;
    ${({ isOpen }) =>
      !isOpen
        ? `
		  transform: rotate(180deg);
  		
	  `
        : `transform: rotate(360deg);`}
  }
`;
