import styled from "styled-components";
import { getAlertGradient } from "../AlertCard/style";

export const Container = styled.div<{
  color: "orange" | "red" | "yellow" | "green" | "gray";
}>`
  padding: 12px 8px;
  border-radius: 8px;
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 136px;
  flex-shrink: 0;
  height: 92px;
  justify-content: space-between;
  background: ${({ color }) => getAlertGradient(color)};

  -webkit-box-shadow: 0px 3px 3px 1px #e3caca;
  box-shadow: 0px 3px 3px 1px #e3caca;
`;

export const Title = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.white};
`;

export const SubTitle = styled.label`
  color: ${({ theme }) => theme.colors.white};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

export const Icon = styled.div`
  display: flex;
  height: 32px;
  width: 32px;

  border: 1.5px solid white;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px;
  align-items: flex-start;
  //   margin-bottom: 10px;
  > img {
    fill: ${({ theme }) => theme.colors.white};
    height: 100%;
    width: 20px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
`;

export const TopIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  height: 16px;
  width: 16px;
  background: white;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    color: rgba(255, 164, 113, 0.97);
  }
`;
