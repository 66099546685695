import { ReactComponent as Aesthetics } from "../assets/symptoms/symptoms-icon/Aesthetics.svg";
import { ReactComponent as Allergies } from "../assets/symptoms/symptoms-icon/Allergies.svg";
import { ReactComponent as Antiaging } from "../assets/symptoms/symptoms-icon/Anti-aging.svg";
import { ReactComponent as AntiInflammatory } from "../assets/symptoms/symptoms-icon/Anti-inflammatory.svg";
import { ReactComponent as Biomarker } from "../assets/symptoms/symptoms-icon/Biomarker.svg";
import { ReactComponent as BoneAndJoint } from "../assets/symptoms/symptoms-icon/Bone-and-joint.svg";
import { ReactComponent as BrainHealth } from "../assets/symptoms/symptoms-icon/Brain-Health.svg";
import { ReactComponent as Cardiovascular } from "../assets/symptoms/symptoms-icon/Cardiovascular.svg";
import { ReactComponent as DiseaseCondition } from "../assets/symptoms/symptoms-icon/Disease-Condition.svg";
import { ReactComponent as EatingPatterns } from "../assets/symptoms/symptoms-icon/Eating-Patterns.svg";
import { ReactComponent as Emotional } from "../assets/symptoms/symptoms-icon/Emotional.svg";
import { ReactComponent as EnergyMetabolismAndStimulation } from "../assets/symptoms/symptoms-icon/Energy-Metabolism.svg";
import { ReactComponent as EyeHealth } from "../assets/symptoms/symptoms-icon/Eye-Health.svg";
import { ReactComponent as GeneralHealth } from "../assets/symptoms/symptoms-icon/General-Health.svg";
import { ReactComponent as GutDigestion } from "../assets/symptoms/symptoms-icon/Gut-Digestion.svg";
import { ReactComponent as Hormones } from "../assets/symptoms/symptoms-icon/Hormones.svg";
import { ReactComponent as KidneyBladder } from "../assets/symptoms/symptoms-icon/Kidney-Bladder.svg";
import { ReactComponent as LibidoAndSexualHealth } from "../assets/symptoms/symptoms-icon/Libido-and-Sexual-Health.svg";
import { ReactComponent as LiverHealth } from "../assets/symptoms/symptoms-icon/Liver-Health.svg";
import { ReactComponent as MensHealth } from "../assets/symptoms/symptoms-icon/Mens-Health.svg";
import { ReactComponent as Mood } from "../assets/symptoms/symptoms-icon/Mood.svg";
import { ReactComponent as MuscleGain } from "../assets/symptoms/symptoms-icon/Muscle-Gain.svg";
import { ReactComponent as NervousSystem } from "../assets/symptoms/symptoms-icon/Nervous-System.svg";
import { ReactComponent as SleepQuality } from "../assets/symptoms/symptoms-icon/Sleep-Quality.svg";
import { ReactComponent as WomensHealth } from "../assets/symptoms/symptoms-icon/Women-Health.svg";
import { HeartIcon } from "../components/Icons/HeartIcon";

export const getIconsSvgFielOfActionsComposition = (category: number) => {
  let variants = {
    1: <Aesthetics />,
    2: <AntiInflammatory />,
    3: <Antiaging />,
    4: <Biomarker />,
    5: <Biomarker />,
    6: <BoneAndJoint />,
    7: <Cardiovascular />,
    8: <BrainHealth />,
    10: <DiseaseCondition />,
    11: <EatingPatterns />,
    12: <Emotional />,
    13: <EnergyMetabolismAndStimulation />,
    14: <EyeHealth />,
    15: <EnergyMetabolismAndStimulation />,
    16: <GeneralHealth />,
    17: <GutDigestion />,
    18: <Hormones />,
    19: <KidneyBladder />,
    20: <LibidoAndSexualHealth />,
    21: <LiverHealth />,
    22: <MensHealth />,
    23: <Mood />,
    24: <MuscleGain />,
    25: <NervousSystem />,
    26: <Biomarker />,
    27: <SleepQuality />,
    28: <WomensHealth />,
    29: <NervousSystem />,
    30: <Allergies />,
    31: <Biomarker />,
  };

  return (
    (
      <div
        style={{
          width: "auto",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          {
            variants[category as keyof typeof variants]
          }
        </>
      </div>
    ) || <HeartIcon />
  );
};