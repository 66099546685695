import styled from "styled-components";
import { getColorByQuartile } from "../Nutrient/style";

export const Container = styled.div`
  width: 100%;
  padding: 24px;
  max-width: 420px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  backgroud: white;
  ${({ theme }) => theme.lightShadow};
  //   border: 2px solid red;
  background-color: ${({ theme }) => theme.colors.white};

  margin-bottom: 24px;
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  //   border: 2px solid red;
`;

export const ImageContainer = styled.div`
  height: 65px;
  width: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  //   border: 2px solid green;
  margin-left: 22px;
`;

export const ProductName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const CompatibilityLabel = styled.label<{ value: number }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.gray};

  > label {
    color: ${({ theme, value }) => getColorByQuartile(value, theme)};

    font-weight: 700;
  }
`;

export const ProductImage = styled.img`
  height: 65px;
  width: auto;
  object-fit: contain;
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: row;
  // border: 2px solid red;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
`;

export const PriceLabel = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.gray};
`;
