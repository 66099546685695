import React from "react";
import { useNavigate } from "react-router";
import { TabPanel } from "react-tabs";
import { ProductProps } from "../../../../api/types";
import {
  FlagContainer,
  TabListContent,
  TabsContainer,
  UnorderedTab,
} from "./style";

interface TabViewProps {
  labels: string[];
  contents: React.ReactNode[];
  learnMore?: string;
  selectedProduct?: ProductProps;
  numberOfComponentesLabel?: number[];
  backgroundColor?: string;
  disableSecondTab?: boolean;
}

const TabViewMobile: React.FC<TabViewProps> = ({
  contents,
  labels,
  learnMore,
  selectedProduct,
  numberOfComponentesLabel,
  backgroundColor,
  disableSecondTab = false,
}) => {
  const navigate = useNavigate();

  const [contentSelected, setContentSelected] = React.useState(labels[0]);
  return (
    <TabsContainer
      backgroundColor={backgroundColor}
      selectedIndex={labels.indexOf(contentSelected)}
    >
      <TabListContent backgroundColor={backgroundColor}>
        {labels.map((label, index) => {
          return (
            <UnorderedTab
              key={index}
              onSelect={() => null}
              onClick={() =>
                !disableSecondTab ? setContentSelected(label) : null
              }
              selected={contentSelected === label}
            >
              <FlagContainer
                isDisabled={disableSecondTab && index === 1}
                selected={contentSelected === label}
              >
                <span>{numberOfComponentesLabel?.[index]}</span> {label}
              </FlagContainer>
            </UnorderedTab>
          );
        })}

        {/* <LearnMore
          onClick={() => {
            navigate("/product-details", {
              state: selectedProduct,
              // replace: true,
            });
          }}
        >
          {learnMore}
        </LearnMore> */}
      </TabListContent>

      {contents.map((content, index) => (
        <TabPanel key={index}>{content}</TabPanel>
      ))}
    </TabsContainer>
  );
};
export default TabViewMobile;
