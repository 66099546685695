import styled from "styled-components";

export const SubHeaderMobile = styled.div`
  width: 100%;
  /* padding-top: 20px; */
  /* padding-bottom: 10px; */
  display: flex;
  // align-items: flex-end;
  justify-content: space-between;

  margin: 10px 0 26px;

  > label {
    width: 80%;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};

    > span {
      margin-left: 5px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.blueLight};
    }
  }
`;

export const SubHeader = styled.div`
  width: 100%;
  /* padding-top: 20px; */
  /* padding-bottom: 10px; */
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  margin: 10px 0 26px;

  > label {
    width: 80%;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};

    > span {
      margin-left: 5px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.blueLight};
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  > label {
    /* width: 80%; */
    font-size: 18px;
    color: ${({ theme }) => theme.colors.black};
  }

  .base-products {
    /* margin-left: 5px; */
    font-size: 16px;

    span {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.blueLight};
    }
  }
`;

export const LoadingContainer = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchContainer = styled.div`
  width: 434px;
  height: 42px;
  display: flex;
  flex-direction: row;
  /* margin-left: 5px; */
  border-radius: 100px;
  /* flex: auto; */
  align-items: center;
  /* padding: 5px; */
  cursor: text;
  background-color: ${({ theme }) => theme.colors.white};
  > svg {
    margin-left: 10px;
    height: 25px;
    width: 25px;
  }
`;
interface SearchContainerMobileProps {
  isOpenedSearch: boolean;
  inResearch: boolean;
}

export const SearchContainerMobile = styled.div<SearchContainerMobileProps>`
  /* width: 134px; */

  // border: 2px solid blue;

  width: ${({ isOpenedSearch, inResearch }) =>
    isOpenedSearch
      ? "150px"
      : !isOpenedSearch && inResearch
      ? "150px"
      : "50px"};
  height: 30px;
  display: flex;
  /* flex-direction: row; */
  /* margin-left: 5px; */
  border-radius: 100px;
  /* flex: auto; */
  align-items: center;
  transition: all 0.2s;
  z-index: 500;

  ${({ isOpenedSearch, inResearch }) =>
    !isOpenedSearch && !inResearch && `margin-right: -20px;`}

  ${({ isOpenedSearch }) =>
    isOpenedSearch &&
    `
      // position: absolute;
      // right: 10px;

      > svg {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px;
      }
    `}

  ${({ isOpenedSearch, inResearch }) =>
    !isOpenedSearch &&
    inResearch &&
    `
        // position: absolute;
        // right: 10px;
        > svg {
          width: 30px;
          height: 30px;
          margin-left: 10px;
          margin-right: 10px;
        }
      `}

  cursor: text;
  background-color: ${({ theme, isOpenedSearch, inResearch }) =>
    isOpenedSearch
      ? theme.colors.white
      : !isOpenedSearch && inResearch
      ? theme.colors.white
      : "transparent"};

  /* > svg {
    width: 30px;
    height: 30px;
  } */
`;

export const FilterButtonSearchContainer = styled.div`
  /* padding: 10px; */
  display: flex;
  /* flex: auto;
  font-size: 18px; */
  // border: 2px solid red;
  margin-top: 10px;
`;

export const SearchInput = styled.input`
  padding: 10px;
  display: flex;
  flex: auto;
  font-size: 18px;

  :focus::placeholder {
    color: transparent;
  }
`;

export const SearchInputMobile = styled.input`
  width: 100%;
  /* padding: 10px; */
  display: flex;
  /* flex: auto; */
  font-size: 16px;
`;

export const Container = styled.div`
  max-width: 1000px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;

  margin: 1.5rem auto 0;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const FilteredContainer = styled.div`
  display: flex;
`;

export const FilteredContainerMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
`;

export const FilterButton = styled.button`
  width: 6.25rem;
  height: 1.68rem;

  font-size: 1rem;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  cursor: pointer;

  svg path,
  span {
    transition: 0.3s;
  }

  &:hover {
    svg path,
    span {
      color: ${({ theme }) => theme.colors.grayLight};
      stroke: ${({ theme }) => theme.colors.grayLight};
    }
  }
`;

export const FilterButtonMobile = styled.button<SearchContainerMobileProps>`
  /* width: 6.25rem;
  height: 1.68rem; */

  // border: 2px solid orange;

  margin-right: 10px

  ${({ isOpenedSearch }) =>
    isOpenedSearch &&
    `
      margin-right: 0px;
  `}

  font-size: 1rem;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  cursor: pointer;

  svg path,
  span {
    transition: 0.3s;
  }
`;

export const FilterTag = styled.div`
  // width: 7.279rem;
  height: 1.68rem;

  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.gray};

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.colors.grayLightBorder};
  border-radius: 24px;
  padding-left: 8px;
  padding-right: 8px;

  & + .filterTag {
    /* margin-left: 0.5625rem; */
  }

  svg {
    cursor: pointer;
    transition: 0.3s;
    margin-left: 4px;

    &:hover {
      filter: brightness(0.2);
      transform: scale(1.1);
    }
  }
`;
