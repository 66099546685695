import React, { createContext, useContext, useState } from "react";
import {
  ProductProps,
} from "../api/types";
import { RoundEffect } from "../components/Paginate/style";
import { FilterContext } from "./filter";

interface PaginationContextInterface {
  nextPage: () => void
  prevPage: () => void
  currentItens: ProductProps[]
  items: React.ReactNode
  setCurrentPage: (number: number) => void
  currentPage: number
}

export const PaginationContext = createContext({} as PaginationContextInterface);

const PaginationProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    filteredProducts,
  } = useContext(FilterContext);

  const [itensPerPage, setItensPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  const pages = Math.ceil(filteredProducts.length / itensPerPage - 1);
  const startIndex = currentPage * itensPerPage;
  const endIndex = startIndex + itensPerPage;
  const currentItens = filteredProducts.slice(startIndex, endIndex);

  let items = [];
  let leftSide = currentPage - 2;
  if (leftSide <= 0) leftSide = 0;
  let rightSide = currentPage + 2;
  if (rightSide > pages) rightSide = pages;

  for (let number = leftSide; number <= rightSide; number++) {
    items.push(
      <RoundEffect
        key={number}
        className={number === currentPage ? "active" : "round-effect"}
        onClick={() => setCurrentPage(number)}
      >
        {number + 1}
      </RoundEffect>
    );
  }

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <PaginationContext.Provider
      value={{
        nextPage,
        prevPage,
        currentItens,
        items,
        setCurrentPage,
        currentPage
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};
export { PaginationProvider };
