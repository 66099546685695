import React from "react";
import { useTranslation } from "react-i18next";

import iconNoInformation from "../../../../../assets/banners/no-information.svg";

import {
  DietInformationContainer,
  DietInformationDefault,
  DietInformationImageDefault,
  DietInformationTitleContainerDefault,
  DietInformationTitleDefault,
} from "./style";

const DietInformationDefaultCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DietInformationContainer>
      <DietInformationDefault>
        <DietInformationImageDefault src={iconNoInformation} />
        <DietInformationTitleContainerDefault>
          <DietInformationTitleDefault>
            {t("screens.productDetail.no_additional_information")}
          </DietInformationTitleDefault>
        </DietInformationTitleContainerDefault>
      </DietInformationDefault>
    </DietInformationContainer>
  );
};

export default DietInformationDefaultCard;
