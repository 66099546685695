import styled, { css } from "styled-components";
import { getColorByQuartile } from "../../../../components/Cards/Nutrient/style";

export const BestProductCard = styled.div<{ color?: "primary" | "secondary" }>`
  background: ${({ color }) =>
    color !== "primary"
      ? `linear-gradient(89.73deg, #2effaf 25.48%, #00aaff 91.69%)`
      : `linear-gradient(105.57deg, #3135C6 17.51%, #00AAFF 74.49%)`};
  padding: 2px;
  padding-top: 12px;
  margin-bottom: 30px;
  margin-top: 12px;

  border-radius: 8px;
  position: relative;
`;
export const BestProductContent = styled.div`
  width: 100%;
  height: 184px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  //   border: 2px solid red;
`;

export const ImageContainer = styled.div`
  /* max-width: 94px;
  padding: 56px; */
  // border: 2px solid blue;
  /* margin-left: 56px; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BestProductImage = styled.img`
  // border: 2px solid red;
  // height: 205px;
  max-width: 94px;
  max-height: 78px;
  // width: 254px;
  // border: 2px solid blue;
`;

export const BestProductInformation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: row; */
  flex: auto;
  gap: 30px;
  // border: 2px solid green;
  margin: 16px;
  position: relative;
`;

export const TitileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;
export const BrandName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const ProductName = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 10px;
`;

export const CompatibilityLabel = styled.label<{ value: number }>`
  /* margin-top: 22px; */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray};

  > label {
    color: ${({ theme, value }) => getColorByQuartile(value, theme)};
    font-weight: 700;
  }
`;

export const PriceLabel = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 45px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  // border: 2px solid red;
  /* position: absolute; */
  /* top: 70px;
  right: 58px; */
  gap: 6px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 67px;
  right: 58px;
`;

const iconBox = css`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  > svg {
    height: 13.5px;
    width: 13.5px;
  }
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;

export const AlertContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.redGradient}
`;

export const Spacing = styled.div`
  height: 26px;
`;
export const BestProductFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  div > label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.selected};
  }
`;
