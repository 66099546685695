export const getGradient = (
  score: number,
  backgroundType: string = "nutrition"
) => {
  //   const gradient = {
  //     1: "#B662CF, #E491dE",
  //     2: "#4AC7C9, #17AEB1",
  //     3: "#A581D4, #7B4AB9",
  //     4: "#6CB1E5, #3988C4",
  //   };

  const gradient = {
    1: "228.69deg, #F68E61 24.77%, #FF6737 86.3%",
    2: "33.35deg, #D24848 14.59%, #FD8787 79%",
    3: "0deg, #FF9922 32.67%, #F7C177 96.76%",
    4: "#01C1B6, #01C1B6",
  };

  const isLifeStyleRisk = backgroundType === "health risk";

  if (valueIsBetween(score, 0, 25)) return gradient[isLifeStyleRisk ? 4 : 1];
  if (valueIsBetween(score, 26, 50)) return gradient[isLifeStyleRisk ? 3 : 2];
  if (valueIsBetween(score, 51, 75)) return gradient[isLifeStyleRisk ? 2 : 3];
  if (score >= 76) return gradient[isLifeStyleRisk ? 1 : 4];
};

const valueIsBetween = (value: number, min: number, max: number) => {
  return value >= min && value <= max;
};
