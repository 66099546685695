export const getCurrencyString = (currency: string) => {
  if (currency === "eur") {
    return "€";
  } else {
    return "U$";
  }
};

export const getCurrencyFormat = (currency: string, price: number) => {
  let newPrice = price.toFixed(2);

  if (newPrice.split(".")[1] === "00") {
    newPrice = newPrice.split(".")[0];
  }

  if (currency === "eur") {
    // return price.toFixed(2).replace(".", "€");
    return `${newPrice}€`;
  } else if (currency === "usd") {
    return "$" + newPrice.replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  } else {
    return `${currency} ${newPrice}`;
  }
};

export const formatterCurrencyFilter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
  maximumFractionDigits: 0,
});
