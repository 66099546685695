import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    *, 
    *:before,
    *:after {
        box-sizing: border-box;
    }
    * {
        margin:0;
        padding:0;
        outline:0;
        box-sizing:border-box;
        user-select: none;
        -webkit-user-drag: none;
        scroll-behavior: smooth;
        -webkit-font-smoothing: antialiased;
    }
    a {
        text-decoration: none;
    }
    input,textarea {
        transition:border 0.3s ease;
        border-radius:0.2rem;
        transition:box-shadow 0.1s ease;
    }
    button {
        cursor: pointer;
        border: 0;
    }
    html, body, #root {
        min-height: 100%;
        // height: 100vh;
        width: 100%;
    }
    *, button, input,textarea {
    border: 0;
    background: none;
    outline: 0 !important;
    font-family: "Roboto";
    }
    body{
        font:14px 'Roboto', sans-serif;
        /* -webkit-font-font-smoothing: antialiased !important; */
        background: #f7f7f7;
        color:#333;
    }
    label{
        font-family: "Roboto";
    }
    span{
        font-family: "Roboto";
    }

    .ReactModal__Body--open {
        overflow-y: hidden;
    }

    .ReactModal__Overlay {
        z-index: 9999;
    }
`;

export default GlobalStyles;
