import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { SetupAnswerContext } from "../../../context/setupAnswer";
import { useDisableBodyScroll } from "../../../hooks/Scroll/useDisableScroll";
import PrimaryButton from "../../Button/PrimaryButton";
import LanguagePicker from "../../LanguagePicker";
import {
  ButtonContainer,
  Container,
  FormContainer,
  FormInput,
  FormLabel,
  FormSelect,
  LanguagePickerContainer,
  Title,
} from "./style";

const SetupAnswerModal = () => {
  const { t } = useTranslation();

  const {
    modalOpen,
    handleCloseModal,
    handleOpenModal,
    answerId,
    language,
    pharmacyId,
    setAnswerId,
    setLanguage,
    setPharmacyId,
  } = useContext(SetupAnswerContext);

  const [_answerId, _setAnswerId] = useState(answerId);
  const [_pharmacyId, _setPharmacyId] = useState(pharmacyId);
  const [_language, _setLanguage] = useState(language);

  const reset = () => {
    _setAnswerId(answerId);
    _setPharmacyId(pharmacyId);
    _setLanguage(language);
  };

  const handleClose = () => {
    reset();
    handleCloseModal();
  };

  useEffect(() => {
    if (language !== _language) {
      _setLanguage(language);
    }
    if (answerId !== _answerId) {
      _setAnswerId(answerId);
    }
    if (pharmacyId !== _pharmacyId) {
      _setPharmacyId(pharmacyId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerId, pharmacyId, language]);

  useDisableBodyScroll(modalOpen);

  const customStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "405px",
      minHeight: "305px",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.3)",
      padding: "0",
    },
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27) {
      if (modalOpen) {
        handleClose();
      }
    }
    if (e.keyCode === 13) {
      onSubmit(e);
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setAnswerId(_answerId);
    setLanguage(_language);
    setPharmacyId(_pharmacyId);
    handleCloseModal();
  };

  return (
    <Modal
      isOpen={modalOpen}
      shouldCloseOnEsc
      style={customStyles}
      ariaHideApp={false}
      //   shouldCloseOnOverlayClick
      onRequestClose={handleClose}
    >
      <Container>
        <Title>Setup Answer</Title>
        <div style={{ height: "100%" }}>
          <FormContainer onSubmit={onSubmit} onKeyDown={handleKeyDown}>
            <FormLabel>
              Answer ID:
              <FormInput
                type="number"
                name="answer"
                autoFocus
                value={_answerId}
                onChange={(event: any) => _setAnswerId(event.target.value)}
              />
            </FormLabel>
            <FormLabel>
              Pharmacy:
              <FormSelect
                name="pharmacy"
                value={_pharmacyId}
                onChange={(event: any) => _setPharmacyId(event.target.value)}
              >
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
              </FormSelect>
            </FormLabel>
            <FormLabel>
              Language:
              <FormSelect
                name="language"
                value={_language}
                onChange={(event: any) => _setLanguage(event.target.value)}
              >
                <option value={"en"}>en</option>
                <option value={"fr"}>fr</option>
                <option value={"pt"}>pt</option>
              </FormSelect>
            </FormLabel>
            <ButtonContainer>
              <PrimaryButton
                onClick={handleClose}
                label={"Close"}
                color="red"
              />
              <PrimaryButton
                onClick={() => null}
                label={"Submit"}
                type="submit"
              />
            </ButtonContainer>
          </FormContainer>
        </div>

        <LanguagePickerContainer>
          <LanguagePicker />
        </LanguagePickerContainer>
      </Container>
    </Modal>
  );
};

export { SetupAnswerModal };
