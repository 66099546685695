import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoAlertOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { ProductProps } from "../../../../../../api/types";
import { ReactComponent as CapsuleIcon } from "../../../../../../assets/content/capsule.svg";
import { ReactComponent as StarIcon } from "../../../../../../assets/content/star.svg";
import fallbackImage from "../../../../../../assets/jpg/fallbackImage.jpg";
import RoundedProgress from "../../../../../../components/RoundedProgress";
import StandardTooltip from "../../../../../../components/Tooltip";
import { ShoppingCartContext } from "../../../../../../context/shoppingCart";
import { getCurrencyFormat } from "../../../../../../utils/getCurrencyString";
import { getHealthGoalsIcon } from "../../../../../../utils/getHealthGoalsIcon";
import { getImageCompletePath } from "../../../../../../utils/getImageCompletePath";
import { getSymptomsIcon } from "../../../../../../utils/getSymptomsIcon";
import {
  AddProductButton,
  AlertContainer,
  Body,
  BrandName,
  CapsuleContainer,
  Circle,
  Container,
  Footer,
  Header,
  HealthGoalAndSymptomContent,
  HealthGoalAndSymptomScoreProgress,
  HealthGoalAndSymptomTitle,
  HealthGoalsAndSymptomsContainer,
  HealthGoalsAndSymptomsIcon,
  InfosProductContainer,
  Product,
  ProductCompability,
  ProductInfo,
  ProductName,
  StarContainer,
  TitleContainer,
} from "./style";

interface CardProduct {
  product: ProductProps;
}

const InfoProductMobile: React.FC<CardProduct> = ({ product }) => {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const { addOneProduct, isProductAlreadyAdded, handleOpenRemoveProductModal } =
    useContext(ShoppingCartContext);

  const {
    name,
    brand,
    isInPack,
    hasPrecaution,
    hasSynergy,
    healthGoals,
    symptoms,
    capsuleAmount,
    imageUrl,
    price,
    currency,
    score,
  } = product;

  const addToShoppingCart = () => {
    if (isProductAlreadyAdded(product)) {
      handleOpenRemoveProductModal(product);
    } else {
      addOneProduct(product);
    }
  };

  return (
    <Container>
      <Header
        onClick={() => {
          navigation("/product-details", {
            state: product,
            // replace: true,
          });
        }}
      >
        <ProductInfo>
          {isInPack && (
            <StarContainer id={`product-is-in-top-ten-${product.id}`}>
              <StarIcon />
            </StarContainer>
          )}
          {isInPack && (
            <StandardTooltip
              anchor={`product-is-in-top-ten-${product.id}`}
              color="blue"
              text={t("hovers.product_is_recommended")}
              place="bottom"
            />
          )}
          {hasPrecaution && (
            <AlertContainer id={`product-has-alert-${product.id}`}>
              <IoAlertOutline color="white" />
            </AlertContainer>
          )}
          {hasPrecaution && (
            <StandardTooltip
              anchor={`product-has-alert-${product.id}`}
              color="orange"
              text={t("hovers.product_has_alert")}
              place="bottom"
              clickable
            />
          )}
        </ProductInfo>

        <CapsuleContainer>
          <CapsuleIcon />
          <label>{capsuleAmount}</label>
        </CapsuleContainer>
        {/* <Product
          src={getImageCompletePath("product", imageUrl)}
          onError={(e) => (e.currentTarget.src = fallbackImage)}
        /> */}
      </Header>
      <Body
        onClick={() => {
          navigation("/product-details", {
            state: product,
            // replace: true,
          });
        }}
      >
        <InfosProductContainer>
          <TitleContainer>
            <BrandName>{brand}</BrandName>
            <ProductName>{name}</ProductName>
            <ProductCompability value={Math.round(score)}>
              {t("screens.home.tabs.bestProducts.your_compatibility")}
              <span>{`${Math.round(score)}/100`}</span>
            </ProductCompability>
          </TitleContainer>

          <Product
            src={getImageCompletePath("product", imageUrl)}
            onError={(e) => (e.currentTarget.src = fallbackImage)}
          />
        </InfosProductContainer>
        {/* <BrandName>{brand}</BrandName>
        <ProductName>{name}</ProductName>
        <ProductCompability>
          {t("screens.home.tabs.bestProducts.your_compatibility")}
          <span>{`${Math.round(score)}/100`}</span>
        </ProductCompability> */}
        <HealthGoalsAndSymptomsContainer>
          {healthGoals.length > 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <label>
                    {t("screens.home.tabs.bestProducts.health_goals")}
                  </label>
                  <Circle>
                    <label>{healthGoals.length}</label>
                  </Circle>
                </HealthGoalAndSymptomTitle>
                <span>{t(`healthGoals.${healthGoals[0].name}`)}</span>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={healthGoals[0].score}
                  thickness={5}
                  grayForeground
                >
                  <HealthGoalsAndSymptomsIcon>
                    {getHealthGoalsIcon(healthGoals[0].name, 22)}
                  </HealthGoalsAndSymptomsIcon>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
          {symptoms.length > 0 && (
            <HealthGoalAndSymptomContent>
              <div>
                <HealthGoalAndSymptomTitle>
                  <label>{t("screens.home.tabs.bestProducts.symptoms")}</label>
                  <Circle>
                    <label>{symptoms.length}</label>
                  </Circle>
                </HealthGoalAndSymptomTitle>
                <span>{symptoms[0].name}</span>
              </div>
              <HealthGoalAndSymptomScoreProgress>
                <RoundedProgress
                  size={42}
                  progress={symptoms[0].score}
                  thickness={5}
                  grayForeground
                >
                  <HealthGoalsAndSymptomsIcon>
                    {getSymptomsIcon(symptoms[0].categoryId)}
                  </HealthGoalsAndSymptomsIcon>
                </RoundedProgress>
              </HealthGoalAndSymptomScoreProgress>
            </HealthGoalAndSymptomContent>
          )}
        </HealthGoalsAndSymptomsContainer>
      </Body>
      <Footer>
        <div
          onClick={() => {
            navigation("/product-details", {
              state: product,
              // replace: true,
            });
          }}
        >
          <label>{t("screens.home.tabs.bestProducts.learn_more")}</label>
        </div>
        <div>
          <AddProductButton
            onClick={addToShoppingCart}
            added={isProductAlreadyAdded(product)}
          >
            <label>
              {isProductAlreadyAdded(product)
                ? t("screens.home.tabs.bestProducts.in_cart")
                : `${getCurrencyFormat(currency, price)}`}
            </label>

            {isProductAlreadyAdded(product) ? (
              <IoCheckmarkCircleOutline color="white" size={22} />
            ) : (
              <IoIosAddCircleOutline size={22} />
            )}
          </AddProductButton>
        </div>
      </Footer>
    </Container>
  );
};
export default InfoProductMobile;
