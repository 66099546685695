import React from "react";
import { ReactComponent as CapsuleIcon } from "../../../../assets/content/capsule.svg";
import { ReactComponent as StarIcon } from "../../../../assets/content/star.svg";
import fallbackImage from "../../../../assets/jpg/fallbackImage.jpg";

import {
  AlertContainer,
  CapsuleContainer,
  Container,
  Footer,
  Header,
  IconContainer,
  Product,
  ProductContainer,
  StarContainer,
} from "./style";

import { useTranslation } from "react-i18next";
import { IoAlertOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { ProductProps } from "../../../../api/types";
import StandardTooltip from "../../../../components/Tooltip";
import TooltipAlert from "../../../../components/TooltipAlert";
import { getCurrencyFormat } from "../../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../../utils/getImageCompletePath";
import handleImportance from "../../../../utils/getImportance";
interface InfoComumProps {
  product: ProductProps;
  amountProducts: number;
}
const InfoComum: React.FC<InfoComumProps> = ({ product, amountProducts }) => {
  const {
    brand,
    capsuleAmount,
    currency,
    hasPrecaution,
    hasSynergy,
    healthGoals,
    imageUrl,
    isInPack,
    name,
    price,
    score,
    symptoms,
    alerts,
  } = product;

  const { t } = useTranslation();
  const navigation = useNavigate();

  return (
    <Container
      elements={amountProducts}
      onClick={() => {
        navigation("/product-details", {
          state: product,
          // replace: true,
        });
      }}
    >
      <Header>
        <IconContainer>
          <CapsuleContainer>
            <CapsuleIcon />
            <label>{capsuleAmount}</label>
          </CapsuleContainer>
          {isInPack && (
            <StarContainer id={`product-is-in-top-ten-${product.id}`}>
              <StarIcon />
            </StarContainer>
          )}
          {isInPack && (
            <StandardTooltip
              anchor={`product-is-in-top-ten-${product.id}`}
              color="blue"
              text={t("hovers.product_is_recommended")}
              place="bottom"
            />
          )}
          {hasPrecaution && (
            <AlertContainer
              id={`product-has-alert-${product.id}`}
              importance={handleImportance(product.alerts)}
            >
              <IoAlertOutline color="white" />
            </AlertContainer>
          )}
          {hasPrecaution && (
            <TooltipAlert
              anchor={`product-has-alert-${product.id}`}
              color={handleImportance(product.alerts)}
              text={t("hovers.product_has_alert")}
              place="bottom"
            />
          )}
        </IconContainer>
      </Header>
      <ProductContainer>
        <Product
          src={getImageCompletePath("product", imageUrl)}
          onError={(e) => (e.currentTarget.src = fallbackImage)}
        />
      </ProductContainer>
      <Footer>
        <label>{brand}</label>
        <b>{name}</b>
        <span>{getCurrencyFormat(currency, price)}</span>
      </Footer>
    </Container>
  );
};
export default InfoComum;
