import { createRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductProps, SymptomType } from "../../api/types";
import Page from "../../components/Page";
import { HeaderContainer, HeaderLabel } from "../../styles/commomStyles";
import {
  BackButton,
  HealthGoalsContainer,
  SymptomScrollButtonContainer,
} from "./style";

import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CardSimple from "../../components/Cards/Simple";
import Symptom from "../../components/Cards/Sympthon";
import HorizontalScrollView from "../../components/HorizontalScroll";
import ProgressCard from "../../components/ProgressCard";
import ScrollButtons from "../../components/ScrollButtons";
import TabView from "../../components/TabView";
import { getHealthGoalsIcon } from "../../utils/getHealthGoalsIcon";
import { getSymptomsSvg } from "../../utils/getSymptomSvg";
import ProductAlerts from "./components/Alerts";
import { Card } from "./components/Card";
import { SymptomNutrientsModal } from "./components/SymptomNutrientsModal";
import Composition from "./tabs/Composition";
import ProductFieldsOfAction from "./tabs/FieldsOfAction";

export type ProductViewType = {
  state: ProductProps;
};

const ProductDetails = () => {
  const { state: product } = useLocation() as ProductViewType;
  const navigation = useNavigate();
  const sliderRef = createRef<HTMLDivElement>();
  const { t } = useTranslation();

  const [openSymptomModal, setOpenSymptomModal] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState<
    SymptomType | undefined
  >();

  const handleOpenSymptomModal = (symptom: SymptomType) => {
    setSelectedSymptom(symptom);
    setOpenSymptomModal(true);
  };

  const handleCloseSymptomModal = () => {
    setSelectedSymptom(undefined);
    setOpenSymptomModal(false);
  };

  const shouldRenderAlertsAndPrecautions = () => {
    return (
      product.alerts.contraindications.length > 0 ||
      product.alerts.excipientAllergy.length > 0 ||
      product.alerts.excipientIntolerance.length > 0 ||
      product.alerts.drugInteraction.length > 0 ||
      product.alerts.componentsAllergy.length > 0 ||
      product.alerts.componentsIntolerance.length > 0
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RenderHeader = () => (
    <HeaderContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BackButton onClick={() => navigation(-1)}>
          <AiOutlineArrowLeft size={25} />
        </BackButton>
        <HeaderLabel>
          <b>{t("screens.productDetail.best_products")}</b>
        </HeaderLabel>
      </div>
    </HeaderContainer>
  );

  const compositionTabRef = useRef<HTMLDivElement>(null);
  const executeCompositionTabScroll = () => {
    if (compositionTabRef && compositionTabRef.current) {
      compositionTabRef.current.scrollIntoView();
    }
  };

  return (
    <Page screen="" header={RenderHeader}>
      <Card state={product} />
      {shouldRenderAlertsAndPrecautions() && (
        <ProductAlerts alerts={product.alerts} />
      )}
      {product.healthGoals.filter((elem) => elem.score !== 0).length > 0 && (
        <CardSimple title={t("screens.productDetail.health_goals")}>
          <HealthGoalsContainer>
            {product.healthGoals.map((healthGoal, index) => {
              return (
                <ProgressCard
                  key={index}
                  title={healthGoal.name}
                  icon={getHealthGoalsIcon(healthGoal.name, 40)}
                  progress={healthGoal.score}
                  height={10}
                />
              );
            })}
          </HealthGoalsContainer>
        </CardSimple>
      )}
      {product.symptoms.length > 0 && (
        <CardSimple
          title={`${product.symptoms.length} ${t(
            "screens.productDetail.symptoms"
          )}`}
        >
          <SymptomScrollButtonContainer>
            <ScrollButtons ref={sliderRef} length={174} />
          </SymptomScrollButtonContainer>
          <HorizontalScrollView ref={sliderRef} size={180}>
            {product.symptoms.map((symptom, index) => {
              return (
                <Symptom
                  //   warning={index % 2 === 1}
                  key={index}
                  icon={getSymptomsSvg(symptom.categoryId, "white")}
                  progress={symptom.score}
                  title={symptom.name}
                  onClick={() => handleOpenSymptomModal(symptom)}
                  warning={symptom.score < 0}
                />
              );
            })}
          </HorizontalScrollView>
        </CardSimple>
      )}
      <div ref={compositionTabRef} />
      <TabView
        labels={[
          `${t("screens.productDetail.composition")}`,
          `${t("screens.productDetail.fields_of_action")}`,
        ]}
        contents={[
          <Composition
            composition={product.composition}
            otherIngredients={product.otherIngredients}
            excipients={product.excipients}
            executeScroll={executeCompositionTabScroll}
            deliveryMethod={product.deliveryMethod}
          />,
          <ProductFieldsOfAction
            nutritionDeficiency={product.nutritionDeficiency}
            fieldsOfAction={product.fieldsOfAction}
          />,
        ]}
        labelsCounts={[
          product.composition.length,
          product.fieldsOfAction.length,
        ]}
      />

      <SymptomNutrientsModal
        isOpen={openSymptomModal}
        symptom={selectedSymptom}
        onClose={handleCloseSymptomModal}
      />
    </Page>
  );
};

export default ProductDetails;
