import React, { createContext } from "react";
import { useState } from "react";

interface SidebarContextInterface {
	sidebarOpen: boolean;
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarContext = createContext<SidebarContextInterface>({
	setSidebarOpen: () => null,
	sidebarOpen: false,
});

const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	return (
		<SidebarContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
			{children}
		</SidebarContext.Provider>
	);
};
export { SidebarProvider };
