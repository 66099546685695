import { useContext, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { ReactComponent as ShoppingIcon } from "../../assets/content/shopping.svg";
import { ShoppingCartContext } from "../../context/shoppingCart";
import { useScrollDirection } from "../../hooks/Scroll/useScrollDirection";
import RoundedProgress from "../RoundedProgress";
import { SidebarMobile } from "../Sidebar/NavMobile";
import {
  Commom,
  Content,
  HeaderMobileContainer,
  IconContainer,
  LogoContainer,
} from "./styles";

interface HeaderMobileProps {
  headerChildren?: () => JSX.Element;
  logo?: JSX.Element;
  shouldRenderMenuIcon: boolean;
}

const HeaderMobile = ({
  headerChildren,
  logo,
  shouldRenderMenuIcon,
}: HeaderMobileProps) => {
  const { inCartNutritionalProgress, openShoppingCartSidebar } =
    useContext(ShoppingCartContext);
  const [clicked, setCliked] = useState(false);

  function handleClick() {
    setCliked(!clicked);
  }

  const scrollDirection = useScrollDirection();

  return (
    <HeaderMobileContainer scrollDirection={scrollDirection}>
      <Content>
        <SidebarMobile clicked={clicked} setClicked={handleClick} />
        {headerChildren && headerChildren()}
        {shouldRenderMenuIcon && (
          <GiHamburgerMenu color="#fff" size={24} onClick={handleClick} />
        )}
      </Content>
      <Commom>
        {/* <LanguagePicker /> */}
        <RoundedProgress size={42} progress={inCartNutritionalProgress}>
          <IconContainer onClick={openShoppingCartSidebar}>
            <ShoppingIcon />
          </IconContainer>
        </RoundedProgress>
      </Commom>
      <LogoContainer>{logo}</LogoContainer>
    </HeaderMobileContainer>
  );
};
export default HeaderMobile;
