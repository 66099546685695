import React, { ReactElement, createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { processAnswer, processAnswerProd } from "../api";
import { FilterProps, NutrientProps, ProductProps } from "../api/types";
import redirectToQuiz from "../utils/redirectToQuiz";
import { SetupAnswerContext } from "./setupAnswer";
import { useTranslation } from "react-i18next";

interface GlobalContextInterface {
  processingAnswer: boolean;
  waitingAnimation: boolean;
  products: ProductProps[];
  nutrients: NutrientProps[];
  filters: FilterProps;
  essentialPack: ProductProps[];
  optimalPack: ProductProps[];
  premiumPack: ProductProps[];
  essentialPackScore: number;
  optimalPackScore: number;
  premiumPackScore: number;
  setStillProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  defaultCurrency: string;
  amountOfStudies: number;
  userName: string;
  highestPrice: number;
  lowestPrice: number;
  userGender: string
}

export const GlobalContext = createContext<GlobalContextInterface>(
  {} as GlobalContextInterface
);

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [products, setProducts] = useState<any[]>([]);
  const [nutrients, setNutrients] = useState<any[]>([]);
  const [essentialPack, setEssentialPack] = useState<ProductProps[]>([]);
  const [optimalPack, setOptimalPack] = useState<ProductProps[]>([]);
  const [premiumPack, setPremiumPack] = useState<ProductProps[]>([]);
  const [filters, setFilters] = useState<FilterProps>({
    healthGoals: {},
    symptomsAvatar: [],
    symptomsCategory: [],
  });
  const [amountOfStudies, setAmountOfStudies] = useState(0);
  const [userName, setUserName] = useState("");
  const [userGender, setUserGender] = useState<"male" | "female">("male")

  const [defaultCurrency, setDefaultCurrency] = useState("");

  const [essentialPackScore, setEssentialPackScore] = useState<number>(0);
  const [optimalPackScore, setOptimalPackScore] = useState<number>(0);
  const [premiumPackScore, setPremiumPackScore] = useState<number>(0);

  const [processingAnswer, setStillProcessing] = useState(false);
  const [waitingAnimation, setWaitingAnimation] = useState(true);

  // const [searchParams, setSearchParams] = useSearchParams();
  const [searchParams] = useSearchParams();

  const [highestPrice, setHighestPrice] = useState(0);
  const [lowestPrice, setLowestPrice] = useState(0);

  // const { t } = useTranslation();
  const { answerId, pharmacyId, language, token } =
    useContext(SetupAnswerContext);

  const navigation = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setStillProcessing(true);
      console.log("Processing...");

      let _response;

      if (process.env.REACT_APP_MODE === "production") {
        _response = await processAnswerProd(
          answerId,
          pharmacyId,
          language,
          token
        );
      } else {
        _response = await processAnswer(answerId, pharmacyId, language);
      }
      if (_response) {
        // console.log("_response", _response);

        if (_response.bestProducts) {
          setProducts(_response.bestProducts);
          setHighestPrice(
            Math.max(
              ..._response.bestProducts.map((product: any) => product.price)
            )
          );
          setLowestPrice(
            Math.min(
              ..._response.bestProducts.map((product: any) => product.price)
            )
          );
          setDefaultCurrency(_response.bestProducts[0].currency);
        } else {
          redirectToQuiz();
        }
        if (_response.bestNutrients) {
          setNutrients(_response.bestNutrients);
        }
        if (_response.filters) {
          setFilters(_response.filters);
        }

        if (_response.packages) {
          setEssentialPackScore(_response.packages.essentialScore);
          setOptimalPackScore(_response.packages.optimalScore);
          setPremiumPackScore(_response.packages.premiumScore);

          const premiumIds = _response.packages.premiumProductsIds;
          const packagesProducts = _response.bestProducts.filter(
            (product: any) => premiumIds.includes(product.id)
          );
          // @ts-ignore
          setEssentialPack(packagesProducts.slice(0, 2));
          // @ts-ignore
          setOptimalPack(packagesProducts.slice(0, 3));
          // @ts-ignore
          setPremiumPack(packagesProducts.slice(0, 4));
        }

        if (_response.amountOfStudies) {
          setAmountOfStudies(_response.amountOfStudies);
        }

        if (_response.user) {
          setUserName(_response.user.name)
          setUserGender(_response.user.gender)
        }

        setStillProcessing(false);
        navigation("/");
      } else {
        console.log("Não tem response");

        redirectToQuiz();
      }
    }
    if (!!answerId) {
      console.log("Answer ID: ", answerId);

      try {
        if (!processingAnswer) {
          fetchData();
        }
      } catch (err) {
        redirectToQuiz();
      }
    }
  }, [answerId, pharmacyId, language]);

  useEffect(() => {
    let timer: string | any;
    if (process.env.NODE_ENV === "production") {
      timer = setTimeout(() => setWaitingAnimation(false), 9700);
    } else {
      timer = setTimeout(() => setWaitingAnimation(false), 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        products,
        processingAnswer,
        waitingAnimation,
        essentialPack,
        optimalPack,
        premiumPack,
        essentialPackScore,
        optimalPackScore,
        premiumPackScore,
        nutrients,
        setStillProcessing,
        filters,
        defaultCurrency,
        amountOfStudies,
        userName,
        highestPrice,
        lowestPrice,
        userGender
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export { GlobalProvider };
