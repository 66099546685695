import axios from "axios";
import redirectToQuiz from "../utils/redirectToQuiz";
import { RelatedPublicationsProps } from "./types";
// import data from './answerJson.json'
export const api = axios.create({
  // baseURL: `https://test.chatbot.reload.co/api`,
  // baseURL: `http://localhost:5000`,
  baseURL:
    process.env.REACT_APP_MODE === "homologation"
      ? process.env.REACT_APP_API_LINK_HOMOLOGATION
      : process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_LINK_TEST
      : process.env.REACT_APP_API_LINK_PRODUCTION,
  // process.env[
  //   `REACT_APP_API_LINK${
  //     process.env.NODE_ENV === "DEV" ? "TEST" : "PRODUCTION"
  //   }`
  // ],
});

export const processAnswerProd = async (
  answerId?: number,
  pharmacyId?: number,
  language?: string,
  token?: string | null
) => {
  try {
    let response;
    try {
      if (!!answerId && !!pharmacyId && !!token) {
        response = await api.post(
          "kpsule/dashboard/",
          {
            answer_id: answerId,
            farmacy_id: pharmacyId,
            locale: language ?? "en",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        throw new Error("Invalid params");
      }
    } catch (err) {
      redirectToQuiz();
      // window.location.replace(`https://quiz.kpsule.co/`);
    }

    // const responseData = data.data;
    const responseData = response && response.data.data;
    return responseData;
  } catch (err: any) {
    console.error(err.message);
  }
};

export const processAnswer = async (
  answerId?: number,
  pharmacyId?: number,
  language?: string
) => {
  try {
    let response;

    response = await api.post("kpsule/dashboard-recommendation/", {
      answer_id: answerId ?? 1017,
      farmacy_id: pharmacyId ?? 10,
      locale: language ?? "en",
    });

    // const responseData = data.data;
    const responseData = response && response.data.data;
    return responseData;
  } catch (err: any) {
    console.error(err.message);
  }
};

export const getRelatedStudies = async (
  studiesID: number[],
  language: string
) => {
  try {
    const response = await api.post("kpsule/get-studies-data/", {
      studiesID,
      language,
    });

    const responseData = response && response.data.data;

    return responseData as RelatedPublicationsProps[];
  } catch (err: any) {
    console.error(err.message);
  }
};

