import styled from "styled-components";
import scrollbar from "../../../../styles/scrollbar";

export const ContainerSlider = styled.div`
  /* width: 100%; 
height: 100%; */
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: #eff2fa;
  border-radius: 12px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
`;

interface BannerProps {
  img: string;
}

export const BannerWrapper = styled.div<BannerProps>`
  width: 100%;
  height: 330px;
  background: ${({ img }) => img && `url(${img}) no-repeat center`};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.5s;
  position: relative;
`;

export const BackgroundGradientBanner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(57.75deg, #3136a0 15.85%, #2cd091 80.62%);
  /* opacity: 0.9; */
  mix-blend-mode: multiply;
`;

export const TitleBanner = styled.h1`
  width: 275px;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  // line-height: 38px;
  margin: 0 auto;
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  color: #fff;

  span {
    font-weight: 700;
    /* color: #21E7C5; */
  }
`;

export const CircularProgressContainer = styled.div`
  width: 102px;
  height: 102px;

  left: 0;
  right: 0;
  margin: 0 auto;
  top: 45%;
  position: absolute;

  // border: 2px solid green;
`;

export const ContainerPackProductsLength = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  margin: 0 auto;
  bottom: 9%;
  left: 0;
  right: 0;
`;

export const PackProductCount = styled.span`
  width: 28px;
  height: 28px;
  color: #21e7c5;
  background-color: #fff;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitlePackProductCount = styled.h2`
  font-size: 18px;
  color: #fff;
`;

export const ContainerCardsPacks = styled.div`
  /* width: 100%;  */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #E6E7EC; */
  border-radius: 0 12px 12px 0;
`;

export const HeaderPacks = styled.div`
  width: 100%;
  height: 70px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px 0 24px;
  border-radius: 0 12px 0 0;
`;

export const TitleHeaderPacks = styled.h1`
  font-size: 18px;
`;

export const CardsContainer = styled.div`
  width: 100%;
  /* padding: 0 17px 8px; */
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-x: scroll;

  overflow: overlay;

  ${scrollbar}
  padding-left: 8px;

  @media screen and (max-width: 1024px) {
    width: 100%;

    align-items: stretch;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    height: 100%;
    overflow-x: scroll;

    justify-content: flex-start;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }
`;

export const SeeAllLink = styled.span`
  width: 100px;
  font-size: 18px;
  text-align: right;
  padding: 10px 0 10px 10px;
  color: ${({ theme }) => theme.colors.selected};
  transition: 0.3s;
  cursor: pointer;
`;
