import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flexdirection: row;
  gap: 12px;
`;

export const ArrowButton = styled.button<{ disabled: boolean }>`
  padding: 6px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background: #21e7c520;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;

    :hover {
      background: #00000000;
    }
  `}
`;
