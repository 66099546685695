import styled from "styled-components";

interface CardPacksProps {
  selected: boolean;
}

export const CardPacksContainer = styled.div<CardPacksProps>`
  width: 40%;
  height: 7.5625rem;
  background: ${(props) => (props.selected ? "#21E7C5" : "#fff")};
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    box-shadow: ${({ selected }) => (selected ? "" : "0px 6px 6px #C6C9D6")};
  }

  svg path {
    fill: ${(props) => (props.selected ? "#fff" : "#026ffd")};
  }

  span {
    font-size: 1.2rem;
    color: ${(props) => (props.selected ? "#fff" : "#545976")};
  }

  @media screen and (max-width: 1187px) {
    justify-items: center;
    flex-direction: column;
    height: 11.5rem;
    text-align: center;

    gap: 1rem;

    span {
      width: 100px;
    }
  }

  @media screen and (max-width: 600px) {
    width: 121px;
    box-shadow: ${({ selected }) => (selected ? "" : "0px 6px 6px #C6C9D6")};
  }

  @media screen and (max-width: 390px) {
    width: 110px;
    height: 11rem;
  }

  @media screen and (max-width: 320px) {
    width: 90px;
    height: 11rem;
  }
`;
