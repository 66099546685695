import React from "react";
import { useTranslation } from "react-i18next";
import { ProductNutritionDeficiencyType } from "../../../../../../api/types";
import RoundedProgress from "../../../../../../components/RoundedProgress";
import {
  CompositionField,
  Container,
  Content,
  NutrientName,
  TextContent,
} from "./styles";

type NutrientDeficiencyCardProps = {
  nutrientDeficiency: ProductNutritionDeficiencyType;
};

// #EDD79F
// #ED9F9F

const NutrientDeficiencyCard: React.FC<NutrientDeficiencyCardProps> = ({
  nutrientDeficiency,
}) => {
  const { name, score } = nutrientDeficiency;
  const { t } = useTranslation();

  const getColor = () => {
    if (score < 50) {
      return {
        color1: "#EDD79F",
        color2: "#ED9F9F",
      };
    }
  };

  return (
    <Container>
      <Content>
        <RoundedProgress
          size={100}
          progress={score}
          linear={getColor()}
          grayForeground
        >
          <CompositionField>
            <label>{`${score}%`}</label>
          </CompositionField>
        </RoundedProgress>
        <NutrientName>{name}</NutrientName>
      </Content>
      <TextContent>
        <span>
          {t("screens.productDetail.your_diet_supply", {
            score,
            name,
          })}
        </span>
      </TextContent>
    </Container>
  );
};

export default NutrientDeficiencyCard;
