import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";

type GenericProps = FlattenInterpolation<ThemeProps<DefaultTheme>>;

const cardProps = css`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1.5rem;
  padding: 2rem;
  box-shadow: 0px 2px 8px rgba(55, 84, 102, 0.15);
`;

const shadow = css`
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
`;

const lightShadow = css`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
`;

const blueGradient = css`
  background: linear-gradient(165.98deg, #3135c6 14.08%, #00aaff 89.86%);
`;

const blueLightGradient = css`
  background: linear-gradient(
    0.23deg,
    rgba(46, 255, 175, 0) 1.72%,
    #00aaff 94.31%
  );
`;

const redGradient = css`
  background: linear-gradient(33.35deg, #D24848 14.59%, #FD8787 79%);
`;

const orangeGradient = css`
  background: linear-gradient(228.69deg, #F68E61 24.77%, #FF6737 86.3%);
`;

const yellowGradient = css`
  background: linear-gradient(0deg, #FF9922 14.67%, rgba(255, 198, 113, 0.97) 90.76%);
`;

export interface Theme {
  colors: {
    blueLight: string;
    background: string;
    selected: string;
    primary: string;
    white: string;
    black: string;
    gray: string;
    grayLightBorder: string;
    grayBgButton: string;
    blue: string;
    midBlue: string;
    grayLight: string;
    lightRed: string;
    darkRed: string;
  };
  shadow: GenericProps;
  lightShadow: GenericProps;
  cardProps: GenericProps;
  blueGradient: GenericProps;
  yellowGradient: GenericProps;
  orangeGradient: GenericProps;
  redGradient: GenericProps;
  blueLightGradient: GenericProps;
}

const defaultTheme: Theme = {
  colors: {
    blueLight: "#0aadff",
    background: "#F5F7FE",
    selected: "#21E7C5",
    blue: "#205dda",
    primary: "#006341",
    white: "#ffffff",
    black: "#545976 ",
    gray: "#545976",
    grayLight: "#AAADBB",
    grayBgButton: "#E6E7EC;",
    grayLightBorder: "#DCDFEE",
    midBlue: "#2D9CDB",
    lightRed: "#ED9F9F",
    darkRed: "#E26161",
  },
  blueGradient,
  shadow,
  lightShadow,
  cardProps,
  blueLightGradient,
  yellowGradient,
  orangeGradient,
  redGradient,
};

export default defaultTheme;
