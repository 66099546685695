import { Tab, TabList, Tabs } from "react-tabs";
import styled from "styled-components";

export const TabsContainer = styled(Tabs)<{withOutGapAndHeight: boolean}>`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 350px;

  ${({ withOutGapAndHeight }) => 
    withOutGapAndHeight &&
  `
    gap: 5px;
    height: 250px;
  `}

  .horizontal-scroll {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
    }
  }
`;

export const TabListContent = styled(TabList)`
  width: 100%;
  list-style-type: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
`;

export const UnorderedTab = styled(Tab) <{ selected: boolean, hasLabel: boolean }>`
  display:  ${({ hasLabel }) =>
    hasLabel ? "block" : "none"};
  font-size: 24px;
  color: ${({ theme, selected, hasLabel }) =>
    selected && hasLabel ? theme.colors.selected : theme.colors.black};
  font-weight: bold;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: color 360ms;
  padding-right: 38.5px;
  :not(:first-child) {
    ${({ hasLabel }) =>
    hasLabel ?
      `
      padding-left: 0px;
      padding-right: 0px;
    ` : `
      padding-left: 38.5px;
      padding-right: 38.5px;
    `};
    /* padding-left: 38.5px;
    padding-right: 38.5px; */
  }
`;

export const FlagContainer = styled.div`
  position: relative;

  > label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 7px;
  }

  span {
    height: 24px;
  }
`;

export const Flag = styled.div<{ show: boolean }>`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 5px;
  position: absolute;
  margin-top: 12px;
  left: 0;
  right: 0;
  transition: opacity 360ms;
  ${({ show }) => (show ? `opacity: 1` : `opacity: 0`)};
  background-color: ${({ theme }) => theme.colors.selected};
`;

export const IconContainer = styled.div<{ selected: boolean }>`
  width: 24px;
  height: 24px;

  svg {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  svg path {
    fill: ${({ theme, selected }) =>
    selected ? theme.colors.selected : theme.colors.black};
  }
`;

export const Circle = styled.div<{ value: number }>`
  width: ${({ value }) => (value <= 99 ? "25px" : value >= 1000 ? "45px" : "35px")};
  height: 25px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #2effaf 15.34%, #00aaff 98.15%);

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;
