import styled from "styled-components";
import scrollbar from "../../../../../../../../styles/scrollbar";

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid red;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  width: 100%;
  padding: 32px 10px 20px 15px;

  // border: 2px solid red;

  -webkit-box-shadow: 0px 3px 18px 1px #dbdbdb;
  box-shadow: 0px 3px 18px 1px #dbdbdb;

  position: relative;
  z-index: 50;

  .icon-close {
    transition: all 0.3s ease-out;
    cursor: pointer;
    // border: 2px solid red;
  }
`;

export const Title = styled.h2`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};
  // border: 2px solid red;

  @media screen and (max-width: 428px) {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 26px;
  background-color: #f5f7fe;
  padding-bottom: 15px;
  /* padding: 20px 15px 15px; */
  /* overflow-y: scroll;
  max-height: 60vh; */

  @media screen and (max-width: 600px) {
    padding: 8px;
    padding-right: 5px;
  }

  ${scrollbar}// border: 2px solid red;
`;

export const Footer = styled.div`
  height: 32px;
  width: 100%;

  -webkit-box-shadow: 0px -3px 18px 1px #dbdbdb;
  box-shadow: 0px -3px 18px 1px #dbdbdb;

  position: relative;
  z-index: 50;
`;
