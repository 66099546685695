import styled from "styled-components";

export const SymptomsCollapse = styled.div`
  // border: 2px solid green;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SymptomsCollapseTitle = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: #6a6a6a;
`;

export const SymptomsProgressContainer = styled.div`
  top: 5px;
  //   transform: translate(0, -50%);
  //   bottom: 50%;
  right: 0;
  border-bottom-width: thin;
`;

export const SymptomMoreDetails = styled.div`
  //   border: 2px solid green;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  //   justify-content: center;
  align-items: center;
  > svg {
    height: 25px;
    width: 25px;
    // border: 2px solid orange;
  }
  > label {
    margin-right: 12px;
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    // line-height: 39px;
    color: ${({ theme }) => theme.colors.selected};
    // border: 2px solid red;
  }
`;

export const NutrientHeader = styled.div`
  display: flex;
  //   border: 2px solid red;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`;

export const NutrientName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: #6a6a6a;
`;

export const CompositionField = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 2px solid orange;

  > label {
    width: 80%;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }
`;

export const NutrientContent = styled.div`
  margin-bottom: 8px;
`;
