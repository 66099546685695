import { useState } from "react";
import { ReactComponent as TrashIcon } from "../../../assets/icons/svg/trashCan.svg";
import { TrashButtonContainer } from "./style";

interface TrashButtonProps {
  onClick: () => void;
  disabled?: boolean;
  label?: string;
}

const TrashButton = ({
  onClick,
  disabled = false,
  label,
}: TrashButtonProps) => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    if (!active) {
      setActive(true);
    } else {
      setActive(false);
      onClick();
    }
  };

  const handleOnBlur = () => {
    setActive(false);
  };

  return (
    <TrashButtonContainer
      active={active}
      onClick={handleClick}
      onBlur={handleOnBlur}
      disabled={disabled}
    >
      {label && <label>{label}</label>}
      <TrashIcon />
    </TrashButtonContainer>
  );
};

export { TrashButton };
