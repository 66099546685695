import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { IoChevronForwardSharp, IoClose } from "react-icons/io5";
import { ProductProps } from "../../api/types";
import { ReactComponent as IconFilter } from "../../assets/filter-icon.svg";
import logoMobile from "../../assets/logo-mobile.svg";
import Loading from "../../components/Loading";
import Page from "../../components/Page";
import { BackDrop } from "../../components/Sidebar/FilterSidebar/styles";
import { FilterContext } from "../../context/filter";
import { HeaderContainer } from "../../styles/commomStyles";
import { useDebounce } from "../../utils/hooks/useDebounce";
import replaceAccentedCharacters from "../../utils/replaceAccentedCharacters";
import InfoProductMobile from "./components/CardProductsFilteredMobile/InfoComumMobile/InfoProductMobile";

import {
  Container,
  FilterButton,
  FilterButtonMobile,
  FilterButtonSearchContainer,
  FilteredContainerMobile,
  FilterTag,
  SearchContainerMobile,
  SearchInputMobile,
  SubHeaderMobile,
  Title,
} from "./style";

export default function ProductsMobile() {
  const {
    avatarSymptoms,
    categorySymptoms,
    handleOpenSidebar,
    categorySymptomsIndexSelected,
    setCategorySymptomsIndexSelected,
    avatarSymptomsIndexSelected,
    setAvatarSymptomsIndexsSelected,
    healthGoals,
    healthGoalsIndexSelected,
    setHealthGoalsIndexSelected,
    clearFilters,
    loadingFilter,
    productsSearchInput,
    setProductsSearchInput,
  } = useContext(FilterContext);

  const { t } = useTranslation();

  // useEffect(() => {
  //   return () => clearFilters();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const searchInput = useRef<HTMLInputElement>(null);

  const { filteredProducts } = useContext(FilterContext);
  const [isOpenedSearch, setIsOpenedSearch] = useState(false);
  const [inResearch, setInResearch] = useState(false);

  const debouncedSearch = useDebounce(productsSearchInput, 200);

  function handleClickedSearch() {
    setIsOpenedSearch(true);
    const focus = () => searchInput?.current?.focus();
    focus();
  }

  function handleUnclickedSearch() {
    setIsOpenedSearch(false);

    if (productsSearchInput.length === 0) {
      setInResearch(false);
      return;
    }
    setInResearch(true);
  }

  const filterBySearchInput = (product: ProductProps) => {
    return (
      replaceAccentedCharacters(product.name)
        .toLowerCase()
        .includes(replaceAccentedCharacters(debouncedSearch.toLowerCase())) ||
      replaceAccentedCharacters(product.brand)
        .toLowerCase()
        .includes(replaceAccentedCharacters(debouncedSearch.toLowerCase()))
    );
  };

  const RenderHeaderLogo = () => (
    <HeaderContainer>
      <img src={logoMobile} alt="" />
    </HeaderContainer>
  );

  return (
    <Page
      screen="home"
      logo={RenderHeaderLogo()}
      // onClick={handleUnclickedSearch}
    >
      <SubHeaderMobile>
        <Title>
          <label>
            <b>{t("screens.home.best_products")} </b>
          </label>
          <label className="base-products">
            {t("screens.products.from_a_base")}
            <span>
              {t("screens.products.amount_products", {
                count: filteredProducts.length,
              })}
            </span>
          </label>
        </Title>

        <FilterButtonSearchContainer>
          <FilterButtonMobile
            onClick={handleOpenSidebar}
            isOpenedSearch={isOpenedSearch}
            inResearch={inResearch}
          >
            {/* <IconFilter className="icon-filter" /> */}
          </FilterButtonMobile>
          <SearchContainerMobile
            isOpenedSearch={isOpenedSearch}
            inResearch={inResearch}
          >
            <AiOutlineSearch onClick={handleClickedSearch} size={50} />
            <SearchInputMobile
              ref={searchInput}
              value={productsSearchInput}
              onClick={handleClickedSearch}
              onChange={(e) => {
                setProductsSearchInput(e.target.value);
              }}
              // placeholder={t("screens.products.search_product")}
              name="searchInput"
            />
          </SearchContainerMobile>
        </FilterButtonSearchContainer>
      </SubHeaderMobile>

      <FilteredContainerMobile>
        <FilterButton onClick={handleOpenSidebar}>
          <IconFilter className="icon-filter" />
          <span>{t("screens.nutritionalNeeds.filter")}</span>
          <IoChevronForwardSharp size={15} />
        </FilterButton>

        {avatarSymptomsIndexSelected.map((value) => {
          return (
            <FilterTag className="filterTag">
              <span>{avatarSymptoms[value].name}</span>
              <IoClose
                size={15}
                fill={"#545976"}
                className="icon-close"
                onClick={() => {
                  setAvatarSymptomsIndexsSelected((prev) =>
                    prev.filter((elem) => elem !== value)
                  );
                }}
              />
            </FilterTag>
          );
        })}

        {categorySymptomsIndexSelected.map((value) => {
          return (
            <FilterTag className="filterTag">
              <span>{categorySymptoms[value].name}</span>
              <IoClose
                size={15}
                fill={"#545976"}
                className="icon-close"
                onClick={() => {
                  setCategorySymptomsIndexSelected((prev) =>
                    prev.filter((elem) => elem !== value)
                  );
                }}
              />
            </FilterTag>
          );
        })}

        {healthGoalsIndexSelected.map((value) => {
          return (
            <FilterTag className="filterTag">
              <span>{t(`healthGoals.${Object.keys(healthGoals)[value]}`)}</span>
              <IoClose
                size={15}
                fill={"#545976"}
                className="icon-close"
                onClick={() => {
                  setHealthGoalsIndexSelected((prev) =>
                    prev.filter((elem) => elem !== value)
                  );
                }}
              />
            </FilterTag>
          );
        })}
      </FilteredContainerMobile>

      {!loadingFilter && filteredProducts ? (
        <Container>
          {filteredProducts.filter(filterBySearchInput).map((product, key) => (
            <InfoProductMobile product={{ ...product }} key={key} />
          ))}
        </Container>
      ) : (
        <div
          style={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      )}

      {/* {filteredProducts && (
          
        )} */}
      <BackDrop
        isSidebarOpen={isOpenedSearch}
        onClick={handleUnclickedSearch}
      />
    </Page>
  );
}
