import styled from "styled-components";

interface ContentProps {
  selected: boolean;
}

// interface ProgressProps {
//   linear?: {
//     color1: string;
//     color2: string;
//   };
//   progress: number;
//   size: number;
//   gray?: boolean;
//   thickness: number;
// }>

export const Progress = styled.div<{
  linear?: {
    color1: string;
    color2: string;
  };
  progress: number;
  size: number;
  gray?: boolean;
  thickness: number;
}>`
  display: flex;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;

  justify-content: center;
  align-items: center;

  background: ${({ linear, progress, gray }) =>
    linear
      ? `
		conic-gradient(
			${linear.color1},
			${linear.color2} ${progress}deg,
			${gray ? "rgba(0,0,0,0.1)" : "transparent"} ${progress}deg,
			${gray ? "rgba(0,0,0,0.1)" : "transparent"}
		);
		`
      : `conic-gradient(
				#2effaf,
				#00aaff ${progress}deg,
				${gray ? "rgba(0,0,0,0.1)" : "transparent"} ${progress}deg,
				${gray ? "rgba(0,0,0,0.1)" : "transparent"}
			);`};
  font-size: 0;
  > div {
    width: ${({ size, thickness }) => size - thickness}px;
    height: ${({ size, thickness }) => size - thickness}px;
  }
`;

export const Content = styled.div<{
  thickness: number;
  backgroundColor: string;
}>`
  margin: ${({ thickness }) => thickness / 2}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 2px solid red;
`;
