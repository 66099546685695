export type Enviroments = "production" | "homologation" | "development";

const redirectToQuiz = () => {
  const env = process.env.REACT_APP_MODE;
  
  if (!window.location.href.includes("/validate-product-list")) {
    if (env === "production") {
      // window.location.replace("https://quiz.kpsule.co/");
      window.location.replace("https://quiz-medi-market.kpsule.co/");
    } else if (env === "homologation") {
      window.location.replace("https://quiz-medi-market.kpsule.co/");
    } else {
      alert("Calling redirectToQuiz()");
    }
  }
};

export default redirectToQuiz;
