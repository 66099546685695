import styled from "styled-components";

export const Container = styled.div`
  width: 97px;
  /* min-width: 123px; */
  height: 123px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.lightShadow}
  padding: 55px;
  margin-right: 8px;
  //   border: 2px solid black;
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-out;
  // border: 2px solid red;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const CompositionField = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 2px solid orange;

  > label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    // line-height: 39px;
    width: 80%;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    // border: 2px solid blue;
  }
`;

export const NutrientName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 39px;
  text-align: center;
`;

// export const TextContent = styled.div`
//   display: flex;
//   height: 100%;
//   justify-content: center;
//   align-items: center;
//   flex: auto;
//   padding: 10px;

//   > span {
//     text-align: center;
//     font-family: "Roboto";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 18px;
//     color: ${({ theme }) => theme.colors.gray};
//   }
// `;
