import { ChangeEvent, useContext } from "react";
import { IoAlertOutline, IoCalendarOutline } from "react-icons/io5";
import { useTheme } from "styled-components";
import { ReactComponent as CapsuleIcon } from "../../../../assets/content/capsule.svg";
import { ReactComponent as StarIcon } from "../../../../assets/content/star.svg";
import { ReactComponent as SunriseIcon } from "../../../../assets/content/sunrise.svg";
import { ReactComponent as CapsuleIntakeIcon } from "../../../../assets/icons/svg/capsuleIcon.svg";
import fallbackImage from "../../../../assets/jpg/fallbackImage.jpg";
import { ShoppingCartContext } from "../../../../context/shoppingCart";
import { getCurrencyFormat } from "../../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../../utils/getImageCompletePath";

import { useTranslation } from "react-i18next";
import { ProductProps } from "../../../../api/types";
import { MoreOrLessInput } from "../../../../components/Input/MoreOrLess";
import StandardTooltip from "../../../../components/Tooltip";
import {
  AlertContainer,
  BrandName,
  CapsuleContainer,
  CardContainer,
  CardContent,
  CardHeader,
  Compatibility,
  ImageContainer,
  InfoContainer,
  MedicineTimeContainer,
  MedicineWrapper,
  PriceContainer,
  PriceText,
  ProductIconsContainer,
  ProductName,
  ProductsButtonsContainer,
  StarContainer,
  TitleContainer,
} from "./style";

interface CardMobileProps {
  amountToBuy: number;
  productDurationInDays: number;
  handleOnClickMore: () => void;
  handleOnClickLess: () => void;
  handleOnChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  product: ProductProps;
}

export function CardMobile({
  product,
  handleOnClickMore,
  handleOnClickLess,
  handleOnChangeInput,
  productDurationInDays,
  amountToBuy,
}: CardMobileProps) {
  // const [amountToBuy, setAmountToBuy] = useState(1);
  const {
    addProduct,
    isProductAlreadyAdded,
    handleOpenRemoveProductModal,
    getAmountOfProduct,
  } = useContext(ShoppingCartContext);

  const theme = useTheme();

  const isInCart = () => isProductAlreadyAdded(product);
  const { t } = useTranslation();
  // const [productDuration, setProductDuration] = useState(0);

  // useEffect(() => {
  //   setProductDuration(
  //     ((product.capsuleAmount / product.capsuleDosage) * amountToBuy) / 30
  //   );
  // }, [amountToBuy]);

  const getDuration = () => {
    let months = Math.floor(productDurationInDays / 30);
    let weeks = Math.floor(productDurationInDays / 7.5) - months * 4;

    if (weeks >= 4) {
      weeks -= 4;
      months += 1;
    }

    if (months > 0 && weeks > 0) {
      return `${months} ${
        months > 1
          ? t("screens.productDetail.months")
          : t("screens.productDetail.month")
      } ${t("screens.productDetail.and")} ${weeks} ${
        weeks > 1
          ? t("screens.productDetail.weeks")
          : t("screens.productDetail.week")
      }`;
    } else if (months > 0) {
      return `${months} ${
        months > 1
          ? t("screens.productDetail.months")
          : t("screens.productDetail.month")
      }`;
    } else if (weeks > 0) {
      return `${weeks} ${
        weeks > 1
          ? t("screens.productDetail.weeks")
          : t("screens.productDetail.week")
      }`;
    }
  };

  const {
    brand,
    name,
    score,
    capsuleAmount,
    capsuleDosage,
    currency,
    deliveryMethod, // TODO use this to change the icon
    hasPrecaution,
    hasSynergy,
    isInPack,
    imageUrl,
    intakeSuggestion,
    price,
  } = product;

  const hasDosageInfo = () => {
    return !!capsuleDosage && !!capsuleAmount;
  };

  return (
    <CardContainer>
      <CardContent>
        <CardHeader>
          <ImageContainer>
            <img
              src={getImageCompletePath("product", imageUrl)}
              alt="product"
              onError={(e) => (e.currentTarget.src = fallbackImage)}
            />
          </ImageContainer>

          <ProductIconsContainer>
            {isInPack && (
              <StarContainer id={`product-is-in-top-ten-${product.id}`}>
                <StarIcon />
              </StarContainer>
            )}
            {isInPack && (
              <StandardTooltip
                anchor={`product-is-in-top-ten-${product.id}`}
                color="blue"
                text={t("hovers.product_is_recommended")}
                place="bottom"
                clickable
              />
            )}
            {hasPrecaution && (
              <AlertContainer id={`product-has-alert-${product.id}`}>
                <IoAlertOutline color="white" />
              </AlertContainer>
            )}
            {hasPrecaution && (
              <StandardTooltip
                anchor={`product-has-alert-${product.id}`}
                color="orange"
                text={t("hovers.product_has_alert")}
                place="bottom"
                clickable
              />
            )}

            <CapsuleContainer>
              <CapsuleIcon />
              <label>{capsuleAmount}</label>
            </CapsuleContainer>
          </ProductIconsContainer>
        </CardHeader>
      </CardContent>

      <TitleContainer>
        <BrandName>{brand}</BrandName>
        <ProductName>{name}</ProductName>
        <Compatibility value={Math.round(score)}>
          {t("screens.productDetail.your_compatibility")}
          <span>{`${Math.round(score)}/100`}</span>
        </Compatibility>
      </TitleContainer>

      <MedicineWrapper>
        <MedicineTimeContainer>
          {!!intakeSuggestion && (
            <div>
              <SunriseIcon />
              <label>{intakeSuggestion.en_text}</label>
            </div>
          )}

          {hasDosageInfo() && (
            <div>
              <CapsuleIntakeIcon />

              <label>
                {capsuleDosage > 1
                  ? t("screens.productDetail.capsules_per_day", {
                      count: capsuleDosage,
                    })
                  : t("screens.productDetail.capsule_per_day", {
                      count: capsuleDosage,
                    })}
              </label>
            </div>
          )}
        </MedicineTimeContainer>

        {hasDosageInfo() && (
          <MedicineTimeContainer>
            <InfoContainer>
              <IoCalendarOutline />
              <label>{`Duration: ${getDuration()}`}</label>
            </InfoContainer>
          </MedicineTimeContainer>
        )}
      </MedicineWrapper>

      <PriceContainer>
        <ProductsButtonsContainer>
          <MoreOrLessInput
            actualValue={amountToBuy}
            onChangeInput={handleOnChangeInput}
            onClickLess={handleOnClickLess}
            onClickMore={handleOnClickMore}
          />
        </ProductsButtonsContainer>

        <PriceText>{`${getCurrencyFormat(
          currency,
          amountToBuy * price
        )}`}</PriceText>
        {/* 
          <HoverButton
            isActive={isInCart()}
            label={
              isInCart()
                ? t("screens.productDetail.in_cart")
                : `${t("screens.productDetail.add_to_cart")}:  ${
                    amountToBuy - getAmountOfProduct(product)
                  }`
            }
            onClick={addProductInShoppingCart}
          /> */}
      </PriceContainer>
    </CardContainer>
  );
}

// export default CardMobile;

{
  /* <PriceContainer>
<ProductsButtonsContainer>
  <LabelButton>
    <button
      onClick={() => {
        amountToBuy > 1 && setAmountToBuy(amountToBuy - 1);
      }}
    >
      <IoIosRemove size={20} color={theme.colors.gray} />
    </button>
    <ToogleNumber
      type="number"
      value={amountToBuy}
      onChange={(e) => {
        setAmountToBuy(Number(e.target.value));
      }}
      disabled
    />
    <button
      onClick={() => {
        setAmountToBuy(amountToBuy + 1);
      }}
    >
      <IoIosAdd size={20} color={theme.colors.gray} />
    </button>
  </LabelButton>
  <LabelButton>
    <SelectMonth>
      <option value="1">1 month</option>
      <option value="1">2 months</option>
      <option value="1">3 months</option>
      <option value="1">4 months</option>
    </SelectMonth>
  </LabelButton>
</ProductsButtonsContainer>

<PriceText>{`${getCurrencyFormat(currency, price)}`}</PriceText>

</PriceContainer> */
}
