import styled from "styled-components";

export const BackButton = styled.button`
  display: flex;
  margin-right: 1rem;
`;

export const Vertical = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  > div {
    margin-top: 1rem;
  }
`;

export const SymptomScrollButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 24px;
`;

export const Title = styled.label`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
`;

interface ExcipientCardProps {
  excipientAllergies?: boolean;
  excipientIntolerances?: boolean;
  color1?: string;
  color2?: string;
  importance: number;
}


export const ExcipientCard = styled.div<ExcipientCardProps>`

svg {
  & path, ellipse {
      ${({excipientIntolerances, excipientAllergies}) => excipientAllergies ? `fill: url(#linear-red);` : (excipientIntolerances && excipientAllergies === false) ? `fill: url(#linear-orange);` : `fill: url(#linear-gradient);`}
      
    }
  /* stop:first-child {
    stop-color: ${props => props.color1};
  }

  stop:last-child {
    stop-color: ${props => props.color2};
  } */
}
  padding: 0.1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 230px;
  background-image: linear-gradient(white, white), 
  radial-gradient(circle at top left, ${props => props.color1}, ${props => props.color2});
  border-radius: 8px;
  background-origin: border-box;
  background-clip: content-box, border-box;

  /* border: 2px solid
    ${({ importance }) =>
      importance < 2
        ? "#EDCE9F"
        : importance === 2
        ? "rgba(255, 164, 113, 0.97)"
        : "rgba(237, 159, 159, 1)"}; */

  > div {
    /* border-radius: 50%; */
    padding: 1.4rem 0.1rem 1.4rem 3.4rem;
    // width: 32px;
    // height: 32px;
    /* border: 1px solid
      ${({ importance }) =>
        importance < 2
          ? "#EDCE9F"
          : importance === 2
          ? "rgba(255, 164, 113, 0.97)"
          : "rgba(237, 159, 159, 1)"}; */
    position: relative;
    > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 50px;
      width: 50px;
    }
  }

  > label {
    margin-left: 12px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    padding: 1.4rem 1.4rem 1.4rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }
`;

export const GapDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 100%;
`;

export const ContainerScrollBotton = styled.div`
  margin-top: -55px;
`;

export const ExcipientAlertIcon = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 18px;
    height: 100%;
  }
`;

export const HorizontalScrollContainer = styled.div`
  width: 100%;
`;

export const HealthGoalsContainer = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  gap: 6px;
`;
