import styled, { css } from "styled-components";
import { getColorByQuartile } from "../../../../components/Cards/Nutrient/style";

interface ContainerProps {
  elements?: number;
}

const iconBox = css`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 13.5px;
    width: 13.5px;
  }
`;

export const Container = styled.div<ContainerProps>`
  max-width: 343px;
  // min-width: 300px;
  // border: 2px solid red;
  padding: 12px;
  // max-width: ;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  * {
    cursor: pointer;
  }
`;

export const Nutrient = styled.img`
  width: 100px;
  height: 100px;
  image-rendering: auto;
`;

export const ScrollContainer = styled.div`
  height: 350px;
  width: auto;
  > .scroll-horizontal {
    overflow-x: scroll !important;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  position: relative;

  > img {
    max-width: 100px;
  }
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;

export const AlertContainer = styled.div.attrs(
  (props: { isInPack: boolean }) => props
)`
  ${iconBox}
  ${({ theme }) => theme.redGradient}
`;

export const NutrientInfo = styled.div`
  display: flex;
  gap: 6px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -20px;
`;

export const InfosNutrientContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    margin-right: 10px;
  }
`;

export const TitleContainer = styled.div`
  width: 70%;
`;

export const NutrientName = styled.label`
  font-size: 18px;
  margin-top: 4px;
  margin-bottom: 6px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  width: 250px;
  display: flex;
  justify-content: center;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  > span {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const NutrientCompability = styled.span<{ value: number }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.black};
  > span {
    margin-left: 6px;
    color: ${({ theme, value }) => getColorByQuartile(value, theme)};
    font-weight: bold;
  }
`;

export const HealthGoalAndSymptomTitle = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const HealthGoalAndSymptomContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  :not(:first-child) {
    margin-top: 16px;
  }

  > div {
    justify-content: center;
    display: flex;
    flex-direction: column;

    > span {
      margin-top: 6px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 12px;

      color: ${({ theme }) => theme.colors.gray};

      opacity: 0.6;
    }
  }
`;

export const HealthGoalsAndSymptomsContainer = styled.div`
  margin-top: 12px;
  background-color: #f5f7fe;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
`;

export const HealthGoalAndSymptomScoreProgress = styled.div``;

export const HealthGoalsAndSymptomsIcon = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: auto;
  height: 100%;

  > * {
    width: 20px;
    height: auto;
  }
`;

export const Circle = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 8px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180.32deg, #2effaf 15.34%, #00aaff 98.15%);

  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    // line-height: 39px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 4px;
  margin: 16px 0 24px;

  > div {
    width: 50%;
    display: flex;
    align-items: center;
    > label {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.selected};
      cursor: pointer;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;

      :hover {
        text-decoration: underline;
      }
    }

    > button {
      width: 100%;
      border-radius: 16px;
      background: ${({ theme }) => theme.colors.selected};
      padding: 6px;
      justify-content: center;
      align-items: center;
      display: flex;
      > label {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.white};
        font-weight: bold;
        cursor: pointer;
      }

      > svg {
        margin-left: 6px;
        margin-bottom: 1px;
        fill: white;
      }

      &:hover {
        background: linear-gradient(180.32deg, #2effaf 15.34%, #00aaff 98.15%);
      }
    }
  }
`;
