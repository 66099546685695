import styled from "styled-components";
import bannerBackground from "../../../../../../../../assets/banners/banner-background.png";
import { getGradient } from "../../../../../../../../utils/getBannerGradient";

export const Container = styled.div`
  width: 563px;
  min-width: 563px;
  height: 152px;
  background: #FFF;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 6px 6px #C6C9D6;
`;

export const Header = styled.div`
  width: 364px;
  height: 100%;
  /* background: #000; */
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProgressContainer = styled.div`
  height: 54px;
  width: 54px;
`;

export const Icon = styled.img`
  height: 25px;
  width: 25px;
`;

export const DietInformation = styled.div<{
  score: number;
  backgroundType: string;
}>`
  width: 100%;
  height: 100%;
  // border: 2px solid red;
  border-radius: 0 8px 8px 0;
  ${({ backgroundType, score, theme }) => {
    if (backgroundType === "gray")
      return `background: ${theme.colors.grayBgButton}`;

    return `background: url(${bannerBackground}),
    linear-gradient(
      ${getGradient(score, backgroundType)}
    )`;
  }};
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  z-index: 85;
  /* opacity: 0.8; */
`;

export const Text = styled.p`
  width: 260px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  padding-left: 16px;
  text-align: left;
  line-height: 18px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImgContainer = styled.div`
  overflow: hidden;
  height: 100%;
`;