import React from "react";
import { ContentPack, ProgressPack } from "./style";

const RoundedProgressPack: React.FC<{
  packName?: string;
  selectedPack?: string;
  children: React.ReactNode;
  size: number;
  progress: number;
  linear?: {
    color1: string;
    color2: string;
  };
  grayForeground?: boolean;
  thickness?: number;
}> = ({
  children,
  progress,
  linear,
  size,
  grayForeground,
  selectedPack,
  packName,
  thickness = 12,
}) => {
  const calculate = (progress * 360) / 100;

  return (
    <ProgressPack
      selected={selectedPack === packName}
      size={size}
      progress={calculate}
      gray={grayForeground}
      linear={linear}
      thickness={thickness}
    >
      <ContentPack selected={selectedPack === packName}>{children}</ContentPack>
    </ProgressPack>
  );
};

export default RoundedProgressPack;
