import styled, { css } from "styled-components";
import { getColorByQuartile } from "../Nutrient/style";

export const BestProductCard = styled.div<{ color?: "primary" | "secondary" }>`
  background: ${({ color }) =>
    color !== "primary"
      ? `linear-gradient(89.73deg, #2effaf 25.48%, #00aaff 91.69%)`
      : `linear-gradient(105.57deg, #3135C6 17.51%, #00AAFF 74.49%)`};
  padding: 2px;
  padding-top: 12px;

  border-radius: 8px;
  position: relative;
  cursor: pointer;
`;
export const BestProductContent = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  border-radius: 0 0 8px 8px;
  //   border: 2px solid red;
`;

export const ImageContainer = styled.div`
  max-width: 254px;
  padding: 56px;
  // border: 2px solid blue;
  margin-left: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const BestProductImage = styled.img`
  // border: 2px solid red;
  // height: 205px;
  max-height: 205px;
  max-width: 254px;
  // width: 254px;
  // border: 2px solid blue;
`;

export const BestProductInformation = styled.div`
  display: flex;
  flex-direction: column;
  // border: 2px solid green;
  flex: auto;
  justify-content: center;
  margin-left: 70px;
  position: relative;
  cursor: pointer;
`;
export const BrandName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;
`;

export const ProductName = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 10px;
  cursor: pointer;
`;

export const CompatibilityLabel = styled.label<{ value: number }>`
  margin-top: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;

  > label {
    color: ${({ theme, value }) => getColorByQuartile(value, theme)};
    font-weight: 700;
  }
`;

export const PriceLabel = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 45px;
  cursor: pointer;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  // border: 2px solid red;
  position: absolute;
  top: 70px;
  right: 58px;
  gap: 21px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 67px;
  right: 58px;
`;

const iconBox = css`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  > svg {
    height: 29px;
    width: 29px;
  }
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;

export const AlertContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.redGradient}
`;

export const Spacing = styled.div`
  height: 26px;
`;
