import styled from "styled-components";

export const Container = styled.div`
  min-width: 565px;
  height: 75px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
`;

export const ContainerHealthRisk = styled.div`
  display: flex; 
  align-items: center;
  justify-content: flex-start;
  /* gap: 8px; */
  overflow-x: scroll;
  padding: 8px 0;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
`;

export const ScrollButtonContainer = styled.div`
/* position: absolute;
top: 20px;
right: 24px; */
display: flex;
justify-content: flex-end;
/* margin-bottom: -50px; */
`;