import styled, { css } from "styled-components";
import { getColorByQuartile } from "../../../../components/Cards/Nutrient/style";

const iconBox = css`
  height: 31px;
  width: 31px;
  margin-left: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 17.5px;
    width: 17.5px;
  }
`;

export const CardContainer = styled.div<{ horizontal?: boolean }>`
  width: 100%;
  height: 100%;
  /* background-color: ${({ theme }) => theme.colors.white}; */
  /* padding: 30px 25px; */
  /* padding-left: 58px; */
  /* padding-right: 58px; */
  /* border-radius: 8px; */
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
  justify-content: space-between;
  align-items: flex-start;
  /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3); */
  /* margin-bottom: 1rem; */

  margin-bottom: 12px;
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 85%;
  min-height: 150px;
  justify-content: center;
  align-items: center;

  > img {
    // min-height: 100px;
    height: 150px;
    width: auto;
  }
`;

export const CardContent = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  padding: 30px 25px;
  /* margin-left: 1rem; */
  /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3); */
  border-bottom: 1.5px solid #e6e7ec;
`;

// export const BrandName = styled.span`
//   font-family: "Roboto";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 29px;
//   color: ${({ theme }) => theme.colors.gray};
// `;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 16px 0;
  // border: 2px solid orange;
`;

export const NutrientName = styled.label`
  /* font-family: "Roboto";
  font-style: normal; */
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray};
  /* margin-top: 8px; */
  > label {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Compatibility = styled.span<{ value: number }>`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 11px;

  > span {
    font-weight: 700;
    font-size: 16px;
    color: ${({ theme, value }) => getColorByQuartile(value, theme)};
    margin-left: 6px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const ProductIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

export const CardHeader = styled.div`
  width: 100%;
  //   padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const AlertContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.redGradient}
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;
