import React from "react";
import { ChildrenType } from "react-tooltip";
import StandardTooltip from "../../Tooltip";
import {
  Container,
  Icon,
  SubTitle,
  Title,
  TitleContainer,
  TopIcon,
  WithoutBorderIcon,
} from "./style";

// import { Container } from './styles';
type AlertCardProps = {
  title: string;
  subtitle: string;
  topIcon: React.ReactNode;
  icon: React.ReactNode;
  anchor: string;
  tooltipText?: string;
  color: "red" | "orange" | "yellow" | "green" | "gray";
  tooltipChildren?: ChildrenType;
  withoutBorderIcon?: boolean;
};
const AlertCard: React.FC<AlertCardProps> = ({
  icon,
  subtitle,
  topIcon,
  title,
  anchor,
  tooltipText,
  color = "orange",
  tooltipChildren,
  withoutBorderIcon,
}) => {
  return (
    <Container id={anchor} color={color}>
      <TopIcon>{topIcon}</TopIcon>
      {withoutBorderIcon ? (
        <WithoutBorderIcon>{icon}</WithoutBorderIcon>
      ) : (
        <Icon>{icon}</Icon>
      )}
      <TitleContainer>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </TitleContainer>
      {tooltipText && tooltipText !== "" && (
        <StandardTooltip
          color={color}
          anchor={anchor}
          text={tooltipText}
          children={tooltipChildren}
        />
      )}
    </Container>
  );
};

export default AlertCard;
