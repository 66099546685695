import React from "react";
import RoundedProgress from "../../../../../../../../components/RoundedProgress";
import {
  CompositionField,
  SymptomMoreDetails,
  SymptomsCollapse,
  SymptomsCollapseTitle,
  SymptomsProgressContainer,
} from "./styles";

import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useTheme } from "styled-components";
import { FieldSymptom } from "../../../../../../../../api/types";
import NutrientItem from "../NutrientBar.tsx";

type SymptomItemProps = {
  symptom: FieldSymptom;
  setOpen: (value: number) => void;
  selected: number;
  value: number;
};

const SymptomItem: React.FC<SymptomItemProps> = ({
  symptom,
  selected,
  setOpen,
  value,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { name, score, nutrients } = symptom;

  // const [open, setOpen] = useState(false);
  // const toggleOpen = () => {
  //   setOpen(!open);
  // };

  return (
    <SymptomsCollapse>
      <SymptomsCollapseTitle>{name}</SymptomsCollapseTitle>
      <SymptomMoreDetails onClick={() => setOpen(value)}>
        <label>
          {value === selected
            ? t("screens.productDetail.less_details")
            : t("screens.productDetail.more_details")}
        </label>
        {value === selected ? (
          <AiOutlineMinusCircle
            width={25}
            height={25}
            color={theme.colors.selected}
          />
        ) : (
          <AiOutlinePlusCircle
            width={25}
            height={25}
            color={theme.colors.selected}
          />
        )}
      </SymptomMoreDetails>
      <SymptomsProgressContainer>
        <RoundedProgress size={55} progress={Math.round(score)}>
          <CompositionField>
            <label>{`${Math.round(score)}%`}</label>
          </CompositionField>
        </RoundedProgress>
      </SymptomsProgressContainer>
      <Collapse isOpened={selected === value}>
        {nutrients.map((nutrient, index) => {
          return (
            <NutrientItem
              key={index}
              iconId={nutrient.categoryId}
              name={nutrient.name}
              score={nutrient.score}
              isContraindication={nutrient.isContraindication}
            />
          );
        })}
        <div style={{ padding: "8px" }} />
        <div style={{ padding: "8px" }} />
      </Collapse>
    </SymptomsCollapse>
  );
};

export default SymptomItem;
