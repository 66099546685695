/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ScrollContainer } from "./style";

interface HorizontalScrollViewProps {
  size?: number;
  children: React.ReactNode;
  gap?: number;
  padding?: number;
  margin?: number;
  disableScroll?: boolean;
  style?: React.CSSProperties;
  onScroll?: React.UIEventHandler<HTMLElement> | undefined;
}
// function handleScroll(event: UIEvent<HTMLHeadingElement>) {
//   console.log('scrollTop: ', event.currentTarget.scrollTop);
//   console.log('offsetWidth: ', event.currentTarget.offsetWidth);
// };

const HorizontalScrollView = React.forwardRef<
  HTMLDivElement,
  HorizontalScrollViewProps
>(
  (
    {
      children,
      size,
      gap,
      style,
      padding,
      margin,
      disableScroll,
      onScroll,
    },
    ref
  ) => {
    return (
      // <ScrollContainer onScroll={handleScroll} ref={ref} size={size} gap={gap}>
      <ScrollContainer
        padding={padding}
        margin={margin}
        ref={ref}
        size={size}
        gap={gap}
        disableScroll={disableScroll}
        style={style}
        onScroll={onScroll}
      >
        {children}
      </ScrollContainer>
    );
  }
);

export default HorizontalScrollView;
