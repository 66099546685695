export const HeartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20."
      height="35"
      viewBox="0 0 25.159 30.4"
      style={{
        transform: "scale(0.8)",
      }}
    >
      <defs>
        <linearGradient
          id="3iybg2v8wa"
          x1=".5"
          x2=".5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#4a54df" />
          <stop offset="1" stopColor="#15d4d8" />
        </linearGradient>
      </defs>
      <path
        fill="url(#3iybg2v8wa)"
        d="M48.62 3.02c-.16-.17-3.969-4.148-9.32-2.706a7.458 7.458 0 0 0-5.015 4.713C33.755 6.3 32.21 6.5 30.48 6.535c-.482.009-.956.1-1.309-.229a1.218 1.218 0 0 1-.32-1.289 8.28 8.28 0 0 1 1.01-2.009.651.651 0 0 0-.093-.851l-.683-.628-.521-.485a.651.651 0 0 0-1.014.161c-.507.916-1.326 2.458-1.681 3.138a16.289 16.289 0 0 0-1.711 5.252c-.463 3.7-.4 5.912.488 8.255 3.184 8.384 9.862 13.922 14.821 12.253s5.849-12.326 3.562-19.121c-.666-1.978-2.4-3.1-4.764-3.789a1.284 1.284 0 0 1-.765-1.859 4.133 4.133 0 0 1 2.585-2.076A6.817 6.817 0 0 1 46.4 5.107a1.525 1.525 0 0 0 2.22-2.087zM30.466 22.214a.732.732 0 0 1-1.034-.049c-3-3.3-3.772-8.507-3.8-8.727a.732.732 0 0 1 1.45-.206c.007.049.739 4.985 3.436 7.947a.732.732 0 0 1-.052 1.035zm1.334 2.427a.773.773 0 1 1 .773-.773.773.773 0 0 1-.773.773z"
        transform="translate(-23.873)"
      />
    </svg>
  );
};
