import React from "react";
import { Container, Content, Title } from "./style";

const CardSimpleMobile: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ children, title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  );
};

export default CardSimpleMobile;
