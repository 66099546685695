import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as AnimationMobile1 } from "../../assets/animation/validateBasket/animation-mobile-01.svg";
import { ReactComponent as AnimationMobile2 } from "../../assets/animation/validateBasket/animation-mobile-02.svg";

import { ReactComponent as Amazon } from "../../assets/animation/validateBasket/amazon.svg";
import { ReactComponent as Kpsule } from "../../assets/animation/validateBasket/kpsule.svg";
import { ReactComponent as DietiNatura } from "../../assets/pharmacy-logos/pharmacy-5-dieti-natura.svg";

import { useTranslation } from "react-i18next";
import Page from "../../components/Page";
import { SetupAnswerContext } from "../../context/setupAnswer";
import { ShoppingCartContext } from "../../context/shoppingCart";
import { useViewport } from "../../hooks/Viewport/useViewport";
import { Container, ContainerFooter, ContainerMobile, Title } from "./style";

const ValidateBasket = () => {
  const [showAnimation1, setShowAnimation1] = useState(true);
  const [showAnimation2, setShowAnimation2] = useState(false);
  const { shoppingCart } = useContext(ShoppingCartContext);
  const { isUnderMobile } = useViewport();

  const { t } = useTranslation();

  const { pharmacyId } = useContext(SetupAnswerContext);

  const renderPartnerLogo = () => {
    console.log("PharmacyId: ", typeof pharmacyId);

    if (pharmacyId == 5) {
      return <DietiNatura className="amazon" />;
    } else {
      return <Amazon className="amazon" />;
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => setShowAnimation1(false), 2000);
    setTimeout(() => setShowAnimation2(true), 2000);

    setTimeout(() => {
      shoppingCart.forEach((item) => {
        if (item.product.links?.us !== "" && !!item.product.links?.us) {
          window.open(item.product.links?.us, "_blank");
        } else {
          window.open(item.product.links?.fr, "_blank");
        }
      });

      navigate("/");
    }, 4000);
  }, []);

  return (
    <>
      {isUnderMobile ? (
        <Page screen="validate-basket" hideHeader>
          <ContainerMobile
            showAnimation1={showAnimation1}
            showAnimation2={showAnimation2}
          >
            <AnimationMobile1 className="animation-1" />
            <AnimationMobile2 className="animation-2" />

            <ContainerFooter
              showAnimation1={showAnimation1}
              showAnimation2={showAnimation2}
            >
              <Title>{t("screens.validate.redirect")}</Title>
              {renderPartnerLogo()}
              <Kpsule className="kpsule" />
            </ContainerFooter>
          </ContainerMobile>
        </Page>
      ) : (
        <Page screen="validate-basket" hideHeader>
          <Container
            showAnimation1={showAnimation1}
            showAnimation2={showAnimation2}
          >
            <Title>{t("screens.validate.redirect")}</Title>
            {renderPartnerLogo()}
            <Kpsule className="kpsule" />
            <AnimationMobile1 className="animation-1" />
            <AnimationMobile2 className="animation-2" />
          </Container>
        </Page>
      )}
    </>
  );
};

export default ValidateBasket;
