import styled from "styled-components";
import scrollbar from "../../../../styles/scrollbar";

interface ScrollContainerProps {
  disableScroll?: boolean;
  size?: number;
  gap?: number;
  padding?: number;
  margin?: number;
}

export const ScrollContainer = styled.section<ScrollContainerProps>`
  width: 100%;
  display: flex;
  overflow-x: auto;
  gap: ${({ gap }) => (gap ? `${gap}px` : `${18}px`)};
  /* padding-bottom: 1rem; */
  overflow-y: hidden;
  /* margin-top: 12px; */

  ${({ margin }) => margin || (margin === 0 && `margin: ${margin}px`)};

  ${({ padding }) =>
    padding ? `padding-left: ${padding}px;` : ''};

  /* padding-right: 15px; */

  ${({ disableScroll }) => (!disableScroll ? scrollbar : "overflow-x: scroll;")}

  scroll-snap-stop: initial;

  ${({ disableScroll }) => (disableScroll ? `
    overflow: overlay;

    ::-webkit-scrollbar {
         width: 10px;
         background: transparent;
      }
  
      ::-webkit-scrollbar-thumb {
         background: transparent;
         border-radius: 2px;
      }
  ` : "")}

  /* overflow: overlay;

  ::-webkit-scrollbar {
       width: 10px;
       background: transparent;
    }

    ::-webkit-scrollbar-thumb {
       background: white;
       border-radius: 2px;
    } */

`;
