import styled from "styled-components";

export const Container = styled.div`
  flex-direction: column;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid red;
`;

export const Title = styled.h1`
  margin-top: 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;
