import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { ReactComponent as CartImage } from "../../../assets/content/cart.svg";
import { ShoppingCartContext } from "../../../context/shoppingCart";
import { useDisableBodyScroll } from "../../../hooks/Scroll/useDisableScroll";
import { useViewport } from "../../../hooks/Viewport/useViewport";
import PrimaryButton from "../../Button/PrimaryButton";
import { ButtonContainer, Container, Title } from "./style";

const RemoveProductModal = () => {
  const { t } = useTranslation();

  const { isUnderMobile } = useViewport();

  const {
    productToRemove,
    isRemoveProductModalOpen,
    handleCloseRemoveProductModal,
    removeProduct,
  } = useContext(ShoppingCartContext);

  const handleRemoveProduct = () => {
    if (productToRemove) {
      removeProduct(productToRemove, 9999);
    }
    handleCloseRemoveProductModal();
  };

  const customStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "405px",
      minWidth: isUnderMobile ? "90%" : "",
      minHeight: "305px",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.3)",
      padding: "0",
    },
  };

  useDisableBodyScroll(isRemoveProductModalOpen);

  return (
    <Modal
      isOpen={isRemoveProductModalOpen}
      shouldCloseOnEsc
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      onRequestClose={handleCloseRemoveProductModal}
    >
      <Container>
        <CartImage />
        <Title>
          {t("components.modal.already_added", {
            productName: productToRemove?.name,
          })}
        </Title>
        <ButtonContainer>
          <PrimaryButton
            onClick={handleRemoveProduct}
            label={t("components.modal.yes")}
            color="red"
            // width="50%"
          />
          <PrimaryButton
            onClick={handleCloseRemoveProductModal}
            label={t("components.modal.no")}
            // width="50%"
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export { RemoveProductModal };
