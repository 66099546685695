import styled from "styled-components";

export const NutrientHeader = styled.div`
  display: flex;
  //   border: 2px solid red;
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
`;

export const NutrientName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 29px;
  color: #6a6a6a;
`;

export const NutrientContent = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;
