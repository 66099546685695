import { css } from "styled-components";

const scrollbar = css`
  ${({ theme: { colors } }) => css`
    &::-webkit-scrollbar {
      -webkit-appearance: none;

      background-color: #ededed;

      &:vertical {
        width: 6px;

        border-radius: 10px;
      }

      &:horizontal {
        height: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;

      &:vertical {
        background-image: linear-gradient(to bottom, #4a54df, #15d4d8);
      }

      &:horizontal {
        background-image: linear-gradient(to left, #4a54df, #15d4d8);
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  `}
`;

export const whiteScrollbar = css`
  ${({ theme: { colors } }) => css`
    &::-webkit-scrollbar {
      -webkit-appearance: none;

      background-color: #ededed;

      &:vertical {
        width: 6px;

        border-radius: 10px;
      }

      &:horizontal {
        height: 6px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;

      &:vertical {
        background-image: linear-gradient(to bottom, #FFF, #FFF);
      }

      &:horizontal {
        background-image: linear-gradient(to left, #FFF, #FFF);
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
  `}
`;

export const hiddenScrollbar = css`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

export default scrollbar;
