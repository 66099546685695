import React from "react";
import { useNavigate } from "react-router";
import { TabPanel } from "react-tabs";
import { ProductProps } from "../../api/types";
import {
  Circle,
  Flag,
  FlagContainer,
  TabListContent,
  TabsContainer,
  UnorderedTab,
} from "./style";

interface TabViewProps {
  labels: string[];
  contents: React.ReactNode[];
  learnMore?: string;
  selectedProduct?: ProductProps;
  controlledContentSelected?: string;
  setControlledContentSelected?: React.Dispatch<React.SetStateAction<string>>;
  onTabClick?: (label: string) => void;
  labelsCounts?: number[];
}

const TabView: React.FC<TabViewProps> = ({
  contents,
  labels,
  learnMore,
  selectedProduct,
  controlledContentSelected,
  setControlledContentSelected,
  onTabClick,
  labelsCounts,
}) => {
  const navigate = useNavigate();

  const [contentSelected, setContentSelected] = React.useState(labels[0]);

  const getContent = () => {
    if (setControlledContentSelected) {
      return controlledContentSelected;
    } else {
      return contentSelected;
    }
  };

  const handleClick = (label: string) => {
    if (onTabClick) onTabClick(label);

    if (setControlledContentSelected) {
      setControlledContentSelected(label);
    } else {
      setContentSelected(label);
    }
  };

  return (
    <TabsContainer selectedIndex={labels.indexOf(getContent() as string)}>
      <TabListContent>
        {labels.map((label, index) => {
          return (
            <UnorderedTab
              key={index}
              onClick={() => handleClick(label)}
              selected={getContent() === label}
              onSelect={() => null}
            >
              <FlagContainer>
                <label>
                  {label}
                  {labelsCounts && labelsCounts[index] > 0 && (
                    <Circle value={labelsCounts[index]}>
                      <label>{labelsCounts[index]}</label>
                    </Circle>
                  )}
                </label>

                <Flag show={getContent() === label} />
              </FlagContainer>
            </UnorderedTab>
          );
        })}

        {/* <LearnMore
          onClick={() => {
            navigate("/product-details", {
              state: selectedProduct,
              // replace: true,
            });
          }}
        >
          {learnMore}
        </LearnMore> */}
      </TabListContent>

      {contents.map((content, index) => (
        <TabPanel key={index}>{content}</TabPanel>
      ))}
    </TabsContainer>
  );
};
export default TabView;
