import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ProductProps } from "../../api/types";
import { ReactComponent as EssentialPack } from "../../assets/icons/svg/essential-pack.svg";
import { ReactComponent as OptimalPack } from "../../assets/icons/svg/optimalPack.svg";
import { ReactComponent as PremiumPack } from "../../assets/icons/svg/premiumPack.svg";
import HoverButton from "../../components/Button/HoverButton";
import CardSimple from "../../components/Cards/Simple";
import Page from "../../components/Page";
import TabView from "../../components/TabView";
import { GlobalContext } from "../../context/global";
import { ShoppingCartContext } from "../../context/shoppingCart";
import { HeaderContainer, HeaderLabel } from "../../styles/commomStyles";
import CardsProductsPack from "./CardProductsPack";
import { FieldOfAction } from "./Tab/FieldsOfAction";
import { YourBenefits } from "./Tab/YourBenefits";
import {
  CardPacksRecommendationContainer,
  RecommendedPacksContainer,
  SelectAndButtoContainer,
} from "./styles";
// import { ProductViewType } from "../ProductDetails";

import logoMobile from "../../assets/logo-mobile.svg";

import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../context/sidebar";
import { useViewport } from "../../hooks/Viewport/useViewport";
import { getCurrencyFormat } from "../../utils/getCurrencyString";
import ProductAlerts from "../ProductDetails/components/Alerts";
import { CardPacks } from "./CardPacks";

// export type ProductViewType = {
//   state: ProductProps;
// };

const RenderHeader = () => {
  const { t } = useTranslation();
  return (
    <HeaderContainer>
      <HeaderLabel>{t("screens.recommendedPack.choose_your_pack")}</HeaderLabel>
    </HeaderContainer>
  );
};

const RenderHeaderLogo = () => (
  <HeaderContainer>
    <img src={logoMobile} alt="" />
  </HeaderContainer>
);

export function RecommendedPacks() {
  const { products } = useContext(GlobalContext);
  const { t } = useTranslation();

  const { isUnderTablet } = useViewport();
  const { sidebarOpen } = useContext(SidebarContext);
  const {
    addPack,
    isPackAlreadyAdded,
    getPriceOfPackByDuration,
    addPackByDuration,
  } = useContext(ShoppingCartContext);
  const {
    essentialPack,
    optimalPack,
    premiumPack,
    essentialPackScore,
    optimalPackScore,
    premiumPackScore,
  } = useContext(GlobalContext);
  const [productsPack, setProductsPack] =
    useState<ProductProps[]>(essentialPack);
  const [selectedPack, setSelectedPack] = useState("Essential Pack");
  const [selectedPackName, setSelectedPackName] = useState(
    t("screens.recommendedPack.essential_pack")
  );
  const [selectedPackScore, setSelectedPackScore] =
    useState(essentialPackScore);
  const [isInCart, setIsInCart] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(
    essentialPack[0].id
  );
  const [selectedProduct, setSelectedProduct] = useState<ProductProps>(
    productsPack[0]
  );

  const [durationInMonths, setDurationInMonths] = useState(1);

  const [wait, setWait] = useState(true);

  const product = selectedProduct;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const get = setTimeout(() => {
      setWait(false);
    }, 1300);
    setWait(true);
    return () => clearTimeout(get);
  }, [selectedPack]);

  function addPackInShoppingCart() {
    addPackByDuration(productsPack, durationInMonths);
  }

  function handlePackClick(packName: string) {
    setSelectedPack(packName);
  }

  function handleCardProductClick(id: number) {
    setSelectedProductId(id);
  }

  const handleChangeMonths = (e: ChangeEvent<HTMLSelectElement>) => {
    setDurationInMonths(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    const isInCart = () => isPackAlreadyAdded(productsPack);
    setIsInCart((state) => (state = isInCart()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsPack, []]);

  useEffect(() => {
    const productIndex = productsPack.findIndex(
      (product) => product.id === selectedProductId
    );
    const product = productsPack[productIndex];
    setSelectedProduct(product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId]);

  useEffect(() => {
    const product = productsPack.find(
      (product) => product.id === selectedProductId
    );
    if (!product) {
      setSelectedProductId(productsPack[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsPack]);

  useEffect(() => {
    if (selectedPack === "Essential Pack") {
      setProductsPack(essentialPack);
      setSelectedPackName(t("screens.recommendedPack.essential_pack"));
      setSelectedPackScore(essentialPackScore);
    }

    if (selectedPack === "Optimal Pack") {
      setProductsPack(optimalPack);
      setSelectedPackName(t("screens.recommendedPack.optimal_pack"));
      setSelectedPackScore(optimalPackScore);
    }
    if (selectedPack === "Premium Pack") {
      setProductsPack(premiumPack);
      setSelectedPackName(t("screens.recommendedPack.premium_pack"));
      setSelectedPackScore(premiumPackScore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPack]);

  const shouldRenderAlertsAndPrecautions = () => {
    return (
      product.alerts.contraindications.length > 0 ||
      product.alerts.excipientAllergy.length > 0 ||
      product.alerts.excipientIntolerance.length > 0 ||
      product.alerts.drugInteraction.length > 0 ||
      product.alerts.componentsAllergy.length > 0 ||
      product.alerts.componentsIntolerance.length > 0
    );
  };

  // const getAlertsAndPrecautionsTab = () => {
  //   if (
  //     (product.contraindicationsAlerts.length > 0 ||
  //       product.excipientAlerts.length > 0 ||
  //       product.drugInteractionAlerts.length > 0) &&
  //     product.drugInteractionCaution.length > 0
  //   ) {
  //     return [
  //       t("screens.recommendedPack.alerts"),
  //       t("screens.recommendedPack.precautions"),
  //     ];
  //   } else if (
  //     product.contraindicationsAlerts.length > 0 ||
  //     product.excipientAlerts.length > 0 ||
  //     product.drugInteractionAlerts.length > 0
  //   ) {
  //     return [t("screens.recommendedPack.alerts")];
  //   } else {
  //     return [t("screens.recommendedPack.precautions")];
  //   }
  // };

  return (
    <Page screen="recommended-packs" header={RenderHeader}>
      <RecommendedPacksContainer>
        <CardPacksRecommendationContainer>
          <CardPacks
            onClick={handlePackClick}
            title={t("screens.recommendedPack.essential_pack")}
            packName={"Essential Pack"}
            iconSvg={<EssentialPack />}
            selectedPack={selectedPack}
            score={essentialPackScore}
          />

          <CardPacks
            onClick={handlePackClick}
            title={t("screens.recommendedPack.optimal_pack")}
            packName={"Optimal Pack"}
            iconSvg={<PremiumPack />}
            selectedPack={selectedPack}
            score={optimalPackScore}
          />

          <CardPacks
            onClick={handlePackClick}
            iconSvg={<OptimalPack />}
            title={t("screens.recommendedPack.premium_pack")}
            packName={"Premium Pack"}
            selectedPack={selectedPack}
            score={premiumPackScore}
          />
        </CardPacksRecommendationContainer>

        <div className="pack-info">
          <div>
            <h3
              dangerouslySetInnerHTML={{
                __html: t("screens.recommendedPack.pack_supports", {
                  packName: selectedPackName,
                  score: selectedPackScore,
                }),
              }}
            ></h3>
          </div>

          <SelectAndButtoContainer>
            <select onChange={handleChangeMonths}>
              <option value="1">1 {t("screens.recommendedPack.month")}</option>
              <option value="2">2 {t("screens.recommendedPack.months")}</option>
              <option value="3">3 {t("screens.recommendedPack.months")}</option>
              <option value="4">4 {t("screens.recommendedPack.months")}</option>
            </select>

            <HoverButton
              cursorNotAllowed={isInCart}
              isActive={isInCart}
              label={
                isInCart
                  ? t("screens.recommendedPack.in_cart")
                  : `${t(
                      "screens.recommendedPack.add_to_cart"
                    )}: ${getCurrencyFormat(
                      product.currency,
                      getPriceOfPackByDuration(productsPack, durationInMonths)
                    )}`
              }
              onClick={addPackInShoppingCart}
            />
          </SelectAndButtoContainer>
        </div>

        <div className="products-pack-container">
          {productsPack.map((product, key) => {
            return (
              <CardsProductsPack
                onClicked={handleCardProductClick}
                selectedCardId={product.id}
                clickedCard={selectedProductId}
                key={key}
                amountProducts={productsPack.length}
                product={product}
              />
            );
          })}
        </div>

        {shouldRenderAlertsAndPrecautions() && (
          <TabView
            labels={[t("screens.recommendedPack.alerts")]}
            contents={[
              <ProductAlerts alerts={product.alerts} />,
              <CardSimple title={t("screens.recommendedPack.precautions")}>
                {/* <CardPrecautions /> */}
              </CardSimple>,
            ]}
          />
        )}

        <TabView
          labels={[
            t("screens.recommendedPack.your_benefits"),
            t("screens.recommendedPack.field_of_action"),
          ]}
          learnMore={t("screens.recommendedPack.learn_more")}
          selectedProduct={selectedProduct}
          contents={[
            <YourBenefits product={selectedProduct} />,
            <FieldOfAction product={selectedProduct} />,
          ]}
          labelsCounts={[
            selectedProduct.symptoms.length +
              selectedProduct.healthGoals.length,
            selectedProduct.fieldsOfAction.length,
          ]}
        />
      </RecommendedPacksContainer>
    </Page>
  );
}
