import React, { createRef, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  CompositionType,
  ExcipientType,
  OtherIngredientType,
} from "../../../../api/types";
import HorizontalScrollView from "../../../../components/HorizontalScroll";
import { getExcipientIcon } from "../../../../utils/getExcipientIcon";
import { ExcipientsContainer } from "../../../ProductDetails/tabs/Composition/style";
import OtherIngredientsMobile from "../../components/OtherIngredientsMobile";
import { ExcipientCard, Title } from "../../style";
import ComponentCard from "./components/ComponentCard";
import {
  CircleCompositionNumber,
  CompositionContainer,
  CompositionCountLeft,
  CompositionHeader,
  CompositionTitle,
} from "./style";

type CompositionProps = {
  composition: CompositionType[];
  executeScroll: () => void;
  otherIngredients: OtherIngredientType[];
  excipients: ExcipientType[];
};

const CompositionMobile: React.FC<CompositionProps> = ({
  composition,
  executeScroll,
  excipients,
  otherIngredients,
}) => {
  const [viewMore, setViewMore] = useState(false);
  const { t } = useTranslation();

  const otherIngredientsRef = useRef<HTMLDivElement>(null);
  const executeOtherIngredientsScroll = () => {
    if (otherIngredientsRef && otherIngredientsRef.current) {
      otherIngredientsRef.current.scrollIntoView();
    }
  };

  const theme = useTheme();

  const toggleViewMore = () => {
    if (viewMore) {
      executeScroll();
    }
    setViewMore(!viewMore);
  };

  const sliderRef = createRef<HTMLDivElement>();

  const [compositionCountLeft, setCompositionCountLeft] = useState(1);

  return (
    <CompositionContainer>
      <CompositionHeader>
        <CompositionTitle>
          <label>{t("screens.productDetail.composition")}</label>
          <CircleCompositionNumber>
            <label>{composition.length}</label>
          </CircleCompositionNumber>
        </CompositionTitle>
        {composition.length > 1 && (
          <CompositionCountLeft>
            {`${compositionCountLeft}/${composition.length}`}
          </CompositionCountLeft>
        )}
      </CompositionHeader>

      <HorizontalScrollView
        gap={10}
        // padding={10}
        margin={0}
        disableScroll
        ref={sliderRef}
        onScroll={(e) => {
          if (!sliderRef.current) return;
          const cardWidth = 355;
          // @ts-ignore
          const scrollLeft = e.target.scrollLeft;
          // @ts-ignore
          const scrollWidth = e.target.scrollWidth;
          const leftToScroll = Math.floor(
            (scrollWidth - scrollLeft) / cardWidth
          );
          setCompositionCountLeft(composition.length - leftToScroll + 1);
        }}
      >
        {composition
          .filter((component) => component.fieldsOfAction.length > 0)
          .map((component, index) => {
            return (
              <ComponentCard
                key={index}
                component={component}
                viewMore={!viewMore}
                index={index}
              />
            );
          })}
      </HorizontalScrollView>
      <div ref={otherIngredientsRef} />
      {otherIngredients.length > 0 && (
        <OtherIngredientsMobile
          otherIngredients={otherIngredients}
          executeScroll={executeOtherIngredientsScroll}
        />
      )}
      {excipients.length > 0 && (
        <ExcipientsContainer>
          <Title>{t("screens.productDetail.excipient")}</Title>
          <HorizontalScrollView gap={8}>
            {excipients.map((excipient, index) => {
              return (
                <ExcipientCard key={index} importance={excipient.importance}>
                  <div>{getExcipientIcon(excipient.iconName, "blue")}</div>
                  <label>{excipient.name}</label>
                </ExcipientCard>
              );
            })}
          </HorizontalScrollView>
        </ExcipientsContainer>
      )}
    </CompositionContainer>
  );
};

export default CompositionMobile;
