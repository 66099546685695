import styled from "styled-components";
interface SidebarProps {
  width: number
}

export const SidebarMobileContainer = styled.nav<SidebarProps>`
  width: 428px;

  @media screen and (max-width: 427px) {
    width: ${({ width }) => `${width}px`};
  }

  height: 100vh;
  color: #fff;
  background: linear-gradient(165.98deg, #3135C6 14.08%, #00AAFF 89.86%);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 0;

  position: fixed;
  top: 0;
  left: -120%;
  opacity: 1;
  z-index: 999;
  transition: all 0.5s ease;

  .logo-bg {
    width: ${({ width }) => `${width}px`};
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.5;
    z-index: -1;
  }

  > svg {
    @media screen and (max-width: 427px) {
      margin-left: ${({ width }) => `-${width / 1.2}px`};
    }

    margin-left: -350px;
  }

  &.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
  }

  img {
    width: 14.1875rem;
  }

  h4 {
    font-size: 0.875rem;
    font-weight: 100;
    letter-spacing: 0.8px;
    filter: brightness(0.8);
  }

  ul {
    width: 360px;
    font-size: 1.125rem;
    padding: 0 1rem;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    letter-spacing: 1px;

    list-style: none;
    
    li {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    svg path, svg circle {
      fill: #fff;
    }
  }

  button {
    width: 18.0625rem;
    height: 3.4375rem;
    font-size: 1.125rem;
    color: #fff;
    background: #21E7C5;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    border-radius: 29px;
    margin-top: 5rem;

    svg, path {
      width: 1.875rem;
      height: 1.875rem;
      fill: #fff;
    }
  }
`