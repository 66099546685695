import React from "react";
import { ReactComponent as CapsuleIcon } from "../../../../../assets/content/capsule.svg";
import { Container } from "./style";

// import { Container } from './styles';
type OtherIngredientType = {
  title: string;
  description: string;
  index: number;
  viewMore: boolean;
};
const OtherIngredientCard: React.FC<OtherIngredientType> = ({
  description,
  title,
  index,
  viewMore,
}) => {
  return (
    <Container viewMore={viewMore} index={index}>
      <div>
        <CapsuleIcon />
        <label>{title}</label>
      </div>
      <label>{description}</label>
    </Container>
  );
};

export default OtherIngredientCard;
