/**
 *
 * @param {("product"|"nutrient"|"aliment")} type
 * @param {string} fileName
 */
export const getImageCompletePath = (
  type: "product" | "nutrient" | "aliment",
  fileName: string
) => {
  switch (type) {
    case "product":
      return `https://cdn-base.reload.co/nutripoint/farmacy_product/processed_images/${fileName}`;
    case "nutrient":
      return `https://cdn-base.reload.co/agent_sub_category/images/${fileName}`;
    case "aliment":
      return `https://cdn-base.reload.co/usda_foods/processed_images/${fileName}`;
    default:
      throw new Error("Image type is not correct");
  }
};
