import RoundedProgressPack from './RoundedProgressPack';
import { CardPacksContainer } from './styles';
import { ReactElement } from 'react';

interface CardPackProps {
  iconSvg: ReactElement
  title: string
  packName: string
  selectedPack: string
  score: number
  onClick: (packName: string) => void
};

export function CardPacks({ iconSvg, score, packName, title, selectedPack, onClick }: CardPackProps) {

  function handleClick() {
    onClick(packName)
  }

  return (
    <CardPacksContainer
      selected={selectedPack === packName}
      onClick={handleClick}
    >
      <RoundedProgressPack
        linear={{color1:"#fff", color2:"#fff"}}
        packName={packName}
        selectedPack={selectedPack}
        size={91}
        progress={score}
      >
        {iconSvg}
      </RoundedProgressPack >
      <span>{title}</span>
    </ CardPacksContainer>
  )
}