import { TFunction } from "react-i18next";
import { DrugInteractionAlertType, DrugInteractionType } from "../api/types";

const getDrugInteractionPhrasesFormatted = (
  drugInteractionAlerts: DrugInteractionAlertType[] | DrugInteractionType[],
  t: TFunction<"translation", undefined>
) => {
  if (drugInteractionAlerts.length === 0) {
    return [];
  }

  if (drugInteractionAlerts.length === 1) {
    const { phrase, design, agentName, drugName, action } =
      drugInteractionAlerts[0];

    return [
      {
        // phrase: phrase.replace(agentName, `<strong>${agentName}</strong>`),
        phrase: phrase,
        design: design,
        drugName: drugName,
        action,
      },
    ];
  }

  const prhasesWithoutAgentNames = drugInteractionAlerts.map((alert) =>
    alert.phrase.replace(alert.agentName, "{agentName}")
  );

  const phrasesObject = {} as any;
  const phrasesDesign = {} as any;
  const phrasesDrugName = {} as any;

  prhasesWithoutAgentNames.forEach((phrase, index) => {
    if (!(phrase in phrasesObject)) {
      phrasesObject[`${phrase}`] = [
        // `<strong>${drugInteractionAlerts[index].agentName}</strong>`,
        `${drugInteractionAlerts[index].agentName}`,
      ];
      phrasesDesign[`${phrase}`] = drugInteractionAlerts[index].design;
      phrasesDrugName[`${phrase}`] = drugInteractionAlerts[index].drugName;
    } else {
      phrasesObject[`${phrase}`].push(
        // `<strong>${drugInteractionAlerts[index].agentName}</strong>`
        `${drugInteractionAlerts[index].agentName}`
      );
    }
  });

  const phrasesToReturn: {
    phrase: string;
    design: number;
    drugName: string;
    action: string;
  }[] = [];

  Object.keys(phrasesObject).forEach((phrase) => {
    let agentNames = ``;
    phrasesObject[`${phrase}`].forEach((agentName: string, index: number) => {
      if (index < phrasesObject[`${phrase}`].length - 2) {
        agentNames += `${agentName}, `;
      } else if (index < phrasesObject[`${phrase}`].length - 1) {
        agentNames += `${agentName} ${t("common.and")} `;
      } else {
        agentNames += `${agentName}`;
      }
    });

    const finalPhrase = phrase.replace("{agentName}", agentNames);

    phrasesToReturn.push({
      phrase: finalPhrase,
      design: phrasesDesign[`${phrase}`],
      drugName: phrasesDrugName[`${phrase}`],
      action: drugInteractionAlerts[0].action,
    });
  });

  return phrasesToReturn;
};

export default getDrugInteractionPhrasesFormatted;
