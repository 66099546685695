import { FC, SVGProps, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router";
import { ReactComponent as BigLogoIcon } from "../../../assets/bigLogo.svg";
import { ReactComponent as LogoIcon } from "../../../assets/logo.svg";
import { FilterContext } from "../../../context/filter";
import { GlobalContext } from "../../../context/global";
import { SidebarContext } from "../../../context/sidebar";
import { sidebarElements } from "../../../utils/constants/sidebarElements";
import {
  Container,
  Content,
  Element,
  ElementContainer,
  LogoContainer,
  SidebarAllContent,
  Title,
  Toogle,
} from "./style";

type NavSidebarElement = {
  key: string;
  title: string;
  path: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  filled: boolean;
};

const Sidebar = ({ screen }: { screen: string }) => {
  const navigate = useNavigate();
  const { processingAnswer } = useContext(GlobalContext);

  const { t } = useTranslation();

  const { sidebarOpen, setSidebarOpen } = useContext(SidebarContext);

  const { clearFilters } = useContext(FilterContext);

  const navigateToScreen = (item: NavSidebarElement) => {
    sessionStorage.removeItem("home_best_nutrients_scroll_position");
    sessionStorage.removeItem("home_best_products_scroll_position");
    sessionStorage.removeItem("nutrient_tab");
    clearFilters();

    if (processingAnswer) {
      return;
    } else {
      navigate(item.path);
    }
  };

  const toggleSidebar = () => {
    if (!processingAnswer) setSidebarOpen(!sidebarOpen);
  };

  // useEffect(() => {
  //   console.log("Screen: ", screen);
  //   console.log("Sidebar Elements: ", sidebarElements);
  // }, [screen]);

  return (
    <Container opened={sidebarOpen}>
      <SidebarAllContent>
        <Toogle onClick={toggleSidebar}>
          {sidebarOpen ? (
            <BsChevronLeft size={20} />
          ) : (
            <BsChevronRight size={20} />
          )}
        </Toogle>
        <Content>
          <LogoContainer
            sidebarOpen={sidebarOpen}
            textLength={t("components.navMobile.the_right_supplements").length}
          >
            <BigLogoIcon className="big-logo-class" />
            <LogoIcon className="small-logo-class" />
            {sidebarOpen && (
              <label className="logo-phrase">
                {t("components.navMobile.the_right_supplements")}
              </label>
            )}
          </LogoContainer>
          <ElementContainer opened={sidebarOpen}>
            {sidebarElements.map((item) => (
              <Element
                sidebarOpen={sidebarOpen}
                key={item.path}
                onClick={() => {
                  navigateToScreen(item);
                }}
                filled={screen === item.key}
              >
                <item.icon />
                <Title sidebarOpen={sidebarOpen} filled={screen === item.key}>
                  {t(item.title)}
                </Title>
              </Element>
            ))}
          </ElementContainer>
        </Content>
      </SidebarAllContent>
    </Container>
  );
};

export { Sidebar };
