import { createContext, useContext, useEffect, useState } from "react";

interface ViewportProviderProps {
  children: React.ReactNode;
}

interface ViewportContextData {
  width: number;
  height: number;
  breakpoints: Breakpoints;
  isUnderTablet: boolean;
  isUnderMobile: boolean;
  isUnderIpad: boolean;
}

type Breakpoints = {
  tablet: number;
  mobile: number;
  ipad: number;
};

const viewportContext = createContext<ViewportContextData>(
  {} as ViewportContextData
);

export function ViewportProvider({ children }: ViewportProviderProps) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const breakpoints: Breakpoints = {
    ipad: 1366,
    tablet: 1024,
    mobile: 600,
  };

  const isUnderTablet = width <= breakpoints.tablet;
  const isUnderMobile = width <= breakpoints.mobile;
  const isUnderIpad = width <= breakpoints.ipad;

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return (
    <viewportContext.Provider
      value={{
        width,
        height,
        breakpoints,
        isUnderTablet,
        isUnderMobile,
        isUnderIpad,
      }}
    >
      {children}
    </viewportContext.Provider>
  );
}

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
export function useViewport() {
  /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
  const context = useContext(viewportContext);
  return context;
}
