import AestheticsBlue from "../assets/symptoms/blue-version/Aesthetics.svg";
import AllergiesBlue from "../assets/symptoms/blue-version/Allergies.svg";
import AntiagingBlue from "../assets/symptoms/blue-version/Anti-aging.svg";
import Anti_inflammatoryBlue from "../assets/symptoms/blue-version/Anti-inflammatory.svg";
import BiomarkerBlue from "../assets/symptoms/blue-version/Biomarker.svg";
import Bone_and_jointBlue from "../assets/symptoms/blue-version/Bone-and-joint.svg";
import Brain_HealthBlue from "../assets/symptoms/blue-version/Brain-Health.svg";
import CardiovascularBlue from "../assets/symptoms/blue-version/Cardiovascular.svg";
import Disease_ConditionBlue from "../assets/symptoms/blue-version/Disease-Condition.svg";
import Eating_PatternsBlue from "../assets/symptoms/blue-version/Eating-Patterns.svg";
import EmotionalBlue from "../assets/symptoms/blue-version/Emotional.svg";
import Energy_Metabolism_and_StimulationBlue from "../assets/symptoms/blue-version/Energy-Metabolism.svg";
import Eye_HealthBlue from "../assets/symptoms/blue-version/Eye-Health.svg";
import General_HealthBlue from "../assets/symptoms/blue-version/General-Health.svg";
import Gut_DigestionBlue from "../assets/symptoms/blue-version/Gut-Digestion.svg";
import HormonesBlue from "../assets/symptoms/blue-version/Hormones.svg";
import Kidney_BladderBlue from "../assets/symptoms/blue-version/Kidney-Bladder.svg";
import Libido_and_Sexual_HealthBlue from "../assets/symptoms/blue-version/Libido-and-Sexual-Health.svg";
import Liver_HealthBlue from "../assets/symptoms/blue-version/Liver-Health.svg";
import Mens_HealthBlue from "../assets/symptoms/blue-version/Mens-Health.svg";
import MoodBlue from "../assets/symptoms/blue-version/Mood.svg";
import Muscle_GainBlue from "../assets/symptoms/blue-version/Muscle-Gain.svg";
import Nervous_SystemBlue from "../assets/symptoms/blue-version/Nervous-System.svg";
import Sleep_QualityBlue from "../assets/symptoms/blue-version/Sleep-Quality.svg";
import Womens_HealthBlue from "../assets/symptoms/blue-version/Women-Health.svg";

import AestheticsRed from "../assets/symptoms/red-version/Aesthetics.svg";
import AllergiesRed from "../assets/symptoms/red-version/Allergies.svg";
import AntiagingRed from "../assets/symptoms/red-version/Anti-aging.svg";
import Anti_inflammatoryRed from "../assets/symptoms/red-version/Anti-inflammatory.svg";
import BiomarkerRed from "../assets/symptoms/red-version/Biomarker.svg";
import Bone_and_jointRed from "../assets/symptoms/red-version/Bone-and-joint.svg";
import Brain_HealthRed from "../assets/symptoms/red-version/Brain-Health.svg";
import CardiovascularRed from "../assets/symptoms/red-version/Cardiovascular.svg";
import Disease_ConditionRed from "../assets/symptoms/red-version/Disease-Condition.svg";
import Eating_PatternsRed from "../assets/symptoms/red-version/Eating-Patterns.svg";
import EmotionalRed from "../assets/symptoms/red-version/Emotional.svg";
import Energy_Metabolism_and_StimulationRed from "../assets/symptoms/red-version/Energy-Metabolism.svg";
import Eye_HealthRed from "../assets/symptoms/red-version/Eye-Health.svg";
import General_HealthRed from "../assets/symptoms/red-version/General-Health.svg";
import Gut_DigestionRed from "../assets/symptoms/red-version/Gut-Digestion.svg";
import HormonesRed from "../assets/symptoms/red-version/Hormones.svg";
import Kidney_BladderRed from "../assets/symptoms/red-version/Kidney-Bladder.svg";
import Libido_and_Sexual_HealthRed from "../assets/symptoms/red-version/Libido-and-Sexual-Health.svg";
import Liver_HealthRed from "../assets/symptoms/red-version/Liver-Health.svg";
import Mens_HealthRed from "../assets/symptoms/red-version/Mens-Health.svg";
import MoodRed from "../assets/symptoms/red-version/Mood.svg";
import Muscle_GainRed from "../assets/symptoms/red-version/Muscle-Gain.svg";
import Nervous_SystemRed from "../assets/symptoms/red-version/Nervous-System.svg";
import Sleep_QualityRed from "../assets/symptoms/red-version/Sleep-Quality.svg";
import Womens_HealthRed from "../assets/symptoms/red-version/Women-Health.svg";

import AestheticsWhite from "../assets/symptoms/white-version/Aesthetics.svg";
import AllergiesWhite from "../assets/symptoms/white-version/Allergies.svg";
import AntiagingWhite from "../assets/symptoms/white-version/Anti-aging.svg";
import Anti_inflammatoryWhite from "../assets/symptoms/white-version/Anti-inflammatory.svg";
import BiomarkerWhite from "../assets/symptoms/white-version/Biomarker.svg";
import Bone_and_jointWhite from "../assets/symptoms/white-version/Bone-and-joint.svg";
import Brain_HealthWhite from "../assets/symptoms/white-version/Brain-Health.svg";
import CardiovascularWhite from "../assets/symptoms/white-version/Cardiovascular.svg";
import Disease_ConditionWhite from "../assets/symptoms/white-version/Disease-Condition.svg";
import Eating_PatternsWhite from "../assets/symptoms/white-version/Eating-Patterns.svg";
import EmotionalWhite from "../assets/symptoms/white-version/Emotional.svg";
import Energy_Metabolism_and_StimulationWhite from "../assets/symptoms/white-version/Energy-Metabolism.svg";
import Eye_HealthWhite from "../assets/symptoms/white-version/Eye-Health.svg";
import General_HealthWhite from "../assets/symptoms/white-version/General-Health.svg";
import Gut_DigestionWhite from "../assets/symptoms/white-version/Gut-Digestion.svg";
import HormonesWhite from "../assets/symptoms/white-version/Hormones.svg";
import Kidney_BladderWhite from "../assets/symptoms/white-version/Kidney-Bladder.svg";
import Libido_and_Sexual_HealthWhite from "../assets/symptoms/white-version/Libido-and-Sexual-Health.svg";
import Liver_HealthWhite from "../assets/symptoms/white-version/Liver-Health.svg";
import Mens_HealthWhite from "../assets/symptoms/white-version/Mens-Health.svg";
import MoodWhite from "../assets/symptoms/white-version/Mood.svg";
import Muscle_GainWhite from "../assets/symptoms/white-version/Muscle-Gain.svg";
import Nervous_SystemWhite from "../assets/symptoms/white-version/Nervous-System.svg";
import Sleep_QualityWhite from "../assets/symptoms/white-version/Sleep-Quality.svg";
import Womens_HealthWhite from "../assets/symptoms/white-version/Women-Health.svg";

export const getSymptomsSvg = (
  category: number,
  variant: "blue" | "white" | "red"
) => {
  let variantsBlue = {
    1: AestheticsBlue,
    2: Anti_inflammatoryBlue,
    3: AntiagingBlue,
    4: BiomarkerBlue,
    5: BiomarkerBlue,
    6: Bone_and_jointBlue,
    7: CardiovascularBlue,
    8: Brain_HealthBlue,
    10: Disease_ConditionBlue,
    11: Eating_PatternsBlue,
    12: EmotionalBlue,
    13: Energy_Metabolism_and_StimulationBlue,
    14: Eye_HealthBlue,
    15: Energy_Metabolism_and_StimulationBlue,
    16: General_HealthBlue,
    17: Gut_DigestionBlue,
    18: HormonesBlue,
    19: Kidney_BladderBlue,
    20: Libido_and_Sexual_HealthBlue,
    21: Liver_HealthBlue,
    22: Mens_HealthBlue,
    23: MoodBlue,
    24: Muscle_GainBlue,
    25: Nervous_SystemBlue,
    26: BiomarkerBlue,
    27: Sleep_QualityBlue,
    28: Womens_HealthBlue,
    29: Nervous_SystemBlue,
    30: AllergiesBlue,
    31: BiomarkerBlue,
  };

  let variantsWhite = {
    1: AestheticsWhite,
    2: Anti_inflammatoryWhite,
    3: AntiagingWhite,
    4: BiomarkerWhite,
    5: BiomarkerWhite,
    6: Bone_and_jointWhite,
    7: CardiovascularWhite,
    8: Brain_HealthWhite,
    10: Disease_ConditionWhite,
    11: Eating_PatternsWhite,
    12: EmotionalWhite,
    13: Energy_Metabolism_and_StimulationWhite,
    14: Eye_HealthWhite,
    15: Energy_Metabolism_and_StimulationWhite,
    16: General_HealthWhite,
    17: Gut_DigestionWhite,
    18: HormonesWhite,
    19: Kidney_BladderWhite,
    20: Libido_and_Sexual_HealthWhite,
    21: Liver_HealthWhite,
    22: Mens_HealthWhite,
    23: MoodWhite,
    24: Muscle_GainWhite,
    25: Nervous_SystemWhite,
    26: BiomarkerWhite,
    27: Sleep_QualityWhite,
    28: Womens_HealthWhite,
    29: Nervous_SystemWhite,
    30: AllergiesWhite,
    31: BiomarkerWhite,
  };

  const variantsRed = {
    1: AestheticsRed,
    2: Anti_inflammatoryRed,
    3: AntiagingRed,
    4: BiomarkerRed,
    5: BiomarkerRed,
    6: Bone_and_jointRed,
    7: CardiovascularRed,
    8: Brain_HealthRed,
    10: Disease_ConditionRed,
    11: Eating_PatternsRed,
    12: EmotionalRed,
    13: Energy_Metabolism_and_StimulationRed,
    14: Eye_HealthRed,
    15: Energy_Metabolism_and_StimulationRed,
    16: General_HealthRed,
    17: Gut_DigestionRed,
    18: HormonesRed,
    19: Kidney_BladderRed,
    20: Libido_and_Sexual_HealthRed,
    21: Liver_HealthRed,
    22: Mens_HealthRed,
    23: MoodRed,
    24: Muscle_GainRed,
    25: Nervous_SystemRed,
    26: BiomarkerRed,
    27: Sleep_QualityRed,
    28: Womens_HealthRed,
    29: Nervous_SystemRed,
    30: AllergiesRed,
    31: BiomarkerRed,
  };

  let variants =
    variant === "blue"
      ? variantsBlue
      : variant === "red"
      ? variantsRed
      : variantsWhite;

  return (
    <img alt="Symptom Icon" src={variants[category as keyof typeof variants]} />
  );
};

// import { ReactComponent as Aesthetics} from "../assets/symptoms/blue-version/Aesthetics.svg";
// import { ReactComponent as Allergies} from "../assets/symptoms/blue-version/Allergies.svg";
// import { ReactComponent as Antiaging} from "../assets/symptoms/blue-version/Anti-aging.svg";
// import { ReactComponent as Anti_inflammatory} from "../assets/symptoms/blue-version/Anti-inflammatory.svg";
// import { ReactComponent as Biomarker} from "../assets/symptoms/blue-version/Biomarker.svg";
// import { ReactComponent as Bone_and_joint} from "../assets/symptoms/blue-version/Bone-and-joint.svg";
// import { ReactComponent as Brain_Health} from "../assets/symptoms/blue-version/Brain-Health.svg";
// import { ReactComponent as Cardiovascular} from "../assets/symptoms/blue-version/Cardiovascular.svg";
// import { ReactComponent as Disease_Condition} from "../assets/symptoms/blue-version/Disease-Condition.svg";
// import { ReactComponent as Eating_Patterns} from "../assets/symptoms/blue-version/Eating-Patterns.svg";
// import { ReactComponent as Emotional} from "../assets/symptoms/blue-version/Emotional.svg";
// import { ReactComponent as Energy_Metabolism_and_Stimulation} from "../assets/symptoms/blue-version/Energy-Metabolism.svg";
// import { ReactComponent as Eye_Health} from "../assets/symptoms/blue-version/Eye-Health.svg";
// import { ReactComponent as General_Health} from "../assets/symptoms/blue-version/General-Health.svg";
// import { ReactComponent as Gut_Digestion} from "../assets/symptoms/blue-version/Gut-Digestion.svg";
// import { ReactComponent as Hormones} from "../assets/symptoms/blue-version/Hormones.svg";
// import { ReactComponent as Kidney_Bladder} from "../assets/symptoms/blue-version/Kidney-Bladder.svg";
// import { ReactComponent as Libido_and_Sexual_Health} from "../assets/symptoms/blue-version/Libido-and-Sexual-Health.svg";
// import { ReactComponent as Liver_Health} from "../assets/symptoms/blue-version/Liver-Health.svg";
// import { ReactComponent as Mens_Health} from "../assets/symptoms/blue-version/Mens-Health.svg";
// import { ReactComponent as Mood} from "../assets/symptoms/blue-version/Mood.svg";
// import { ReactComponent as Muscle_Gain} from "../assets/symptoms/blue-version/Muscle-Gain.svg";
// import { ReactComponent as Nervous_System} from "../assets/symptoms/blue-version/Nervous-System.svg";
// import { ReactComponent as Sleep_Quality} from "../assets/symptoms/blue-version/Sleep-Quality.svg";
// import { ReactComponent as Womens_Health} from "../assets/symptoms/blue-version/Women-Health.svg";
