import React, { useCallback, useState } from "react";
import { ReactComponent as HealthIcon } from "../../../../../../assets/health-goals/t_man.svg";
import RoundedProgress from "../../../../../../components/RoundedProgress";
import {
  CollapseButton,
  CollapseContent,
  CompositionField,
  Container,
  Footer,
  FooterText,
  Header,
  HealthCategoryName,
  IconContainer,
  Section,
  SectionTitle,
} from "./styles";

import { Collapse } from "react-collapse";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useTheme } from "styled-components";
import { ProductFieldOfAction } from "../../../../../../api/types";
import { getSymptomsIcon } from "../../../../../../utils/getSymptomsIcon";
import SymptomItem from "./components/SymptomItem";

type HealthCategoriesCardProps = {
  fieldsOfAction: ProductFieldOfAction;
};

const HealthCategoriesCard: React.FC<HealthCategoriesCardProps> = ({
  fieldsOfAction,
}) => {
  const [isCollapseOpen, setCollapseOpen] = useState(false);
  const theme = useTheme();

  const { name, score, symptoms, categoryId } = fieldsOfAction;

  const amountOfNutrients = useCallback(() => {
    const set = new Set();

    symptoms.forEach((symptom) => {
      set.add(symptom.name);
    });

    return set.size;
  }, [symptoms]);

  const toggleCollapse = () => {
    setCollapseOpen(!isCollapseOpen);
  };

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const setOpen = (value: number) => {
    if (value === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(value);
    }
  };

  return (
    <Container isCollapseOpen={isCollapseOpen}>
      <Header>
        <HealthCategoryName>{name}</HealthCategoryName>
        <RoundedProgress size={55} progress={score}>
          <CompositionField>{getSymptomsIcon(categoryId)}</CompositionField>
        </RoundedProgress>
      </Header>
      <Section>
        <SectionTitle>{`${symptoms.length} Symptom${
          symptoms.length > 1 ? `s` : ``
        }`}</SectionTitle>
        <IconContainer>
          {/* <Circle>
            <label>{symptoms.length}</label>
          </Circle> */}
          <HealthIcon />
        </IconContainer>
      </Section>
      <Footer>
        <FooterText>
          {`Has `}
          <span>{amountOfNutrients()}</span>
          {` Nutrient${symptoms.length > 1 ? `s` : ``} in this Category`}
        </FooterText>
        <CollapseButton onClick={toggleCollapse}>
          <label>{isCollapseOpen ? "Less Details" : "More Details"}</label>
          {isCollapseOpen ? (
            <AiOutlineMinusCircle
              width={25}
              height={25}
              color={theme.colors.selected}
            />
          ) : (
            <AiOutlinePlusCircle
              width={25}
              height={25}
              color={theme.colors.selected}
            />
          )}
        </CollapseButton>
      </Footer>
      <Collapse isOpened={isCollapseOpen}>
        <CollapseContent isCollapseOpen={isCollapseOpen}>
          {symptoms.map((symptom, index) => (
            <SymptomItem
              symptom={symptom}
              setOpen={setOpen}
              selected={selectedIndex}
              value={index}
            />
          ))}
        </CollapseContent>
      </Collapse>
    </Container>
  );
};

export default HealthCategoriesCard;
