import React from "react";
import { Content, Progress } from "./style";

const RoundedProgress: React.FC<{
  children: React.ReactNode;
  size: number;
  progress: number;
  linear?: {
    color1: string;
    color2: string;
  };
  grayForeground?: boolean;
  thickness?: number; // standard = 8, thin = 6
  backgroundColor?: string;
}> = ({
  children,
  progress,
  linear,
  size,
  grayForeground,
  thickness = 8,
  backgroundColor = "white",
}) => {
  const calculate = (progress * 360) / 100;

  return (
    <Progress
      size={size}
      progress={calculate}
      gray={grayForeground}
      linear={linear}
      thickness={thickness}
    >
      <Content backgroundColor={backgroundColor} thickness={thickness}>
        {children}
      </Content>
    </Progress>
  );
};

export default RoundedProgress;
