import { TFunction } from "react-i18next";
import { ContraindicationAlertType } from "../api/types";

const getContraindicationPhrasesFormatted = (
  contraindicationsAlerts: ContraindicationAlertType[],
  t: TFunction<"translation", undefined>
) => {
  if (contraindicationsAlerts.length === 0) {
    return [];
  }

  if (contraindicationsAlerts.length === 1) {
    return [
      {
        ...contraindicationsAlerts[0],
      },
    ];
  }

  const categoriesId = {} as any;

  // TODO descomentar isso e entender o erro

  contraindicationsAlerts.forEach((alert) => {
    const newAlert = {
      ...alert,
      nutrientName: alert.nutrientName,
      // nutrientName: alert.nutrientName.replace(
      //   alert.nutrientName,
      //   `<strong>${alert.nutrientName}</strong>`
      // ),
    };

    if (!(alert.categoryId in categoriesId)) {
      categoriesId[`${alert.id}`] = [newAlert];
    } else {
      categoriesId[`${alert.id}`].push(newAlert);
    }
  });

  const returnContraindicationAlerts = [] as ContraindicationAlertType[];

  for (const categoryId in categoriesId) {
    let agentNames = ``;
    categoriesId[`${categoryId}`].forEach(
      (alert: ContraindicationAlertType, index: number) => {
        if (index < categoriesId[`${categoryId}`].length - 2) {
          agentNames += `${alert.nutrientName}, `;
        } else if (index < categoriesId[`${categoryId}`].length - 1) {
          agentNames += `${alert.nutrientName} ${t("common.and")} `;
        } else {
          agentNames += `${alert.nutrientName}`;
        }
      }
    );

    returnContraindicationAlerts.push({
      nutrientName: agentNames,
      icon: categoriesId[`${categoryId}`][0].icon,
      categoryId: categoriesId[`${categoryId}`][0].categoryId,
      name: categoriesId[`${categoryId}`][0].name,
      id: categoriesId[`${categoryId}`][0].id,
    });
  }

  return returnContraindicationAlerts;
};

export default getContraindicationPhrasesFormatted;
