import { useContext } from "react";
import Page from "../../components/Page";
import { GlobalContext } from "../../context/global";
import {
  HeaderBlueLabel,
  HeaderContainer,
  HeaderLabel,
} from "../../styles/commomStyles";

export default function FavoriteFoods() {
  const { amountOfStudies, userName } = useContext(GlobalContext);

  const RenderHeader = () => (
    <HeaderContainer>
      <HeaderLabel>Hello {userName}! Your recommendations</HeaderLabel>
      <HeaderBlueLabel>
        Based on {amountOfStudies} Scientific Publications
      </HeaderBlueLabel>
    </HeaderContainer>
  );

  return (
    <Page screen="favorite-foods" header={RenderHeader}>
      <h1>Favorite Foods</h1>
    </Page>
  );
}
