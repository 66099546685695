const messages = {
  en: {
    translation: {
      components: {
        sidebar: {
          home: "Home",
          productRecommendation: "Product Recommendations",
          nutritionalNeeds: "Nutritional Needs",
          favoriteFoods: "Favorite Foods",
          recommendedPacks: "Recommended Packs",
        },
        shoppingCartSidebar: {
          qr_code:
            "Scan the <span>QRcode</span> to find the complete list of your selection:",
          email:
            "Our recommended products have been sent to you by <span>email</span>",
          product_list: "Product list",
          your_basket: "Your Basket",
          of_your_nutritional_needs: " of your nutritional needs",
          total: "Total",
          your_compatibility: "Your compatibility",
          validate: "Validate",
          remove_all: "Remove all",
        },
        navMobile: {
          the_right_supplements: "The right supplements for you",
        },
        modal: {
          already_added:
            'You\'ve already added "{{ productName }}" to your basket. Do you want to remove it?',
          yes: "Yes",
          no: "No",
          read_more: "Read More",
          related_publications: "Related Publications: {{ componentName }}",
          publications_tab: "Publications",
          full_spectrum: "Full spectrum",
          nutrient_allergy: "Nutrient Allergy",
          nutrient_allergy_text:
            "Your are allergic to this nutrient: {{ agentName }}",
          nutrient_intolerance: "Nutrient Intolerance",
          nutrient_intolerance_text:
            "Your are intolerant to this nutrient: {{ agentName }}",
          drugs_interaction: "Drugs interaction",
        },
        filterSidebar: {
          find_the_best: "Find the best products according to your needs",
          select_below: "Select below",
          your_symptoms: "Specific Symptoms",
          symptoms_category: "General Health Issues",
          your_health_goals: "Your Health Goals",
          clear_filters: "Clear Filters",
          apply: "Apply",
          price: "Price",
        },
      },
      languages: {
        actualInitials: "en",
        actual: "English",
        fr: "French",
        pt: "Portuguese",
        en: "English",
      },
      healthGoals: {
        energy: "Increase Energy",
        boost: "Boost Performance",
        beauty: "Enhance Beauty",
        weight: "Lose Weight",
        digestion: "Upgrade Digestion",
        cognition: "Enhance Cognition",
        mood: "Optmize Mood",
        longevity: "Promote Longevity",
        sleep: "Improve Sleep",
        immunity: "Immunity",
        pain: "Pain Test",
      },
      screens: {
        home: {
          hello: "Hello {{ name }}!",
          hello_male: "Hello, {{ name }}!",
          hello_female: "Hello, {{ name }}!",
          title: "Your recommendations",
          based_on: "Based on {{ count }} Scientific Publications",
          based_of: "Based Of",
          best_products: "Best Products",
          best_nutrients: "Best Nutrients",
          see_our_pack: "See our pack recommendation",
          see_other_possibilities:
            "See Other Possibilities To <span>Improve Your Health</span>",
          banner: {
            pack_essential: "Essential Pack",
            optimal: "Optimal Pack",
            premium: "Premium Pack",
            recommended_packs: "Recommended Packs",
            pack_supports:
              "The <strong>{{ packName }}</strong> Supports <strong>{{ packPercentage }}%</strong> of Your Health  Needs",
            add_to_cart: "Add to cart",
            see_all: "See All",
            your_compatibility: "Your Compatibility",
            in_cart: "In cart",
            products: "Products",
          },
          cardBests: {
            view_all: "View All",
          },
          tabs: {
            bestProducts: {
              based_of_products: "{{ count }} Products",
              see_all: "See All",
              see_the_most: "See the most compatible supplements based on",
              your_goals_and_needs: "your Goals & Health needs:",
              your_compatibility: "Your Compatibility",
              health_goals: "Health Goals",
              symptoms: "Symptoms",
              learn_more: "Learn More",
              in_cart: "In cart",
            },
            bestNutrients: {
              based_of_nutrients: "{{ count }} Nutrients",
              see_all: "See All",
              see_the_most: "See the most compatible supplements based on",
              your_goals_and_needs: "your Goals & Health needs:",
              your_compatibility: "Your Compatibility",
              health_goals: "Health Goals",
              symptoms: "Symptoms",
              learn_more: "Learn More",
              best_products: "Best Products",
            },
          },
        },
        products: {
          best_products_from_a_base:
            "<strong>Best supplements</strong> from a base of <label>{{ count }} products</label>",
          find_the_best: "Find the best products according to your needs.",
          select_below: "Select below:",
          amount_available_products: "{{ count }} available products",
          search_product: "Search Product",
          filter: "Filter",
          your_symptoms: "Your Symptoms",
        },
        nutritionalNeeds: {
          best_nutrients: "Best nutrients recommended",
          find_the_best: "Find the best nutrients according to your needs.",
          search_nutrient: "Search nutrient",
          filter: "Filter",
          your_symptoms: "Your symptoms",
        },
        productDetail: {
          alerts: "Alerts",
          precautions: "Precautions",
          your_compatibility: "Your Compatibility",
          best_products: "Best Products",
          health_goals: "Health Goals",
          symptoms: "Symptoms",
          composition: "Composition",
          see_more: "See More",
          fields_of_action: "Fields of Action",
          excipients: "Excipients",
          capsules_per_day: "{{ count }} capsules per day",
          capsule_per_day: "{{ count }} capsule per day",
          month: "month",
          months: "months",
          week: "week",
          weeks: "weeks",
          and: "and",
          in_cart: "In cart",
          add_to_cart: "Add to cart",
          fills_need_day: "Fills {{ nutrientFills }}% of your need/day",
          support_your_deficiencies:
            "Support Your Following Nutrient Deficiencies",
          support_your_health: "Support Your Following Health Categories",
          your_diet_supply:
            "Your diet supply only fils {{score}}% of your daily contibutions recommended in {{ name }}!",
          symptom: "Symptom",
          less_details: "Less Details",
          more_details: "More Details",
          has_nutrients_in_this_category:
            "Has <label>{{ amountOfNutrients }}</label> Nutrients in this Category",
          has_nutrient_in_this_category:
            "Has <label>{{ amountOfNutrients }}</label> Nutrient in this Category",
          see_all_related_publications: "See all related publications",
          no_additional_information:
            "No additional information related to this nutrient,",
          for_each_capsule: "For Each <span>Capsule</span>",
          other_ingredients: "Other Ingredients",
          per_dose: "per dose",
          contraindication_alert_modal:
            "<strong>Contraindication:</strong> {{ componentName }} is contraindicated for {{ title }}",
          synergy_alert_modal: "Synergy",
          health_risk: "Health Risk",
          nutrition: "Nutrition",
        },
        recommendedPack: {
          alerts: "Alerts",
          precautions: "Precautions",
          choose_your_pack: "Choose your pack",
          essential_pack: "Essential Pack",
          optimal_pack: "Optimal Pack",
          premium_pack: "Premium Pack",
          pack_supports:
            "The <span>{{ packName }}</span> Supports <span>{{ score }}%</span> of Your Health Needs",
          score_health_needs: "{{ score }}%",
          duration: "Duration",
          month: "month",
          months: "months",
          in_cart: "In cart",
          add_to_cart: "Add to cart",
          dosage_of: "Dosage of",
          products_in_pack: "10 of products in pack",
          your_benefits: "Your Benefits",
          field_of_action: "Field of Action",
          learn_more: "Learn More",
          your_compatibility: "Your Compatibilty",
          health_goals: "Health Goals",
          symptoms: "Symptoms",
        },
        nutrientDetail: {
          best_products: "Best Products",
          best_products_with_nutrient: "Best Products with {{ name }}",
          nutrient_detail: "Nutrient Detail",
          diet_deficiency:
            "Your diet only meets {{ nutritionDeficiency }}% of the recommended daily allowances for this nutrient",
          health_goals: "Health Goals",
          symptoms: "Symptoms",
          fields_of_action: "Fields of Action",
          your_compatibility: "Your compatibility",
          products_containing: "Products containing this nutrients",
          day: "day",
          in_cart: "In Cart",
          add_to_cart: "Add To Cart",
          better_compatibility: "Better Compatibility",
          best_report: "Best report quality / price",
          support_your_following: "Support Your Following Health Categories",
          symptom: "Symptom",
          less_details: "Less Details",
          more_details: "More Details",
        },
        validate: {
          product_list:
            "vos suppléments sélectionnés sont disponibles dans le Rayon C de votre magasin",
        },
      },
      common: {
        view_more: "View More",
        view_less: "View Less",
        alerts: "Alerts",
        precautions: "Precautions",
        contraindication: "Contraindication",
        study: "Study",
        studies: "Studies",
        and: "and",
        excipient: "Excipient",
        products: "Products",
        day: "day",
        drug_interaction: "Drug Interaction",
        allergy: "Allergy",
        intolerance: "Intolerance",
        plant_extract: "Plant Extract",
      },
      hovers: {
        agent_has_drug_interation: "This nutrient has drug interation",
        agent_has_drug_interations: "This nutrient has drug interations",
        agent_has_alert: "Alert associated with this nutrient",
        agent_has_alerts: "Alerts associated with this nutrient",
        agent_has_synergy_alert: "This nutrient has synergy",
        agent_has_nutrition_deficient_alert:
          "You are deficient in this nutrient",
        agent_has_healthRisk_alert: "This nutrient supports your health risks",
        product_has_alert: "This product has an alert",
        product_is_recommended:
          "This product contains one of the best nutrient for you",
        product_is_highly_recommended:
          "This product is hightly recommended for you",
        nutrient_is_recommended: "One of the bests nutrients for you",
        nutrient_has_alert: "This nutrient has an alert",
        nutrient_contraindicated:
          "{{ nutrientName }} is contraindicated for {{ symptomName }}",
        top_10: "Top 10 of your needs",
        you_are_allergic_to: "You are allergic to this nutrient: {{ name }}",
        you_are_intolerant_to:
          "You are intolerant to this nutrient: {{ name }}",
        you_are_allergic_to_excipient:
          "You are allergic to this product excipient: {{ name }}",
        you_are_intolerant_to_excipient:
          "You are intolerant to this product excipient: {{ name }}",
      },
    },
  },
};

export { messages };

// "Increase energy": "Increase energy",
//   "Boost performance": "Boost performance",
//   "Enhance beauty": "Enhance beauty",
//   "Restore body balance": "Restore body balance",
//   "Upgrade digestion": "Upgrade digestion",
//   "Enhance cognition": "Enhance cognition",
//   "Optmize mood": "Optmize mood",
//   "Promote longevity": "Promote longevity",
//   "Improve sleep": "Improve sleep",
