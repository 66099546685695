import React, { useContext, useEffect, useState } from "react";
import fallbackImage from "../../../assets/jpg/fallbackImage.jpg";
import { getCurrencyFormat } from "../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../utils/getImageCompletePath";

import {
  BottomContent,
  CompatibilityLabel,
  Container,
  ImageContainer,
  PriceLabel,
  ProductImage,
  ProductInfo,
  ProductName,
  TopContent,
} from "./style";

import { useTranslation } from "react-i18next";
import {
  ShoppingCartContext,
  ShoppingCartItem,
} from "../../../context/shoppingCart";
import { TrashButton } from "../../Button/ThrashButton";
import { MoreOrLessInput } from "../../Input/MoreOrLess";

interface ShoppingCartItemCardProps {
  shoppingCartItem: ShoppingCartItem;
}

const ShoppingCartItemCard: React.FC<ShoppingCartItemCardProps> = ({
  shoppingCartItem,
}) => {
  const { amount, product } = shoppingCartItem;
  const { imageUrl, name, score, currency, price } = product;
  const { removeProduct, changeAmountOfProduct } =
    useContext(ShoppingCartContext);
  const { t } = useTranslation();

  const [cartAmount, setCartAmount] = useState(amount);

  const handleOnClickMore = () => {
    setCartAmount(cartAmount + 1);
  };

  const handleOnClickLess = () => {
    if (cartAmount > 1) {
      setCartAmount(cartAmount - 1);
    }
  };

  const handleOnChangeInput = (e: any) => {
    setCartAmount(e.target.value);
  };

  const handleButtonClick = () => {
    removeProduct(product, 9999);
  };

  useEffect(() => {
    changeAmountOfProduct(cartAmount, product);
  }, [cartAmount]);

  useEffect(() => {
    setCartAmount(amount);
  }, [amount]);

  return (
    <Container>
      <TopContent>
        <ImageContainer>
          <ProductImage
            src={getImageCompletePath("product", imageUrl)}
            onError={(e) => (e.currentTarget.src = fallbackImage)}
          />
        </ImageContainer>
        <ProductInfo>
          <ProductName>{name}</ProductName>
          <CompatibilityLabel value={Math.round(score)}>
            {t("components.shoppingCartSidebar.your_compatibility")}{" "}
            <label>{`${Math.round(score)}/100`}</label>
          </CompatibilityLabel>
        </ProductInfo>
      </TopContent>
      <BottomContent>
        <MoreOrLessInput
          actualValue={cartAmount}
          onClickLess={handleOnClickLess}
          onClickMore={handleOnClickMore}
          onChangeInput={handleOnChangeInput}
        />
        <PriceLabel>{`${getCurrencyFormat(
          currency,
          price * cartAmount
        )}`}</PriceLabel>
        <TrashButton onClick={handleButtonClick} />
      </BottomContent>
    </Container>
  );
};

export { ShoppingCartItemCard };
