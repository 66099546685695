import styled from "styled-components";

interface FieldsetProps {
  isCheckFormOpen?: boolean;
}

export const FieldsetContainer = styled.fieldset<FieldsetProps>`
  > form {
    width: 274px;
    padding: 0px 1.5rem 20px 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form-check-container {
      transition: visibility 0.3s, opacity 0.3s linear;
      height: ${({ isCheckFormOpen }) => (isCheckFormOpen ? "auto" : "0")};
      overflow: hidden;
      opacity: ${({ isCheckFormOpen }) => (isCheckFormOpen ? "1" : "0")};
      visibility: ${({ isCheckFormOpen }) =>
        isCheckFormOpen ? "visible" : "hidden"};
      /* padding-bottom: 10px; */
    }

    .title {
      width: 100%;
      display: flex;
      padding-bottom: 10px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid ${({ theme }) => theme.colors.grayLightBorder};
      // border: 2px solid orange;

      .icon-close {
        // border: 2px solid red;
        transition: all 0.3s ease-out;
        cursor: pointer;
        transform: ${({ isCheckFormOpen }) =>
          isCheckFormOpen ? "rotate(0)" : "rotate(45deg)"};
      }
    }
    legend {
      font-size: 1rem;
      align-items: center;
      /* margin-bottom: 24px; */
      /* margin-top: 24px; */
      cursor: pointer;

      // border: 2px solid blue;
    }

    label {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.grayLight};
      display: grid;
      grid-template-columns: 1em auto;
      gap: 1em;
      margin-top: 1em;
      cursor: pointer;

      input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        display: grid;
        place-content: center;

        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.3em;
        height: 1.3em;
        border: 0.1em solid ${({ theme }) => theme.colors.grayLight};
        border-radius: 50%;
        transform: translateY(-0.075em);
        cursor: pointer;
      }
      input[type="radio"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em ${({ theme }) => theme.colors.selected};

        background-color: CanvasText;
      }

      input[type="radio"]:checked::before {
        transform: scale(1);
      }

      input[type="radio"]:focus {
        outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em);
      }
    }
  }
`;
