import { IoAlert } from "react-icons/io5";
import { DrugInteractionType } from "../../../../api/types";
import AlertCard from "../../../../components/Cards/AlertCard";
import CardSimple from "../../../../components/Cards/Simple";
import { GapDiv } from "../../style";

import { useTranslation } from "react-i18next";
import { getDrugInteractionsIcon } from "../../../../utils/getDrugInteractionIcon";
import getDrugInteractionPhrasesFormatted from "../../../../utils/getDrugInteractionPhrasesFormatted";
import getInnerHtmlInsideDiv from "../../../../utils/getInnerHtmlInsideDiv";

interface NutrientAlertsProps {
  drugInteractionAlerts: DrugInteractionType[];
}

const NutrientAlerts = ({ drugInteractionAlerts }: NutrientAlertsProps) => {
  const { t } = useTranslation();

  return (
    <CardSimple title={t("common.alerts")}>
      <GapDiv>
        {getDrugInteractionPhrasesFormatted(drugInteractionAlerts, t).map(
          (alert, index) => {
            return (
              <AlertCard
                icon={getDrugInteractionsIcon(alert.design)}
                subtitle={t("common.drug_interaction")}
                title={alert.drugName}
                topIcon={<IoAlert />}
                key={index}
                anchor={`drug-interaction-alert-${index}`}
                tooltipText={alert.phrase}
                color="red"
                tooltipChildren={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getInnerHtmlInsideDiv(alert.phrase),
                    }}
                  ></div>
                }
              />
            );
          }
        )}
      </GapDiv>
    </CardSimple>
  );
};

export default NutrientAlerts;
