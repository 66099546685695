import styled from "styled-components";

export const Container = styled.div`
  margin-top: 42px;
  // border: 2px solid red;
`;

export const HorizontalScrollContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
  // border: 2px solid blue;
`;

export const HealthCategoriesContainer = styled.div`
  // border: 2px solid red;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
`;
