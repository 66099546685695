import "react-circular-progressbar/dist/styles.css";
import { ThemeProvider } from "styled-components";
import { FilterProvider } from "./context/filter";
import { GlobalProvider } from "./context/global";
import { SetupAnswerProvider } from "./context/setupAnswer";
import { ShoppingCartProvider } from "./context/shoppingCart";
import { SidebarProvider } from "./context/sidebar";
import { StudiesProvider } from "./context/studies";
import { ViewportProvider } from "./hooks/Viewport/useViewport";
import Router from "./router";
import defaultTheme from "./styles/default";
import GlobalStyles from "./styles/globalStyles";
import { PaginationProvider } from "./context/pagination";
require("./App.css");

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ViewportProvider>
        <GlobalStyles />
        <SetupAnswerProvider>
          <GlobalProvider>
            <StudiesProvider>
              <ShoppingCartProvider>
                <FilterProvider>
                  <PaginationProvider>
                  <SidebarProvider>
                    <Router />
                  </SidebarProvider>
                  </PaginationProvider>
                </FilterProvider>
              </ShoppingCartProvider>
            </StudiesProvider>
          </GlobalProvider>
        </SetupAnswerProvider>
      </ViewportProvider>
    </ThemeProvider>
  );
}

export default App;
