import styled, { css } from "styled-components";
import { getColorByQuartile } from "../../../../components/Cards/Nutrient/style";

const iconBox = css`
  height: 35px;
  width: 35px;
  margin-left: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  > svg {
    height: 20px;
    width: 20px;
  }
`;


export const CardContainer = styled.div<{ horizontal?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 48px;
  padding-left: 58px;
  padding-right: 58px;
  border-radius: 8px;
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? "row" : "column")};
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  align-items: center;
  margin-bottom: 1rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  width: 20%;
  min-height: 150px;
  justify-content: center;
  align-items: center;

  > img {
    // min-height: 100px;
    height: 205px;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  margin-left: 1rem;
`;

export const BrandName = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductName = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Compatibility = styled.span<{ value: number }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 22px;

  > span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: ${({ theme, value }) => getColorByQuartile(value, theme)};
    margin-left: 6px;
  }
`;

export const ProductIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardHeader = styled.div`
  width: 100%;
  //   padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CapsuleContainer = styled.div`
  height: 36px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fe;
  border-radius: 8px;
  > svg {
    height: 20px;
    width: 20px;
  }
  > label {
    margin-left: 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const AlertContainer = styled.div.attrs(
  (props: { importance: string }) => props
)`
  ${iconBox}
  ${({ theme, importance }) => importance === 'hasAvoid' ? theme.redGradient : importance === 'hasContraindications' ? theme.orangeGradient : theme.yellowGradient }
  /* ${({ theme }) => theme.redGradient} */
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;

export const MedicineTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f5f7fe;
    padding: 0.5rem;
    border-radius: 8px;
    padding-left: 1rem;
    padding-right: 1rem;

    > svg {
      height: 25px;
      width: 25px;
    }
    > label {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: ${({ theme }) => theme.colors.black};
      margin-left: 8px;
    }

    // :last-child:not(:first-child) {
    //   margin-left: 1rem;
    // }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f5f7fe;
  padding: 0.5rem;
  border-radius: 8px;
  padding-left: 1rem;
  padding-right: 1rem;

  > svg {
    height: 25px;
    width: 25px;
    color: ${({ theme }) => theme.colors.black};
  }
  > label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.black};
    margin-left: 8px;
  }
`;

export const ToogleNumber = styled.input`
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-align: center;
  margin: 0;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.gray};

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabelButton = styled.div`
  justify-content: space-between;

  // border: 2px solid green;

  // :last-child {
  //   margin-left: 1rem;
  //   > select {
  //     width: 100%;
  //   }
  // }
  display: flex;
  height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 50px;
  padding: 1rem;
  width: 120px;

  align-items: center;
`;

export const SelectMonth = styled.select`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};

  > option {
    // border: 2px solid red;
  }
`;

export const ProductsButtonsContainer = styled.div`
  display: flex;
  width: auto;
  overflow: hidden;
  // min-width: 250px;
  justify-content: space-between;
  // border: 2px solid red;
`;

export const PriceText = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const AddToCartButton = styled.button<{ isAdded: boolean }>`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.selected};
  align-items: center;
  padding: 0.8rem;
  border-radius: 80px;
  cursor: pointer;
  width: 250px;
  position: relative;

  > svg {
    // border: 2px solid red;
    margin-left: 6px;
    margin-bottom: 1px;
    fill: white;
    position: absolute;
    right: 12px;
  }

  > label {
    cursor: pointer;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    background: ${({ isAdded }) =>
      isAdded
        ? ``
        : `linear-gradient(180.32deg, #2effaf 15.34%, #00aaff 98.15%)`};
  }

  ${({ isAdded, theme }) => isAdded && `background: ${theme.colors.midBlue}`}
`;

export const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  justify-content: space-between;
`;
