import React from "react";
import { useTranslation } from "react-i18next";
import {
  ProductFieldOfAction,
  ProductNutritionDeficiencyType,
} from "../../../../api/types";
import HorizontalScrollView from "../../../../components/HorizontalScroll";
import { Title } from "../../style";
import HealthCategoriesCard from "./components/HealthCategoriesCard/HealthCategoriesCard";
import NutrientDeficiencyCard from "./components/NutrientDeficiencyCard/NutrientDeficiencyCard";
import {
  Container,
  HealthCategoriesContainer,
  HorizontalScrollContainer,
} from "./styles";

type ProductFieldsOfActionProps = {
  nutritionDeficiency: ProductNutritionDeficiencyType[];
  fieldsOfAction: ProductFieldOfAction[];
};

const ProductFieldsOfAction: React.FC<ProductFieldsOfActionProps> = ({
  nutritionDeficiency,
  fieldsOfAction,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      {nutritionDeficiency.length > 0 && (
        <>
          <Title>{t("screens.productDetail.support_your_deficiencies")}</Title>
          <HorizontalScrollContainer>
            <HorizontalScrollView size={220}>
              {nutritionDeficiency.map((deficiency) => (
                <NutrientDeficiencyCard nutrientDeficiency={deficiency} />
              ))}
            </HorizontalScrollView>
          </HorizontalScrollContainer>
        </>
      )}
      <Title>{t("screens.productDetail.support_your_health")}</Title>
      <HealthCategoriesContainer>
        {fieldsOfAction.map((field) => (
          <HealthCategoriesCard fieldsOfAction={field} />
        ))}
      </HealthCategoriesContainer>
    </Container>
  );
};

export default ProductFieldsOfAction;
