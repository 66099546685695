import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { useTheme } from "styled-components";
import { LabelButton, ToogleNumber } from "./style";

interface MoreOrLessInputProps {
  onClickMore: () => void;
  onClickLess: () => void;
  onChangeInput: (e: any) => void;
  actualValue: string | number;
}

const MoreOrLessInput = ({
  actualValue,
  onClickLess,
  onClickMore,
  onChangeInput,
}: MoreOrLessInputProps) => {
  const theme = useTheme();

  return (
    <LabelButton>
      <button onClick={onClickLess}>
        <IoIosRemove size={20} color={theme.colors.gray} />
      </button>
      <ToogleNumber
        // type="number"
        // value={actualValue}
        onChange={onChangeInput}
        // disabled
      >
        {actualValue}
      </ToogleNumber>
      <button onClick={onClickMore}>
        <IoIosAdd size={20} color={theme.colors.gray} />
      </button>
    </LabelButton>
  );
};

export { MoreOrLessInput };
