import styled, { css } from "styled-components";

const iconBox = css`
  height: 35px;
  width: 35px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  > svg {
    height: 20px;
    width: 20px;
  }
`;

export const HeaderLabel = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.black};

  > label {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.blueLight};
  }
`;

export const HeaderBlueLabel = styled.label`
  font-family: "Roboto";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blueLight};
  margin-left: 0.5rem;
`;

export const HeaderContainerMobile = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > img {
    /* margin-left: 2rem; */
  }

  @media (max-width: 428px) {
    justify-content: center;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > img {
    /* margin-left: 2rem; */
  }

  @media (max-width: 428px) {
    justify-content: center;
  }
`;

export const AlertContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.redGradient}
`;

export const StarContainer = styled.div`
  ${iconBox}
  ${({ theme }) => theme.blueGradient}
`;
