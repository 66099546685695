import img_0 from "./images/img_0.png"

export const animationData = {
  v: "5.6.10",
  fr: 29.9700012207031,
  ip: 0,
  op: 300.00001221925,
  w: 1920,
  h: 1080,
  nm: "Comp 1",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 161,
      h: 295,
      u: "",
      p: img_0,
      e: 0
    },
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 3,
          nm: "CONTROL",
          sr: 1,
          ks: {
            o: { a: 0, k: 0, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [250, 250, 100], ix: 6 }
          },
          ao: 0,
          ip: 0,
          op: 39.0000015885026,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Dot 35",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 17,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 18.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 19.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 20.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 22.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 24,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 25.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 26.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 28.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 29.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 31,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 31.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 33.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 35.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 36.6,
                  s: [9]
                },
                { t: 38.0000015477717, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -53.37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [-3.919, 9.076, 0],
                  to: [-54.276, 27.638, 0],
                  ti: [75.181, -18.946, 0]
                },
                { t: 32.0000013033867, s: [-98.393, 149.22, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 2,
                  s: [24.084, 24.084, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 18,
                  s: [11.56, 11.56, 100]
                },
                { t: 39.0000015885026, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 40.0000016292334,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Dot 34",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -53.37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-20.518, -9.699, 0],
                  to: [31.666, 53.738, 0],
                  ti: [-36.152, -67.14, 0]
                },
                { t: 31.0000012626559, s: [131.539, 100.631, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [24.084, 24.084, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [11.56, 11.56, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Dot 33",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -53.37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-20.518, -9.699, 0],
                  to: [77.048, -20.28, 0],
                  ti: [-67.67, 19.599, 0]
                },
                { t: 31.0000012626559, s: [85.925, -127.894, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [24.084, 24.084, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [11.56, 11.56, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Dot 32",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -53.37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-20.518, -9.699, 0],
                  to: [-72.072, 0.736, 0],
                  ti: [67.984, -11.429, 0]
                },
                { t: 31.0000012626559, s: [-136.199, -63.849, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [24.084, 24.084, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [11.56, 11.56, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Dot 31",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 18,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 19.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 20.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 21.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 23.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 25,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 26.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 27.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 29.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 30.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 32,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 32.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 34.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 36.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 37.6,
                  s: [9]
                },
                { t: 39.0000015885026, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -8.37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [-14.062, -6.987, 0],
                  to: [-57.922, -18.836, 0],
                  ti: [66.557, 39.764, 0]
                },
                { t: 33.0000013441176, s: [-179.962, 25.306, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [24.084, 24.084, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 19,
                  s: [11.56, 11.56, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 41.0000016699642,
          st: 3.00000012219251,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Dot 30",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 17,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 18.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 19.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 20.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 22.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 24,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 25.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 26.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 28.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 29.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 31,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 31.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 33.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 35.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 36.6,
                  s: [9]
                },
                { t: 38.0000015477717, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -8.37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [-12.524, -32.001, 0],
                  to: [-15.608, 60.39, 0],
                  ti: [21.912, -73.039, 0]
                },
                { t: 32.0000013033867, s: [16.982, 153.535, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 2,
                  s: [24.084, 24.084, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 18,
                  s: [11.56, 11.56, 100]
                },
                { t: 39.0000015885026, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 40.0000016292334,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Dot 29",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -8.37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-12.524, -32.001, 0],
                  to: [68.821, 40.14, 0],
                  ti: [-61.708, -33.991, 0]
                },
                { t: 31.0000012626559, s: [146.319, -40.311, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [24.084, 24.084, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [11.56, 11.56, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Dot 28",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -8.37, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-12.524, -32.001, 0],
                  to: [-51.483, -50.442, 0],
                  ti: [56.154, 39.99, 0]
                },
                { t: 31.0000012626559, s: [-56.032, -152.089, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [24.084, 24.084, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [11.56, 11.56, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Dot 27",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [10.932, 11.422, 0],
                  to: [-23.725, 49.741, 0],
                  ti: [42.976, -54.752, 0]
                },
                { t: 31.0000012626559, s: [17.77, 165.333, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [21.955, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [10.539, 10.133, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "Dot 26",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 17,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 18.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 19.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 20.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 22.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 24,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 25.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 26.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 28.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 29.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 31,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 31.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 33.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 35.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 36.6,
                  s: [9]
                },
                { t: 38.0000015477717, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [-10.564, 6.715, 0],
                  to: [50.379, 22.09, 0],
                  ti: [-60.544, -29.454, 0]
                },
                { t: 32.0000013033867, s: [154.1, 4.723, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 2,
                  s: [21.955, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 18,
                  s: [10.539, 10.133, 100]
                },
                { t: 39.0000015885026, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 40.0000016292334,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: "Dot 25",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-10.564, 6.715, 0],
                  to: [43.591, -56.744, 0],
                  ti: [-37.351, 51.145, 0]
                },
                { t: 31.0000012626559, s: [2.496, -137.649, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [21.955, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [10.539, 10.133, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "Dot 24",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 18,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 19.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 20.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 21.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 23.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 25,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 26.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 27.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 29.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 30.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 32,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 32.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 34.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 36.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 37.6,
                  s: [9]
                },
                { t: 39.0000015885026, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [-10.564, 6.715, 0],
                  to: [-50.313, 39.74, 0],
                  ti: [41.845, -45.339, 0]
                },
                { t: 33.0000013441176, s: [-120.257, 30.021, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [21.955, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 19,
                  s: [10.539, 10.133, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 41.0000016699642,
          st: 3.00000012219251,
          bm: 0
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: "Dot 23",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -45, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-4.604, 5.187, 0],
                  to: [-50.596, 17.725, 0],
                  ti: [67.615, -7.111, 0]
                },
                { t: 31.0000012626559, s: [-104.415, 119.047, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [21.537, 21.521, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [10.338, 10.33, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: "Dot 22",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 17,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 18.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 19.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 20.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 22.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 24,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 25.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 26.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 28.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 29.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 31,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 31.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 33.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 35.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 36.6,
                  s: [9]
                },
                { t: 38.0000015477717, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -45, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [-16.604, -13.949, 0],
                  to: [20.604, 52.669, 0],
                  ti: [-22.785, -65.351, 0]
                },
                { t: 32.0000013033867, s: [101.185, 105.735, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 2,
                  s: [21.537, 21.521, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 18,
                  s: [10.338, 10.33, 100]
                },
                { t: 39.0000015885026, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 40.0000016292334,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: "Dot 21",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 21.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 23,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 24.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 25.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 27.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 28.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 30,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 30.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 32.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 34.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 35.6,
                  s: [9]
                },
                { t: 37.0000015070409, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -45, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-16.604, -13.949, 0],
                  to: [69.404, -8.067, 0],
                  ti: [-61.185, 8.697, 0]
                },
                { t: 31.0000012626559, s: [90.785, -106.425, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [21.537, 21.521, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [10.338, 10.33, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: "Dot 20",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 18,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 19.235,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 20.8,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 21.706,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 23.6,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 25,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 26.4,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 27.883,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 29.2,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 30.6,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 32,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 32.824,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 34.8,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 36.2,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 37.6,
                  s: [9]
                },
                { t: 39.0000015885026, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -45, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [-16.604, -13.949, 0],
                  to: [-62.596, -8.899, 0],
                  ti: [60.415, -1.287, 0]
                },
                { t: 33.0000013441176, s: [-110.015, -78.137, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [21.537, 21.521, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 19,
                  s: [10.338, 10.33, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 41.0000016699642,
          st: 3.00000012219251,
          bm: 0
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: "Dot 47",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 15,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 16,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 17.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 18,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 19.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 20.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 21.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 23,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 24.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 25.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 26.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 27,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 28.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 29.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 30.866,
                  s: [9]
                },
                { t: 32.0000013033867, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -37.111, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [-46.152, -10.764, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 33.0000013441176, s: [-121.445, -21.3, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 19,
                  s: [8.694, 8.694, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 5.00000020365417,
          op: 41.0000016699642,
          st: 3.00000012219251,
          bm: 0
        },
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: "Dot 46",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 15,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 16,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 17.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 18,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 19.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 20.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 21.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 23,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 24.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 25.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 26.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 27,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 28.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 29.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 30.866,
                  s: [9]
                },
                { t: 32.0000013033867, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 232.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [-10.764, 46.152, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 33.0000013441176, s: [-21.3, 121.445, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 19,
                  s: [8.694, 8.694, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 5.00000020365417,
          op: 41.0000016699642,
          st: 3.00000012219251,
          bm: 0
        },
        {
          ddd: 0,
          ind: 20,
          ty: 4,
          nm: "Dot 45",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 15,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 16,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 17.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 18,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 19.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 20.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 21.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 23,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 24.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 25.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 26.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 27,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 28.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 29.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 30.866,
                  s: [9]
                },
                { t: 32.0000013033867, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 142.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [46.152, 10.764, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 33.0000013441176, s: [121.445, 21.3, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 19,
                  s: [8.694, 8.694, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 5.00000020365417,
          op: 41.0000016699642,
          st: 3.00000012219251,
          bm: 0
        },
        {
          ddd: 0,
          ind: 21,
          ty: 4,
          nm: "Dot 44",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 15,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 16,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 17.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 18,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 19.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 20.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 21.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 23,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 24.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 25.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 26.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 27,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 28.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 29.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 30.866,
                  s: [9]
                },
                { t: 32.0000013033867, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 52.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 5,
                  s: [10.764, -46.152, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 33.0000013441176, s: [21.3, -121.445, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 19,
                  s: [8.694, 8.694, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 5.00000020365417,
          op: 41.0000016699642,
          st: 3.00000012219251,
          bm: 0
        },
        {
          ddd: 0,
          ind: 22,
          ty: 4,
          nm: "Dot 43",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 14,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 15,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 16.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 17,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 18.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 19.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 20.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 22,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 23.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 24.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 25.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 26,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 27.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 28.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 29.866,
                  s: [9]
                },
                { t: 31.0000012626559, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -37.111, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [-46.152, -10.764, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 32.0000013033867, s: [-121.445, -21.3, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 2,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 18,
                  s: [21.735, 21.735, 100]
                },
                { t: 39.0000015885026, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 4.00000016292334,
          op: 40.0000016292334,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 23,
          ty: 4,
          nm: "Dot 42",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 14,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 15,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 16.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 17,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 18.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 19.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 20.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 22,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 23.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 24.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 25.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 26,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 27.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 28.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 29.866,
                  s: [9]
                },
                { t: 31.0000012626559, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 232.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [-10.764, 46.152, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 32.0000013033867, s: [-21.3, 121.445, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 2,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 18,
                  s: [21.735, 21.735, 100]
                },
                { t: 39.0000015885026, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 4.00000016292334,
          op: 40.0000016292334,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 24,
          ty: 4,
          nm: "Dot 41",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 14,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 15,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 16.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 17,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 18.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 19.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 20.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 22,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 23.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 24.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 25.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 26,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 27.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 28.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 29.866,
                  s: [9]
                },
                { t: 31.0000012626559, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 142.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [46.152, 10.764, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 32.0000013033867, s: [121.445, 21.3, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 2,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 18,
                  s: [21.735, 21.735, 100]
                },
                { t: 39.0000015885026, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 4.00000016292334,
          op: 40.0000016292334,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 25,
          ty: 4,
          nm: "Dot 40",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 14,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 15,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 16.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 17,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 18.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 19.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 20.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 22,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 23.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 24.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 25.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 26,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 27.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 28.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 29.866,
                  s: [9]
                },
                { t: 31.0000012626559, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 52.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [10.764, -46.152, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 32.0000013033867, s: [21.3, -121.445, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 2,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 18,
                  s: [21.735, 21.735, 100]
                },
                { t: 39.0000015885026, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 4.00000016292334,
          op: 40.0000016292334,
          st: 2.00000008146167,
          bm: 0
        },
        {
          ddd: 0,
          ind: 26,
          ty: 4,
          nm: "Dot 39",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.059,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.4,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.176,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 20.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 22,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 23.2,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 24.47,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 25.6,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 26.8,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 28,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 28.706,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 30.4,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 31.6,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 32.8,
                  s: [9]
                },
                { t: 34.0000013848484, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -37.111, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-46.152, -10.764, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 31.0000012626559, s: [-121.445, -21.3, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [36.226, 36.226, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 27,
          ty: 4,
          nm: "Dot 38",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.059,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.4,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.176,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 20.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 22,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 23.2,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 24.47,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 25.6,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 26.8,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 28,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 28.706,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 30.4,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 31.6,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 32.8,
                  s: [9]
                },
                { t: 34.0000013848484, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 232.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-10.764, 46.152, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 31.0000012626559, s: [-21.3, 121.445, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [36.226, 36.226, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 28,
          ty: 4,
          nm: "Dot 37",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.059,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.4,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.176,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 20.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 22,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 23.2,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 24.47,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 25.6,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 26.8,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 28,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 28.706,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 30.4,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 31.6,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 32.8,
                  s: [9]
                },
                { t: 34.0000013848484, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 142.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [46.152, 10.764, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 31.0000012626559, s: [121.445, 21.3, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [36.226, 36.226, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 29,
          ty: 4,
          nm: "Dot 36",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 16,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 17.059,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 18.4,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 19.176,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 20.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 22,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 23.2,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 24.47,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 25.6,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 26.8,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 28,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 28.706,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 30.4,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 31.6,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 32.8,
                  s: [9]
                },
                { t: 34.0000013848484, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 52.889, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [10.764, -46.152, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 31.0000012626559, s: [21.3, -121.445, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [18.113, 18.113, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [36.226, 36.226, 100]
                },
                { t: 40.0000016292334, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 30,
          ty: 4,
          nm: "Dot 16",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 13,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 14,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 15.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 16,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 17.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 18.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 19.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 21,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 22.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 23.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 24.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 25,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 26.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 27.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 28.866,
                  s: [9]
                },
                { t: 30.0000012219251, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -84.379, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-45.716, 30.999, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 31.0000012626559, s: [-114.285, 87.125, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [10.133, 10.133, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 31,
          ty: 4,
          nm: "Dot 15",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 13,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 14,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 15.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 16,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 17.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 18.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 19.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 21,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 22.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 23.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 24.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 25,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 26.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 27.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 28.866,
                  s: [9]
                },
                { t: 30.0000012219251, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 185.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [30.999, 45.716, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 31.0000012626559, s: [87.125, 114.285, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [10.133, 10.133, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 32,
          ty: 4,
          nm: "Dot 14",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 13,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 14,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 15.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 16,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 17.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 18.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 19.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 21,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 22.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 23.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 24.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 25,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 26.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 27.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 28.866,
                  s: [9]
                },
                { t: 30.0000012219251, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 95.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [45.716, -30.999, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 31.0000012626559, s: [114.285, -87.125, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [10.133, 10.133, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 33,
          ty: 4,
          nm: "Dot 13",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 13,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 14,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 15.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 16,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 17.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 18.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 19.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 21,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 22.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 23.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 24.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 25,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 26.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 27.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 28.866,
                  s: [9]
                },
                { t: 30.0000012219251, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 5.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [-30.999, -45.716, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 31.0000012626559, s: [-87.125, -114.285, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 1,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 17,
                  s: [10.133, 10.133, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 39.0000015885026,
          st: 1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 34,
          ty: 4,
          nm: "Dot 12",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 12,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 13,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 14.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 15,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 16.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 17.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 18.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 20,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 21.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 22.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 23.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 24,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 25.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 26.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 27.866,
                  s: [9]
                },
                { t: 29.0000011811942, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -84.379, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 2,
                  s: [-45.716, 30.999, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 30.0000012219251, s: [-114.285, 87.125, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 16,
                  s: [25.333, 25.333, 100]
                },
                { t: 37.0000015070409, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 2.00000008146167,
          op: 38.0000015477717,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 35,
          ty: 4,
          nm: "Dot 11",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 12,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 13,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 14.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 15,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 16.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 17.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 18.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 20,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 21.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 22.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 23.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 24,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 25.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 26.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 27.866,
                  s: [9]
                },
                { t: 29.0000011811942, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 185.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 2,
                  s: [30.999, 45.716, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 30.0000012219251, s: [87.125, 114.285, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 16,
                  s: [25.333, 25.333, 100]
                },
                { t: 37.0000015070409, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 2.00000008146167,
          op: 38.0000015477717,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 36,
          ty: 4,
          nm: "Dot 10",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 12,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 13,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 14.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 15,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 16.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 17.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 18.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 20,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 21.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 22.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 23.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 24,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 25.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 26.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 27.866,
                  s: [9]
                },
                { t: 29.0000011811942, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 95.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 2,
                  s: [45.716, -30.999, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 30.0000012219251, s: [114.285, -87.125, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 16,
                  s: [25.333, 25.333, 100]
                },
                { t: 37.0000015070409, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 2.00000008146167,
          op: 38.0000015477717,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 37,
          ty: 4,
          nm: "Dot 9",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 12,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 13,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 14.266,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 15,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 16.534,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 17.666,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 18.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 20,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 21.066,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 22.2,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 23.334,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 24,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 25.6,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 26.734,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.07] },
                  t: 27.866,
                  s: [9]
                },
                { t: 29.0000011811942, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 5.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 2,
                  s: [-30.999, -45.716, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 30.0000012219251, s: [-87.125, -114.285, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 16,
                  s: [25.333, 25.333, 100]
                },
                { t: 37.0000015070409, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 2.00000008146167,
          op: 38.0000015477717,
          st: 0,
          bm: 0
        },
        {
          ddd: 0,
          ind: 38,
          ty: 4,
          nm: "Dot 8",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 14,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 15.059,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 16.4,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 17.176,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 18.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 20,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 21.2,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 22.47,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 23.6,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 24.8,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 26,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 26.706,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 28.4,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 29.6,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 30.8,
                  s: [9]
                },
                { t: 32.0000013033867, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: -84.379, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 1,
                  s: [-45.716, 30.999, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 29.0000011811942, s: [-114.285, 87.125, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -1,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 15,
                  s: [42.222, 42.222, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 1.00000004073083,
          op: 37.0000015070409,
          st: -1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 39,
          ty: 4,
          nm: "Dot 7",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 14,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 15.059,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 16.4,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 17.176,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 18.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 20,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 21.2,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 22.47,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 23.6,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 24.8,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 26,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 26.706,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 28.4,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 29.6,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 30.8,
                  s: [9]
                },
                { t: 32.0000013033867, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 185.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 1,
                  s: [30.999, 45.716, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 29.0000011811942, s: [87.125, 114.285, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -1,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 15,
                  s: [42.222, 42.222, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 1.00000004073083,
          op: 37.0000015070409,
          st: -1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 40,
          ty: 4,
          nm: "Dot 6",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 14,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 15.059,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 16.4,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 17.176,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 18.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 20,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 21.2,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 22.47,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 23.6,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 24.8,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 26,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 26.706,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 28.4,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 29.6,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 30.8,
                  s: [9]
                },
                { t: 32.0000013033867, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 95.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 1,
                  s: [45.716, -30.999, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 29.0000011811942, s: [114.285, -87.125, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -1,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 15,
                  s: [42.222, 42.222, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 1.00000004073083,
          op: 37.0000015070409,
          st: -1.00000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 41,
          ty: 4,
          nm: "Dot 5",
          parent: 1,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [0.993] },
                  o: { x: [0.333], y: [0] },
                  t: 14,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.014] },
                  o: { x: [0.333], y: [-0.009] },
                  t: 15.059,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.982] },
                  o: { x: [0.333], y: [0.011] },
                  t: 16.4,
                  s: [97.666]
                },
                {
                  i: { x: [0.667], y: [1.039] },
                  o: { x: [0.333], y: [-0.037] },
                  t: 17.176,
                  s: [29.954]
                },
                {
                  i: { x: [0.667], y: [0.903] },
                  o: { x: [0.333], y: [0.069] },
                  t: 18.8,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.104] },
                  o: { x: [0.333], y: [-0.097] },
                  t: 20,
                  s: [70.666]
                },
                {
                  i: { x: [0.667], y: [0.953] },
                  o: { x: [0.333], y: [0.036] },
                  t: 21.2,
                  s: [100]
                },
                {
                  i: { x: [0.667], y: [1.066] },
                  o: { x: [0.333], y: [-0.06] },
                  t: 22.47,
                  s: [10.318]
                },
                {
                  i: { x: [0.667], y: [0.923] },
                  o: { x: [0.333], y: [0.072] },
                  t: 23.6,
                  s: [72.705]
                },
                {
                  i: { x: [0.667], y: [0.792] },
                  o: { x: [0.333], y: [0.388] },
                  t: 24.8,
                  s: [12.066]
                },
                {
                  i: { x: [0.667], y: [1.058] },
                  o: { x: [0.333], y: [-0.029] },
                  t: 26,
                  s: [0]
                },
                {
                  i: { x: [0.667], y: [0.91] },
                  o: { x: [0.333], y: [0.211] },
                  t: 26.706,
                  s: [50.803]
                },
                {
                  i: { x: [0.667], y: [0.707] },
                  o: { x: [0.333], y: [0.139] },
                  t: 28.4,
                  s: [17.383]
                },
                {
                  i: { x: [0.667], y: [0.911] },
                  o: { x: [0.333], y: [-0.643] },
                  t: 29.6,
                  s: [2]
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [-0.069] },
                  t: 30.8,
                  s: [9]
                },
                { t: 32.0000013033867, s: [0] }
              ],
              ix: 11
            },
            r: { a: 0, k: 5.621, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.1, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 1,
                  s: [-30.999, -45.716, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0]
                },
                { t: 29.0000011811942, s: [-87.125, -114.285, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: -1,
                  s: [21.111, 21.111, 100]
                },
                {
                  i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 15,
                  s: [42.222, 42.222, 100]
                },
                { t: 38.0000015477717, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [9.559, 9.559], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, -0.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 1.00000004073083,
          op: 37.0000015070409,
          st: -1.00000004073083,
          bm: 0
        }
      ]
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Circle 10",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.518, y: 0.837 },
                  o: { x: 0.167, y: 0.167 },
                  t: 10,
                  s: [557.473, 619.686, 0],
                  to: [-18.347, 0, 0],
                  ti: [45.124, 0, 0]
                },
                {
                  i: { x: 0.52, y: 1 },
                  o: { x: 0.215, y: 0.219 },
                  t: 16,
                  s: [443.615, 619.686, 0],
                  to: [-32.392, 0, 0],
                  ti: [13.17, 0, 0]
                },
                { t: 28.0000011404634, s: [368.373, 619.686, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 10,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 16,
                  s: [61, 61, 100]
                },
                { t: 32.0000013033867, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 10.0000004073083,
          op: 28.0000011404634,
          st: 10.0000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Circle 9",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 12,
                  s: [928.073, 309.366, 0],
                  to: [-31.517, 0, 0],
                  ti: [31.517, 0, 0]
                },
                { t: 30.0000012219251, s: [738.973, 309.366, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 12,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 18,
                  s: [61, 61, 100]
                },
                { t: 34.0000013848484, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 12.00000048877,
          op: 28.0000011404634,
          st: 12.00000048877,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Circle 11",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [742.073, 488.286, 0],
                  to: [-31.517, 0, 0],
                  ti: [31.517, 0, 0]
                },
                { t: 22.0000008960784, s: [552.973, 488.286, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 4,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 10,
                  s: [61, 61, 100]
                },
                { t: 26.0000010590017, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 4.00000016292334,
          op: 23.0000009368092,
          st: 4.00000016292334,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Circle 8",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 4,
                  s: [684.073, 197.286, 0],
                  to: [-31.517, 0, 0],
                  ti: [31.517, 0, 0]
                },
                { t: 22.0000008960784, s: [494.973, 197.286, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 4,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 10,
                  s: [61, 61, 100]
                },
                { t: 26.0000010590017, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 4.00000016292334,
          op: 23.0000009368092,
          st: 4.00000016292334,
          bm: 0
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Circle 7",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 7,
                  s: [623.073, 61.566, 0],
                  to: [-31.517, 0, 0],
                  ti: [31.517, 0, 0]
                },
                { t: 25.0000010182709, s: [433.973, 61.566, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 7,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 13,
                  s: [61, 61, 100]
                },
                { t: 29.0000011811942, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 7.00000028511585,
          op: 26.0000010590017,
          st: 7.00000028511585,
          bm: 0
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Circle 6",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 6,
                  s: [491.573, 219.746, 0],
                  to: [-31.517, 0, 0],
                  ti: [31.517, 0, 0]
                },
                { t: 24.00000097754, s: [302.473, 219.746, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 6,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 12,
                  s: [61, 61, 100]
                },
                { t: 28.0000011404634, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 6.00000024438501,
          op: 25.0000010182709,
          st: 6.00000024438501,
          bm: 0
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Circle 5",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 9,
                  s: [458.373, 431.166, 0],
                  to: [-31.517, 0, 0],
                  ti: [31.517, 0, 0]
                },
                { t: 27.0000010997325, s: [269.273, 431.166, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 9,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 15,
                  s: [61, 61, 100]
                },
                { t: 31.0000012626559, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 9.00000036657752,
          op: 28.0000011404634,
          st: 9.00000036657752,
          bm: 0
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Circle 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 3,
                  s: [936, 438.6, 0],
                  to: [-45.533, 0, 0],
                  ti: [45.533, 0, 0]
                },
                { t: 21.0000008553475, s: [662.8, 438.6, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 9,
                  s: [122, 122, 100]
                },
                { t: 25.0000010182709, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 3.00000012219251,
          op: 22.0000008960784,
          st: 3.00000012219251,
          bm: 0
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Circle 2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.518, y: 0.837 },
                  o: { x: 0.167, y: 0.167 },
                  t: 6,
                  s: [805, 267.8, 0],
                  to: [-36.701, 0, 0],
                  ti: [90.263, 0, 0]
                },
                {
                  i: { x: 0.52, y: 1 },
                  o: { x: 0.215, y: 0.219 },
                  t: 12,
                  s: [577.226, 267.8, 0],
                  to: [-64.761, 0, 0],
                  ti: [26.332, 0, 0]
                },
                { t: 24.00000097754, s: [426.8, 267.8, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 6,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 12,
                  s: [122, 122, 100]
                },
                { t: 28.0000011404634, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 6.00000024438501,
          op: 25.0000010182709,
          st: 6.00000024438501,
          bm: 0
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Circle 4",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 10,
                  s: [744, 353.2, 0],
                  to: [-63.033, 0, 0],
                  ti: [63.033, 0, 0]
                },
                { t: 28.0000011404634, s: [365.8, 353.2, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 10,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 16,
                  s: [122, 122, 100]
                },
                { t: 32.0000013033867, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 10.0000004073083,
          op: 28.0000011404634,
          st: 10.0000004073083,
          bm: 0
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "Circle",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.32, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 0,
                  s: [475.6, 341, 0],
                  to: [-63.033, 0, 0],
                  ti: [63.033, 0, 0]
                },
                { t: 18.000000733155, s: [97.4, 341, 0] }
              ],
              ix: 2
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100]
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                  t: 6,
                  s: [122, 122, 100]
                },
                { t: 22.0000008960784, s: [0, 0, 100] }
              ],
              ix: 6
            }
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [19.802, 19.802], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0.503, -1.759], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform"
                }
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false
            }
          ],
          ip: 0,
          op: 19.0000007738859,
          st: 0,
          bm: 0
        }
      ]
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Camada 1",
      parent: 39,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.42], y: [1] },
              o: { x: [0.56], y: [0] },
              t: 78,
              s: [0]
            },
            {
              i: { x: [0.42], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 126,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 161,
              s: [100]
            },
            { t: 195.000007942513, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [3.334, 8.408, 0], ix: 2 },
        a: { a: 0, k: [3.334, 8.408, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-34.361, -0.722],
                    [0, 0],
                    [0, -70.035],
                    [0, 0],
                    [10.802, -1.062],
                    [0.358, 12.035],
                    [0, 0],
                    [16.617, 16.218],
                    [23.194, -0.547],
                    [0, 0],
                    [0, 0],
                    [0, -47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.177, -4.803],
                    [0, 0],
                    [24.598, -24.186],
                    [33.458, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 70.033],
                    [0, 0],
                    [-12.411, -1.239],
                    [0, 0],
                    [0, -10.812],
                    [0, 0],
                    [-16.617, -16.218],
                    [-23.194, 0.547],
                    [0, 47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.177, 4.803],
                    [0, 0],
                    [-24.598, 24.186]
                  ],
                  o: [
                    [0, 0],
                    [68.895, 1.263],
                    [0, 0],
                    [0, 10.81],
                    [-12.413, 1.06],
                    [0, 0],
                    [0, -23.329],
                    [-16.617, -16.219],
                    [0, 0],
                    [0, 0],
                    [-46.214, 1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.627, 0.001],
                    [0, 0],
                    [0, 34.29],
                    [-23.872, 23.462],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-68.892, -1.266],
                    [0, 0],
                    [0, -12.21],
                    [0, 0],
                    [10.803, 1.06],
                    [0, 0],
                    [0, 23.329],
                    [16.618, 16.219],
                    [46.215, -1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-4.627, 0],
                    [0, 0],
                    [0, -34.29],
                    [24.597, -24.185]
                  ],
                  v: [
                    [3.087, -235.208],
                    [3.089, -235.208],
                    [128.056, -105.979],
                    [128.056, -75.54],
                    [109.265, -54.296],
                    [86.208, -74.823],
                    [86.208, -107.972],
                    [60.275, -169.208],
                    [-1.635, -193.629],
                    [-1.623, -193.129],
                    [-1.635, -193.629],
                    [-85.459, -106.161],
                    [-85.459, -5.421],
                    [-85.459, -4.921],
                    [-84.959, -4.921],
                    [118.933, -5.103],
                    [118.934, -5.103],
                    [127.693, 3.466],
                    [127.693, 139.708],
                    [89.533, 230.307],
                    [0.374, 266.763],
                    [0.37, 266.763],
                    [0.366, 266.763],
                    [0.362, 266.763],
                    [0.357, 266.763],
                    [0.353, 266.763],
                    [0.349, 266.763],
                    [0.344, 266.763],
                    [0.34, 266.763],
                    [0.336, 266.763],
                    [0.332, 266.763],
                    [0.327, 266.763],
                    [0.323, 266.763],
                    [0.319, 266.763],
                    [0.314, 266.763],
                    [0.31, 266.763],
                    [0.306, 266.763],
                    [0.301, 266.763],
                    [0.297, 266.763],
                    [0.293, 266.763],
                    [0.288, 266.763],
                    [0.284, 266.763],
                    [0.28, 266.763],
                    [0.275, 266.763],
                    [0.271, 266.763],
                    [0.267, 266.763],
                    [0.262, 266.763],
                    [0.258, 266.763],
                    [0.254, 266.763],
                    [0.249, 266.763],
                    [0.245, 266.763],
                    [0.241, 266.763],
                    [0.236, 266.763],
                    [0.232, 266.763],
                    [0.227, 266.763],
                    [0.223, 266.763],
                    [0.219, 266.763],
                    [0.214, 266.763],
                    [0.21, 266.763],
                    [0.205, 266.763],
                    [0.201, 266.763],
                    [0.197, 266.763],
                    [0.192, 266.763],
                    [0.188, 266.763],
                    [0.183, 266.763],
                    [0.179, 266.763],
                    [0.174, 266.763],
                    [0.17, 266.763],
                    [0.166, 266.763],
                    [0.161, 266.763],
                    [0.157, 266.763],
                    [0.152, 266.763],
                    [0.148, 266.763],
                    [0.143, 266.763],
                    [0.139, 266.763],
                    [0.134, 266.763],
                    [0.13, 266.763],
                    [0.126, 266.763],
                    [0.121, 266.763],
                    [0.117, 266.763],
                    [0.112, 266.763],
                    [0.108, 266.763],
                    [0.103, 266.763],
                    [0.099, 266.763],
                    [0.094, 266.763],
                    [0.09, 266.763],
                    [0.085, 266.763],
                    [0.081, 266.763],
                    [0.076, 266.763],
                    [0.072, 266.763],
                    [0.067, 266.763],
                    [0.063, 266.763],
                    [0.058, 266.763],
                    [0.054, 266.763],
                    [0.049, 266.763],
                    [0.045, 266.763],
                    [0.04, 266.763],
                    [0.036, 266.763],
                    [0.031, 266.763],
                    [0.027, 266.763],
                    [0.022, 266.763],
                    [0.017, 266.763],
                    [0.013, 266.763],
                    [0.008, 266.763],
                    [0.004, 266.763],
                    [-0.001, 266.763],
                    [-0.005, 266.763],
                    [-0.01, 266.763],
                    [-0.014, 266.763],
                    [-0.019, 266.763],
                    [-0.023, 266.763],
                    [-0.028, 266.763],
                    [-0.033, 266.763],
                    [-0.037, 266.763],
                    [-0.042, 266.763],
                    [-0.046, 266.763],
                    [-0.051, 266.763],
                    [-0.055, 266.763],
                    [-0.06, 266.763],
                    [-0.065, 266.763],
                    [-0.069, 266.763],
                    [-0.074, 266.763],
                    [-0.078, 266.763],
                    [-0.083, 266.763],
                    [-0.088, 266.763],
                    [-0.092, 266.763],
                    [-0.097, 266.763],
                    [-0.101, 266.763],
                    [-0.106, 266.763],
                    [-0.111, 266.763],
                    [-0.115, 266.763],
                    [-0.12, 266.763],
                    [-0.124, 266.763],
                    [-0.129, 266.763],
                    [-0.134, 266.763],
                    [-0.138, 266.763],
                    [-0.143, 266.763],
                    [-0.148, 266.763],
                    [-0.152, 266.763],
                    [-0.157, 266.763],
                    [-0.162, 266.763],
                    [-0.166, 266.763],
                    [-0.171, 266.763],
                    [-0.175, 266.763],
                    [-0.18, 266.763],
                    [-0.185, 266.763],
                    [-0.189, 266.763],
                    [-0.194, 266.763],
                    [-0.199, 266.763],
                    [-0.203, 266.763],
                    [-0.208, 266.763],
                    [-0.213, 266.763],
                    [-0.217, 266.763],
                    [-0.222, 266.763],
                    [-0.227, 266.763],
                    [-0.231, 266.763],
                    [-0.236, 266.763],
                    [-0.241, 266.763],
                    [-0.245, 266.763],
                    [-0.25, 266.763],
                    [-0.255, 266.763],
                    [-0.259, 266.763],
                    [-0.264, 266.763],
                    [-0.269, 266.763],
                    [-0.273, 266.763],
                    [-0.278, 266.763],
                    [-0.283, 266.763],
                    [-0.287, 266.763],
                    [-0.292, 266.763],
                    [-0.297, 266.763],
                    [-0.302, 266.763],
                    [-0.306, 266.763],
                    [-0.311, 266.763],
                    [-0.316, 266.763],
                    [-0.32, 266.763],
                    [-0.325, 266.763],
                    [-0.33, 266.763],
                    [-0.334, 266.763],
                    [-0.339, 266.763],
                    [-0.344, 266.763],
                    [-0.349, 266.763],
                    [-0.353, 266.763],
                    [-0.358, 266.763],
                    [-0.363, 266.763],
                    [-0.367, 266.763],
                    [-0.372, 266.763],
                    [-0.377, 266.763],
                    [-0.382, 266.763],
                    [-0.386, 266.763],
                    [-0.391, 266.763],
                    [-0.396, 266.763],
                    [-0.401, 266.763],
                    [-0.405, 266.763],
                    [-0.41, 266.763],
                    [-0.415, 266.763],
                    [-0.419, 266.763],
                    [-0.424, 266.763],
                    [-0.429, 266.763],
                    [-0.434, 266.763],
                    [-0.438, 266.763],
                    [-0.443, 266.763],
                    [-0.448, 266.763],
                    [-0.453, 266.763],
                    [-0.457, 266.763],
                    [-0.462, 266.763],
                    [-0.467, 266.763],
                    [-0.472, 266.763],
                    [-0.476, 266.763],
                    [-0.481, 266.763],
                    [-0.486, 266.763],
                    [-0.491, 266.763],
                    [-0.495, 266.763],
                    [-0.5, 266.763],
                    [-0.505, 266.763],
                    [-0.51, 266.763],
                    [-0.514, 266.763],
                    [-0.519, 266.763],
                    [-0.524, 266.763],
                    [-0.529, 266.763],
                    [-0.533, 266.763],
                    [-0.538, 266.763],
                    [-0.543, 266.763],
                    [-0.548, 266.763],
                    [-0.552, 266.763],
                    [-0.557, 266.763],
                    [-0.562, 266.763],
                    [-0.567, 266.763],
                    [-0.572, 266.763],
                    [-0.576, 266.763],
                    [-0.581, 266.763],
                    [-0.586, 266.763],
                    [-0.591, 266.763],
                    [-0.595, 266.763],
                    [-0.6, 266.763],
                    [-0.605, 266.763],
                    [-0.61, 266.763],
                    [-0.614, 266.763],
                    [-0.619, 266.763],
                    [-0.624, 266.763],
                    [-0.629, 266.763],
                    [-0.634, 266.763],
                    [-0.638, 266.763],
                    [-0.643, 266.763],
                    [-0.648, 266.763],
                    [-0.653, 266.763],
                    [-0.657, 266.763],
                    [-0.662, 266.763],
                    [-0.667, 266.763],
                    [-0.672, 266.763],
                    [-0.677, 266.763],
                    [-0.681, 266.763],
                    [-0.686, 266.763],
                    [-0.691, 266.763],
                    [-0.696, 266.763],
                    [-0.7, 266.763],
                    [-0.705, 266.763],
                    [-0.71, 266.763],
                    [-0.715, 266.763],
                    [-0.72, 266.763],
                    [-0.724, 266.763],
                    [-0.729, 266.763],
                    [-0.734, 266.763],
                    [-0.739, 266.763],
                    [-0.744, 266.763],
                    [-0.748, 266.763],
                    [-0.753, 266.763],
                    [-0.758, 266.763],
                    [-0.763, 266.763],
                    [-0.767, 266.763],
                    [-0.772, 266.763],
                    [-0.777, 266.763],
                    [-0.782, 266.763],
                    [-0.787, 266.763],
                    [-0.791, 266.763],
                    [-0.796, 266.763],
                    [-0.801, 266.763],
                    [-0.806, 266.763],
                    [-0.811, 266.763],
                    [-0.815, 266.763],
                    [-0.82, 266.763],
                    [-0.825, 266.763],
                    [-0.83, 266.763],
                    [-0.834, 266.763],
                    [-0.839, 266.763],
                    [-0.844, 266.763],
                    [-0.849, 266.763],
                    [-0.854, 266.763],
                    [-0.858, 266.763],
                    [-0.863, 266.763],
                    [-0.868, 266.763],
                    [-0.873, 266.763],
                    [-0.878, 266.763],
                    [-0.882, 266.763],
                    [-0.887, 266.763],
                    [-0.892, 266.763],
                    [-0.897, 266.763],
                    [-0.901, 266.763],
                    [-0.906, 266.763],
                    [-0.911, 266.763],
                    [-0.916, 266.763],
                    [-0.921, 266.763],
                    [-0.925, 266.763],
                    [-0.93, 266.763],
                    [-0.935, 266.763],
                    [-0.94, 266.763],
                    [-0.945, 266.763],
                    [-0.949, 266.763],
                    [-0.954, 266.763],
                    [-0.959, 266.763],
                    [-0.964, 266.763],
                    [-0.968, 266.763],
                    [-0.973, 266.763],
                    [-0.978, 266.763],
                    [-0.983, 266.763],
                    [-0.988, 266.763],
                    [-0.992, 266.763],
                    [-0.997, 266.763],
                    [-1.002, 266.763],
                    [-1.007, 266.763],
                    [-1.011, 266.763],
                    [-1.016, 266.763],
                    [-1.021, 266.763],
                    [-1.026, 266.763],
                    [-1.03, 266.763],
                    [-1.035, 266.763],
                    [-1.04, 266.763],
                    [-1.045, 266.763],
                    [-1.05, 266.763],
                    [-1.054, 266.763],
                    [-1.059, 266.763],
                    [-1.064, 266.763],
                    [-1.069, 266.763],
                    [-1.073, 266.763],
                    [-1.078, 266.763],
                    [-1.083, 266.763],
                    [-1.088, 266.763],
                    [-1.092, 266.763],
                    [-1.097, 266.763],
                    [-1.102, 266.763],
                    [-1.107, 266.763],
                    [-1.111, 266.763],
                    [-1.116, 266.763],
                    [-1.121, 266.763],
                    [-1.126, 266.763],
                    [-1.13, 266.763],
                    [-1.135, 266.763],
                    [-1.14, 266.763],
                    [-1.145, 266.763],
                    [-1.149, 266.763],
                    [-1.154, 266.763],
                    [-1.159, 266.763],
                    [-1.164, 266.763],
                    [-1.168, 266.763],
                    [-1.173, 266.763],
                    [-1.178, 266.763],
                    [-1.183, 266.763],
                    [-1.187, 266.763],
                    [-1.192, 266.763],
                    [-1.197, 266.763],
                    [-1.202, 266.763],
                    [-1.206, 266.763],
                    [-1.211, 266.763],
                    [-1.216, 266.763],
                    [-1.22, 266.763],
                    [-1.225, 266.763],
                    [-1.23, 266.763],
                    [-1.235, 266.763],
                    [-1.239, 266.763],
                    [-1.244, 266.763],
                    [-1.249, 266.763],
                    [-1.254, 266.763],
                    [-1.258, 266.763],
                    [-1.263, 266.763],
                    [-1.268, 266.763],
                    [-1.272, 266.763],
                    [-1.277, 266.763],
                    [-1.282, 266.763],
                    [-1.286, 266.763],
                    [-1.291, 266.763],
                    [-1.296, 266.763],
                    [-1.301, 266.763],
                    [-1.305, 266.763],
                    [-1.31, 266.763],
                    [-1.315, 266.763],
                    [-1.319, 266.763],
                    [-1.324, 266.763],
                    [-1.329, 266.763],
                    [-1.333, 266.763],
                    [-1.338, 266.763],
                    [-1.343, 266.763],
                    [-1.347, 266.763],
                    [-1.352, 266.763],
                    [-1.357, 266.763],
                    [-1.362, 266.763],
                    [-1.366, 266.763],
                    [-1.371, 266.763],
                    [-1.376, 266.763],
                    [-1.38, 266.763],
                    [-1.385, 266.763],
                    [-1.39, 266.763],
                    [-1.394, 266.763],
                    [-1.399, 266.763],
                    [-1.404, 266.763],
                    [-1.408, 266.763],
                    [-1.413, 266.763],
                    [-1.418, 266.763],
                    [-1.422, 266.763],
                    [-1.427, 266.763],
                    [-1.431, 266.763],
                    [-1.436, 266.763],
                    [-1.441, 266.763],
                    [-1.445, 266.763],
                    [-1.45, 266.763],
                    [-1.455, 266.763],
                    [-1.459, 266.763],
                    [-1.464, 266.763],
                    [-1.469, 266.763],
                    [-1.473, 266.763],
                    [-1.478, 266.763],
                    [-1.482, 266.763],
                    [-1.487, 266.763],
                    [-1.492, 266.763],
                    [-1.496, 266.763],
                    [-1.501, 266.763],
                    [-1.505, 266.763],
                    [-1.51, 266.763],
                    [-1.515, 266.763],
                    [-1.519, 266.763],
                    [-1.524, 266.763],
                    [-1.529, 266.763],
                    [-1.533, 266.763],
                    [-1.538, 266.763],
                    [-1.542, 266.763],
                    [-1.547, 266.763],
                    [-1.551, 266.763],
                    [-1.556, 266.763],
                    [-1.561, 266.763],
                    [-1.565, 266.763],
                    [-1.57, 266.763],
                    [-1.574, 266.763],
                    [-1.579, 266.763],
                    [-1.583, 266.763],
                    [-1.588, 266.763],
                    [-1.593, 266.763],
                    [-1.597, 266.763],
                    [-1.602, 266.763],
                    [-1.606, 266.763],
                    [-1.611, 266.763],
                    [-1.615, 266.763],
                    [-1.62, 266.763],
                    [-1.624, 266.763],
                    [-1.629, 266.763],
                    [-1.634, 266.763],
                    [-1.638, 266.763],
                    [-1.643, 266.763],
                    [-1.647, 266.763],
                    [-1.652, 266.763],
                    [-1.656, 266.763],
                    [-1.661, 266.763],
                    [-1.665, 266.763],
                    [-1.67, 266.763],
                    [-1.674, 266.763],
                    [-1.679, 266.763],
                    [-1.683, 266.763],
                    [-1.688, 266.763],
                    [-1.692, 266.763],
                    [-1.697, 266.763],
                    [-1.701, 266.763],
                    [-1.706, 266.763],
                    [-1.71, 266.763],
                    [-1.715, 266.763],
                    [-1.719, 266.763],
                    [-1.724, 266.763],
                    [-1.728, 266.763],
                    [-1.733, 266.763],
                    [-1.737, 266.763],
                    [-1.742, 266.763],
                    [-1.746, 266.763],
                    [-1.75, 266.763],
                    [-1.755, 266.763],
                    [-1.759, 266.763],
                    [-1.764, 266.763],
                    [-1.768, 266.763],
                    [-1.773, 266.763],
                    [-1.777, 266.763],
                    [-1.782, 266.763],
                    [-1.786, 266.763],
                    [-1.79, 266.763],
                    [-1.795, 266.763],
                    [-1.799, 266.763],
                    [-1.804, 266.763],
                    [-1.808, 266.763],
                    [-1.813, 266.763],
                    [-1.817, 266.763],
                    [-1.821, 266.763],
                    [-1.826, 266.763],
                    [-1.83, 266.763],
                    [-1.835, 266.763],
                    [-1.839, 266.763],
                    [-1.843, 266.763],
                    [-1.848, 266.763],
                    [-1.852, 266.763],
                    [-1.856, 266.763],
                    [-1.861, 266.763],
                    [-1.865, 266.763],
                    [-1.87, 266.763],
                    [-1.874, 266.763],
                    [-1.878, 266.763],
                    [-1.883, 266.763],
                    [-1.887, 266.763],
                    [-1.891, 266.763],
                    [-1.896, 266.763],
                    [-1.9, 266.763],
                    [-1.904, 266.763],
                    [-1.909, 266.763],
                    [-1.913, 266.763],
                    [-1.917, 266.763],
                    [-1.922, 266.763],
                    [-1.926, 266.763],
                    [-1.93, 266.763],
                    [-1.935, 266.763],
                    [-1.939, 266.763],
                    [-1.943, 266.763],
                    [-1.947, 266.763],
                    [-1.952, 266.763],
                    [-1.956, 266.763],
                    [-1.96, 266.763],
                    [-1.965, 266.763],
                    [-1.969, 266.763],
                    [-1.973, 266.763],
                    [-1.977, 266.763],
                    [-1.982, 266.763],
                    [-126.944, 137.534],
                    [-126.944, 106.37],
                    [-104.073, 85.669],
                    [-104.072, 85.669],
                    [-85.278, 106.914],
                    [-85.278, 139.527],
                    [-59.346, 200.763],
                    [2.565, 225.184],
                    [86.389, 137.715],
                    [86.389, 36.976],
                    [86.389, 36.476],
                    [85.889, 36.476],
                    [-96.035, 36.476],
                    [-117.822, 36.476],
                    [-126.581, 27.908],
                    [-126.581, -108.154],
                    [-88.422, -198.752]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "gs",
              o: { a: 0, k: 100, ix: 9 },
              w: { a: 0, k: 1, ix: 10 },
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 8
                }
              },
              s: { a: 0, k: [0.782, -233.555], ix: 4 },
              e: { a: 0, k: [-3.779, 267.443], ix: 5 },
              t: 1,
              lc: 1,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 13 },
              bm: 0,
              nm: "Gradient Stroke 1",
              mn: "ADBE Vector Graphic - G-Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 4.00000016292334,
      op: 347.0000141336,
      st: 47.0000019143492,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 2",
      parent: 9,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [76.5, 154.375, 0], ix: 2 },
        a: { a: 0, k: [-4, -23.125, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 49,
              s: [100, 0, 100]
            },
            {
              i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 96,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
              o: { x: [0.75, 0.75, 0.75], y: [0, 0, 0] },
              t: 121,
              s: [100, 100, 100]
            },
            { t: 157.000006394741, s: [100, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [103.5, 12.25], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.03137254902, 0.254901960784, 0.219607858097, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [-4, -23.125], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 7.00000028511585,
      op: 227.0000092459,
      st: 50.0000020365418,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 1",
      parent: 11,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [76.5, 154.375, 0], ix: 2 },
        a: { a: 0, k: [-4, -23.125, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 49,
              s: [100, 0, 100]
            },
            {
              i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 97,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
              o: { x: [0.75, 0.75, 0.75], y: [0, 0, 0] },
              t: 122,
              s: [100, 100, 100]
            },
            { t: 158.000006435472, s: [100, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [103.5, 12.25], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.011764706817, 0.121568634931, 0.294117647059, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [-4, -23.125], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 8.00000032584668,
      op: 227.0000092459,
      st: 51.0000020772726,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "Group 338.png",
      cl: "png",
      parent: 6,
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 286,
              s: [0]
            },
            { t: 299.00001217852, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [4.677, -62.756, 0], ix: 2 },
        a: { a: 0, k: [80.5, 147.5, 0], ix: 1 },
        s: { a: 0, k: [119.655, 119.655, 100], ix: 6 }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              v: [
                [129.5, 301],
                [24.5, 301],
                [25, 153.969],
                [130, 153.969]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1"
        }
      ],
      ip: 86.0000035028518,
      op: 314.000012789482,
      st: 138.000005620855,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "Group 338.png",
      cl: "png",
      parent: 7,
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 286,
              s: [0]
            },
            { t: 299.00001217852, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [4.732, 76.137, 0], ix: 2 },
        a: { a: 0, k: [80.5, 147.5, 0], ix: 1 },
        s: { a: 0, k: [121.243, 121.243, 100], ix: 6 }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              v: [
                [130, 8],
                [25, 8],
                [25, 154.281],
                [130, 154.281]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1"
        }
      ],
      ip: 43.0000017514259,
      op: 313.000012748751,
      st: 138.000005620855,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Camada 13",
      parent: 9,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              t: 142,
              s: [0]
            },
            { t: 177.000007209358, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.7, y: 1 },
              o: { x: 0.55, y: 0 },
              t: 272,
              s: [77.056, 153.928, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.7, y: 1 },
              o: { x: 0.55, y: 0 },
              t: 286,
              s: [77.056, 172.294, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 299.00001217852, s: [77.056, 104.494, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.556, -52.822, 0], ix: 1 },
        s: { a: 0, k: [83.574, 83.574, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-34.425, 0],
                    [0, 34.425],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 34.606],
                    [34.606, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-61.954, -52.822],
                    [-61.954, 52.158],
                    [0.556, 114.667],
                    [63.065, 52.158],
                    [63.065, -52.822]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [-20, -48.167], ix: 5 },
              e: { a: 0, k: [-3.193, 95.281], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 8.00000032584668,
      op: 351.000014296523,
      st: 51.0000020772726,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Camada 12",
      parent: 11,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.75], y: [0] },
              t: 141,
              s: [0]
            },
            { t: 176.000007168627, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.7, y: 1 },
              o: { x: 0.55, y: 0 },
              t: 272,
              s: [77.056, 154.155, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.7, y: 1 },
              o: { x: 0.55, y: 0 },
              t: 286,
              s: [77.056, 135.789, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 299.00001217852, s: [77.056, 190.762, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.556, 84.155, 0], ix: 1 },
        s: { a: 0, k: [82.479, 82.479, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [34.425, 0],
                    [0, -34.425],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, -34.606],
                    [-34.606, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [63.065, 83.974],
                    [63.132, -27.292],
                    [0.623, -89.801],
                    [-61.886, -27.292],
                    [-61.954, 84.155],
                    [63.065, 84.155]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.192, 0.208, 0.776, 0.5, 0.096, 0.437, 0.888, 1, 0,
                    0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [-25.104, -51.403], ix: 5 },
              e: { a: 0, k: [73.384, 100.706], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: -18.000000733155,
      op: 378.000015396256,
      st: 51.0000020772726,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 3,
      nm: "NULL CONTROL",
      parent: 26,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 51,
              s: [3.477, 44.772, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 193.000007861051, s: [3.477, 54.695, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [99.222, 99.222, 100], ix: 6 }
      },
      ao: 0,
      ip: 8.00000032584668,
      op: 378.000015396256,
      st: 51.0000020772726,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "Group 338.png",
      cl: "png",
      parent: 8,
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.976], y: [1] },
              o: { x: [1], y: [0] },
              t: 193,
              s: [100]
            },
            { t: 216.00000879786, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.28, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 49,
              s: [60, 24, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 97.000003950891, s: [60, 60, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [80.5, 147.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              v: [
                [129.5, 301],
                [24.5, 301],
                [25, 153.969],
                [130, 153.969]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1"
        }
      ],
      ip: -1.00000004073083,
      op: 227.0000092459,
      st: 51.0000020772726,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 3,
      nm: "NULL CONTROL",
      parent: 26,
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 51,
              s: [3.477, -21.706, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 183.000007453743, s: [3.477, -31.628, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [99.222, 99.222, 100], ix: 6 }
      },
      ao: 0,
      ip: -20.0000008146167,
      op: 226.000009205169,
      st: 51.0000020772726,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "Group 338.png",
      cl: "png",
      parent: 10,
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.976], y: [1] },
              o: { x: [1], y: [0] },
              t: 193,
              s: [100]
            },
            { t: 216.00000879786, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.28, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 49,
              s: [60, 91, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 97.000003950891, s: [60, 60, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [80.5, 147.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              v: [
                [130, 8],
                [25, 8],
                [25, 154.281],
                [130, 154.281]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1"
        }
      ],
      ip: -44.0000017921567,
      op: 226.000009205169,
      st: 51.0000020772726,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: "Boom 6",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [954, 546, 0], ix: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1 },
        s: { a: 0, k: [45, 45, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: "Fill",
          np: 9,
          mn: "ADBE Fill",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 10,
              nm: "Fill Mask",
              mn: "ADBE Fill-0001",
              ix: 1,
              v: { a: 0, k: 0, ix: 1 }
            },
            {
              ty: 7,
              nm: "All Masks",
              mn: "ADBE Fill-0007",
              ix: 2,
              v: { a: 0, k: 0, ix: 2 }
            },
            {
              ty: 2,
              nm: "Color",
              mn: "ADBE Fill-0002",
              ix: 3,
              v: {
                a: 0,
                k: [0, 0.458823561668, 0.760784387589, 1],
                ix: 3
              }
            },
            {
              ty: 7,
              nm: "Invert",
              mn: "ADBE Fill-0006",
              ix: 4,
              v: { a: 0, k: 0, ix: 4 }
            },
            {
              ty: 0,
              nm: "Horizontal Feather",
              mn: "ADBE Fill-0003",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            },
            {
              ty: 0,
              nm: "Vertical Feather",
              mn: "ADBE Fill-0004",
              ix: 6,
              v: { a: 0, k: 0, ix: 6 }
            },
            {
              ty: 0,
              nm: "Opacity",
              mn: "ADBE Fill-0005",
              ix: 7,
              v: { a: 0, k: 1, ix: 7 }
            }
          ]
        }
      ],
      w: 1000,
      h: 1000,
      ip: 83.0000033806593,
      op: 122.000004969162,
      st: 83.0000033806593,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 151,
              s: [891.705, 548.008, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 152,
              s: [892.205, 548.008, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 153,
              s: [892.705, 548.008, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 154,
              s: [893.205, 548.008, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 155,
              s: [893.705, 548.008, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 156,
              s: [900.705, 548.008, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 157.000006394741, s: [905.705, 548.008, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 75.8137530879574,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: "Camada 8",
      parent: 13,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.11, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 75.814,
              s: [124.257, 62.087, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.11, y: 1 },
              o: { x: 0.9, y: 0 },
              t: 119,
              s: [20.257, 60.587, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 184.18625750206, s: [121.757, 59.087, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 75.814,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 99.814,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.78, 0.78, 0.78], y: [0, 0, 0] },
              t: 152,
              s: [100, 100, 100]
            },
            { t: 166.000006761319, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.48, -5.041],
                    [-5.041, -3.48],
                    [-3.48, 5.041],
                    [5.041, 3.48]
                  ],
                  o: [
                    [-3.48, 5.041],
                    [5.041, 3.48],
                    [3.48, -5.041],
                    [-5.041, -3.48]
                  ],
                  v: [
                    [-9.127, -6.301],
                    [-6.301, 9.127],
                    [9.127, 6.301],
                    [6.301, -9.127]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.192, 0.208, 0.776, 0.5, 0.096, 0.437, 0.888, 1, 0,
                    0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0.76, 12.585], ix: 5 },
              e: { a: 0, k: [-1.147, -8.243], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 76,
                    s: [100, 100]
                  },
                  { t: 188.000007657397, s: [200, 200] }
                ],
                ix: 3
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 75.8137530879574,
      op: 180.00000733155,
      st: 64.8137526399181,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: "Camada 9",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.29, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 76.111,
              s: [965.653, 556.933, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.29, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 127.356,
              s: [1005.653, 538.933, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 172.000007005704, s: [950.653, 538.933, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [-54.923, 7.591, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 76.111,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 100.111,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.78, 0.78, 0.78], y: [0, 0, 0] },
              t: 147.575,
              s: [100, 100, 100]
            },
            { t: 167.262506812741, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [22.181, 22.181], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.192, 0.208, 0.776, 0.5, 0.096, 0.437, 0.888, 1, 0,
                    0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0.173, 12.034], ix: 5 },
              e: { a: 0, k: [-0.514, -10.115], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [-54.923, 7.591], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 76.1112531000748,
      op: 158.000006435472,
      st: 65.1112526520356,
      bm: 0
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: "Camada 7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.29, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 73.478,
              s: [954.153, 560.933, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.29, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 125,
              s: [911.653, 553.933, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 172.000007005704, s: [966.653, 553.933, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [-54.923, 7.591, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 73.478,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 97.478,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.78, 0.78, 0.78], y: [0, 0, 0] },
              t: 141.311,
              s: [100, 100, 100]
            },
            { t: 166.365006776185, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 74,
                    s: [22.181, 22.181]
                  },
                  { t: 174.000007087165, s: [15, 15] }
                ],
                ix: 2
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.192, 0.208, 0.776, 0.5, 0.096, 0.437, 0.888, 1, 0,
                    0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0.173, 12.034], ix: 5 },
              e: { a: 0, k: [-0.514, -10.115], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [-54.923, 7.591], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 73.4775029927999,
      op: 184.000007494474,
      st: 62.4775025447607,
      bm: 0
    },
    {
      ddd: 0,
      ind: 17,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 150,
              s: [1039.34, 547.82, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 151,
              s: [1019.84, 547.82, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 152,
              s: [1014.84, 547.82, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 153,
              s: [1010.34, 547.82, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 154,
              s: [1005.34, 547.82, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 155,
              s: [1000.34, 547.82, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 156,
              s: [994.84, 547.82, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 157.000006394741, s: [989.84, 547.82, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 71.8325029257977,
      op: 180.00000733155,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: "Camada 6",
      parent: 17,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.3, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 71.833,
              s: [-19.899, 67.729, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.838 },
              o: { x: 0.66, y: 0 },
              t: 111,
              s: [83.101, 156.229, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.3, y: 1 },
              o: { x: 0.167, y: 0.171 },
              t: 146,
              s: [83.101, 60.229, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 185.000007535204, s: [-17.899, 59.229, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [99.471, 94.274, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 71.833,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 95.833,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.75, 0.75, 0.75], y: [0, 0, 0] },
              t: 154,
              s: [100, 100, 100]
            },
            { t: 192.00000782032, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 72,
                    s: [37.942, 37.942]
                  },
                  { t: 184.000007494474, s: [50, 50] }
                ],
                ix: 2
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0.529, 19.726], ix: 5 },
              e: { a: 0, k: [0.104, -25.367], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [99.471, 94.274], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [87.938, 87.938], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 71.8325029257977,
      op: 159.000006476203,
      st: 60.8325024777585,
      bm: 0
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: "Camada 5",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 143,
              s: [100]
            },
            { t: 150.000006109625, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.28, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 70.234,
              s: [951.759, 546.699, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.69 },
              o: { x: 0.167, y: 0 },
              t: 109.234,
              s: [849.759, 638.199, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.756 },
              o: { x: 0.167, y: 0.056 },
              t: 135,
              s: [863.38, 723.907, 0],
              to: [0, 0, 0],
              ti: [-53.278, -0.917, 0]
            },
            {
              i: { x: 0.833, y: 0.909 },
              o: { x: 0.167, y: 0.113 },
              t: 141,
              s: [958.759, 777.199, 0],
              to: [0, 0, 0],
              ti: [-0.259, 95.301, 0]
            },
            {
              i: { x: 0.28, y: 1 },
              o: { x: 0.167, y: 0.265 },
              t: 145,
              s: [1061.759, 672.199, 0],
              to: [0.259, -95.301, 0],
              ti: [1.241, 16.301, 0]
            },
            { t: 154.000006272549, s: [1061.759, 547.199, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [-100.245, 92.974, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 70.234,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 94.234,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.75, 0.75, 0.75], y: [0, 0, 0] },
              t: 133,
              s: [100, 100, 100]
            },
            { t: 165.000006720588, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833], y: [0.833, 0.833] },
                    o: { x: [0.167, 0.167], y: [0.167, 0.167] },
                    t: 70,
                    s: [34.44, 34.44]
                  },
                  { t: 174.000007087165, s: [25, 25] }
                ],
                ix: 2
              },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0.495, 17.026], ix: 5 },
              e: { a: 0, k: [1.586, -17.151], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [-100.245, 92.974], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 70.2337528606793,
      op: 188.000007657397,
      st: 59.2337524126401,
      bm: 0
    },
    {
      ddd: 0,
      ind: 20,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 63,
              s: [0]
            },
            { t: 229.000009327361, s: [-115] }
          ],
          ix: 10
        },
        p: { a: 0, k: [959.354, 547.061, 0], ix: 2 },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 73.7525030040009,
      op: 375.033765275438,
      st: 51.0000020772726,
      bm: 0
    },
    {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: "Camada 4",
      parent: 20,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.28, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 86.034,
              s: [56.549, 69.058, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 125.033755092729, s: [65.049, 72.558, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [7.827, 21.309, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 86.034,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 110.034,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.23, 0.23, 0.23], y: [1, 1, 1] },
              o: { x: [0.75, 0.75, 0.75], y: [0, 0, 0] },
              t: 120,
              s: [100, 100, 100]
            },
            { t: 140.000005702317, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [11.091, 11.091], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0.048, -5.434], ix: 5 },
              e: { a: 0, k: [-0.003, 5.831], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [7.827, 21.309], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 86.0337535042265,
      op: 375.033765275438,
      st: 75.0337530561873,
      bm: 0
    },
    {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: "Camada 3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 143,
              s: [100]
            },
            { t: 150.000006109625, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.36, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 74.124,
              s: [953.429, 547.869, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.833, y: 0.764 },
              o: { x: 0.65, y: 0 },
              t: 113.124,
              s: [1064.429, 454.869, 0],
              to: [0, 0, 0],
              ti: [118.571, 9.131, 0]
            },
            {
              i: { x: 0.833, y: 0.868 },
              o: { x: 0.167, y: 0.091 },
              t: 136.886,
              s: [959.429, 313.869, 0],
              to: [0, 0, 0],
              ti: [2.571, -114.869, 0]
            },
            {
              i: { x: 0.36, y: 1 },
              o: { x: 0.167, y: 0.222 },
              t: 145.164,
              s: [847.429, 429.869, 0],
              to: [1.429, 36.869, 0],
              ti: [0, 0, 0]
            },
            { t: 154.000006272549, s: [847.429, 543.869, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [116.09, -93.691, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 82,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 106,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 140,
              s: [100, 100, 100]
            },
            { t: 156.00000635401, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [39.109, 39.109], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0.91, 15.441], ix: 5 },
              e: { a: 0, k: [-0.52, -22.124], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [116.09, -93.691], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 74.1237530191222,
      op: 157.000006394741,
      st: 63.123752571083,
      bm: 0
    },
    {
      ddd: 0,
      ind: 23,
      ty: 4,
      nm: "Camada 2",
      parent: 20,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.28, y: 1 },
              o: { x: 0.72, y: 0 },
              t: 73.753,
              s: [54.951, 67.442, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 112.752504592503, s: [54.951, 47.442, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [-2.972, -3.499, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.28, 0.28, 0.28], y: [1, 1, 1] },
              o: { x: [0.72, 0.72, 0.72], y: [0, 0, 0] },
              t: 73.753,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.27, 0.27, 0.27], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 97.753,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.27, 0.27, 0.27], y: [1, 1, 1] },
              o: { x: [0.74, 0.74, 0.74], y: [0, 0, 0] },
              t: 113,
              s: [100, 100, 100]
            },
            { t: 134.000005457932, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [10.507, 10.507], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [-0.028, -5.501], ix: 5 },
              e: { a: 0, k: [0.174, 5.375], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [-2.972, -3.499], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 73.7525030040009,
      op: 373.752515223251,
      st: 73.7525030040009,
      bm: 0
    },
    {
      ddd: 0,
      ind: 24,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.12], y: [1] },
              o: { x: [0.87], y: [0] },
              t: 0,
              s: [0]
            },
            { t: 51.0000020772726, s: [360] }
          ],
          ix: 10
        },
        p: { a: 0, k: [957.056, 546.763, 0], ix: 2 },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 87.0000035435826,
      op: 356.000014500177,
      st: 24.00000097754,
      bm: 0
    },
    {
      ddd: 0,
      ind: 25,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: -90, ix: 10 },
        p: { a: 0, k: [944.392, 694, 0], ix: 2 },
        a: { a: 0, k: [60, 60, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 123.000005009893,
      op: 191.00000777959,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 26,
      ty: 4,
      nm: "Camada 11",
      parent: 24,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.31], y: [1] },
              o: { x: [0.67], y: [0] },
              t: 173,
              s: [0]
            },
            {
              i: { x: [0.13], y: [1] },
              o: { x: [0.67], y: [0] },
              t: 196,
              s: [100]
            },
            {
              i: { x: [0.13], y: [1] },
              o: { x: [0.85], y: [0] },
              t: 264,
              s: [100]
            },
            { t: 299.00001217852, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 8,
              s: [59.327, 52.028, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 44.0000017921567, s: [60, 60, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.556, 15.763, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 8,
              s: [107.784, 107.784, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 44,
              s: [100.784, 100.784, 100]
            },
            {
              i: { x: [0, 0, 0.833], y: [1, 1, 1] },
              o: { x: [0.225, 0.225, 0.87], y: [0, 0, 0] },
              t: 203,
              s: [100.784, 100.784, 100]
            },
            { t: 299.00001217852, s: [110, 110, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-34.361, -0.722],
                    [0, 0],
                    [0, -70.035],
                    [0, 0],
                    [10.802, -1.062],
                    [0.358, 12.035],
                    [0, 0],
                    [16.617, 16.218],
                    [23.194, -0.547],
                    [0, 0],
                    [0, 0],
                    [0, -47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.177, -4.803],
                    [0, 0],
                    [24.598, -24.186],
                    [33.458, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 70.033],
                    [0, 0],
                    [-12.411, -1.239],
                    [0, 0],
                    [0, -10.812],
                    [0, 0],
                    [-16.617, -16.218],
                    [-23.194, 0.547],
                    [0, 47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.177, 4.803],
                    [0, 0],
                    [-24.598, 24.186]
                  ],
                  o: [
                    [0, 0],
                    [68.895, 1.263],
                    [0, 0],
                    [0, 10.81],
                    [-12.413, 1.06],
                    [0, 0],
                    [0, -23.329],
                    [-16.617, -16.219],
                    [0, 0],
                    [0, 0],
                    [-46.214, 1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.627, 0.001],
                    [0, 0],
                    [0, 34.29],
                    [-23.872, 23.462],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-68.892, -1.266],
                    [0, 0],
                    [0, -12.21],
                    [0, 0],
                    [10.803, 1.06],
                    [0, 0],
                    [0, 23.329],
                    [16.618, 16.219],
                    [46.215, -1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-4.627, 0],
                    [0, 0],
                    [0, -34.29],
                    [24.597, -24.185]
                  ],
                  v: [
                    [3.087, -235.208],
                    [3.089, -235.208],
                    [128.056, -105.979],
                    [128.056, -75.54],
                    [109.265, -54.296],
                    [86.208, -74.823],
                    [86.208, -107.972],
                    [60.275, -169.208],
                    [-1.635, -193.629],
                    [-1.623, -193.129],
                    [-1.635, -193.629],
                    [-85.459, -106.161],
                    [-85.459, -5.421],
                    [-85.459, -4.921],
                    [-84.959, -4.921],
                    [118.933, -5.103],
                    [118.934, -5.103],
                    [127.693, 3.466],
                    [127.693, 139.708],
                    [89.533, 230.307],
                    [0.374, 266.763],
                    [0.37, 266.763],
                    [0.366, 266.763],
                    [0.362, 266.763],
                    [0.357, 266.763],
                    [0.353, 266.763],
                    [0.349, 266.763],
                    [0.344, 266.763],
                    [0.34, 266.763],
                    [0.336, 266.763],
                    [0.332, 266.763],
                    [0.327, 266.763],
                    [0.323, 266.763],
                    [0.319, 266.763],
                    [0.314, 266.763],
                    [0.31, 266.763],
                    [0.306, 266.763],
                    [0.301, 266.763],
                    [0.297, 266.763],
                    [0.293, 266.763],
                    [0.288, 266.763],
                    [0.284, 266.763],
                    [0.28, 266.763],
                    [0.275, 266.763],
                    [0.271, 266.763],
                    [0.267, 266.763],
                    [0.262, 266.763],
                    [0.258, 266.763],
                    [0.254, 266.763],
                    [0.249, 266.763],
                    [0.245, 266.763],
                    [0.241, 266.763],
                    [0.236, 266.763],
                    [0.232, 266.763],
                    [0.227, 266.763],
                    [0.223, 266.763],
                    [0.219, 266.763],
                    [0.214, 266.763],
                    [0.21, 266.763],
                    [0.205, 266.763],
                    [0.201, 266.763],
                    [0.197, 266.763],
                    [0.192, 266.763],
                    [0.188, 266.763],
                    [0.183, 266.763],
                    [0.179, 266.763],
                    [0.174, 266.763],
                    [0.17, 266.763],
                    [0.166, 266.763],
                    [0.161, 266.763],
                    [0.157, 266.763],
                    [0.152, 266.763],
                    [0.148, 266.763],
                    [0.143, 266.763],
                    [0.139, 266.763],
                    [0.134, 266.763],
                    [0.13, 266.763],
                    [0.126, 266.763],
                    [0.121, 266.763],
                    [0.117, 266.763],
                    [0.112, 266.763],
                    [0.108, 266.763],
                    [0.103, 266.763],
                    [0.099, 266.763],
                    [0.094, 266.763],
                    [0.09, 266.763],
                    [0.085, 266.763],
                    [0.081, 266.763],
                    [0.076, 266.763],
                    [0.072, 266.763],
                    [0.067, 266.763],
                    [0.063, 266.763],
                    [0.058, 266.763],
                    [0.054, 266.763],
                    [0.049, 266.763],
                    [0.045, 266.763],
                    [0.04, 266.763],
                    [0.036, 266.763],
                    [0.031, 266.763],
                    [0.027, 266.763],
                    [0.022, 266.763],
                    [0.017, 266.763],
                    [0.013, 266.763],
                    [0.008, 266.763],
                    [0.004, 266.763],
                    [-0.001, 266.763],
                    [-0.005, 266.763],
                    [-0.01, 266.763],
                    [-0.014, 266.763],
                    [-0.019, 266.763],
                    [-0.023, 266.763],
                    [-0.028, 266.763],
                    [-0.033, 266.763],
                    [-0.037, 266.763],
                    [-0.042, 266.763],
                    [-0.046, 266.763],
                    [-0.051, 266.763],
                    [-0.055, 266.763],
                    [-0.06, 266.763],
                    [-0.065, 266.763],
                    [-0.069, 266.763],
                    [-0.074, 266.763],
                    [-0.078, 266.763],
                    [-0.083, 266.763],
                    [-0.088, 266.763],
                    [-0.092, 266.763],
                    [-0.097, 266.763],
                    [-0.101, 266.763],
                    [-0.106, 266.763],
                    [-0.111, 266.763],
                    [-0.115, 266.763],
                    [-0.12, 266.763],
                    [-0.124, 266.763],
                    [-0.129, 266.763],
                    [-0.134, 266.763],
                    [-0.138, 266.763],
                    [-0.143, 266.763],
                    [-0.148, 266.763],
                    [-0.152, 266.763],
                    [-0.157, 266.763],
                    [-0.162, 266.763],
                    [-0.166, 266.763],
                    [-0.171, 266.763],
                    [-0.175, 266.763],
                    [-0.18, 266.763],
                    [-0.185, 266.763],
                    [-0.189, 266.763],
                    [-0.194, 266.763],
                    [-0.199, 266.763],
                    [-0.203, 266.763],
                    [-0.208, 266.763],
                    [-0.213, 266.763],
                    [-0.217, 266.763],
                    [-0.222, 266.763],
                    [-0.227, 266.763],
                    [-0.231, 266.763],
                    [-0.236, 266.763],
                    [-0.241, 266.763],
                    [-0.245, 266.763],
                    [-0.25, 266.763],
                    [-0.255, 266.763],
                    [-0.259, 266.763],
                    [-0.264, 266.763],
                    [-0.269, 266.763],
                    [-0.273, 266.763],
                    [-0.278, 266.763],
                    [-0.283, 266.763],
                    [-0.287, 266.763],
                    [-0.292, 266.763],
                    [-0.297, 266.763],
                    [-0.302, 266.763],
                    [-0.306, 266.763],
                    [-0.311, 266.763],
                    [-0.316, 266.763],
                    [-0.32, 266.763],
                    [-0.325, 266.763],
                    [-0.33, 266.763],
                    [-0.334, 266.763],
                    [-0.339, 266.763],
                    [-0.344, 266.763],
                    [-0.349, 266.763],
                    [-0.353, 266.763],
                    [-0.358, 266.763],
                    [-0.363, 266.763],
                    [-0.367, 266.763],
                    [-0.372, 266.763],
                    [-0.377, 266.763],
                    [-0.382, 266.763],
                    [-0.386, 266.763],
                    [-0.391, 266.763],
                    [-0.396, 266.763],
                    [-0.401, 266.763],
                    [-0.405, 266.763],
                    [-0.41, 266.763],
                    [-0.415, 266.763],
                    [-0.419, 266.763],
                    [-0.424, 266.763],
                    [-0.429, 266.763],
                    [-0.434, 266.763],
                    [-0.438, 266.763],
                    [-0.443, 266.763],
                    [-0.448, 266.763],
                    [-0.453, 266.763],
                    [-0.457, 266.763],
                    [-0.462, 266.763],
                    [-0.467, 266.763],
                    [-0.472, 266.763],
                    [-0.476, 266.763],
                    [-0.481, 266.763],
                    [-0.486, 266.763],
                    [-0.491, 266.763],
                    [-0.495, 266.763],
                    [-0.5, 266.763],
                    [-0.505, 266.763],
                    [-0.51, 266.763],
                    [-0.514, 266.763],
                    [-0.519, 266.763],
                    [-0.524, 266.763],
                    [-0.529, 266.763],
                    [-0.533, 266.763],
                    [-0.538, 266.763],
                    [-0.543, 266.763],
                    [-0.548, 266.763],
                    [-0.552, 266.763],
                    [-0.557, 266.763],
                    [-0.562, 266.763],
                    [-0.567, 266.763],
                    [-0.572, 266.763],
                    [-0.576, 266.763],
                    [-0.581, 266.763],
                    [-0.586, 266.763],
                    [-0.591, 266.763],
                    [-0.595, 266.763],
                    [-0.6, 266.763],
                    [-0.605, 266.763],
                    [-0.61, 266.763],
                    [-0.614, 266.763],
                    [-0.619, 266.763],
                    [-0.624, 266.763],
                    [-0.629, 266.763],
                    [-0.634, 266.763],
                    [-0.638, 266.763],
                    [-0.643, 266.763],
                    [-0.648, 266.763],
                    [-0.653, 266.763],
                    [-0.657, 266.763],
                    [-0.662, 266.763],
                    [-0.667, 266.763],
                    [-0.672, 266.763],
                    [-0.677, 266.763],
                    [-0.681, 266.763],
                    [-0.686, 266.763],
                    [-0.691, 266.763],
                    [-0.696, 266.763],
                    [-0.7, 266.763],
                    [-0.705, 266.763],
                    [-0.71, 266.763],
                    [-0.715, 266.763],
                    [-0.72, 266.763],
                    [-0.724, 266.763],
                    [-0.729, 266.763],
                    [-0.734, 266.763],
                    [-0.739, 266.763],
                    [-0.744, 266.763],
                    [-0.748, 266.763],
                    [-0.753, 266.763],
                    [-0.758, 266.763],
                    [-0.763, 266.763],
                    [-0.767, 266.763],
                    [-0.772, 266.763],
                    [-0.777, 266.763],
                    [-0.782, 266.763],
                    [-0.787, 266.763],
                    [-0.791, 266.763],
                    [-0.796, 266.763],
                    [-0.801, 266.763],
                    [-0.806, 266.763],
                    [-0.811, 266.763],
                    [-0.815, 266.763],
                    [-0.82, 266.763],
                    [-0.825, 266.763],
                    [-0.83, 266.763],
                    [-0.834, 266.763],
                    [-0.839, 266.763],
                    [-0.844, 266.763],
                    [-0.849, 266.763],
                    [-0.854, 266.763],
                    [-0.858, 266.763],
                    [-0.863, 266.763],
                    [-0.868, 266.763],
                    [-0.873, 266.763],
                    [-0.878, 266.763],
                    [-0.882, 266.763],
                    [-0.887, 266.763],
                    [-0.892, 266.763],
                    [-0.897, 266.763],
                    [-0.901, 266.763],
                    [-0.906, 266.763],
                    [-0.911, 266.763],
                    [-0.916, 266.763],
                    [-0.921, 266.763],
                    [-0.925, 266.763],
                    [-0.93, 266.763],
                    [-0.935, 266.763],
                    [-0.94, 266.763],
                    [-0.945, 266.763],
                    [-0.949, 266.763],
                    [-0.954, 266.763],
                    [-0.959, 266.763],
                    [-0.964, 266.763],
                    [-0.968, 266.763],
                    [-0.973, 266.763],
                    [-0.978, 266.763],
                    [-0.983, 266.763],
                    [-0.988, 266.763],
                    [-0.992, 266.763],
                    [-0.997, 266.763],
                    [-1.002, 266.763],
                    [-1.007, 266.763],
                    [-1.011, 266.763],
                    [-1.016, 266.763],
                    [-1.021, 266.763],
                    [-1.026, 266.763],
                    [-1.03, 266.763],
                    [-1.035, 266.763],
                    [-1.04, 266.763],
                    [-1.045, 266.763],
                    [-1.05, 266.763],
                    [-1.054, 266.763],
                    [-1.059, 266.763],
                    [-1.064, 266.763],
                    [-1.069, 266.763],
                    [-1.073, 266.763],
                    [-1.078, 266.763],
                    [-1.083, 266.763],
                    [-1.088, 266.763],
                    [-1.092, 266.763],
                    [-1.097, 266.763],
                    [-1.102, 266.763],
                    [-1.107, 266.763],
                    [-1.111, 266.763],
                    [-1.116, 266.763],
                    [-1.121, 266.763],
                    [-1.126, 266.763],
                    [-1.13, 266.763],
                    [-1.135, 266.763],
                    [-1.14, 266.763],
                    [-1.145, 266.763],
                    [-1.149, 266.763],
                    [-1.154, 266.763],
                    [-1.159, 266.763],
                    [-1.164, 266.763],
                    [-1.168, 266.763],
                    [-1.173, 266.763],
                    [-1.178, 266.763],
                    [-1.183, 266.763],
                    [-1.187, 266.763],
                    [-1.192, 266.763],
                    [-1.197, 266.763],
                    [-1.202, 266.763],
                    [-1.206, 266.763],
                    [-1.211, 266.763],
                    [-1.216, 266.763],
                    [-1.22, 266.763],
                    [-1.225, 266.763],
                    [-1.23, 266.763],
                    [-1.235, 266.763],
                    [-1.239, 266.763],
                    [-1.244, 266.763],
                    [-1.249, 266.763],
                    [-1.254, 266.763],
                    [-1.258, 266.763],
                    [-1.263, 266.763],
                    [-1.268, 266.763],
                    [-1.272, 266.763],
                    [-1.277, 266.763],
                    [-1.282, 266.763],
                    [-1.286, 266.763],
                    [-1.291, 266.763],
                    [-1.296, 266.763],
                    [-1.301, 266.763],
                    [-1.305, 266.763],
                    [-1.31, 266.763],
                    [-1.315, 266.763],
                    [-1.319, 266.763],
                    [-1.324, 266.763],
                    [-1.329, 266.763],
                    [-1.333, 266.763],
                    [-1.338, 266.763],
                    [-1.343, 266.763],
                    [-1.347, 266.763],
                    [-1.352, 266.763],
                    [-1.357, 266.763],
                    [-1.362, 266.763],
                    [-1.366, 266.763],
                    [-1.371, 266.763],
                    [-1.376, 266.763],
                    [-1.38, 266.763],
                    [-1.385, 266.763],
                    [-1.39, 266.763],
                    [-1.394, 266.763],
                    [-1.399, 266.763],
                    [-1.404, 266.763],
                    [-1.408, 266.763],
                    [-1.413, 266.763],
                    [-1.418, 266.763],
                    [-1.422, 266.763],
                    [-1.427, 266.763],
                    [-1.431, 266.763],
                    [-1.436, 266.763],
                    [-1.441, 266.763],
                    [-1.445, 266.763],
                    [-1.45, 266.763],
                    [-1.455, 266.763],
                    [-1.459, 266.763],
                    [-1.464, 266.763],
                    [-1.469, 266.763],
                    [-1.473, 266.763],
                    [-1.478, 266.763],
                    [-1.482, 266.763],
                    [-1.487, 266.763],
                    [-1.492, 266.763],
                    [-1.496, 266.763],
                    [-1.501, 266.763],
                    [-1.505, 266.763],
                    [-1.51, 266.763],
                    [-1.515, 266.763],
                    [-1.519, 266.763],
                    [-1.524, 266.763],
                    [-1.529, 266.763],
                    [-1.533, 266.763],
                    [-1.538, 266.763],
                    [-1.542, 266.763],
                    [-1.547, 266.763],
                    [-1.551, 266.763],
                    [-1.556, 266.763],
                    [-1.561, 266.763],
                    [-1.565, 266.763],
                    [-1.57, 266.763],
                    [-1.574, 266.763],
                    [-1.579, 266.763],
                    [-1.583, 266.763],
                    [-1.588, 266.763],
                    [-1.593, 266.763],
                    [-1.597, 266.763],
                    [-1.602, 266.763],
                    [-1.606, 266.763],
                    [-1.611, 266.763],
                    [-1.615, 266.763],
                    [-1.62, 266.763],
                    [-1.624, 266.763],
                    [-1.629, 266.763],
                    [-1.634, 266.763],
                    [-1.638, 266.763],
                    [-1.643, 266.763],
                    [-1.647, 266.763],
                    [-1.652, 266.763],
                    [-1.656, 266.763],
                    [-1.661, 266.763],
                    [-1.665, 266.763],
                    [-1.67, 266.763],
                    [-1.674, 266.763],
                    [-1.679, 266.763],
                    [-1.683, 266.763],
                    [-1.688, 266.763],
                    [-1.692, 266.763],
                    [-1.697, 266.763],
                    [-1.701, 266.763],
                    [-1.706, 266.763],
                    [-1.71, 266.763],
                    [-1.715, 266.763],
                    [-1.719, 266.763],
                    [-1.724, 266.763],
                    [-1.728, 266.763],
                    [-1.733, 266.763],
                    [-1.737, 266.763],
                    [-1.742, 266.763],
                    [-1.746, 266.763],
                    [-1.75, 266.763],
                    [-1.755, 266.763],
                    [-1.759, 266.763],
                    [-1.764, 266.763],
                    [-1.768, 266.763],
                    [-1.773, 266.763],
                    [-1.777, 266.763],
                    [-1.782, 266.763],
                    [-1.786, 266.763],
                    [-1.79, 266.763],
                    [-1.795, 266.763],
                    [-1.799, 266.763],
                    [-1.804, 266.763],
                    [-1.808, 266.763],
                    [-1.813, 266.763],
                    [-1.817, 266.763],
                    [-1.821, 266.763],
                    [-1.826, 266.763],
                    [-1.83, 266.763],
                    [-1.835, 266.763],
                    [-1.839, 266.763],
                    [-1.843, 266.763],
                    [-1.848, 266.763],
                    [-1.852, 266.763],
                    [-1.856, 266.763],
                    [-1.861, 266.763],
                    [-1.865, 266.763],
                    [-1.87, 266.763],
                    [-1.874, 266.763],
                    [-1.878, 266.763],
                    [-1.883, 266.763],
                    [-1.887, 266.763],
                    [-1.891, 266.763],
                    [-1.896, 266.763],
                    [-1.9, 266.763],
                    [-1.904, 266.763],
                    [-1.909, 266.763],
                    [-1.913, 266.763],
                    [-1.917, 266.763],
                    [-1.922, 266.763],
                    [-1.926, 266.763],
                    [-1.93, 266.763],
                    [-1.935, 266.763],
                    [-1.939, 266.763],
                    [-1.943, 266.763],
                    [-1.947, 266.763],
                    [-1.952, 266.763],
                    [-1.956, 266.763],
                    [-1.96, 266.763],
                    [-1.965, 266.763],
                    [-1.969, 266.763],
                    [-1.973, 266.763],
                    [-1.977, 266.763],
                    [-1.982, 266.763],
                    [-126.944, 137.534],
                    [-126.944, 106.37],
                    [-104.073, 85.669],
                    [-104.072, 85.669],
                    [-85.278, 106.914],
                    [-85.278, 139.527],
                    [-59.346, 200.763],
                    [2.565, 225.184],
                    [86.389, 137.715],
                    [86.389, 36.976],
                    [86.389, 36.476],
                    [85.889, 36.476],
                    [-96.035, 36.476],
                    [-117.822, 36.476],
                    [-126.581, 27.908],
                    [-126.581, -108.154],
                    [-88.422, -198.752]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.2, 0.2, 0.2, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.44, 0.44], y: [1, 1] },
                    o: { x: [1, 1], y: [0, 0] },
                    t: 203,
                    s: [100, 100]
                  },
                  { t: 299.00001217852, s: [100, 100] }
                ],
                ix: 3
              },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 90, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 87.0000035435826,
      op: 356.000014500177,
      st: 56.0000022809268,
      bm: 0
    },
    {
      ddd: 0,
      ind: 27,
      ty: 0,
      nm: "Particles_1",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [500, 341, 0], ix: 1 },
        s: { a: 0, k: [29, 29, 100], ix: 6 }
      },
      ao: 0,
      w: 1000,
      h: 682,
      ip: 155.000006313279,
      op: 170.000006924242,
      st: 155.000006313279,
      bm: 0
    },
    {
      ddd: 0,
      ind: 28,
      ty: 4,
      nm: "Circle 8",
      parent: 25,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 129,
              s: [60.276, -28.527, 0],
              to: [-64.607, 11.932, 0],
              ti: [19.5, -26.5, 0]
            },
            { t: 158.000006435472, s: [-17.284, 19.973, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.625, -0.938, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 126,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 139,
              s: [50, 50, 100]
            },
            { t: 162.000006598395, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [19.802, 19.802], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.503, -1.759], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 126.000005132085,
      op: 167.000006802049,
      st: 126.000005132085,
      bm: 0
    },
    {
      ddd: 0,
      ind: 29,
      ty: 4,
      nm: "Circle 6",
      parent: 25,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.651, y: 0.917 },
              o: { x: 0.314, y: 0 },
              t: 134,
              s: [-1.784, 123.473, 0],
              to: [-0.399, 0, 0],
              ti: [-3.701, -1.981, 0]
            },
            {
              i: { x: 0.832, y: 1 },
              o: { x: 0.374, y: 0.182 },
              t: 138,
              s: [30.987, 166.991, 0],
              to: [38.407, 20.559, 0],
              ti: [-47.66, 7.821, 0]
            },
            { t: 161.000006557664, s: [180.716, 180.473, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.625, -0.938, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 129,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 142,
              s: [30, 30, 100]
            },
            { t: 165.000006720588, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [19.802, 19.802], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.503, -1.759], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 138.000005620855,
      op: 170.000006924242,
      st: 129.000005254278,
      bm: 0
    },
    {
      ddd: 0,
      ind: 30,
      ty: 4,
      nm: "Circle 5",
      parent: 25,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.797 },
              o: { x: 0.296, y: 0 },
              t: 128.4,
              s: [-3.284, -13.527, 0],
              to: [-27.707, 27.136, 0],
              ti: [18.859, -34.673, 0]
            },
            {
              i: { x: 0.81, y: 1 },
              o: { x: 0.167, y: 0.137 },
              t: 143,
              s: [-17.533, 68.232, 0],
              to: [-22.983, 42.256, 0],
              ti: [-11.65, 0.513, 0]
            },
            { t: 155.000006313279, s: [9.716, 160.473, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.625, -0.938, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 123,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.677, 0.677, 0.677], y: [0.998, 0.998, 0.965] },
              o: { x: [0.344, 0.344, 0.344], y: [0, 0, 0] },
              t: 135,
              s: [51.96, 51.96, 100]
            },
            {
              i: { x: [0.316, 0.316, 0.316], y: [1, 1, 1] },
              o: { x: [0.641, 0.641, 0.641], y: [0.087, 0.087, 1.594] },
              t: 136,
              s: [24.355, 24.355, 100]
            },
            { t: 159.000006476203, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [19.802, 19.802], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.503, -1.759], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 123.000005009893,
      op: 164.000006679857,
      st: 123.000005009893,
      bm: 0
    },
    {
      ddd: 0,
      ind: 31,
      ty: 4,
      nm: "Circle 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 131,
              s: [1034.607, 346.068, 0],
              to: [-22.607, 18.932, 0],
              ti: [33, -108.5, 0]
            },
            { t: 158.000006435472, s: [871.607, 354.068, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.625, -0.938, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 126,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 139,
              s: [50, 50, 100]
            },
            { t: 162.000006598395, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [19.802, 19.802], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.503, -1.759], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 126.000005132085,
      op: 167.000006802049,
      st: 126.000005132085,
      bm: 0
    },
    {
      ddd: 0,
      ind: 32,
      ty: 4,
      nm: "Circle 4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 155,
              s: [853.607, 391.568, 0],
              to: [-32.978, 57, 0],
              ti: [14, -47, 0]
            },
            { t: 182.000007413012, s: [862.607, 558.068, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.625, -0.938, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 150,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 163,
              s: [30, 30, 100]
            },
            { t: 186.000007575935, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [19.802, 19.802], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.503, -1.759], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 150.000006109625,
      op: 170.000006924242,
      st: 150.000006109625,
      bm: 0
    },
    {
      ddd: 0,
      ind: 33,
      ty: 4,
      nm: "Circle 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 144,
              s: [853.607, 483.068, 0],
              to: [-2.228, 0, 0],
              ti: [-108, 1, 0]
            },
            { t: 171.000006964973, s: [931.607, 559.068, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.625, -0.938, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 139,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 152,
              s: [30, 30, 100]
            },
            { t: 175.000007127896, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [19.802, 19.802], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.503, -1.759], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 148.000006028164,
      op: 170.000006924242,
      st: 139.000005661586,
      bm: 0
    },
    {
      ddd: 0,
      ind: 34,
      ty: 4,
      nm: "Circle",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.835 },
              o: { x: 0.296, y: 0 },
              t: 128.4,
              s: [1064.107, 449.568, 0],
              to: [18.793, -27.364, 0],
              ti: [43.359, 20.827, 0]
            },
            {
              i: { x: 0.81, y: 1 },
              o: { x: 0.167, y: 0.169 },
              t: 143.25,
              s: [1038.359, 363.827, 0],
              to: [-43.359, -20.827, 0],
              ti: [30.85, -5.987, 0]
            },
            { t: 155.000006313279, s: [979.107, 316.568, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0.625, -0.938, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.33, 0.33, 0.33], y: [1, 1, 1] },
              o: { x: [0.67, 0.67, 0.67], y: [0, 0, 0] },
              t: 123,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.677, 0.677, 0.677], y: [0.998, 0.998, 0.965] },
              o: { x: [0.344, 0.344, 0.344], y: [0, 0, 0] },
              t: 135,
              s: [51.96, 51.96, 100]
            },
            {
              i: { x: [0.316, 0.316, 0.316], y: [1, 1, 1] },
              o: { x: [0.641, 0.641, 0.641], y: [0.087, 0.087, 1.594] },
              t: 136,
              s: [24.355, 24.355, 100]
            },
            { t: 159.000006476203, s: [0, 0, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [19.802, 19.802], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.503, -1.759], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 123.000005009893,
      op: 164.000006679857,
      st: 123.000005009893,
      bm: 0
    },
    {
      ddd: 0,
      ind: 35,
      ty: 4,
      nm: "Camada 15",
      parent: 26,
      sr: 1,
      ks: {
        o: { a: 0, k: 50, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0.556, 15.763, 0], ix: 2 },
        a: { a: 0, k: [0.556, 15.763, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-34.361, -0.722],
                    [0, 0],
                    [0, -70.035],
                    [0, 0],
                    [10.802, -1.062],
                    [0.358, 12.035],
                    [0, 0],
                    [16.617, 16.218],
                    [23.194, -0.547],
                    [0, 0],
                    [0, 0],
                    [0, -47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.177, -4.803],
                    [0, 0],
                    [24.598, -24.186],
                    [33.458, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 70.033],
                    [0, 0],
                    [-12.411, -1.239],
                    [0, 0],
                    [0, -10.812],
                    [0, 0],
                    [-16.617, -16.218],
                    [-23.194, 0.547],
                    [0, 47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.177, 4.803],
                    [0, 0],
                    [-24.598, 24.186]
                  ],
                  o: [
                    [0, 0],
                    [68.895, 1.263],
                    [0, 0],
                    [0, 10.81],
                    [-12.413, 1.06],
                    [0, 0],
                    [0, -23.329],
                    [-16.617, -16.219],
                    [0, 0],
                    [0, 0],
                    [-46.214, 1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.627, 0.001],
                    [0, 0],
                    [0, 34.29],
                    [-23.872, 23.462],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-68.892, -1.266],
                    [0, 0],
                    [0, -12.21],
                    [0, 0],
                    [10.803, 1.06],
                    [0, 0],
                    [0, 23.329],
                    [16.618, 16.219],
                    [46.215, -1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-4.627, 0],
                    [0, 0],
                    [0, -34.29],
                    [24.597, -24.185]
                  ],
                  v: [
                    [3.087, -235.208],
                    [3.089, -235.208],
                    [128.056, -105.979],
                    [128.056, -75.54],
                    [109.265, -54.296],
                    [86.208, -74.823],
                    [86.208, -107.972],
                    [60.275, -169.208],
                    [-1.635, -193.629],
                    [-1.623, -193.129],
                    [-1.635, -193.629],
                    [-85.459, -106.161],
                    [-85.459, -5.421],
                    [-85.459, -4.921],
                    [-84.959, -4.921],
                    [118.933, -5.103],
                    [118.934, -5.103],
                    [127.693, 3.466],
                    [127.693, 139.708],
                    [89.533, 230.307],
                    [0.374, 266.763],
                    [0.37, 266.763],
                    [0.366, 266.763],
                    [0.362, 266.763],
                    [0.357, 266.763],
                    [0.353, 266.763],
                    [0.349, 266.763],
                    [0.344, 266.763],
                    [0.34, 266.763],
                    [0.336, 266.763],
                    [0.332, 266.763],
                    [0.327, 266.763],
                    [0.323, 266.763],
                    [0.319, 266.763],
                    [0.314, 266.763],
                    [0.31, 266.763],
                    [0.306, 266.763],
                    [0.301, 266.763],
                    [0.297, 266.763],
                    [0.293, 266.763],
                    [0.288, 266.763],
                    [0.284, 266.763],
                    [0.28, 266.763],
                    [0.275, 266.763],
                    [0.271, 266.763],
                    [0.267, 266.763],
                    [0.262, 266.763],
                    [0.258, 266.763],
                    [0.254, 266.763],
                    [0.249, 266.763],
                    [0.245, 266.763],
                    [0.241, 266.763],
                    [0.236, 266.763],
                    [0.232, 266.763],
                    [0.227, 266.763],
                    [0.223, 266.763],
                    [0.219, 266.763],
                    [0.214, 266.763],
                    [0.21, 266.763],
                    [0.205, 266.763],
                    [0.201, 266.763],
                    [0.197, 266.763],
                    [0.192, 266.763],
                    [0.188, 266.763],
                    [0.183, 266.763],
                    [0.179, 266.763],
                    [0.174, 266.763],
                    [0.17, 266.763],
                    [0.166, 266.763],
                    [0.161, 266.763],
                    [0.157, 266.763],
                    [0.152, 266.763],
                    [0.148, 266.763],
                    [0.143, 266.763],
                    [0.139, 266.763],
                    [0.134, 266.763],
                    [0.13, 266.763],
                    [0.126, 266.763],
                    [0.121, 266.763],
                    [0.117, 266.763],
                    [0.112, 266.763],
                    [0.108, 266.763],
                    [0.103, 266.763],
                    [0.099, 266.763],
                    [0.094, 266.763],
                    [0.09, 266.763],
                    [0.085, 266.763],
                    [0.081, 266.763],
                    [0.076, 266.763],
                    [0.072, 266.763],
                    [0.067, 266.763],
                    [0.063, 266.763],
                    [0.058, 266.763],
                    [0.054, 266.763],
                    [0.049, 266.763],
                    [0.045, 266.763],
                    [0.04, 266.763],
                    [0.036, 266.763],
                    [0.031, 266.763],
                    [0.027, 266.763],
                    [0.022, 266.763],
                    [0.017, 266.763],
                    [0.013, 266.763],
                    [0.008, 266.763],
                    [0.004, 266.763],
                    [-0.001, 266.763],
                    [-0.005, 266.763],
                    [-0.01, 266.763],
                    [-0.014, 266.763],
                    [-0.019, 266.763],
                    [-0.023, 266.763],
                    [-0.028, 266.763],
                    [-0.033, 266.763],
                    [-0.037, 266.763],
                    [-0.042, 266.763],
                    [-0.046, 266.763],
                    [-0.051, 266.763],
                    [-0.055, 266.763],
                    [-0.06, 266.763],
                    [-0.065, 266.763],
                    [-0.069, 266.763],
                    [-0.074, 266.763],
                    [-0.078, 266.763],
                    [-0.083, 266.763],
                    [-0.088, 266.763],
                    [-0.092, 266.763],
                    [-0.097, 266.763],
                    [-0.101, 266.763],
                    [-0.106, 266.763],
                    [-0.111, 266.763],
                    [-0.115, 266.763],
                    [-0.12, 266.763],
                    [-0.124, 266.763],
                    [-0.129, 266.763],
                    [-0.134, 266.763],
                    [-0.138, 266.763],
                    [-0.143, 266.763],
                    [-0.148, 266.763],
                    [-0.152, 266.763],
                    [-0.157, 266.763],
                    [-0.162, 266.763],
                    [-0.166, 266.763],
                    [-0.171, 266.763],
                    [-0.175, 266.763],
                    [-0.18, 266.763],
                    [-0.185, 266.763],
                    [-0.189, 266.763],
                    [-0.194, 266.763],
                    [-0.199, 266.763],
                    [-0.203, 266.763],
                    [-0.208, 266.763],
                    [-0.213, 266.763],
                    [-0.217, 266.763],
                    [-0.222, 266.763],
                    [-0.227, 266.763],
                    [-0.231, 266.763],
                    [-0.236, 266.763],
                    [-0.241, 266.763],
                    [-0.245, 266.763],
                    [-0.25, 266.763],
                    [-0.255, 266.763],
                    [-0.259, 266.763],
                    [-0.264, 266.763],
                    [-0.269, 266.763],
                    [-0.273, 266.763],
                    [-0.278, 266.763],
                    [-0.283, 266.763],
                    [-0.287, 266.763],
                    [-0.292, 266.763],
                    [-0.297, 266.763],
                    [-0.302, 266.763],
                    [-0.306, 266.763],
                    [-0.311, 266.763],
                    [-0.316, 266.763],
                    [-0.32, 266.763],
                    [-0.325, 266.763],
                    [-0.33, 266.763],
                    [-0.334, 266.763],
                    [-0.339, 266.763],
                    [-0.344, 266.763],
                    [-0.349, 266.763],
                    [-0.353, 266.763],
                    [-0.358, 266.763],
                    [-0.363, 266.763],
                    [-0.367, 266.763],
                    [-0.372, 266.763],
                    [-0.377, 266.763],
                    [-0.382, 266.763],
                    [-0.386, 266.763],
                    [-0.391, 266.763],
                    [-0.396, 266.763],
                    [-0.401, 266.763],
                    [-0.405, 266.763],
                    [-0.41, 266.763],
                    [-0.415, 266.763],
                    [-0.419, 266.763],
                    [-0.424, 266.763],
                    [-0.429, 266.763],
                    [-0.434, 266.763],
                    [-0.438, 266.763],
                    [-0.443, 266.763],
                    [-0.448, 266.763],
                    [-0.453, 266.763],
                    [-0.457, 266.763],
                    [-0.462, 266.763],
                    [-0.467, 266.763],
                    [-0.472, 266.763],
                    [-0.476, 266.763],
                    [-0.481, 266.763],
                    [-0.486, 266.763],
                    [-0.491, 266.763],
                    [-0.495, 266.763],
                    [-0.5, 266.763],
                    [-0.505, 266.763],
                    [-0.51, 266.763],
                    [-0.514, 266.763],
                    [-0.519, 266.763],
                    [-0.524, 266.763],
                    [-0.529, 266.763],
                    [-0.533, 266.763],
                    [-0.538, 266.763],
                    [-0.543, 266.763],
                    [-0.548, 266.763],
                    [-0.552, 266.763],
                    [-0.557, 266.763],
                    [-0.562, 266.763],
                    [-0.567, 266.763],
                    [-0.572, 266.763],
                    [-0.576, 266.763],
                    [-0.581, 266.763],
                    [-0.586, 266.763],
                    [-0.591, 266.763],
                    [-0.595, 266.763],
                    [-0.6, 266.763],
                    [-0.605, 266.763],
                    [-0.61, 266.763],
                    [-0.614, 266.763],
                    [-0.619, 266.763],
                    [-0.624, 266.763],
                    [-0.629, 266.763],
                    [-0.634, 266.763],
                    [-0.638, 266.763],
                    [-0.643, 266.763],
                    [-0.648, 266.763],
                    [-0.653, 266.763],
                    [-0.657, 266.763],
                    [-0.662, 266.763],
                    [-0.667, 266.763],
                    [-0.672, 266.763],
                    [-0.677, 266.763],
                    [-0.681, 266.763],
                    [-0.686, 266.763],
                    [-0.691, 266.763],
                    [-0.696, 266.763],
                    [-0.7, 266.763],
                    [-0.705, 266.763],
                    [-0.71, 266.763],
                    [-0.715, 266.763],
                    [-0.72, 266.763],
                    [-0.724, 266.763],
                    [-0.729, 266.763],
                    [-0.734, 266.763],
                    [-0.739, 266.763],
                    [-0.744, 266.763],
                    [-0.748, 266.763],
                    [-0.753, 266.763],
                    [-0.758, 266.763],
                    [-0.763, 266.763],
                    [-0.767, 266.763],
                    [-0.772, 266.763],
                    [-0.777, 266.763],
                    [-0.782, 266.763],
                    [-0.787, 266.763],
                    [-0.791, 266.763],
                    [-0.796, 266.763],
                    [-0.801, 266.763],
                    [-0.806, 266.763],
                    [-0.811, 266.763],
                    [-0.815, 266.763],
                    [-0.82, 266.763],
                    [-0.825, 266.763],
                    [-0.83, 266.763],
                    [-0.834, 266.763],
                    [-0.839, 266.763],
                    [-0.844, 266.763],
                    [-0.849, 266.763],
                    [-0.854, 266.763],
                    [-0.858, 266.763],
                    [-0.863, 266.763],
                    [-0.868, 266.763],
                    [-0.873, 266.763],
                    [-0.878, 266.763],
                    [-0.882, 266.763],
                    [-0.887, 266.763],
                    [-0.892, 266.763],
                    [-0.897, 266.763],
                    [-0.901, 266.763],
                    [-0.906, 266.763],
                    [-0.911, 266.763],
                    [-0.916, 266.763],
                    [-0.921, 266.763],
                    [-0.925, 266.763],
                    [-0.93, 266.763],
                    [-0.935, 266.763],
                    [-0.94, 266.763],
                    [-0.945, 266.763],
                    [-0.949, 266.763],
                    [-0.954, 266.763],
                    [-0.959, 266.763],
                    [-0.964, 266.763],
                    [-0.968, 266.763],
                    [-0.973, 266.763],
                    [-0.978, 266.763],
                    [-0.983, 266.763],
                    [-0.988, 266.763],
                    [-0.992, 266.763],
                    [-0.997, 266.763],
                    [-1.002, 266.763],
                    [-1.007, 266.763],
                    [-1.011, 266.763],
                    [-1.016, 266.763],
                    [-1.021, 266.763],
                    [-1.026, 266.763],
                    [-1.03, 266.763],
                    [-1.035, 266.763],
                    [-1.04, 266.763],
                    [-1.045, 266.763],
                    [-1.05, 266.763],
                    [-1.054, 266.763],
                    [-1.059, 266.763],
                    [-1.064, 266.763],
                    [-1.069, 266.763],
                    [-1.073, 266.763],
                    [-1.078, 266.763],
                    [-1.083, 266.763],
                    [-1.088, 266.763],
                    [-1.092, 266.763],
                    [-1.097, 266.763],
                    [-1.102, 266.763],
                    [-1.107, 266.763],
                    [-1.111, 266.763],
                    [-1.116, 266.763],
                    [-1.121, 266.763],
                    [-1.126, 266.763],
                    [-1.13, 266.763],
                    [-1.135, 266.763],
                    [-1.14, 266.763],
                    [-1.145, 266.763],
                    [-1.149, 266.763],
                    [-1.154, 266.763],
                    [-1.159, 266.763],
                    [-1.164, 266.763],
                    [-1.168, 266.763],
                    [-1.173, 266.763],
                    [-1.178, 266.763],
                    [-1.183, 266.763],
                    [-1.187, 266.763],
                    [-1.192, 266.763],
                    [-1.197, 266.763],
                    [-1.202, 266.763],
                    [-1.206, 266.763],
                    [-1.211, 266.763],
                    [-1.216, 266.763],
                    [-1.22, 266.763],
                    [-1.225, 266.763],
                    [-1.23, 266.763],
                    [-1.235, 266.763],
                    [-1.239, 266.763],
                    [-1.244, 266.763],
                    [-1.249, 266.763],
                    [-1.254, 266.763],
                    [-1.258, 266.763],
                    [-1.263, 266.763],
                    [-1.268, 266.763],
                    [-1.272, 266.763],
                    [-1.277, 266.763],
                    [-1.282, 266.763],
                    [-1.286, 266.763],
                    [-1.291, 266.763],
                    [-1.296, 266.763],
                    [-1.301, 266.763],
                    [-1.305, 266.763],
                    [-1.31, 266.763],
                    [-1.315, 266.763],
                    [-1.319, 266.763],
                    [-1.324, 266.763],
                    [-1.329, 266.763],
                    [-1.333, 266.763],
                    [-1.338, 266.763],
                    [-1.343, 266.763],
                    [-1.347, 266.763],
                    [-1.352, 266.763],
                    [-1.357, 266.763],
                    [-1.362, 266.763],
                    [-1.366, 266.763],
                    [-1.371, 266.763],
                    [-1.376, 266.763],
                    [-1.38, 266.763],
                    [-1.385, 266.763],
                    [-1.39, 266.763],
                    [-1.394, 266.763],
                    [-1.399, 266.763],
                    [-1.404, 266.763],
                    [-1.408, 266.763],
                    [-1.413, 266.763],
                    [-1.418, 266.763],
                    [-1.422, 266.763],
                    [-1.427, 266.763],
                    [-1.431, 266.763],
                    [-1.436, 266.763],
                    [-1.441, 266.763],
                    [-1.445, 266.763],
                    [-1.45, 266.763],
                    [-1.455, 266.763],
                    [-1.459, 266.763],
                    [-1.464, 266.763],
                    [-1.469, 266.763],
                    [-1.473, 266.763],
                    [-1.478, 266.763],
                    [-1.482, 266.763],
                    [-1.487, 266.763],
                    [-1.492, 266.763],
                    [-1.496, 266.763],
                    [-1.501, 266.763],
                    [-1.505, 266.763],
                    [-1.51, 266.763],
                    [-1.515, 266.763],
                    [-1.519, 266.763],
                    [-1.524, 266.763],
                    [-1.529, 266.763],
                    [-1.533, 266.763],
                    [-1.538, 266.763],
                    [-1.542, 266.763],
                    [-1.547, 266.763],
                    [-1.551, 266.763],
                    [-1.556, 266.763],
                    [-1.561, 266.763],
                    [-1.565, 266.763],
                    [-1.57, 266.763],
                    [-1.574, 266.763],
                    [-1.579, 266.763],
                    [-1.583, 266.763],
                    [-1.588, 266.763],
                    [-1.593, 266.763],
                    [-1.597, 266.763],
                    [-1.602, 266.763],
                    [-1.606, 266.763],
                    [-1.611, 266.763],
                    [-1.615, 266.763],
                    [-1.62, 266.763],
                    [-1.624, 266.763],
                    [-1.629, 266.763],
                    [-1.634, 266.763],
                    [-1.638, 266.763],
                    [-1.643, 266.763],
                    [-1.647, 266.763],
                    [-1.652, 266.763],
                    [-1.656, 266.763],
                    [-1.661, 266.763],
                    [-1.665, 266.763],
                    [-1.67, 266.763],
                    [-1.674, 266.763],
                    [-1.679, 266.763],
                    [-1.683, 266.763],
                    [-1.688, 266.763],
                    [-1.692, 266.763],
                    [-1.697, 266.763],
                    [-1.701, 266.763],
                    [-1.706, 266.763],
                    [-1.71, 266.763],
                    [-1.715, 266.763],
                    [-1.719, 266.763],
                    [-1.724, 266.763],
                    [-1.728, 266.763],
                    [-1.733, 266.763],
                    [-1.737, 266.763],
                    [-1.742, 266.763],
                    [-1.746, 266.763],
                    [-1.75, 266.763],
                    [-1.755, 266.763],
                    [-1.759, 266.763],
                    [-1.764, 266.763],
                    [-1.768, 266.763],
                    [-1.773, 266.763],
                    [-1.777, 266.763],
                    [-1.782, 266.763],
                    [-1.786, 266.763],
                    [-1.79, 266.763],
                    [-1.795, 266.763],
                    [-1.799, 266.763],
                    [-1.804, 266.763],
                    [-1.808, 266.763],
                    [-1.813, 266.763],
                    [-1.817, 266.763],
                    [-1.821, 266.763],
                    [-1.826, 266.763],
                    [-1.83, 266.763],
                    [-1.835, 266.763],
                    [-1.839, 266.763],
                    [-1.843, 266.763],
                    [-1.848, 266.763],
                    [-1.852, 266.763],
                    [-1.856, 266.763],
                    [-1.861, 266.763],
                    [-1.865, 266.763],
                    [-1.87, 266.763],
                    [-1.874, 266.763],
                    [-1.878, 266.763],
                    [-1.883, 266.763],
                    [-1.887, 266.763],
                    [-1.891, 266.763],
                    [-1.896, 266.763],
                    [-1.9, 266.763],
                    [-1.904, 266.763],
                    [-1.909, 266.763],
                    [-1.913, 266.763],
                    [-1.917, 266.763],
                    [-1.922, 266.763],
                    [-1.926, 266.763],
                    [-1.93, 266.763],
                    [-1.935, 266.763],
                    [-1.939, 266.763],
                    [-1.943, 266.763],
                    [-1.947, 266.763],
                    [-1.952, 266.763],
                    [-1.956, 266.763],
                    [-1.96, 266.763],
                    [-1.965, 266.763],
                    [-1.969, 266.763],
                    [-1.973, 266.763],
                    [-1.977, 266.763],
                    [-1.982, 266.763],
                    [-126.944, 137.534],
                    [-126.944, 106.37],
                    [-104.073, 85.669],
                    [-104.072, 85.669],
                    [-85.278, 106.914],
                    [-85.278, 139.527],
                    [-59.346, 200.763],
                    [2.565, 225.184],
                    [86.389, 137.715],
                    [86.389, 36.976],
                    [86.389, 36.476],
                    [85.889, 36.476],
                    [-96.035, 36.476],
                    [-117.822, 36.476],
                    [-126.581, 27.908],
                    [-126.581, -108.154],
                    [-88.422, -198.752]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.738 },
                    o: { x: 0.62, y: 0 },
                    t: 141,
                    s: [0, -233.171],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.806 },
                    o: { x: 0.167, y: 0.168 },
                    t: 157.616,
                    s: [-172.022, 33.708],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.782 },
                    o: { x: 0.167, y: 0.248 },
                    t: 167.4,
                    s: [1.984, 268.891],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.167, y: 0.205 },
                    t: 179.15,
                    s: [159.747, 44.65],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  { t: 192.00000782032, s: [3.969, -234.163] }
                ],
                ix: 5
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.739 },
                    o: { x: 0.62, y: 0 },
                    t: 141,
                    s: [0.778, 264.922],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.798 },
                    o: { x: 0.167, y: 0.162 },
                    t: 157.616,
                    s: [161.621, 8.024],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.816 },
                    o: { x: 0.167, y: 0.215 },
                    t: 167.4,
                    s: [4.747, -233.171],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.167, y: 0.243 },
                    t: 179.15,
                    s: [-153.016, 49.611],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  { t: 192.00000782032, s: [-0.214, 269.883] }
                ],
                ix: 6
              },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 90, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 158.000006435472,
      op: 243.000009897593,
      st: 74.0000030140818,
      bm: 0
    },
    {
      ddd: 0,
      ind: 36,
      ty: 4,
      nm: "Shape Layer 4",
      td: 1,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 122,
              s: [0]
            },
            { t: 129.000005254278, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [103, -85],
                    [93, -226],
                    [-109, -226],
                    [-115, -19],
                    [-115, 7],
                    [-88, 7],
                    [85, 7],
                    [109, 7],
                    [109, 29],
                    [109, 105],
                    [109, 196],
                    [-5, 238],
                    [-114, 199],
                    [-114, 88]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "rd",
              nm: "Round Corners 1",
              r: { a: 0, k: 214, ix: 1 },
              ix: 2,
              mn: "ADBE Vector Filter - RC",
              hd: false
            },
            {
              ty: "tm",
              s: { a: 0, k: 100, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.322], y: [1] },
                    o: { x: [0.814], y: [0] },
                    t: 115,
                    s: [100]
                  },
                  { t: 168.00000684278, s: [50] }
                ],
                ix: 2
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 3,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 65, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 5,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 106.000004317469,
      op: 158.000006435472,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 37,
      ty: 4,
      nm: "Camada 14",
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 50, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [957.056, 546.763, 0], ix: 2 },
        a: { a: 0, k: [0.556, 15.763, 0], ix: 1 },
        s: { a: 0, k: [100.784, 100.784, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-34.361, -0.722],
                    [0, 0],
                    [0, -70.035],
                    [0, 0],
                    [10.802, -1.062],
                    [0.358, 12.035],
                    [0, 0],
                    [16.617, 16.218],
                    [23.194, -0.547],
                    [0, 0],
                    [0, 0],
                    [0, -47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.177, -4.803],
                    [0, 0],
                    [24.598, -24.186],
                    [33.458, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 70.033],
                    [0, 0],
                    [-12.411, -1.239],
                    [0, 0],
                    [0, -10.812],
                    [0, 0],
                    [-16.617, -16.218],
                    [-23.194, 0.547],
                    [0, 47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.177, 4.803],
                    [0, 0],
                    [-24.598, 24.186]
                  ],
                  o: [
                    [0, 0],
                    [68.895, 1.263],
                    [0, 0],
                    [0, 10.81],
                    [-12.413, 1.06],
                    [0, 0],
                    [0, -23.329],
                    [-16.617, -16.219],
                    [0, 0],
                    [0, 0],
                    [-46.214, 1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.627, 0.001],
                    [0, 0],
                    [0, 34.29],
                    [-23.872, 23.462],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-68.892, -1.266],
                    [0, 0],
                    [0, -12.21],
                    [0, 0],
                    [10.803, 1.06],
                    [0, 0],
                    [0, 23.329],
                    [16.618, 16.219],
                    [46.215, -1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-4.627, 0],
                    [0, 0],
                    [0, -34.29],
                    [24.597, -24.185]
                  ],
                  v: [
                    [3.087, -235.208],
                    [3.089, -235.208],
                    [128.056, -105.979],
                    [128.056, -75.54],
                    [109.265, -54.296],
                    [86.208, -74.823],
                    [86.208, -107.972],
                    [60.275, -169.208],
                    [-1.635, -193.629],
                    [-1.623, -193.129],
                    [-1.635, -193.629],
                    [-85.459, -106.161],
                    [-85.459, -5.421],
                    [-85.459, -4.921],
                    [-84.959, -4.921],
                    [118.933, -5.103],
                    [118.934, -5.103],
                    [127.693, 3.466],
                    [127.693, 139.708],
                    [89.533, 230.307],
                    [0.374, 266.763],
                    [0.37, 266.763],
                    [0.366, 266.763],
                    [0.362, 266.763],
                    [0.357, 266.763],
                    [0.353, 266.763],
                    [0.349, 266.763],
                    [0.344, 266.763],
                    [0.34, 266.763],
                    [0.336, 266.763],
                    [0.332, 266.763],
                    [0.327, 266.763],
                    [0.323, 266.763],
                    [0.319, 266.763],
                    [0.314, 266.763],
                    [0.31, 266.763],
                    [0.306, 266.763],
                    [0.301, 266.763],
                    [0.297, 266.763],
                    [0.293, 266.763],
                    [0.288, 266.763],
                    [0.284, 266.763],
                    [0.28, 266.763],
                    [0.275, 266.763],
                    [0.271, 266.763],
                    [0.267, 266.763],
                    [0.262, 266.763],
                    [0.258, 266.763],
                    [0.254, 266.763],
                    [0.249, 266.763],
                    [0.245, 266.763],
                    [0.241, 266.763],
                    [0.236, 266.763],
                    [0.232, 266.763],
                    [0.227, 266.763],
                    [0.223, 266.763],
                    [0.219, 266.763],
                    [0.214, 266.763],
                    [0.21, 266.763],
                    [0.205, 266.763],
                    [0.201, 266.763],
                    [0.197, 266.763],
                    [0.192, 266.763],
                    [0.188, 266.763],
                    [0.183, 266.763],
                    [0.179, 266.763],
                    [0.174, 266.763],
                    [0.17, 266.763],
                    [0.166, 266.763],
                    [0.161, 266.763],
                    [0.157, 266.763],
                    [0.152, 266.763],
                    [0.148, 266.763],
                    [0.143, 266.763],
                    [0.139, 266.763],
                    [0.134, 266.763],
                    [0.13, 266.763],
                    [0.126, 266.763],
                    [0.121, 266.763],
                    [0.117, 266.763],
                    [0.112, 266.763],
                    [0.108, 266.763],
                    [0.103, 266.763],
                    [0.099, 266.763],
                    [0.094, 266.763],
                    [0.09, 266.763],
                    [0.085, 266.763],
                    [0.081, 266.763],
                    [0.076, 266.763],
                    [0.072, 266.763],
                    [0.067, 266.763],
                    [0.063, 266.763],
                    [0.058, 266.763],
                    [0.054, 266.763],
                    [0.049, 266.763],
                    [0.045, 266.763],
                    [0.04, 266.763],
                    [0.036, 266.763],
                    [0.031, 266.763],
                    [0.027, 266.763],
                    [0.022, 266.763],
                    [0.017, 266.763],
                    [0.013, 266.763],
                    [0.008, 266.763],
                    [0.004, 266.763],
                    [-0.001, 266.763],
                    [-0.005, 266.763],
                    [-0.01, 266.763],
                    [-0.014, 266.763],
                    [-0.019, 266.763],
                    [-0.023, 266.763],
                    [-0.028, 266.763],
                    [-0.033, 266.763],
                    [-0.037, 266.763],
                    [-0.042, 266.763],
                    [-0.046, 266.763],
                    [-0.051, 266.763],
                    [-0.055, 266.763],
                    [-0.06, 266.763],
                    [-0.065, 266.763],
                    [-0.069, 266.763],
                    [-0.074, 266.763],
                    [-0.078, 266.763],
                    [-0.083, 266.763],
                    [-0.088, 266.763],
                    [-0.092, 266.763],
                    [-0.097, 266.763],
                    [-0.101, 266.763],
                    [-0.106, 266.763],
                    [-0.111, 266.763],
                    [-0.115, 266.763],
                    [-0.12, 266.763],
                    [-0.124, 266.763],
                    [-0.129, 266.763],
                    [-0.134, 266.763],
                    [-0.138, 266.763],
                    [-0.143, 266.763],
                    [-0.148, 266.763],
                    [-0.152, 266.763],
                    [-0.157, 266.763],
                    [-0.162, 266.763],
                    [-0.166, 266.763],
                    [-0.171, 266.763],
                    [-0.175, 266.763],
                    [-0.18, 266.763],
                    [-0.185, 266.763],
                    [-0.189, 266.763],
                    [-0.194, 266.763],
                    [-0.199, 266.763],
                    [-0.203, 266.763],
                    [-0.208, 266.763],
                    [-0.213, 266.763],
                    [-0.217, 266.763],
                    [-0.222, 266.763],
                    [-0.227, 266.763],
                    [-0.231, 266.763],
                    [-0.236, 266.763],
                    [-0.241, 266.763],
                    [-0.245, 266.763],
                    [-0.25, 266.763],
                    [-0.255, 266.763],
                    [-0.259, 266.763],
                    [-0.264, 266.763],
                    [-0.269, 266.763],
                    [-0.273, 266.763],
                    [-0.278, 266.763],
                    [-0.283, 266.763],
                    [-0.287, 266.763],
                    [-0.292, 266.763],
                    [-0.297, 266.763],
                    [-0.302, 266.763],
                    [-0.306, 266.763],
                    [-0.311, 266.763],
                    [-0.316, 266.763],
                    [-0.32, 266.763],
                    [-0.325, 266.763],
                    [-0.33, 266.763],
                    [-0.334, 266.763],
                    [-0.339, 266.763],
                    [-0.344, 266.763],
                    [-0.349, 266.763],
                    [-0.353, 266.763],
                    [-0.358, 266.763],
                    [-0.363, 266.763],
                    [-0.367, 266.763],
                    [-0.372, 266.763],
                    [-0.377, 266.763],
                    [-0.382, 266.763],
                    [-0.386, 266.763],
                    [-0.391, 266.763],
                    [-0.396, 266.763],
                    [-0.401, 266.763],
                    [-0.405, 266.763],
                    [-0.41, 266.763],
                    [-0.415, 266.763],
                    [-0.419, 266.763],
                    [-0.424, 266.763],
                    [-0.429, 266.763],
                    [-0.434, 266.763],
                    [-0.438, 266.763],
                    [-0.443, 266.763],
                    [-0.448, 266.763],
                    [-0.453, 266.763],
                    [-0.457, 266.763],
                    [-0.462, 266.763],
                    [-0.467, 266.763],
                    [-0.472, 266.763],
                    [-0.476, 266.763],
                    [-0.481, 266.763],
                    [-0.486, 266.763],
                    [-0.491, 266.763],
                    [-0.495, 266.763],
                    [-0.5, 266.763],
                    [-0.505, 266.763],
                    [-0.51, 266.763],
                    [-0.514, 266.763],
                    [-0.519, 266.763],
                    [-0.524, 266.763],
                    [-0.529, 266.763],
                    [-0.533, 266.763],
                    [-0.538, 266.763],
                    [-0.543, 266.763],
                    [-0.548, 266.763],
                    [-0.552, 266.763],
                    [-0.557, 266.763],
                    [-0.562, 266.763],
                    [-0.567, 266.763],
                    [-0.572, 266.763],
                    [-0.576, 266.763],
                    [-0.581, 266.763],
                    [-0.586, 266.763],
                    [-0.591, 266.763],
                    [-0.595, 266.763],
                    [-0.6, 266.763],
                    [-0.605, 266.763],
                    [-0.61, 266.763],
                    [-0.614, 266.763],
                    [-0.619, 266.763],
                    [-0.624, 266.763],
                    [-0.629, 266.763],
                    [-0.634, 266.763],
                    [-0.638, 266.763],
                    [-0.643, 266.763],
                    [-0.648, 266.763],
                    [-0.653, 266.763],
                    [-0.657, 266.763],
                    [-0.662, 266.763],
                    [-0.667, 266.763],
                    [-0.672, 266.763],
                    [-0.677, 266.763],
                    [-0.681, 266.763],
                    [-0.686, 266.763],
                    [-0.691, 266.763],
                    [-0.696, 266.763],
                    [-0.7, 266.763],
                    [-0.705, 266.763],
                    [-0.71, 266.763],
                    [-0.715, 266.763],
                    [-0.72, 266.763],
                    [-0.724, 266.763],
                    [-0.729, 266.763],
                    [-0.734, 266.763],
                    [-0.739, 266.763],
                    [-0.744, 266.763],
                    [-0.748, 266.763],
                    [-0.753, 266.763],
                    [-0.758, 266.763],
                    [-0.763, 266.763],
                    [-0.767, 266.763],
                    [-0.772, 266.763],
                    [-0.777, 266.763],
                    [-0.782, 266.763],
                    [-0.787, 266.763],
                    [-0.791, 266.763],
                    [-0.796, 266.763],
                    [-0.801, 266.763],
                    [-0.806, 266.763],
                    [-0.811, 266.763],
                    [-0.815, 266.763],
                    [-0.82, 266.763],
                    [-0.825, 266.763],
                    [-0.83, 266.763],
                    [-0.834, 266.763],
                    [-0.839, 266.763],
                    [-0.844, 266.763],
                    [-0.849, 266.763],
                    [-0.854, 266.763],
                    [-0.858, 266.763],
                    [-0.863, 266.763],
                    [-0.868, 266.763],
                    [-0.873, 266.763],
                    [-0.878, 266.763],
                    [-0.882, 266.763],
                    [-0.887, 266.763],
                    [-0.892, 266.763],
                    [-0.897, 266.763],
                    [-0.901, 266.763],
                    [-0.906, 266.763],
                    [-0.911, 266.763],
                    [-0.916, 266.763],
                    [-0.921, 266.763],
                    [-0.925, 266.763],
                    [-0.93, 266.763],
                    [-0.935, 266.763],
                    [-0.94, 266.763],
                    [-0.945, 266.763],
                    [-0.949, 266.763],
                    [-0.954, 266.763],
                    [-0.959, 266.763],
                    [-0.964, 266.763],
                    [-0.968, 266.763],
                    [-0.973, 266.763],
                    [-0.978, 266.763],
                    [-0.983, 266.763],
                    [-0.988, 266.763],
                    [-0.992, 266.763],
                    [-0.997, 266.763],
                    [-1.002, 266.763],
                    [-1.007, 266.763],
                    [-1.011, 266.763],
                    [-1.016, 266.763],
                    [-1.021, 266.763],
                    [-1.026, 266.763],
                    [-1.03, 266.763],
                    [-1.035, 266.763],
                    [-1.04, 266.763],
                    [-1.045, 266.763],
                    [-1.05, 266.763],
                    [-1.054, 266.763],
                    [-1.059, 266.763],
                    [-1.064, 266.763],
                    [-1.069, 266.763],
                    [-1.073, 266.763],
                    [-1.078, 266.763],
                    [-1.083, 266.763],
                    [-1.088, 266.763],
                    [-1.092, 266.763],
                    [-1.097, 266.763],
                    [-1.102, 266.763],
                    [-1.107, 266.763],
                    [-1.111, 266.763],
                    [-1.116, 266.763],
                    [-1.121, 266.763],
                    [-1.126, 266.763],
                    [-1.13, 266.763],
                    [-1.135, 266.763],
                    [-1.14, 266.763],
                    [-1.145, 266.763],
                    [-1.149, 266.763],
                    [-1.154, 266.763],
                    [-1.159, 266.763],
                    [-1.164, 266.763],
                    [-1.168, 266.763],
                    [-1.173, 266.763],
                    [-1.178, 266.763],
                    [-1.183, 266.763],
                    [-1.187, 266.763],
                    [-1.192, 266.763],
                    [-1.197, 266.763],
                    [-1.202, 266.763],
                    [-1.206, 266.763],
                    [-1.211, 266.763],
                    [-1.216, 266.763],
                    [-1.22, 266.763],
                    [-1.225, 266.763],
                    [-1.23, 266.763],
                    [-1.235, 266.763],
                    [-1.239, 266.763],
                    [-1.244, 266.763],
                    [-1.249, 266.763],
                    [-1.254, 266.763],
                    [-1.258, 266.763],
                    [-1.263, 266.763],
                    [-1.268, 266.763],
                    [-1.272, 266.763],
                    [-1.277, 266.763],
                    [-1.282, 266.763],
                    [-1.286, 266.763],
                    [-1.291, 266.763],
                    [-1.296, 266.763],
                    [-1.301, 266.763],
                    [-1.305, 266.763],
                    [-1.31, 266.763],
                    [-1.315, 266.763],
                    [-1.319, 266.763],
                    [-1.324, 266.763],
                    [-1.329, 266.763],
                    [-1.333, 266.763],
                    [-1.338, 266.763],
                    [-1.343, 266.763],
                    [-1.347, 266.763],
                    [-1.352, 266.763],
                    [-1.357, 266.763],
                    [-1.362, 266.763],
                    [-1.366, 266.763],
                    [-1.371, 266.763],
                    [-1.376, 266.763],
                    [-1.38, 266.763],
                    [-1.385, 266.763],
                    [-1.39, 266.763],
                    [-1.394, 266.763],
                    [-1.399, 266.763],
                    [-1.404, 266.763],
                    [-1.408, 266.763],
                    [-1.413, 266.763],
                    [-1.418, 266.763],
                    [-1.422, 266.763],
                    [-1.427, 266.763],
                    [-1.431, 266.763],
                    [-1.436, 266.763],
                    [-1.441, 266.763],
                    [-1.445, 266.763],
                    [-1.45, 266.763],
                    [-1.455, 266.763],
                    [-1.459, 266.763],
                    [-1.464, 266.763],
                    [-1.469, 266.763],
                    [-1.473, 266.763],
                    [-1.478, 266.763],
                    [-1.482, 266.763],
                    [-1.487, 266.763],
                    [-1.492, 266.763],
                    [-1.496, 266.763],
                    [-1.501, 266.763],
                    [-1.505, 266.763],
                    [-1.51, 266.763],
                    [-1.515, 266.763],
                    [-1.519, 266.763],
                    [-1.524, 266.763],
                    [-1.529, 266.763],
                    [-1.533, 266.763],
                    [-1.538, 266.763],
                    [-1.542, 266.763],
                    [-1.547, 266.763],
                    [-1.551, 266.763],
                    [-1.556, 266.763],
                    [-1.561, 266.763],
                    [-1.565, 266.763],
                    [-1.57, 266.763],
                    [-1.574, 266.763],
                    [-1.579, 266.763],
                    [-1.583, 266.763],
                    [-1.588, 266.763],
                    [-1.593, 266.763],
                    [-1.597, 266.763],
                    [-1.602, 266.763],
                    [-1.606, 266.763],
                    [-1.611, 266.763],
                    [-1.615, 266.763],
                    [-1.62, 266.763],
                    [-1.624, 266.763],
                    [-1.629, 266.763],
                    [-1.634, 266.763],
                    [-1.638, 266.763],
                    [-1.643, 266.763],
                    [-1.647, 266.763],
                    [-1.652, 266.763],
                    [-1.656, 266.763],
                    [-1.661, 266.763],
                    [-1.665, 266.763],
                    [-1.67, 266.763],
                    [-1.674, 266.763],
                    [-1.679, 266.763],
                    [-1.683, 266.763],
                    [-1.688, 266.763],
                    [-1.692, 266.763],
                    [-1.697, 266.763],
                    [-1.701, 266.763],
                    [-1.706, 266.763],
                    [-1.71, 266.763],
                    [-1.715, 266.763],
                    [-1.719, 266.763],
                    [-1.724, 266.763],
                    [-1.728, 266.763],
                    [-1.733, 266.763],
                    [-1.737, 266.763],
                    [-1.742, 266.763],
                    [-1.746, 266.763],
                    [-1.75, 266.763],
                    [-1.755, 266.763],
                    [-1.759, 266.763],
                    [-1.764, 266.763],
                    [-1.768, 266.763],
                    [-1.773, 266.763],
                    [-1.777, 266.763],
                    [-1.782, 266.763],
                    [-1.786, 266.763],
                    [-1.79, 266.763],
                    [-1.795, 266.763],
                    [-1.799, 266.763],
                    [-1.804, 266.763],
                    [-1.808, 266.763],
                    [-1.813, 266.763],
                    [-1.817, 266.763],
                    [-1.821, 266.763],
                    [-1.826, 266.763],
                    [-1.83, 266.763],
                    [-1.835, 266.763],
                    [-1.839, 266.763],
                    [-1.843, 266.763],
                    [-1.848, 266.763],
                    [-1.852, 266.763],
                    [-1.856, 266.763],
                    [-1.861, 266.763],
                    [-1.865, 266.763],
                    [-1.87, 266.763],
                    [-1.874, 266.763],
                    [-1.878, 266.763],
                    [-1.883, 266.763],
                    [-1.887, 266.763],
                    [-1.891, 266.763],
                    [-1.896, 266.763],
                    [-1.9, 266.763],
                    [-1.904, 266.763],
                    [-1.909, 266.763],
                    [-1.913, 266.763],
                    [-1.917, 266.763],
                    [-1.922, 266.763],
                    [-1.926, 266.763],
                    [-1.93, 266.763],
                    [-1.935, 266.763],
                    [-1.939, 266.763],
                    [-1.943, 266.763],
                    [-1.947, 266.763],
                    [-1.952, 266.763],
                    [-1.956, 266.763],
                    [-1.96, 266.763],
                    [-1.965, 266.763],
                    [-1.969, 266.763],
                    [-1.973, 266.763],
                    [-1.977, 266.763],
                    [-1.982, 266.763],
                    [-126.944, 137.534],
                    [-126.944, 106.37],
                    [-104.073, 85.669],
                    [-104.072, 85.669],
                    [-85.278, 106.914],
                    [-85.278, 139.527],
                    [-59.346, 200.763],
                    [2.565, 225.184],
                    [86.389, 137.715],
                    [86.389, 36.976],
                    [86.389, 36.476],
                    [85.889, 36.476],
                    [-96.035, 36.476],
                    [-117.822, 36.476],
                    [-126.581, 27.908],
                    [-126.581, -108.154],
                    [-88.422, -198.752]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.797 },
                    o: { x: 0.62, y: 0 },
                    t: 141,
                    s: [0, -233.171],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.806 },
                    o: { x: 0.167, y: 0.232 },
                    t: 157.616,
                    s: [-172.022, 33.708],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.782 },
                    o: { x: 0.167, y: 0.248 },
                    t: 175.084,
                    s: [1.984, 268.891],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.167, y: 0.205 },
                    t: 196.059,
                    s: [159.747, 44.65],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  { t: 219.000008920053, s: [3.969, -234.163] }
                ],
                ix: 5
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.798 },
                    o: { x: 0.62, y: 0 },
                    t: 141,
                    s: [0.778, 264.922],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.798 },
                    o: { x: 0.167, y: 0.224 },
                    t: 157.616,
                    s: [161.621, 8.024],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.816 },
                    o: { x: 0.167, y: 0.215 },
                    t: 175.084,
                    s: [4.747, -233.171],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.167, y: 0.243 },
                    t: 196.059,
                    s: [-153.016, 49.611],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  { t: 219.000008920053, s: [-0.214, 269.883] }
                ],
                ix: 6
              },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 90, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 105.000004276738,
      op: 158.000006435472,
      st: 74.0000030140818,
      bm: 0
    },
    {
      ddd: 0,
      ind: 38,
      ty: 4,
      nm: "Shape Layer 3",
      td: 1,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 122,
              s: [0]
            },
            { t: 129.000005254278, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [103, -85],
                    [93, -226],
                    [-109, -226],
                    [-115, -19],
                    [-115, 7],
                    [-88, 7],
                    [85, 7],
                    [109, 7],
                    [109, 29],
                    [109, 105],
                    [109, 247],
                    [-114, 247],
                    [-114, 88]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "rd",
              nm: "Round Corners 1",
              r: { a: 0, k: 214, ix: 1 },
              ix: 2,
              mn: "ADBE Vector Filter - RC",
              hd: false
            },
            {
              ty: "tm",
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.442], y: [1] },
                    o: { x: [0.6], y: [0] },
                    t: 115,
                    s: [0]
                  },
                  { t: 168.00000684278, s: [49] }
                ],
                ix: 2
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 3,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 65, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 5,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 105.000004276738,
      op: 158.000006435472,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 39,
      ty: 4,
      nm: "Camada 10",
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 50, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [957.056, 546.763, 0], ix: 2 },
        a: { a: 0, k: [0.556, 15.763, 0], ix: 1 },
        s: { a: 0, k: [100.784, 100.784, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-34.361, -0.722],
                    [0, 0],
                    [0, -70.035],
                    [0, 0],
                    [10.802, -1.062],
                    [0.358, 12.035],
                    [0, 0],
                    [16.617, 16.218],
                    [23.194, -0.547],
                    [0, 0],
                    [0, 0],
                    [0, -47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.177, -4.803],
                    [0, 0],
                    [24.598, -24.186],
                    [33.458, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 70.033],
                    [0, 0],
                    [-12.411, -1.239],
                    [0, 0],
                    [0, -10.812],
                    [0, 0],
                    [-16.617, -16.218],
                    [-23.194, 0.547],
                    [0, 47.19],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.177, 4.803],
                    [0, 0],
                    [-24.598, 24.186]
                  ],
                  o: [
                    [0, 0],
                    [68.895, 1.263],
                    [0, 0],
                    [0, 10.81],
                    [-12.413, 1.06],
                    [0, 0],
                    [0, -23.329],
                    [-16.617, -16.219],
                    [0, 0],
                    [0, 0],
                    [-46.214, 1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [4.627, 0.001],
                    [0, 0],
                    [0, 34.29],
                    [-23.872, 23.462],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-68.892, -1.266],
                    [0, 0],
                    [0, -12.21],
                    [0, 0],
                    [10.803, 1.06],
                    [0, 0],
                    [0, 23.329],
                    [16.618, 16.219],
                    [46.215, -1.094],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-4.627, 0],
                    [0, 0],
                    [0, -34.29],
                    [24.597, -24.185]
                  ],
                  v: [
                    [3.087, -235.208],
                    [3.089, -235.208],
                    [128.056, -105.979],
                    [128.056, -75.54],
                    [109.265, -54.296],
                    [86.208, -74.823],
                    [86.208, -107.972],
                    [60.275, -169.208],
                    [-1.635, -193.629],
                    [-1.623, -193.129],
                    [-1.635, -193.629],
                    [-85.459, -106.161],
                    [-85.459, -5.421],
                    [-85.459, -4.921],
                    [-84.959, -4.921],
                    [118.933, -5.103],
                    [118.934, -5.103],
                    [127.693, 3.466],
                    [127.693, 139.708],
                    [89.533, 230.307],
                    [0.374, 266.763],
                    [0.37, 266.763],
                    [0.366, 266.763],
                    [0.362, 266.763],
                    [0.357, 266.763],
                    [0.353, 266.763],
                    [0.349, 266.763],
                    [0.344, 266.763],
                    [0.34, 266.763],
                    [0.336, 266.763],
                    [0.332, 266.763],
                    [0.327, 266.763],
                    [0.323, 266.763],
                    [0.319, 266.763],
                    [0.314, 266.763],
                    [0.31, 266.763],
                    [0.306, 266.763],
                    [0.301, 266.763],
                    [0.297, 266.763],
                    [0.293, 266.763],
                    [0.288, 266.763],
                    [0.284, 266.763],
                    [0.28, 266.763],
                    [0.275, 266.763],
                    [0.271, 266.763],
                    [0.267, 266.763],
                    [0.262, 266.763],
                    [0.258, 266.763],
                    [0.254, 266.763],
                    [0.249, 266.763],
                    [0.245, 266.763],
                    [0.241, 266.763],
                    [0.236, 266.763],
                    [0.232, 266.763],
                    [0.227, 266.763],
                    [0.223, 266.763],
                    [0.219, 266.763],
                    [0.214, 266.763],
                    [0.21, 266.763],
                    [0.205, 266.763],
                    [0.201, 266.763],
                    [0.197, 266.763],
                    [0.192, 266.763],
                    [0.188, 266.763],
                    [0.183, 266.763],
                    [0.179, 266.763],
                    [0.174, 266.763],
                    [0.17, 266.763],
                    [0.166, 266.763],
                    [0.161, 266.763],
                    [0.157, 266.763],
                    [0.152, 266.763],
                    [0.148, 266.763],
                    [0.143, 266.763],
                    [0.139, 266.763],
                    [0.134, 266.763],
                    [0.13, 266.763],
                    [0.126, 266.763],
                    [0.121, 266.763],
                    [0.117, 266.763],
                    [0.112, 266.763],
                    [0.108, 266.763],
                    [0.103, 266.763],
                    [0.099, 266.763],
                    [0.094, 266.763],
                    [0.09, 266.763],
                    [0.085, 266.763],
                    [0.081, 266.763],
                    [0.076, 266.763],
                    [0.072, 266.763],
                    [0.067, 266.763],
                    [0.063, 266.763],
                    [0.058, 266.763],
                    [0.054, 266.763],
                    [0.049, 266.763],
                    [0.045, 266.763],
                    [0.04, 266.763],
                    [0.036, 266.763],
                    [0.031, 266.763],
                    [0.027, 266.763],
                    [0.022, 266.763],
                    [0.017, 266.763],
                    [0.013, 266.763],
                    [0.008, 266.763],
                    [0.004, 266.763],
                    [-0.001, 266.763],
                    [-0.005, 266.763],
                    [-0.01, 266.763],
                    [-0.014, 266.763],
                    [-0.019, 266.763],
                    [-0.023, 266.763],
                    [-0.028, 266.763],
                    [-0.033, 266.763],
                    [-0.037, 266.763],
                    [-0.042, 266.763],
                    [-0.046, 266.763],
                    [-0.051, 266.763],
                    [-0.055, 266.763],
                    [-0.06, 266.763],
                    [-0.065, 266.763],
                    [-0.069, 266.763],
                    [-0.074, 266.763],
                    [-0.078, 266.763],
                    [-0.083, 266.763],
                    [-0.088, 266.763],
                    [-0.092, 266.763],
                    [-0.097, 266.763],
                    [-0.101, 266.763],
                    [-0.106, 266.763],
                    [-0.111, 266.763],
                    [-0.115, 266.763],
                    [-0.12, 266.763],
                    [-0.124, 266.763],
                    [-0.129, 266.763],
                    [-0.134, 266.763],
                    [-0.138, 266.763],
                    [-0.143, 266.763],
                    [-0.148, 266.763],
                    [-0.152, 266.763],
                    [-0.157, 266.763],
                    [-0.162, 266.763],
                    [-0.166, 266.763],
                    [-0.171, 266.763],
                    [-0.175, 266.763],
                    [-0.18, 266.763],
                    [-0.185, 266.763],
                    [-0.189, 266.763],
                    [-0.194, 266.763],
                    [-0.199, 266.763],
                    [-0.203, 266.763],
                    [-0.208, 266.763],
                    [-0.213, 266.763],
                    [-0.217, 266.763],
                    [-0.222, 266.763],
                    [-0.227, 266.763],
                    [-0.231, 266.763],
                    [-0.236, 266.763],
                    [-0.241, 266.763],
                    [-0.245, 266.763],
                    [-0.25, 266.763],
                    [-0.255, 266.763],
                    [-0.259, 266.763],
                    [-0.264, 266.763],
                    [-0.269, 266.763],
                    [-0.273, 266.763],
                    [-0.278, 266.763],
                    [-0.283, 266.763],
                    [-0.287, 266.763],
                    [-0.292, 266.763],
                    [-0.297, 266.763],
                    [-0.302, 266.763],
                    [-0.306, 266.763],
                    [-0.311, 266.763],
                    [-0.316, 266.763],
                    [-0.32, 266.763],
                    [-0.325, 266.763],
                    [-0.33, 266.763],
                    [-0.334, 266.763],
                    [-0.339, 266.763],
                    [-0.344, 266.763],
                    [-0.349, 266.763],
                    [-0.353, 266.763],
                    [-0.358, 266.763],
                    [-0.363, 266.763],
                    [-0.367, 266.763],
                    [-0.372, 266.763],
                    [-0.377, 266.763],
                    [-0.382, 266.763],
                    [-0.386, 266.763],
                    [-0.391, 266.763],
                    [-0.396, 266.763],
                    [-0.401, 266.763],
                    [-0.405, 266.763],
                    [-0.41, 266.763],
                    [-0.415, 266.763],
                    [-0.419, 266.763],
                    [-0.424, 266.763],
                    [-0.429, 266.763],
                    [-0.434, 266.763],
                    [-0.438, 266.763],
                    [-0.443, 266.763],
                    [-0.448, 266.763],
                    [-0.453, 266.763],
                    [-0.457, 266.763],
                    [-0.462, 266.763],
                    [-0.467, 266.763],
                    [-0.472, 266.763],
                    [-0.476, 266.763],
                    [-0.481, 266.763],
                    [-0.486, 266.763],
                    [-0.491, 266.763],
                    [-0.495, 266.763],
                    [-0.5, 266.763],
                    [-0.505, 266.763],
                    [-0.51, 266.763],
                    [-0.514, 266.763],
                    [-0.519, 266.763],
                    [-0.524, 266.763],
                    [-0.529, 266.763],
                    [-0.533, 266.763],
                    [-0.538, 266.763],
                    [-0.543, 266.763],
                    [-0.548, 266.763],
                    [-0.552, 266.763],
                    [-0.557, 266.763],
                    [-0.562, 266.763],
                    [-0.567, 266.763],
                    [-0.572, 266.763],
                    [-0.576, 266.763],
                    [-0.581, 266.763],
                    [-0.586, 266.763],
                    [-0.591, 266.763],
                    [-0.595, 266.763],
                    [-0.6, 266.763],
                    [-0.605, 266.763],
                    [-0.61, 266.763],
                    [-0.614, 266.763],
                    [-0.619, 266.763],
                    [-0.624, 266.763],
                    [-0.629, 266.763],
                    [-0.634, 266.763],
                    [-0.638, 266.763],
                    [-0.643, 266.763],
                    [-0.648, 266.763],
                    [-0.653, 266.763],
                    [-0.657, 266.763],
                    [-0.662, 266.763],
                    [-0.667, 266.763],
                    [-0.672, 266.763],
                    [-0.677, 266.763],
                    [-0.681, 266.763],
                    [-0.686, 266.763],
                    [-0.691, 266.763],
                    [-0.696, 266.763],
                    [-0.7, 266.763],
                    [-0.705, 266.763],
                    [-0.71, 266.763],
                    [-0.715, 266.763],
                    [-0.72, 266.763],
                    [-0.724, 266.763],
                    [-0.729, 266.763],
                    [-0.734, 266.763],
                    [-0.739, 266.763],
                    [-0.744, 266.763],
                    [-0.748, 266.763],
                    [-0.753, 266.763],
                    [-0.758, 266.763],
                    [-0.763, 266.763],
                    [-0.767, 266.763],
                    [-0.772, 266.763],
                    [-0.777, 266.763],
                    [-0.782, 266.763],
                    [-0.787, 266.763],
                    [-0.791, 266.763],
                    [-0.796, 266.763],
                    [-0.801, 266.763],
                    [-0.806, 266.763],
                    [-0.811, 266.763],
                    [-0.815, 266.763],
                    [-0.82, 266.763],
                    [-0.825, 266.763],
                    [-0.83, 266.763],
                    [-0.834, 266.763],
                    [-0.839, 266.763],
                    [-0.844, 266.763],
                    [-0.849, 266.763],
                    [-0.854, 266.763],
                    [-0.858, 266.763],
                    [-0.863, 266.763],
                    [-0.868, 266.763],
                    [-0.873, 266.763],
                    [-0.878, 266.763],
                    [-0.882, 266.763],
                    [-0.887, 266.763],
                    [-0.892, 266.763],
                    [-0.897, 266.763],
                    [-0.901, 266.763],
                    [-0.906, 266.763],
                    [-0.911, 266.763],
                    [-0.916, 266.763],
                    [-0.921, 266.763],
                    [-0.925, 266.763],
                    [-0.93, 266.763],
                    [-0.935, 266.763],
                    [-0.94, 266.763],
                    [-0.945, 266.763],
                    [-0.949, 266.763],
                    [-0.954, 266.763],
                    [-0.959, 266.763],
                    [-0.964, 266.763],
                    [-0.968, 266.763],
                    [-0.973, 266.763],
                    [-0.978, 266.763],
                    [-0.983, 266.763],
                    [-0.988, 266.763],
                    [-0.992, 266.763],
                    [-0.997, 266.763],
                    [-1.002, 266.763],
                    [-1.007, 266.763],
                    [-1.011, 266.763],
                    [-1.016, 266.763],
                    [-1.021, 266.763],
                    [-1.026, 266.763],
                    [-1.03, 266.763],
                    [-1.035, 266.763],
                    [-1.04, 266.763],
                    [-1.045, 266.763],
                    [-1.05, 266.763],
                    [-1.054, 266.763],
                    [-1.059, 266.763],
                    [-1.064, 266.763],
                    [-1.069, 266.763],
                    [-1.073, 266.763],
                    [-1.078, 266.763],
                    [-1.083, 266.763],
                    [-1.088, 266.763],
                    [-1.092, 266.763],
                    [-1.097, 266.763],
                    [-1.102, 266.763],
                    [-1.107, 266.763],
                    [-1.111, 266.763],
                    [-1.116, 266.763],
                    [-1.121, 266.763],
                    [-1.126, 266.763],
                    [-1.13, 266.763],
                    [-1.135, 266.763],
                    [-1.14, 266.763],
                    [-1.145, 266.763],
                    [-1.149, 266.763],
                    [-1.154, 266.763],
                    [-1.159, 266.763],
                    [-1.164, 266.763],
                    [-1.168, 266.763],
                    [-1.173, 266.763],
                    [-1.178, 266.763],
                    [-1.183, 266.763],
                    [-1.187, 266.763],
                    [-1.192, 266.763],
                    [-1.197, 266.763],
                    [-1.202, 266.763],
                    [-1.206, 266.763],
                    [-1.211, 266.763],
                    [-1.216, 266.763],
                    [-1.22, 266.763],
                    [-1.225, 266.763],
                    [-1.23, 266.763],
                    [-1.235, 266.763],
                    [-1.239, 266.763],
                    [-1.244, 266.763],
                    [-1.249, 266.763],
                    [-1.254, 266.763],
                    [-1.258, 266.763],
                    [-1.263, 266.763],
                    [-1.268, 266.763],
                    [-1.272, 266.763],
                    [-1.277, 266.763],
                    [-1.282, 266.763],
                    [-1.286, 266.763],
                    [-1.291, 266.763],
                    [-1.296, 266.763],
                    [-1.301, 266.763],
                    [-1.305, 266.763],
                    [-1.31, 266.763],
                    [-1.315, 266.763],
                    [-1.319, 266.763],
                    [-1.324, 266.763],
                    [-1.329, 266.763],
                    [-1.333, 266.763],
                    [-1.338, 266.763],
                    [-1.343, 266.763],
                    [-1.347, 266.763],
                    [-1.352, 266.763],
                    [-1.357, 266.763],
                    [-1.362, 266.763],
                    [-1.366, 266.763],
                    [-1.371, 266.763],
                    [-1.376, 266.763],
                    [-1.38, 266.763],
                    [-1.385, 266.763],
                    [-1.39, 266.763],
                    [-1.394, 266.763],
                    [-1.399, 266.763],
                    [-1.404, 266.763],
                    [-1.408, 266.763],
                    [-1.413, 266.763],
                    [-1.418, 266.763],
                    [-1.422, 266.763],
                    [-1.427, 266.763],
                    [-1.431, 266.763],
                    [-1.436, 266.763],
                    [-1.441, 266.763],
                    [-1.445, 266.763],
                    [-1.45, 266.763],
                    [-1.455, 266.763],
                    [-1.459, 266.763],
                    [-1.464, 266.763],
                    [-1.469, 266.763],
                    [-1.473, 266.763],
                    [-1.478, 266.763],
                    [-1.482, 266.763],
                    [-1.487, 266.763],
                    [-1.492, 266.763],
                    [-1.496, 266.763],
                    [-1.501, 266.763],
                    [-1.505, 266.763],
                    [-1.51, 266.763],
                    [-1.515, 266.763],
                    [-1.519, 266.763],
                    [-1.524, 266.763],
                    [-1.529, 266.763],
                    [-1.533, 266.763],
                    [-1.538, 266.763],
                    [-1.542, 266.763],
                    [-1.547, 266.763],
                    [-1.551, 266.763],
                    [-1.556, 266.763],
                    [-1.561, 266.763],
                    [-1.565, 266.763],
                    [-1.57, 266.763],
                    [-1.574, 266.763],
                    [-1.579, 266.763],
                    [-1.583, 266.763],
                    [-1.588, 266.763],
                    [-1.593, 266.763],
                    [-1.597, 266.763],
                    [-1.602, 266.763],
                    [-1.606, 266.763],
                    [-1.611, 266.763],
                    [-1.615, 266.763],
                    [-1.62, 266.763],
                    [-1.624, 266.763],
                    [-1.629, 266.763],
                    [-1.634, 266.763],
                    [-1.638, 266.763],
                    [-1.643, 266.763],
                    [-1.647, 266.763],
                    [-1.652, 266.763],
                    [-1.656, 266.763],
                    [-1.661, 266.763],
                    [-1.665, 266.763],
                    [-1.67, 266.763],
                    [-1.674, 266.763],
                    [-1.679, 266.763],
                    [-1.683, 266.763],
                    [-1.688, 266.763],
                    [-1.692, 266.763],
                    [-1.697, 266.763],
                    [-1.701, 266.763],
                    [-1.706, 266.763],
                    [-1.71, 266.763],
                    [-1.715, 266.763],
                    [-1.719, 266.763],
                    [-1.724, 266.763],
                    [-1.728, 266.763],
                    [-1.733, 266.763],
                    [-1.737, 266.763],
                    [-1.742, 266.763],
                    [-1.746, 266.763],
                    [-1.75, 266.763],
                    [-1.755, 266.763],
                    [-1.759, 266.763],
                    [-1.764, 266.763],
                    [-1.768, 266.763],
                    [-1.773, 266.763],
                    [-1.777, 266.763],
                    [-1.782, 266.763],
                    [-1.786, 266.763],
                    [-1.79, 266.763],
                    [-1.795, 266.763],
                    [-1.799, 266.763],
                    [-1.804, 266.763],
                    [-1.808, 266.763],
                    [-1.813, 266.763],
                    [-1.817, 266.763],
                    [-1.821, 266.763],
                    [-1.826, 266.763],
                    [-1.83, 266.763],
                    [-1.835, 266.763],
                    [-1.839, 266.763],
                    [-1.843, 266.763],
                    [-1.848, 266.763],
                    [-1.852, 266.763],
                    [-1.856, 266.763],
                    [-1.861, 266.763],
                    [-1.865, 266.763],
                    [-1.87, 266.763],
                    [-1.874, 266.763],
                    [-1.878, 266.763],
                    [-1.883, 266.763],
                    [-1.887, 266.763],
                    [-1.891, 266.763],
                    [-1.896, 266.763],
                    [-1.9, 266.763],
                    [-1.904, 266.763],
                    [-1.909, 266.763],
                    [-1.913, 266.763],
                    [-1.917, 266.763],
                    [-1.922, 266.763],
                    [-1.926, 266.763],
                    [-1.93, 266.763],
                    [-1.935, 266.763],
                    [-1.939, 266.763],
                    [-1.943, 266.763],
                    [-1.947, 266.763],
                    [-1.952, 266.763],
                    [-1.956, 266.763],
                    [-1.96, 266.763],
                    [-1.965, 266.763],
                    [-1.969, 266.763],
                    [-1.973, 266.763],
                    [-1.977, 266.763],
                    [-1.982, 266.763],
                    [-126.944, 137.534],
                    [-126.944, 106.37],
                    [-104.073, 85.669],
                    [-104.072, 85.669],
                    [-85.278, 106.914],
                    [-85.278, 139.527],
                    [-59.346, 200.763],
                    [2.565, 225.184],
                    [86.389, 137.715],
                    [86.389, 36.976],
                    [86.389, 36.476],
                    [85.889, 36.476],
                    [-96.035, 36.476],
                    [-117.822, 36.476],
                    [-126.581, 27.908],
                    [-126.581, -108.154],
                    [-88.422, -198.752]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0, 0.18, 1, 0.686, 0.5, 0.09, 0.833, 0.843, 1, 0, 0.667, 1
                  ],
                  ix: 9
                }
              },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.797 },
                    o: { x: 0.62, y: 0 },
                    t: 141,
                    s: [0, -233.171],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.806 },
                    o: { x: 0.167, y: 0.232 },
                    t: 157.616,
                    s: [-172.022, 33.708],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.782 },
                    o: { x: 0.167, y: 0.248 },
                    t: 175.084,
                    s: [1.984, 268.891],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.167, y: 0.205 },
                    t: 196.059,
                    s: [159.747, 44.65],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  { t: 219.000008920053, s: [3.969, -234.163] }
                ],
                ix: 5
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.798 },
                    o: { x: 0.62, y: 0 },
                    t: 141,
                    s: [0.778, 264.922],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.798 },
                    o: { x: 0.167, y: 0.224 },
                    t: 157.616,
                    s: [161.621, 8.024],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.833, y: 0.816 },
                    o: { x: 0.167, y: 0.215 },
                    t: 175.084,
                    s: [4.747, -233.171],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  {
                    i: { x: 0.4, y: 1 },
                    o: { x: 0.167, y: 0.243 },
                    t: 196.059,
                    s: [-153.016, 49.611],
                    to: [0, 0],
                    ti: [0, 0]
                  },
                  { t: 219.000008920053, s: [-0.214, 269.883] }
                ],
                ix: 6
              },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: { a: 0, k: 100, ix: 2 },
          o: { a: 0, k: 90, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 105.000004276738,
      op: 158.000006435472,
      st: 74.0000030140818,
      bm: 0
    },
    {
      ddd: 0,
      ind: 40,
      ty: 1,
      nm: "White Solid 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      sw: 1920,
      sh: 1080,
      sc: "#ffffff",
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0
    }
  ],
  markers: []
}
