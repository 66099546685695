import styled from "styled-components";

export const OtherIngredientsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding-top: 1rem;
`;

export const ViewMoreContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
`;

export const ViewMoreText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.selected};
`;
