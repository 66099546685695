// import React from "react";
// import { ReactElement } from "react";
import React, { useEffect, useState } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { useTheme } from "styled-components";
import { ArrowButton, Container } from "./style";

interface ScrollButtonsProps {
  length?: number;
}

const ScrollButtons = React.forwardRef<HTMLDivElement, ScrollButtonsProps>(
  ({ length }, ref) => {
    const theme = useTheme();
    const [isRefScrollable, setIsRefScrollable] = useState(false);
    const [leftButtonEnabled, setLeftButtonEnabled] = useState(false);
    const [rightButtonEnabled, setRightButtonEnabled] = useState(true);

    const scrollSize = length ?? window.innerWidth / 8;

    const scrollLeft = () => {
      if (ref !== null) {
        // @ts-ignore
        ref.current!.scrollLeft -= scrollSize;
        setRightButtonEnabled(true);
        // @ts-ignore
        if (ref.current!.scrollLeft - scrollSize <= 0) {
          setLeftButtonEnabled(false);
        }
      }
    };

    const scrollRight = () => {
      if (ref !== null) {
        // @ts-ignore
        ref.current!.scrollLeft += scrollSize;
        // @ts-ignore
        setLeftButtonEnabled(true);

        if (
          // @ts-ignore
          ref.current!.scrollLeft + scrollSize >=
          // @ts-ignore
          ref.current!.scrollWidth - ref.current!.clientWidth
        ) {
          setRightButtonEnabled(false);
        }
      }
    };

    const handleScroll = (event: any) => {
      if (
        event.target.scrollLeft >=
        event.target.scrollWidth - event.target.clientWidth
      ) {
        setRightButtonEnabled(false);
      } else {
        setRightButtonEnabled(true);
      }

      if (event.target.scrollLeft <= 0) {
        setLeftButtonEnabled(false);
      } else {
        setLeftButtonEnabled(true);
      }
    };

    useEffect(() => {
      if (ref !== null) {
        // @ts-ignore
        ref.current.addEventListener("scroll", (event) => handleScroll(event));
      }
    }, []);

    useEffect(() => {
      // @ts-ignore
      return ref.current.removeEventListener("scroll", (event) =>
        handleScroll(event)
      );
    });

    useEffect(() => {
      // @ts-ignore
      if (ref !== null && !!ref.current) {
        // @ts-ignore
        setIsRefScrollable(ref.current!.scrollWidth > ref.current!.clientWidth);
        // @ts-ignore
        setLeftButtonEnabled(ref.current!.scrollLeft > 0);
      }
    }, [ref]);

    // if (!isRefScrollable) {
    //   setRightButtonEnabled(false)
    // }

    return (
      <Container style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <ArrowButton disabled={!leftButtonEnabled} onClick={() => scrollLeft()}>
          <IoArrowBack
            color={
              !leftButtonEnabled && isRefScrollable
                ? theme.colors.grayLight : 
                !isRefScrollable ? 
                'transparent'
                : theme.colors.selected
            }
            size={22}
          />
        </ArrowButton>

        <ArrowButton
          disabled={!rightButtonEnabled || !isRefScrollable}
          onClick={() => scrollRight()}
        >
          <IoArrowForward
            color={
              !rightButtonEnabled
                ? theme.colors.grayLight : 
                !rightButtonEnabled || !isRefScrollable ? 
                'transparent'
                : theme.colors.selected
            }
            size={22}
          />
        </ArrowButton>
      </Container>
    );
  }
);

export default ScrollButtons;
