import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EssentialPack } from "../../../../assets/icons/svg/essential-pack.svg";
import { ReactComponent as PremiumPack } from "../../../../assets/icons/svg/optimalPack.svg";
import { ReactComponent as OptimalPack } from "../../../../assets/icons/svg/premiumPack.svg";
import { Button, Item, List, Nav, TabNewHomeContainer } from "./style";

interface TabNewHomeMobileProps {
  onSelectedPack: (packName: string) => void;
  selectedPack: string;
}

export function TabNewHomeMobile({
  onSelectedPack,
  selectedPack,
}: TabNewHomeMobileProps) {
  const [initialColor, setInitialColor] = useState(true);
  const { t } = useTranslation();

  function handleEssential() {
    onSelectedPack("essential");
    setInitialColor(true);
  }

  function handleOptimal() {
    onSelectedPack("optimal");
    setInitialColor(false);
  }

  function handlePremium() {
    onSelectedPack("premium");
    setInitialColor(false);
  }

  return (
    <TabNewHomeContainer>
      <Nav>
        <List pack={selectedPack}>
          <Button
            onClick={handleEssential}
            selecte={selectedPack === "essential"}
          >
            <Item id="essential" selected={selectedPack === "essential"}>
              <EssentialPack />
              {t("screens.home.banner.pack_essential")}
            </Item>
          </Button>

          <Button onClick={handleOptimal} selecte={selectedPack === "optimal"}>
            <Item id="optimal" selected={selectedPack === "optimal"}>
              <OptimalPack />
              {t("screens.home.banner.optimal")}
            </Item>
          </Button>

          <Button onClick={handlePremium} selecte={selectedPack === "premium"}>
            <Item id="premium" selected={selectedPack === "premium"}>
              <PremiumPack />
              {t("screens.home.banner.premium")}
            </Item>
          </Button>
        </List>
      </Nav>

      {/* <SeeAllLink href="#">See All</SeeAllLink> */}
    </TabNewHomeContainer>
  );
}
