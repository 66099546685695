import styled from "styled-components";

export const Container = styled.div`
  min-width: 224px;
  height: 396px;
  //   padding: 8px;
  margin: 8px;
  /* max-width: 420px; */
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px;

  @media screen and (max-width: 1920px) {
    width: 381.44px;
  }

  @media screen and (max-width: 1680px) {
    width: 420px;
  }

  @media screen and (max-width: 1440px) {
    width: 392.36px;
  }

  @media (max-width: 1366px) {
    width: 369px;
  }

  @media screen and (max-width: 428px) {
    width: 224px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  /* flex-direction: row; */
  //   border: 2px solid red;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  flex-direction: column;
`;
