import styled from "styled-components";

export const TrashButtonContainer = styled.button<{
  active: boolean;
  disabled?: boolean;
}>`
  padding: 4px;
  background: #e6e7ec;
  border-radius: 4px;
  display: flex;
  align-items: center;

  transition: all 200ms;

  > svg {
    ${({ disabled }) =>
      disabled &&
      `
      > path {
        stroke: #8F8F91;
      }
      cursor: not-allowed;
    `}
  }

  > label {
    margin-left: 8px;
    margin-right: 12px;
    font-size: 16px;
    cursor: pointer;

    ${({ disabled }) =>
      disabled &&
      `
      cursor: not-allowed;
      color: #8F8F91;
    `}

    ${({ active }) => active && `color: white;`}
  }

  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.colors.grayLight};
  }

  ${({ active }) =>
    active &&
    `
      background: #ED9F9F;
      > svg {
        transition: all 160ms;
        fill: #ED9F9F;
        > path {
          stroke: white;  
        }
      }
  
      &:hover {
        background: #E26161;
        > svg {
          fill: #E26161;
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
    `}
`;
