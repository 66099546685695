import minerals from "../assets/nutrient-category/1-mineral.svg";
import vitamin from "../assets/nutrient-category/2-vitamin.svg";
import herbs from "../assets/nutrient-category/26-herbs.svg";
import aminoAcid from "../assets/nutrient-category/3-amino-acid.svg";
import others from "../assets/nutrient-category/99-others.svg";

const iconVariants = {
  1: minerals,
  2: vitamin,
  3: aminoAcid,
  26: herbs,
  99: others,
};

export const getNutrientCategoryIcon = (id: number) => {
  return (
    <img
      src={iconVariants[id as keyof typeof iconVariants] || iconVariants[99]}
      alt=""
    />
  );
};
