import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { messages } from "../locales";

i18n
  //   .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ...messages,
    },
    // lng: "pt-BR", // if you're using a language detector, do not define the lng option
    // fallbackLng: "en", // TODO DESCOMENTAR ISSO AQUI
  });

export default i18n;
