import { FC, SVGProps } from "react";

import { ReactComponent as HandIcon } from "../../assets/sidebar/hand.svg";
import { ReactComponent as HomeIcon } from "../../assets/sidebar/home.svg";
import { ReactComponent as MedicineIcon } from "../../assets/sidebar/medicine.svg";
import { ReactComponent as NutrientsIcon } from "../../assets/sidebar/nutrients.svg";

type NavSidebarElement = {
  key: string;
  title: string;
  path: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  filled: boolean;
};

export const sidebarElements: NavSidebarElement[] = [
  {
    key: "home",
    title: "components.sidebar.home",
    path: "/",
    icon: HomeIcon,
    filled: false,
  },
  {
    key: "products",
    title: "components.sidebar.productRecommendation",
    path: "/products",
    icon: HandIcon,
    filled: false,
  },
  {
    key: "nutritional-needs",
    title: "components.sidebar.nutritionalNeeds",
    path: "/nutritional-needs",
    icon: NutrientsIcon,
    filled: false,
  },
  // {
  //   key: "favorite-foods",
  //   title: "components.sidebar.favoriteFoods",
  //   path: "/favorite-foods",
  //   icon: PearAppleIcon,
  //   filled: false,
  // },
  {
    key: "recommended-packs",
    title: "components.sidebar.recommendedPacks",
    path: "/recommended-packs",
    icon: MedicineIcon,
    filled: false,
  },
];
