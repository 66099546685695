import styled from "styled-components";

export const Container = styled.div`
  flex-direction: column;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 2px solid red;
`;

export const Title = styled.h1`
  margin-top: 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};
`;

export const LanguagePickerContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 30px;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;

export const FormInput = styled.input`
  font-size: 18px;
  padding: 10px;
  margin: 10px;
  background: ${({ theme }) => theme.colors.background};
  border: none;
  border-radius: 3px;
  width: 40%;

  ::placeholder {
    color: palevioletred;
  }
`;

export const FormLabel = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormSelect = styled.select`
  font-size: 18px;
  padding: 10px;
  width: 40%;
  margin: 10px;
  background: ${({ theme }) => theme.colors.background};
  border: none;
  border-radius: 3px;
  ::placeholder {
    color: palevioletred;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  justify-content: center;
  align-items: center;
`;
