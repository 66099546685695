import { createRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as StarIcon } from "../../../../../assets/content/star.svg";
import { ReactComponent as BookmarkIcon } from "../../../../../assets/icons/svg/bookmark.svg";
import { ReactComponent as CapsuleIcon } from "../../../../../assets/mock/capsuleWhite.svg";
import HorizontalScrollView from "../../../../../components/HorizontalScroll";
import RoundedProgress from "../../../../../components/RoundedProgress";
import ScrollButtons from "../../../../../components/ScrollButtons";
import { StudiesContext } from "../../../../../context/studies";
import DietInformationCarousel from "./DietInformationCarousel";
import DietInformationDefaultCard from "./DietInformationDefaultCard";
import DietInformationSimpleCard from "./DietInformationSimpleCard";
import FieldOfActionCard from "./FieldOfActionCard";
import {
  AlertContainer,
  CompositionField,
  Container,
  FieldsOfActionLabel,
  FieldsOfActionLabelContainer,
  Fills,
  IconContainer,
  NutrientFills,
  SeeAllRelatedPublicationsMobile,
  StarContainer,
  TitleContainerVitamin,
} from "./style";

import {
  AgentFieldOfActionType,
  CompositionType,
} from "../../../../../api/types";
import StandardTooltip from "../../../../../components/Tooltip";
import getDrugInteractionPhrasesFormatted from "../../../../../utils/getDrugInteractionPhrasesFormatted";
import getInnerHtmlInsideDiv from "../../../../../utils/getInnerHtmlInsideDiv";
import { ContainerScrollButton } from "../style";

type ComponentCardProps = {
  component: CompositionType;
  viewMore: boolean;
  index: number;
};

const ComponentCard = ({ component, index, viewMore }: ComponentCardProps) => {
  const {
    dietInformation,
    dosageAmountUnit,
    dosageAmountValue,
    fieldsOfAction,
    hasDrugInteraction,
    id,
    isInTopTen,
    name,
    studiesId,
    score,
    nutrientFills,
  } = component;

  const sliderRef = createRef<HTMLDivElement>();

  const {
    setStudiesID,
    setComponentName,
    setSuboutcomeIdScoreOrderList,
    setContraindicationSuboutcomeIndex,
  } = useContext(StudiesContext);

  const handleSeeAllRelatedPublications = () => {
    setComponentName(name);
    setStudiesID(studiesId);
    setSuboutcomeIdScoreOrderList(fieldsOfAction.map((elem) => elem.id));
    const indexes: number[] = [];
    fieldsOfAction.forEach((elem, index) => {
      if (elem.isContraindication) {
        indexes.push(index);
      }
    });
    setContraindicationSuboutcomeIndex(indexes);
  };

  const { t } = useTranslation();

  const getIconColor = () => {
    if (component.drugInteractions.some((elem) => elem.action === "Avoid")) {
      return "red";
    }
    if (component.drugInteractions.some((elem) => elem.action === "Lower")) {
      return "orange";
    }
    if (component.drugInteractions.some((elem) => elem.action === "Favor")) {
      return "green";
    }
    return "gray";
  };

  const getSynergyPhrase = (field: AgentFieldOfActionType) => {
    if (!field.hasSynergy) {
      return "";
    }

    const { phrase, activeAgentName, passiveAgentName } = field.synergies[0];

    return phrase
      .replace(activeAgentName, `<strong>${activeAgentName}</strong>`)
      .replace(passiveAgentName, `<strong>${passiveAgentName}</strong>`);
  };

  const getDrugInteractionText = () => {
    const interactionsToAvoid = component.drugInteractions.filter(
      (elem) => elem.action === "Avoid"
    );
    if (interactionsToAvoid.length > 0) {
      const avoid = getDrugInteractionPhrasesFormatted(
        [interactionsToAvoid[0]],
        t
      );

      return avoid[0].phrase;
    }
    const interactionLower = component.drugInteractions.filter(
      (elem) => elem.action === "Lower"
    );
    if (interactionLower.length > 0) {
      const lower = getDrugInteractionPhrasesFormatted(
        [interactionLower[0]],
        t
      );
      return lower[0].phrase;
    }

    const interactionFavor = component.drugInteractions.filter(
      (elem) => elem.action === "Favor"
    );

    if (interactionFavor.length > 0) {
      const favor = getDrugInteractionPhrasesFormatted(
        [interactionFavor[0]],
        t
      );
      return favor[0].phrase;
    }

    return "";
  };

  return (
    <>
      <Container viewMore={viewMore} index={index}>
        <Fills>
          <RoundedProgress size={55} progress={component.score}>
            <CompositionField>
              <label>{`${
                dosageAmountValue % 1 === 0
                  ? dosageAmountValue
                  : dosageAmountValue.toFixed(1)
              } ${dosageAmountUnit}`}</label>
            </CompositionField>
          </RoundedProgress>

          <TitleContainerVitamin>
            <label>{`${name}`}</label>
            <NutrientFills>{`Fills ${nutrientFills}% of your need / day`}</NutrientFills>
          </TitleContainerVitamin>

          <IconContainer>
            <StarContainer
              id={`is-in-top-ten-${component.id}`}
              isInTopTen={isInTopTen}
            >
              <StarIcon />
            </StarContainer>
            {isInTopTen && (
              <StandardTooltip
                anchor={`is-in-top-ten-${component.id}`}
                color="blue"
                text={t("hovers.nutrient_is_recommended")}
                place="left"
                clickable
              />
            )}

            <AlertContainer
              color={getIconColor()}
              id={`drug-interaction-${component.id}`}
            >
              <CapsuleIcon />
            </AlertContainer>
            {hasDrugInteraction && (
              <StandardTooltip
                anchor={`drug-interaction-${component.id}`}
                color={getIconColor()}
                text={getDrugInteractionText()}
                children={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getInnerHtmlInsideDiv(getDrugInteractionText()),
                    }}
                  ></div>
                }
                place="left"
                clickable
              />
            )}
          </IconContainer>
        </Fills>

        {dietInformation.length <= 0 ? (
          <DietInformationDefaultCard />
        ) : dietInformation.length === 1 ? (
          <DietInformationCarousel nutrientDietInformation={dietInformation} />
        ) : (
          <DietInformationSimpleCard
            nutrientDietInformation={dietInformation}
          />
        )}

        <FieldsOfActionLabelContainer
          dietInformation={dietInformation && dietInformation.length}
        >
          <FieldsOfActionLabel>
            {t("screens.productDetail.fields_of_action")}
          </FieldsOfActionLabel>

          <ContainerScrollButton>
            <ScrollButtons ref={sliderRef} length={100} />
          </ContainerScrollButton>
        </FieldsOfActionLabelContainer>

        <HorizontalScrollView
          gap={10}
          ref={sliderRef}
          disableScroll={true}
          style={{ overflowY: "hidden" }}
        >
          {component.fieldsOfAction.map((field, index) => {
            return (
              <FieldOfActionCard
                componentId={component.id}
                componentName={component.name}
                field={field}
                key={index}
              />
            );
          })}
        </HorizontalScrollView>

        <SeeAllRelatedPublicationsMobile
          hasDietInformation={dietInformation.length > 0}
          onClick={handleSeeAllRelatedPublications}
        >
          {t("screens.productDetail.see_all_related_publications")}
          <BookmarkIcon />
        </SeeAllRelatedPublicationsMobile>
      </Container>

      {component.fieldsOfAction.map((field) => {
        if (!field.hasSynergy) return <> </>;

        return (
          <StandardTooltip
            anchor={`${component.id}-${field.name}`}
            color="blue"
            text={field.synergies[0].phrase}
            place="top"
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(getSynergyPhrase(field)),
                }}
              ></div>
            }
            clickable
          />
        );
      })}

      {component.fieldsOfAction.map((field) => {
        if (!field.isContraindication) return <> </>;
        return (
          <StandardTooltip
            anchor={`${component.id}-${field.name}`}
            color="orange"
            text={t("hovers.nutrient_contraindicated", {
              nutrientName: component.name,
              symptomName: field.name,
            })}
            place="right"
            children={
              <div
                dangerouslySetInnerHTML={{
                  // __html: `<strong>${componentName}</strong> is contraindicated for ${field.name}`,
                  __html: getInnerHtmlInsideDiv(
                    t("hovers.nutrient_contraindicated", {
                      nutrientName: `<strong>${component.name}</strong>`,
                      symptomName: field.name,
                    })
                  ),
                }}
              ></div>
            }
            clickable
          />
        );
      })}
    </>
  );
};

export default ComponentCard;
