import styled from "styled-components";

interface ContentProps {
  selected: boolean;
}

interface ProgressProps {
  selected: boolean;
  linear?: {
    color1: string;
    color2: string;
  };
  progress: number;
  size: number;
  gray?: boolean;
  thickness: number;
}

export const ProgressPack = styled.div<ProgressProps>`
  display: flex;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;

  justify-content: center;
  align-items: center;

  background: ${({ selected, progress, gray }) =>
    selected
      ? `
		conic-gradient(
			#fff,
			#fff ${progress}deg,
			${gray ? "rgba(0,0,0,0.1)" : "transparent"} ${progress}deg,
			${gray ? "rgba(0,0,0,0.1)" : "transparent"}
		);
		`
      : `conic-gradient(
				#2effaf,
				#00aaff ${progress}deg,
				${gray ? "rgba(0,0,0,0.1)" : "transparent"} ${progress}deg,
				${gray ? "rgba(0,0,0,0.1)" : "transparent"}
			);`};

  font-size: 0;
  > div {
    width: ${({ size, thickness }) => size - thickness}px;
    height: ${({ size, thickness }) => size - thickness}px;
  }

  @media screen and (max-width: 375px) {
    width: 80px;
    height: 80px;

    > div {
    width: 72px;
    height: 72px;
  }

  }
`;

export const ContentPack = styled.div<ContentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  background: ${(props) => (props.selected ? "#21E7C5" : "#fff")};
  border-radius: 50%;

  svg {
    width: 33px;
    height: 33px;
  }
`;
