interface GradientSVGProps {
  startColor: string;
  endColor: string;
  idCSS: string;
  rotation: number;
}

const GradientSVG = ({
  startColor,
  endColor,
  idCSS,
  rotation,
}: GradientSVGProps) => {
  let gradientTransform = `rotate(${rotation})`;

  return (
    <svg
      style={{
        overflow: "hidden",
        visibility: "hidden",
        height: 0,
        width: 0,
        border: "1px solid red",
        position: "absolute",
        left: "-100px",
      }}
    >
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientSVG;
