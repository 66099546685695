import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  //   border: 2px solid red;
`;

export const Spacing = styled.div`
  height: 16px;
`;

export const HalfSpacing = styled.div`
  height: 12px;
`;

export const SymptomScrollButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;
