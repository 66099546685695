import styled from "styled-components";

interface ListProps {
  pack: string;
}

interface ItemProps {
  default?: boolean;
  selected: boolean;
  textLength: number;
}

interface Selecterops {
  selecte: boolean;
}

export const TabNewHomeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-bottom: solid 1px #e6e7ec;
`;

export const Nav = styled.nav`
  width: 100%;
  /* margin: 0 auto; */
  padding: 3px 0;
  margin-top: 10px;
  /* border-bottom: solid 1px #E6E7EC; */
`;

export const List = styled.ul<ListProps>`
  width: 700px;
  list-style: none;
  display: flex;
  /* justify-content: space-between; */
  gap: 61px;
  position: relative;
  /* border-bottom: solid 1px lightgray; */

  &::after {
    content: " ";
    /* width: 175px; */
    width: ${({ pack }) =>
      pack === "essential"
        ? "190px"
        : pack === "optimal"
        ? "175px"
        : pack === "premium"
        ? "187px"
        : "0px"};
    
    height: 3px;
    background-color: #21E7C5;
    position: absolute;
    bottom: -5px;
    left: ${({ pack }) =>
      pack === "essential"
        ? "0%;"
        : pack === "optimal"
        ? "36%;"
        : pack === "premium"
        ? "70%;"
        : "0%;"}
  
    transition: 0.2s ease-in-out;
  }
`;

export const Button = styled.a<Selecterops>`
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    ${({ selecte }) => (!selecte ? "filter: brightness(0.5);" : "")}
  }
`;

export const Item = styled.li<ItemProps>`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => (props.selected ? "#21E7C5" : "#545976")};

  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 24px;
  }
  svg path {
    fill: ${(props) => (props.selected ? "#21E7C5" : "#545976")};
  }

  /* &:target {
    transition: 0.4s ease-in-out;
    color: #21E7C5;

  svg path{
    fill: #21E7C5;
    transition: 0.4s ease-in-out;
  }
  } */
`;
