import styled from "styled-components";

export const Container = styled.div`
  margin-top: 34px;

  // margin-top: 42px;
  // padding: 16px;
  // border: 2px solid red;
`;

export const HorizontalScrollContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
  // border: 2px solid blue;
`;

export const HealthCategoriesContainer = styled.div`
  // border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  display: flex;
  gap: 16px;
  padding-top: 16px;
`;
