import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRelatedStudies } from "../api";
import { RelatedPublicationsProps } from "../api/types";
import { RelatedPublicationsModal } from "../components/Modal/RelatedPublications";

interface StudiesContextInterface {
  componentName: string;
  setComponentName: React.Dispatch<React.SetStateAction<string>>;
  studiesID: number[];
  setStudiesID: React.Dispatch<React.SetStateAction<number[]>>;
  loading: boolean;
  relatedPublications: RelatedPublicationsProps[];
  clearModal: () => void;
  setSuboutcomeIdScoreOrderList: React.Dispatch<React.SetStateAction<number[]>>;
  suboutcomeIdScoreOrderList: number[];
  setContraindicationSuboutcomeIndex: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  contraindicationSuboutcomeIndex: number[];
}

export const StudiesContext = createContext<StudiesContextInterface>(
  {} as StudiesContextInterface
);

const StudiesProvider = ({ children }: { children: React.ReactNode }) => {
  const [componentName, setComponentName] = useState("");
  const [studiesID, setStudiesID] = useState<number[]>([]);
  const [relatedPublications, setRelatedPublications] = useState<
    RelatedPublicationsProps[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [suboutcomeIdScoreOrderList, setSuboutcomeIdScoreOrderList] = useState<
    number[]
  >([]);

  const [contraindicationSuboutcomeIndex, setContraindicationSuboutcomeIndex] =
    useState<number[]>([]);

  // const { language } = useContext(SetupAnswerContext);

  const { i18n } = useTranslation();

  const language = i18n.language;

  // const loadingStudies = useDebounce(loading, 2000);

  const clearModal = () => {
    setStudiesID([]);
    setComponentName("");
    setRelatedPublications([]);
    setLoading(false);
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const _response = await getRelatedStudies(studiesID, language);
      if (_response) {
        // console.log(_response);

        if (_response) {
          setRelatedPublications(_response);
        } else {
          setStudiesID([]);
        }

        setTimeout(() => setLoading(false), 400);
      }
    }

    if (studiesID.length > 0) {
      fetchData();
    }
  }, [studiesID]);

  return (
    <StudiesContext.Provider
      value={{
        studiesID,
        setStudiesID,
        loading,
        relatedPublications,
        componentName,
        setComponentName,
        clearModal,
        setSuboutcomeIdScoreOrderList,
        suboutcomeIdScoreOrderList,
        contraindicationSuboutcomeIndex,
        setContraindicationSuboutcomeIndex,
      }}
    >
      {children}
      {/* <RelatedPublicationsModal isOpen={studiesID.length < 0} /> */}
    </StudiesContext.Provider>
  );
};
export { StudiesProvider };
