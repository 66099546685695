import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { FieldsetContainer } from "./styles";

interface FieldsetProps {
  legend: string;
  inputName: string;
  listOfIndexSelected: number[];
  // inputValue: string
  // inputState: string[]
  // onChangeInputValue: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick: (index: number) => void;
  labels: string[];
}

export function Fieldset({
  legend,
  listOfIndexSelected,
  // onChangeInputValue,
  labels,
  onClick,
}: FieldsetProps) {
  const [isCheckFormOpen, setIsCheckFormOpen] = useState(false);

  function handleOpenCheckForm() {
    setIsCheckFormOpen(!isCheckFormOpen);
  }

  const handleCheckRadioButton = (index: number) => {
    onClick(index);
  };

  const isChecked = (index: number) => {
    return listOfIndexSelected.includes(index);
  };

  return (
    <FieldsetContainer isCheckFormOpen={isCheckFormOpen}>
      <form onSubmit={() => null}>
        <div className="title">
          <legend onClick={handleOpenCheckForm}>{legend}</legend>
          <IoClose
            size={25}
            fill={"#545976"}
            className="icon-close"
            onClick={handleOpenCheckForm}
          />
        </div>

        <div className="form-check-container">
          {labels.map((label, index) => {
            return (
              <div key={`${label}-${index}`} className="form-check">
                <label className="form-control">
                  <input
                    type="radio"
                    name={index + ""}
                    value={index + ""}
                    className="form-check-input"
                    onClick={() => handleCheckRadioButton(index)}
                    checked={isChecked(index)}
                  />
                  {label}
                </label>
              </div>
            );
          })}
        </div>
      </form>
    </FieldsetContainer>
  );
}
