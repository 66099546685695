import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import styled from "styled-components";
// import { animationData } from "../styles/animations/lottie/loadingScreen/data.js"
import { animationData } from "../../assets/animation/kpsuleIconLoading/data.js";

export const LoadingScreen = () => {
  const { t } = useTranslation("loading");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <StyledLoadingScreen>
      <Lottie
        options={defaultOptions}
        height={window.innerHeight}
        width={"67%"}
        isPaused={false}
        isStopped={false}
        isClickToPauseDisabled
      />
    </StyledLoadingScreen>
  );
};

const StyledLoadingScreen = styled.div`
  background: white;
  // border: 2px solid red;
`;
