import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NutrientProps, ProductProps } from "../../api/types";
import Page from "../../components/Page";
import { HeaderContainer, HeaderLabel } from "../../styles/commomStyles";
import {
  BackButton,
  DietInformationImage,
  HealthGoalsContainer,
  HorizontalScrollContainer,
  NutritionBanner,
  SymptomScrollButtonContainer,
} from "./style";

import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft } from "react-icons/ai";
import forkAndKnifeImg from "../../assets/food_alert_icons/circle_fork_and_fire.svg";
import CardSimple from "../../components/Cards/Simple";
import Symptom from "../../components/Cards/Sympthon";
import HorizontalScrollView from "../../components/HorizontalScroll";
import ProgressCard from "../../components/ProgressCard";
import ScrollButtons from "../../components/ScrollButtons";
import TabView from "../../components/TabView";
import { GlobalContext } from "../../context/global";
import { getHealthGoalsIcon } from "../../utils/getHealthGoalsIcon";
import { getSymptomsSvg } from "../../utils/getSymptomSvg";
import NutrientAlerts from "./components/Alerts";
import { Card } from "./components/Card";
import BestProducts from "./tabs/BestProducts";
import NutrientFieldsOfAction from "./tabs/FieldsOfAction";

export type NutrientViewType = {
  state: {
    nutrient: NutrientProps;
    scroll: boolean;
  };
};

const NutrientDetails: React.FC = () => {
  const { t } = useTranslation();
  const {
    state: { nutrient, scroll },
  } = useLocation() as NutrientViewType;
  const sliderRef = createRef<HTMLDivElement>();
  const navigation = useNavigate();
  const [bestProduct, setBestProduct] = useState<ProductProps | undefined>();
  const [cheaperProduct, setCheaperProduct] = useState<
    ProductProps | undefined
  >();
  const [allProductsWithNutrient, setAllProductsWithNutrient] = useState<
    ProductProps[]
  >([]);

  const { products } = useContext(GlobalContext);

  const bestProductsRef = useRef<HTMLDivElement>(null);

  const labels = [
    t("screens.nutrientDetail.best_products"),
    t("screens.nutrientDetail.fields_of_action"),
  ];

  const [selectedTab, setSelectedTab] = useState(labels[0]);

  useEffect(() => {
    if (scroll && bestProductsRef !== null) {
      // @ts-ignore
      setTimeout(() => bestProductsRef.current.scrollIntoView(), 200);
    } else if (!scroll) {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  }, [scroll, bestProductsRef]);

  useEffect(() => {
    const { productsId } = nutrient;

    if (productsId.length === 0) {
      setBestProduct(undefined);
      setCheaperProduct(undefined);
    } else if (productsId.length === 1) {
      setBestProduct(products.find((product) => product.id === productsId[0]));
      setCheaperProduct(undefined);
    } else {
      const productWithNutrient = products
        .filter((product) => productsId.includes(product.id))
        .sort((a, b) => b.score - a.score);

      setBestProduct(productWithNutrient[0]);
      const productWithNutrientBestCostBenefit = productWithNutrient.sort(
        (a, b) => b.priceRatio - a.priceRatio
      );
      setCheaperProduct(productWithNutrientBestCostBenefit[0]);
    }

    // setBestProduct(products[0]);
    // // TODO cheaperProducts
    // setCheaperProduct(products[1]);

    const nutrientProducts = products.filter((product) =>
      nutrient.productsId.includes(product.id)
    );
    setAllProductsWithNutrient(nutrientProducts);
  }, [products]);

  const handleSetTabSelected = () => {
    setSelectedTab(labels[0]);
  };

  const getIconColor = (action: string) => {
    if (action === "Avoid") {
      return "red";
    }
    if (action === "Lower") {
      return "orange";
    }
    if (action === "Favor") {
      return "green";
    }
    return "gray";
  };

  const RenderHeader = () => (
    <HeaderContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BackButton onClick={() => navigation(-1)}>
          <AiOutlineArrowLeft size={25} />
        </BackButton>
        <HeaderLabel>
          <b>{t("screens.nutrientDetail.nutrient_detail")}</b>
        </HeaderLabel>
      </div>
    </HeaderContainer>
  );

  return (
    <Page screen="" header={RenderHeader}>
      <Card
        nutrient={nutrient}
        ref={bestProductsRef}
        onClick={handleSetTabSelected}
      />
      {nutrient.nutritionDeficiency > 0 &&
        nutrient.nutritionDeficiency <= 75 && (
          <NutritionBanner>
            {<DietInformationImage src={forkAndKnifeImg} />}
            <label>
              {t("screens.nutrientDetail.diet_deficiency", {
                nutritionDeficiency: nutrient.nutritionDeficiency,
              })}
            </label>
          </NutritionBanner>
        )}

      {nutrient.drugInteraction.length > 0 && (
        <NutrientAlerts drugInteractionAlerts={nutrient.drugInteraction} />
      )}

      {nutrient.healthGoals.length > 0 && (
        <CardSimple title={t("screens.nutrientDetail.health_goals")}>
          <HealthGoalsContainer>
            {nutrient.healthGoals.map((healthGoal, index) => {
              return (
                <ProgressCard
                  key={index}
                  title={healthGoal.name}
                  icon={getHealthGoalsIcon(healthGoal.name, 40)}
                  progress={healthGoal.score}
                  height={10}
                />
              );
            })}
          </HealthGoalsContainer>
        </CardSimple>
      )}

      {nutrient.symptoms.length > 0 && (
        <CardSimple
          title={`${nutrient.symptoms.length} ${t(
            "screens.nutrientDetail.symptoms"
          )}`}
        >
          <SymptomScrollButtonContainer>
            <ScrollButtons ref={sliderRef} length={174} />
          </SymptomScrollButtonContainer>
          <HorizontalScrollContainer>
            <HorizontalScrollView ref={sliderRef} size={180}>
              {nutrient.symptoms.map((symptom, index) => {
                return (
                  <Symptom
                    //   warning={index % 2 === 1}
                    key={index}
                    icon={getSymptomsSvg(symptom.categoryId, "white")}
                    progress={symptom.score}
                    title={symptom.name}
                  />
                );
              })}
            </HorizontalScrollView>
          </HorizontalScrollContainer>
        </CardSimple>
      )}

      <TabView
        labels={labels}
        controlledContentSelected={selectedTab}
        setControlledContentSelected={setSelectedTab}
        contents={[
          <BestProducts
            allProducts={allProductsWithNutrient}
            betterProduct={bestProduct}
            cheaperProduct={cheaperProduct}
            nutrientName={nutrient.name}
          />,
          <NutrientFieldsOfAction fieldsOfAction={nutrient.fieldsOfAction} />,
        ]}
        labelsCounts={[
          allProductsWithNutrient.length,
          nutrient.fieldsOfAction.length,
        ]}
      />
      <div ref={bestProductsRef} />
    </Page>
  );
};

export default NutrientDetails;
