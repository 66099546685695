import { useTranslation } from "react-i18next";
import { useViewport } from "../../../../hooks/Viewport/useViewport";
import {
  Button,
  CardBestsContainer,
  ContainerButtonMobile,
  ContainerTitleMobile,
  SubTitle,
  Title,
} from "./style";

interface CardBestsProps {
  title: string;
  subTitle: string;
  typeCard: "products" | "nutrients";
  imgCard: JSX.Element;
  imgMargin?: number;
  onLinkNAvigate: () => void;
}

export function CardBests({
  title,
  subTitle,
  typeCard,
  imgCard,
  imgMargin,
  onLinkNAvigate,
}: CardBestsProps) {
  const { t } = useTranslation();
  const { isUnderTablet } = useViewport();

  return (
    <>
      {isUnderTablet ? (
        <CardBestsContainer bgColor={typeCard} onClick={onLinkNAvigate}>
          <ContainerTitleMobile>
            <SubTitle>
              <span>{subTitle}</span>
            </SubTitle>
            <Title>{title}</Title>
          </ContainerTitleMobile>

          <ContainerButtonMobile imgMargin={imgMargin}>
            <Button>{t("screens.home.cardBests.view_all")}</Button>
            {imgCard}
          </ContainerButtonMobile>
        </CardBestsContainer>
      ) : (
        <CardBestsContainer bgColor={typeCard} onClick={onLinkNAvigate}>
          <div>
            <SubTitle>
              <span>{subTitle}</span>
            </SubTitle>
            <Title>{title}</Title>
            <Button>{t("screens.home.cardBests.view_all")}</Button>
          </div>

          {imgCard}
        </CardBestsContainer>
      )}
    </>
  );
}