import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { AlertColors } from "../Cards/AlertCard/style";

// gradient verde: 43AF45 -> 79D57B

const getBorderColor = (
  color: "orange" | "red" | "yellow" | "green" | "gray" | "blue"
) => {
  switch (color) {
    case "orange":
      return `#FF6737`;
    case "red":
      return `#D24848`;
    case "yellow":
      return `#FF9922`;
    case "green":
      return `#43AF45`;
    case "blue":
      return `#2C4BCC`;
    default:
      return `#E6E7EC`;
  }
};

const getArrowPosition = (
  place: "top" | "left" | "right" | "bottom",
  color: AlertColors | "blue"
) => {
  switch (place) {
    case "top":
      return ` border-right: 1px solid ${getBorderColor(color)};
      border-bottom: 1px solid ${getBorderColor(color)};`;
    case "right":
      return ` border-left: 1px solid ${getBorderColor(color)};
      border-bottom: 1px solid ${getBorderColor(color)};`;
    case "left":
      return ` border-right: 1px solid ${getBorderColor(color)};
        border-top: 1px solid ${getBorderColor(color)};`;
    case "bottom":
      return ` border-left: 1px solid ${getBorderColor(color)};
            border-top: 1px solid ${getBorderColor(color)};`;
  }
};

export const StyledTooltip = styled(Tooltip)<{
  color?: string
  // color: "orange" | "red" | "yellow" | "green" | "gray" | "blue";
  place: "top" | "left" | "right" | "bottom";
}>`
  border: 2px solid ${({ color }) => color === 'hasAvoid' ? '#D24848' : color === 'hasContraindications' ? '#FF6737' : '#FF9922' };
  border-radius: 12px;
  background: white;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  padding: 16px;
  font-size: 14px;



  border-bottom: 2px solid ${({ color }) => color === 'hasAvoid' ? '#D24848' : color === 'hasContraindications' ? '#FF6737' : '#FF9922' };
`;
