import React from "react";
import { Container } from "./style";
import { getNutrientCategoryIcon } from "../../../../../utils/getNutrientCategoryIcon";

type OtherIngredientType = {
  title: string;
  description: string;
  index: number;
  viewMore: boolean;
  categoryId: number;
};
const OtherIngredientCard: React.FC<OtherIngredientType> = ({
  description,
  title,
  index,
  viewMore,
  categoryId,
}) => {
  return (
    <Container viewMore={viewMore} index={index}>
      <div>
        {getNutrientCategoryIcon(categoryId)}
        <label>{title}</label>
      </div>
      <label>{description}</label>
    </Container>
  );
};

export default OtherIngredientCard;
