import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 10px 1rem;
  /* margin-top: 0 1.5rem; */
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  /* ${({ theme }) => theme.shadow}; */
  border-radius: 8px;
  position: relative;

  @media screen and (max-width: 320px) {
    padding: 10px 1rem 18px;
  }
`;

export const ExcipienWarpper = styled.div`
  margin-top: 12px;
  /* display: flex; */
`;

export const Title = styled.label`
  font-family: "Roboto";
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;

  /* &.title-card-simple {
    margin-bottom: 1.5rem;
  } */
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;

  // > div {
  //   :not(:first-child) {
  //     margin-left: 0.5rem;
  //   }
`;
