import { useTranslation } from "react-i18next";
import { StudiesDataProps } from "../../../../../api/types";
import { ReactComponent as BookmarkIcon } from "../../../../../assets/icons/svg/bookmark.svg";
import {
  Container,
  Content,
  Footer,
  Main,
  ReadMore,
  StudyTitle,
  Subtitle,
} from "./style";

interface StudyCardProps {
  study: StudiesDataProps;
}

const StudyCard = ({ study }: StudyCardProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open(study.link, "_blank");
  };

  return (
    <Container onClick={handleClick}>
      <Main>
        <BookmarkIcon />
        <Content>
          <StudyTitle>{study?.title}</StudyTitle>
          {study.note !== "" && !!study.note && (
            <Subtitle>{study.note}</Subtitle>
          )}
        </Content>
      </Main>
      <Footer>
        <ReadMore>{t("components.modal.read_more")}</ReadMore>
      </Footer>
    </Container>
  );
};

export default StudyCard;
