import styled from "styled-components";

export const Container = styled.div`
  width: 386px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 184px;

  @media screen and (max-width: 600px) {
    max-width: 350px;
  }

  cursor: pointer;

  padding: 26px 16px;
  padding-bottom: 6px;
  border-radius: 8px;

  align-items: center;

  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.lightShadow}
  > hover {
    ${({ theme }) => theme.shadow}
  }
`;

export const Main = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  > svg {
    // border: 2px solid orange;
    height: 42px;
    width: 42px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 21px;
`;

export const Footer = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: row-reverse;
  width: 100%;
`;

export const ReadMore = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.selected};
  cursor: pointer;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;

  :hover {
    text-decoration: underline;
  }
`;

export const StudyTitle = styled.h3`
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};

  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  width: 280px;
  @media screen and (max-width: 600px) {
    max-width: 220px;
  }
  // border: 2px solid red;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Subtitle = styled.h4`
  font-weight: 400;
  font-size: 16px;
  margin-top: 8px;
  color: ${({ theme }) => `#00000080`};
  width: 280px;
  @media screen and (max-width: 600px) {
    max-width: 220px;
  }

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;
