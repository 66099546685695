import styled from "styled-components";

export const ViewMoreContainer = styled.div<{horizontalAlign: boolean}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  margin: 30px 0 45px;
  cursor: pointer;
  ${({horizontalAlign}) => horizontalAlign && 
  `
    flex-direction: row;
    gap: 5px;
    margin: 20px 0 30px;
  `}
`;

export const ViewMoreText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  // line-height: 39px;
  color: ${({ theme }) => theme.colors.selected};
`;
