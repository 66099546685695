import styled from "styled-components";

export const SymptomSection = styled.div`
  display: flex;
  flex-direction: column;

  // border: 2px solid red;
`;

export const SymptomTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SymptomName = styled.label`
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 12px;
`;

export const SymptomSubtitle = styled.label`
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grayLight};
  margin-left: 12px;
  margin-top: 4px;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SymptomsIcon = styled.div`
  //   border: 2px solid red;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 50px;
  height: auto;

  > * {
    // width: 20px;
    height: auto;
  }

  //   > * {
  //     border: 2px solid green;
  //   }
`;
