import React from "react";
import { ChildrenType } from "react-tooltip";
import { ProductProps } from "../../../api/types";
import handleImportance from "../../../utils/getImportance";
import TooltipAlert from "../../TooltipAlert";
import {
  Container,
  Icon,
  SubTitle,
  Title,
  TitleContainer,
  TopIcon,
} from "./style";

// import { Container } from './styles';
type AlertCardMobileProps = {
  title: string;
  subtitle: string;
  topIcon: React.ReactNode;
  icon: React.ReactNode;
  anchor: string;
  tooltipText?: string;
  color: "red" | "orange" | "yellow" | "green" | "gray";
  tooltipChildren?: ChildrenType;
};

interface CardProduct {
  product: ProductProps;
}

const AlertCardMobile: React.FC<AlertCardMobileProps> = (
  {
    icon,
    subtitle,
    topIcon,
    title,
    anchor,
    tooltipText,
    color = "orange",
    tooltipChildren,
  },
  { product }: CardProduct
) => {
  return (
    <>
      <Container id={anchor} color={color}>
        <TopIcon>{topIcon}</TopIcon>
        <Icon>{icon}</Icon>
        <TitleContainer>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </TitleContainer>
      </Container>
      {tooltipText && tooltipText !== "" && (
        <TooltipAlert
          anchor={anchor}
          text={tooltipText}
          color={handleImportance(product.alerts)}
          clickable
          children={tooltipChildren}
        />
      )}
    </>
  );
};

export default AlertCardMobile;
