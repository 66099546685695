import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ReactComponent as UserIcon } from "../../../assets/icons/svg/userIcon.svg";
import { ShoppingCartContext } from "../../../context/shoppingCart";
import { useViewport } from "../../../hooks/Viewport/useViewport";
import { getCurrencyFormat } from "../../../utils/getCurrencyString";
import PrimaryButton from "../../Button/PrimaryButton";
import { TrashButton } from "../../Button/ThrashButton";
import { ShoppingCartItemCard } from "../../Cards/ShoppingCartItemCard";
import ProgressBar from "../../ProgressBar";
import {
  BackDrop,
  BasketTitle,
  CardsContainer,
  CloseAndTitle,
  CloseIcon,
  Container,
  Content,
  Footer,
  Header,
  NutritionalNeeds,
  NutritionalNeedsContent,
  NutritionalNeedsTitle,
  PriceContainer,
  PriceLabel,
  SeparateLine,
  TotalLabel,
} from "./style";

const ShoppingCartSidebar: React.FC = () => {
  const {
    isShoppingCartSidebarOpen,
    closeShoppingCartSidebar,
    inCartNutritionalProgress,
    shoppingCart,
    getPrice,
    clearShoppingCart,
  } = useContext(ShoppingCartContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isUnderTablet } = useViewport();

  const validateBasket = () => {
    closeShoppingCartSidebar();

    navigate("validate-basket");
  };

  const handleButtonClick = () => {
    clearShoppingCart();
    closeShoppingCartSidebar();
    // setTimeout(() => closeShoppingCartSidebar(), 550);
  };

  useEffect(() => {
    if (isShoppingCartSidebarOpen) {
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.classList.add("overflow-auto");
    }
  }, [isShoppingCartSidebarOpen]);

  return (
    <>
      <Container open={isShoppingCartSidebarOpen}>
        <Content>
          <Header>
            <CloseAndTitle>
              <CloseIcon
                size={34}
                fill={"#545976"}
                onClick={closeShoppingCartSidebar}
                className="icon-close"
              />
              <BasketTitle>
                {t("components.shoppingCartSidebar.your_basket")}
              </BasketTitle>
            </CloseAndTitle>
            <TrashButton
              onClick={handleButtonClick}
              label={t("components.shoppingCartSidebar.remove_all")}
              disabled={shoppingCart.length === 0}
            />
          </Header>
          <NutritionalNeeds>
            <UserIcon />
            <NutritionalNeedsContent>
              <NutritionalNeedsTitle>
                <label>{`${inCartNutritionalProgress}%`}</label>
                {t("components.shoppingCartSidebar.of_your_nutritional_needs")}
              </NutritionalNeedsTitle>
              <ProgressBar progress={inCartNutritionalProgress} />
            </NutritionalNeedsContent>
          </NutritionalNeeds>
          <CardsContainer>
            {shoppingCart.map((item, key) => (
              <ShoppingCartItemCard shoppingCartItem={item} key={key} />
            ))}
          </CardsContainer>
          <Footer>
            <PriceContainer>
              <TotalLabel>
                {t("components.shoppingCartSidebar.total")}
              </TotalLabel>
              <PriceLabel>
                {getCurrencyFormat(
                  shoppingCart.length > 0
                    ? shoppingCart[0].product.currency
                    : "",
                  getPrice()
                )}
              </PriceLabel>
            </PriceContainer>
            <SeparateLine />
            <PrimaryButton
              label={t("components.shoppingCartSidebar.validate")}
              onClick={validateBasket}
              width={"194px"}
              disabled={shoppingCart.length === 0}
            />
          </Footer>
        </Content>
      </Container>
      <BackDrop
        open={isShoppingCartSidebarOpen}
        onClick={closeShoppingCartSidebar}
      />
    </>
  );
};

export { ShoppingCartSidebar };
