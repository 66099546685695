import { useTranslation } from "react-i18next";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useTheme } from "styled-components";
import { ViewMoreContainer, ViewMoreText } from "./style";

interface ViewMoreButtonProps {
  onClick: () => void;
  isOpen: boolean;
  horizontalAlign?: boolean
}

const ViewMoreButton = ({ onClick, isOpen, horizontalAlign }: ViewMoreButtonProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ViewMoreContainer onClick={onClick} horizontalAlign>
      <ViewMoreText>
        {!isOpen ? t("common.view_more") : t("common.view_less")}
      </ViewMoreText>
      {!isOpen ? (
        <AiOutlinePlusCircle
          color={theme.colors.selected}
          style={{ width: "28px", height: "28px" }}
        />
      ) : (
        <AiOutlineMinusCircle
          color={theme.colors.selected}
          style={{ width: "28px", height: "28px" }}
        />
      )}
    </ViewMoreContainer>
  );
};

export default ViewMoreButton;
