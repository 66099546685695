import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import logoMobile from "../../../../assets/logo-mobile.svg";
import SkeletonBannerCard from "../../../../components/Cards/InfoComum/skeleton";
import { Sidebar } from "../../../../components/Sidebar/NavSidebar";
import { HeaderContainer } from "../../../../styles/commomStyles";
import { ReactComponent as Picture } from "./assets/picture.svg";

import Page from "../../../../components/Page";
import { GlobalContext } from "../../../../context/global";
import { useViewport } from "../../../../hooks/Viewport/useViewport";

import {
  Banner,
  BasedScientificPublications,
  BasedScientificPublicationsMobile,
  Button,
  CardBest,
  CardsContainer,
  CardsWrapper,
  Container,
  ContainerCards,
  ContainerCardsTitle,
  ContainerFooterBanner,
  ContainerHeaderSliderMobile,
  ContainerTitle,
  Header,
  InfoContent,
  ProgressCircle,
  ProgressContainer,
  Vertical,
  WrapperButton,
  WrapperTitleCard,
} from "./style";

import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { ReactComponent as ShoppingIcon } from "../../../../assets/content/shopping.svg";
import {
  CartProgressContainer,
  Circle,
  Commom,
  IconContainer,
} from "../../../../components/Header/style";
import { ShoppingCartContext } from "../../../../context/shoppingCart";
import { SeeAll, TitileSectionCards, WrapperCardsBests } from "../../style";
import HoverButton from "../HoverButton";
import { SeeAllLink } from "../SliderPacksMobile/style";
import { TabNewHome } from "../TabNewHome";
import { TabNewHomeMobile } from "../TabNewHomeMobile";
import { HeaderPacks, TitleHeaderPacks } from "./style";

const SkeletonHomePage: React.FC = () => {
  const RenderHeaderLogo = () => (
    <HeaderContainer>
      <img src={logoMobile} alt="" />
    </HeaderContainer>
  );

  const { t } = useTranslation();
  const { isUnderTablet, isUnderIpad } = useViewport();
  const { setStillProcessing } = useContext(GlobalContext);

  const { inCartNutritionalProgress, openShoppingCartSidebar, shoppingCart } =
    useContext(ShoppingCartContext);

  return (
    <>
      {isUnderTablet ? (
        <Page screen="home" logo={RenderHeaderLogo()}>
          <Container>
            <Vertical>
              <Header>
                <Skeleton
                  baseColor="#545976"
                  width="191px"
                  height="11px"
                  style={{ borderRadius: "25px", opacity: 0.3 }}
                />

                <BasedScientificPublicationsMobile>
                  <Skeleton
                    baseColor="#0AADFF"
                    width="300px"
                    height="11px"
                    style={{ borderRadius: "25px", opacity: 0.3 }}
                  />
                  <Skeleton
                    baseColor="#0AADFF"
                    width="300px"
                    height="11px"
                    style={{ borderRadius: "25px", opacity: 0.3 }}
                  />
                </BasedScientificPublicationsMobile>
              </Header>

              <TabNewHomeMobile
                onSelectedPack={() => {}}
                selectedPack={"essential"}
              />

              <Banner>
                <InfoContent>
                  <ContainerTitle>
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#00000050"
                      width="208px"
                      height="11px"
                      style={{
                        borderRadius: "25px",
                        opacity: 0.3,
                      }}
                    />
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#00000050"
                      width="250px"
                      height="11px"
                      style={{
                        borderRadius: "25px",
                        opacity: 0.3,
                      }}
                    />
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#00000050"
                      width="190px"
                      height="11px"
                      style={{
                        borderRadius: "25px",
                        opacity: 0.3,
                      }}
                    />
                  </ContainerTitle>

                  <ProgressContainer>
                    <ProgressCircle />
                  </ProgressContainer>

                  <ContainerFooterBanner>
                    <Button />
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#00000050"
                      width="76px"
                      height="11px"
                      style={{
                        borderRadius: "25px",
                        opacity: 0.3,
                      }}
                    />
                  </ContainerFooterBanner>
                </InfoContent>

                <ContainerHeaderSliderMobile>
                  <h2>{t("screens.home.banner.recommended_packs")}</h2>

                  <SeeAll>{t("screens.home.banner.see_all")}</SeeAll>
                </ContainerHeaderSliderMobile>

                <CardsContainer>
                  <SkeletonBannerCard />
                  <SkeletonBannerCard />
                </CardsContainer>

                <HoverButton
                  width={254}
                  height={48}
                  sizeFont={16}
                  isActive={false}
                  label={"Add to cart"}
                  onClick={() => {}}
                  margin={24}
                />
              </Banner>

              <ContainerCardsTitle>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t("screens.home.see_other_possibilities"),
                  }}
                ></h2>
              </ContainerCardsTitle>

              <ContainerCards>
                <CardBest backgroundLinear={"green"}>
                  <WrapperTitleCard>
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#00000050"
                      width="155px"
                      height="11px"
                      style={{
                        borderRadius: "25px",
                        opacity: 0.3,
                      }}
                    />
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#00000050"
                      width="172px"
                      height="11px"
                      style={{
                        borderRadius: "25px",
                        opacity: 0.3,
                      }}
                    />
                  </WrapperTitleCard>

                  <WrapperButton>
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#9f9f9f540"
                      width="89px"
                      height="29px"
                      style={{
                        borderRadius: "25px",
                      }}
                    />
                    <Picture />
                  </WrapperButton>
                </CardBest>

                <CardBest backgroundLinear={"blue"}>
                  <WrapperTitleCard>
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#00000050"
                      width="155px"
                      height="11px"
                      style={{
                        borderRadius: "25px",
                        opacity: 0.3,
                      }}
                    />
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#00000050"
                      width="172px"
                      height="11px"
                      style={{
                        borderRadius: "25px",
                        opacity: 0.3,
                      }}
                    />
                  </WrapperTitleCard>

                  <WrapperButton>
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#9f9f9f540"
                      width="89px"
                      height="29px"
                      style={{
                        borderRadius: "25px",
                      }}
                    />
                    <Picture />
                  </WrapperButton>
                </CardBest>
              </ContainerCards>
            </Vertical>
          </Container>
        </Page>
      ) : (
        <Container>
          <Sidebar screen="home" />
          <Vertical>
            <Header>
              <Skeleton
                baseColor="#545976"
                width="180px"
                height="11px"
                style={{ borderRadius: "25px", opacity: 0.3 }}
              />

              <BasedScientificPublications>
                <Skeleton
                  baseColor="#0AADFF"
                  width="432px"
                  height="11px"
                  style={{
                    borderRadius: "25px",
                    opacity: 0.3,
                    marginLeft: "16px",
                  }}
                />
              </BasedScientificPublications>

              <Commom>
                <CartProgressContainer onClick={openShoppingCartSidebar}>
                  <CircularProgressbarWithChildren
                    value={inCartNutritionalProgress}
                    strokeWidth={12}
                    styles={{
                      trail: {
                        stroke: `#00000000`,
                      },

                      root: {
                        background: "white",
                        borderRadius: "200px",
                        border: "2px solid #F5F7FE",
                      },
                    }}
                  >
                    <IconContainer onClick={openShoppingCartSidebar}>
                      <ShoppingIcon />
                    </IconContainer>
                  </CircularProgressbarWithChildren>
                  <Circle>
                    <label>{shoppingCart.length}</label>
                  </Circle>
                </CartProgressContainer>
              </Commom>
            </Header>

            <TabNewHome onSelectedPack={() => {}} selectedPack={"essential"} />

            <Banner>
              <InfoContent>
                <Skeleton
                  baseColor="#FFFFFF"
                  highlightColor="#00000050"
                  width="264px"
                  height="11px"
                  style={{
                    borderRadius: "25px",
                    opacity: 0.3,
                  }}
                />
                <Skeleton
                  baseColor="#FFFFFF"
                  highlightColor="#00000050"
                  width="320px"
                  height="11px"
                  style={{
                    borderRadius: "25px",
                    opacity: 0.3,
                  }}
                />
                <Skeleton
                  baseColor="#FFFFFF"
                  highlightColor="#00000050"
                  width="198px"
                  height="11px"
                  style={{
                    borderRadius: "25px",
                    opacity: 0.3,
                  }}
                />
                <ProgressContainer>
                  <ProgressCircle />
                </ProgressContainer>

                <ContainerFooterBanner>
                  <Button />
                  <Skeleton
                    baseColor="#FFFFFF"
                    highlightColor="#00000050"
                    width="97px"
                    height="11px"
                    style={{
                      borderRadius: "25px",
                      opacity: 0.3,
                    }}
                  />
                </ContainerFooterBanner>
              </InfoContent>

              <CardsContainer>
                <HeaderPacks>
                  <TitleHeaderPacks>
                    {t("screens.home.banner.recommended_packs")}
                  </TitleHeaderPacks>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <HoverButton
                      width={294}
                      height={56}
                      sizeFont={16}
                      cursorNotAllowed={false}
                      isActive={false}
                      price={0}
                      currency={"$"}
                      label={t("screens.recommendedPack.add_to_cart")}
                      onClick={() => {}}
                    />

                    <SeeAllLink onClick={() => {}}>
                      {t("screens.home.banner.see_all")}
                    </SeeAllLink>
                  </div>
                </HeaderPacks>

                <CardsWrapper>
                  <SkeletonBannerCard />
                  <SkeletonBannerCard />
                </CardsWrapper>
              </CardsContainer>
            </Banner>

            {!isUnderIpad && (
              <TitileSectionCards
                dangerouslySetInnerHTML={{
                  __html: t("screens.home.see_other_possibilities"),
                }}
              ></TitileSectionCards>
            )}

            <WrapperCardsBests>
              <CardBest backgroundLinear={"green"}>
                <WrapperTitleCard>
                  <Skeleton
                    baseColor="#FFFFFF"
                    highlightColor="#00000050"
                    width="155px"
                    height="11px"
                    style={{
                      borderRadius: "25px",
                      opacity: 0.3,
                    }}
                  />
                  <Skeleton
                    baseColor="#FFFFFF"
                    highlightColor="#00000050"
                    width="172px"
                    height="11px"
                    style={{
                      borderRadius: "25px",
                      opacity: 0.3,
                    }}
                  />

                  <WrapperButton>
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#9f9f9f540"
                      width="89px"
                      height="29px"
                      style={{
                        borderRadius: "25px",
                        marginTop: "19px",
                      }}
                    />
                  </WrapperButton>
                </WrapperTitleCard>
              </CardBest>

              <CardBest backgroundLinear={"blue"}>
                <WrapperTitleCard>
                  <Skeleton
                    baseColor="#FFFFFF"
                    highlightColor="#00000050"
                    width="155px"
                    height="11px"
                    style={{
                      borderRadius: "25px",
                      opacity: 0.3,
                    }}
                  />
                  <Skeleton
                    baseColor="#FFFFFF"
                    highlightColor="#00000050"
                    width="172px"
                    height="11px"
                    style={{
                      borderRadius: "25px",
                      opacity: 0.3,
                    }}
                  />

                  <WrapperButton>
                    <Skeleton
                      baseColor="#FFFFFF"
                      highlightColor="#9f9f9f540"
                      width="89px"
                      height="29px"
                      style={{
                        borderRadius: "25px",
                        marginTop: "19px",
                      }}
                    />
                  </WrapperButton>
                </WrapperTitleCard>
              </CardBest>
            </WrapperCardsBests>
          </Vertical>
        </Container>
      )}
    </>
  );
};

export default SkeletonHomePage;
