import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ProductProps } from "../../api/types";
import { ReactComponent as EssentialPack } from "../../assets/icons/svg/essential-pack.svg";
import { ReactComponent as OptimalPack } from "../../assets/icons/svg/optimalPack.svg";
import { ReactComponent as PremiumPack } from "../../assets/icons/svg/premiumPack.svg";
import HoverButton from "../../components/Button/HoverButton";
import Page from "../../components/Page";
import { GlobalContext } from "../../context/global";
import { ShoppingCartContext } from "../../context/shoppingCart";
import { HeaderContainer } from "../../styles/commomStyles";
import {
  CardPacksRecommendationContainer,
  ChooseYourPackTitle,
  FooterPackMobilePage,
  RecommendedPacksContainer,
} from "./styles";
// import { ProductViewType } from "../ProductDetails";

import logoMobile from "../../assets/logo-mobile.svg";

import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { SidebarContext } from "../../context/sidebar";
import { getCurrencyFormat } from "../../utils/getCurrencyString";
import { CardPacks } from "./CardPacks";
import CardsProductsPackMobile from "./CardProductsPackMobile";

// export type ProductViewType = {
//   state: ProductProps;
// };

const RenderHeaderLogo = () => (
  <HeaderContainer>
    <img src={logoMobile} alt="" />
  </HeaderContainer>
);

export function RecommendedPacksMobile() {
  const { products } = useContext(GlobalContext);
  const { t } = useTranslation();
  const { sidebarOpen } = useContext(SidebarContext);
  const {
    addPackByDuration,
    isPackAlreadyAddedWithDuration,
    shoppingCart,
    getPriceOfPackByDuration,
  } = useContext(ShoppingCartContext);
  const {
    essentialPack,
    optimalPack,
    premiumPack,
    essentialPackScore,
    optimalPackScore,
    premiumPackScore,
  } = useContext(GlobalContext);
  const [productsPack, setProductsPack] =
    useState<ProductProps[]>(essentialPack);
  const [selectedPack, setSelectedPack] = useState("Essential Pack");
  const [selectedPackScore, setSelectedPackScore] =
    useState(essentialPackScore);
  const [isInCart, setIsInCart] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(
    essentialPack[0].id
  );
  const [selectedProduct, setSelectedProduct] = useState<ProductProps>(
    productsPack[0]
  );
  const [wait, setWait] = useState(true);

  const [durationInMonths, setDurationInMonths] = useState(1);

  const product = selectedProduct;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const get = setTimeout(() => {
      setWait(false);
    }, 1300);
    setWait(true);
    return () => clearTimeout(get);
  }, [selectedPack]);

  const handleChangeMonths = (e: ChangeEvent<HTMLSelectElement>) => {
    setDurationInMonths(parseInt(e.target.value, 10));
  };

  function addPackInShoppingCart() {
    addPackByDuration(productsPack, durationInMonths);
  }

  function handlePackClick(packName: string) {
    setSelectedPack(packName);
  }

  function handleCardProductClick(id: number) {
    setSelectedProductId(id);
  }

  useEffect(() => {
    const isInCart = () =>
      isPackAlreadyAddedWithDuration(productsPack, durationInMonths);
    setIsInCart(isInCart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsPack, shoppingCart, []]);

  useEffect(() => {
    const productIndex = productsPack.findIndex(
      (product) => product.id === selectedProductId
    );
    const product = productsPack[productIndex];
    setSelectedProduct(product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId]);

  useEffect(() => {
    const product = productsPack.find(
      (product) => product.id === selectedProductId
    );
    if (!product) {
      setSelectedProductId(productsPack[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsPack]);

  useEffect(() => {
    if (selectedPack === "Essential Pack") {
      setProductsPack(essentialPack);
      setSelectedPackScore(essentialPackScore);
    }

    if (selectedPack === "Optimal Pack") {
      setProductsPack(optimalPack);
      setSelectedPackScore(optimalPackScore);
    }
    if (selectedPack === "Premium Pack") {
      setProductsPack(premiumPack);
      setSelectedPackScore(premiumPackScore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPack]);

  return (
    <Page screen="recommended-packs" logo={RenderHeaderLogo()}>
      <ChooseYourPackTitle>
        {t("screens.recommendedPack.choose_your_pack")}
      </ChooseYourPackTitle>
      <RecommendedPacksContainer>
        <CardPacksRecommendationContainer>
          <CardPacks
            onClick={handlePackClick}
            title={"Pack Essential"}
            packName={"Essential Pack"}
            iconSvg={<EssentialPack />}
            selectedPack={selectedPack}
            score={essentialPackScore}
          />

          <CardPacks
            onClick={handlePackClick}
            title={"Pack Optimal"}
            packName={"Optimal Pack"}
            iconSvg={<PremiumPack />}
            selectedPack={selectedPack}
            score={optimalPackScore}
          />

          <CardPacks
            onClick={handlePackClick}
            iconSvg={<OptimalPack />}
            title={"Pack Premium"}
            packName={"Premium Pack"}
            selectedPack={selectedPack}
            score={premiumPackScore}
          />
        </CardPacksRecommendationContainer>

        <div className="pack-info">
          <div>
            <h3
              dangerouslySetInnerHTML={{
                __html: t("screens.recommendedPack.pack_supports", {
                  packName: selectedPack,
                  score: selectedPackScore,
                }),
              }}
            ></h3>
          </div>
        </div>

        {wait ? (
          <Loading />
        ) : (
          <div className="products-pack-container">
            {productsPack.map((product, key) => {
              return (
                <CardsProductsPackMobile
                  onClicked={handleCardProductClick}
                  selectedCardId={product.id}
                  clickedCard={selectedProductId}
                  key={key}
                  amountProducts={productsPack.length}
                  product={product}
                />
              );
            })}
          </div>
        )}

        <FooterPackMobilePage>
          <div>
            <p>{t("screens.recommendedPack.duration")}:</p>
            <select onChange={handleChangeMonths}>
              <option value="1">1 {t("screens.recommendedPack.month")}</option>
              <option value="2">2 {t("screens.recommendedPack.months")}</option>
              <option value="3">3 {t("screens.recommendedPack.months")}</option>
              <option value="4">4 {t("screens.recommendedPack.months")}</option>
            </select>
          </div>
          <HoverButton
            width={231}
            cursorNotAllowed={isInCart}
            isActive={isInCart}
            label={
              isInCart
                ? t("screens.recommendedPack.in_cart")
                : `${t(
                    "screens.recommendedPack.add_to_cart"
                  )}: ${getCurrencyFormat(
                    product.currency,
                    getPriceOfPackByDuration(productsPack, durationInMonths)
                  )}`
            }
            onClick={addPackInShoppingCart}
          />
        </FooterPackMobilePage>
      </RecommendedPacksContainer>
    </Page>
  );
}
