import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { Container, DietInformation, Header, Icon, ProgressContainer, Text } from './style';
import IconMockDigest from "../../../../../../../../assets/mock/healthGoals/healthGoals2.svg";
// import { DietInformation } from '../../../../../Old-Composition/components/style';
import { HealthRiskType } from '../../../../../../../../api/types';

interface HealthRiskProps {
  healthRisk: HealthRiskType[]
}

export function HealthRiskCard({ healthRisk }: HealthRiskProps) {
  return (
    <>
      {
        healthRisk.map((element, index) => (
          <Container>
            <Header>
              <DietInformation
                score={element.score}
                backgroundType={element.type}
                key={index}
              >
                <ProgressContainer>
                  <CircularProgressbarWithChildren
                    value={element.score}
                    strokeWidth={4}
                    styles={buildStyles({
                      trailColor: "rgba(255, 255, 255, 0.22)",
                      pathColor: "white",
                    })}
                  >
                    <Icon
                      src={`https://storage.googleapis.com/cdn-base.reload.co/${element.iconUrl}`}
                    />
                  </CircularProgressbarWithChildren>
                </ProgressContainer>
              </DietInformation>
            </Header>

            <Text>
              {
                element.text
              }
            </Text>
          </ Container>
        ))
      }

    </>
  )
}