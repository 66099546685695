import styled from "styled-components";


export const ContainerPaginate = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 10px;
`;

export const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const RoundEffect = styled.button`
  color: #0aadff;
  cursor: pointer;
/*   border:1px solid red; */

  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 18px;
  text-align:center;
  /* padding-left:10px;
  padding-right:10px;
  padding-top:9px; */
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin:5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  text-decoration: none;
  transition: all 0.2s;

   &:hover {
    text-decoration: none;
    background: linear-gradient(180.32deg, #2effaf 15.34%, #00aaff 98.15%);
    color:#FFF;

  a {
    text-decoration: none;
    color:#FFF;
  }
   }

   &.active{
    background: linear-gradient(180.32deg, #2effaf 15.34%, #00aaff 98.15%);
    color:#FFF;
}

& a:link {
  text-decoration: none;
  color:#DD4124;
}
`;



