import React, { useState } from "react";
import { ReactComponent as HealthIcon } from "../../../../../../assets/health-goals/t_man.svg";
import RoundedProgress from "../../../../../../components/RoundedProgress";
import {
  CollapseButton,
  CollapseContent,
  CompositionField,
  Container,
  Footer,
  Header,
  HealthCategoryName,
  IconContainer,
  Section,
  SectionTitle,
} from "./style";

import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { useTheme } from "styled-components";
import { NutrientFieldOfActionType } from "../../../../../../api/types";
import { getSymptomsIcon } from "../../../../../../utils/getSymptomsIcon";
import SymptomItem from "../SymptomItem";

type HealthCategoriesCardProps = {
  fieldsOfAction: NutrientFieldOfActionType;
};

const HealthCategoriesCard: React.FC<HealthCategoriesCardProps> = ({
  fieldsOfAction,
}) => {
  const [isCollapseOpen, setCollapseOpen] = useState(false);
  const theme = useTheme();

  const { t } = useTranslation();

  const { name, score, symptoms, categoryId } = fieldsOfAction;

  const toggleCollapse = () => {
    setCollapseOpen(!isCollapseOpen);
  };

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const setOpen = (value: number) => {
    if (value === selectedIndex) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(value);
    }
  };

  return (
    <Container
      isCollapseOpen={isCollapseOpen}
      amountOfChildren={symptoms.length}
    >
      <Header>
        <HealthCategoryName>{name}</HealthCategoryName>
        <RoundedProgress size={55} progress={score}>
          <CompositionField>{getSymptomsIcon(categoryId)}</CompositionField>
        </RoundedProgress>
      </Header>
      <Section>
        <SectionTitle>{`${symptoms.length} ${
          symptoms.length > 1
            ? t("screens.nutrientDetail.symptoms")
            : t("screens.nutrientDetail.symptom")
        }`}</SectionTitle>
        <IconContainer>
          <HealthIcon />
        </IconContainer>
      </Section>
      <Footer>
        <CollapseButton onClick={toggleCollapse}>
          <label>
            {isCollapseOpen
              ? t("screens.nutrientDetail.less_details")
              : t("screens.nutrientDetail.more_details")}
          </label>
          {isCollapseOpen ? (
            <AiOutlineMinusCircle
              width={25}
              height={25}
              color={theme.colors.selected}
            />
          ) : (
            <AiOutlinePlusCircle
              width={25}
              height={25}
              color={theme.colors.selected}
            />
          )}
        </CollapseButton>
      </Footer>
      <Collapse isOpened={isCollapseOpen}>
        <CollapseContent isCollapseOpen={isCollapseOpen}>
          {symptoms.map((symptom, index) => (
            <SymptomItem symptom={symptom} />
          ))}
        </CollapseContent>
      </Collapse>
    </Container>
  );
};

export default HealthCategoriesCard;
