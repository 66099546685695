import React from "react";
import { useTranslation } from "react-i18next";
import { IoAlertOutline } from "react-icons/io5";
import { NutrientProps } from "../../../../api/types";
import { ReactComponent as StarIcon } from "../../../../assets/content/star.svg";
import fallbackImage from "../../../../assets/jpg/fallbackImage.jpg";
import StandardTooltip from "../../../../components/Tooltip";
import { getImageCompletePath } from "../../../../utils/getImageCompletePath";
import {
  AlertContainer,
  CardContainer,
  CardContent,
  CardHeader,
  Compatibility,
  ImageContainer,
  NutrientName,
  ProductIconsContainer,
  StarContainer,
  TitleContainer,
} from "./style";

interface CardNutrientProps {
  nutrient: NutrientProps;
}

const CardMobile = React.forwardRef<HTMLDivElement, CardNutrientProps>(
  ({ nutrient }, ref) => {
    const [qtdBuy, setQtdBuy] = React.useState(0);
    const { t } = useTranslation();

    const {
      name,
      score,
      hasPrecaution,
      hasSynergy,
      image,
      dosageAmountValue,
      isInTop,
      dosageAmountUnit,
    } = nutrient;

    const executeBestProductsScroll = () => {
      if (ref !== null) {
        // @ts-ignore
        ref.current.scrollIntoView();
      }
    };

    return (
      <CardContainer>
        <CardContent>
          <CardHeader>
            <ImageContainer>
              <img
                src={getImageCompletePath("nutrient", image)}
                alt="nutrient"
                onError={(e) => (e.currentTarget.src = fallbackImage)}
              />
            </ImageContainer>

            <ProductIconsContainer>
              {isInTop && (
                <StarContainer id={`nutrient-is-in-top-ten-${nutrient.id}`}>
                  <StarIcon />
                </StarContainer>
              )}
              {isInTop && (
                <StandardTooltip
                  anchor={`nutrient-is-in-top-ten-${nutrient.id}`}
                  color="blue"
                  text={t("hovers.nutrient_is_recommended")}
                  place="bottom"
                  clickable
                />
              )}
              {hasPrecaution && (
                <AlertContainer id={`nutrient-has-alert-${nutrient.id}`}>
                  <IoAlertOutline color="white" />
                </AlertContainer>
              )}
              {hasPrecaution && (
                <StandardTooltip
                  anchor={`nutrient-has-alert-${nutrient.id}`}
                  color="orange"
                  text={t("hovers.nutrient_has_alert")}
                  place="bottom"
                  clickable
                />
              )}
            </ProductIconsContainer>
          </CardHeader>
        </CardContent>

        <TitleContainer>
          <NutrientName>
            <label>{`${dosageAmountValue} ${dosageAmountUnit}/${t(
              "screens.nutrientDetail.day"
            )}`}</label>{" "}
            {`${name} / `}
          </NutrientName>
          <Compatibility value={Math.round(score)}>
            {t("screens.nutrientDetail.your_compatibility")}
            <span>{`${Math.round(score)}/100`}</span>
          </Compatibility>
        </TitleContainer>
      </CardContainer>
    );
  }
);

export { CardMobile };
