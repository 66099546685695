import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { ReactComponent as MoreIcon } from "../../../../assets/content/more.svg";

import { HoverButtonContainer, IconContainer } from "./style";
import { ButtonPriceContainer } from "../../style";
import { getCurrencyFormat } from "../../../../utils/getCurrencyString";

interface HoverButtonProps {
  cursorNotAllowed?: boolean;
  isActive: boolean;
  price?: number;
  currency?: string;
  onClick: () => void;
  label: string;
  width?: number;
  height?: number;
  sizeFont?: number;
  margin?: number;
}

const HoverButton = ({ cursorNotAllowed, isActive, onClick, label, width, height, sizeFont, price, currency, margin }: HoverButtonProps) => {
  return (
    <HoverButtonContainer
      width={width}
      height={height}
      sizeFont={sizeFont}
      cursorNotAllowed={cursorNotAllowed!}
      onClick={onClick}
      isAdded={isActive}
      margin={margin}
    >
      <label>{label}</label>

      {
        isActive ? <IoCheckmarkCircleOutline size={22} color="white" />
          :
          <ButtonPriceContainer>
            <label>{`${getCurrencyFormat(
              currency ? currency : '',
              price ? price : 0
            )}              
          `}</label>
            <IconContainer>
              <MoreIcon />
            </IconContainer>
          </ButtonPriceContainer>
      }

      {/* {
        isActive ? <IoCheckmarkCircleOutline size={22} color="white" />
          :
          <IconContainer>
            <MoreIcon />
          </IconContainer>
      } */}
    </HoverButtonContainer>
  );
};

export default HoverButton;
