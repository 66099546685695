import { IoAlert } from "react-icons/io5";
import { AlertsType } from "../../../../api/types";
import AlertCard from "../../../../components/Cards/AlertCard";
import CardSimple from "../../../../components/Cards/Simple";
import { getSymptomsSvg } from "../../../../utils/getSymptomSvg";

import { useTranslation } from "react-i18next";
import allergyComponentIcon from "../../../../assets/allergy-intolerance-icons/allergy_component_icon.svg";
import allergyExcipientIcon from "../../../../assets/allergy-intolerance-icons/allergy_excipient_icon.svg";
import intoleranceComponentIcon from "../../../../assets/allergy-intolerance-icons/intolerance_component_icon.svg";
import intoleranceExcipientIcon from "../../../../assets/allergy-intolerance-icons/intolerance_excipient_icon.svg";
import HorizontalScrollView from "../../../../components/HorizontalScroll";
import StandardTooltip from "../../../../components/Tooltip";
import getContraindicationPhrasesFormatted from "../../../../utils/getContraindicationPhrasesFormatted";
import { getDrugInteractionsIcon } from "../../../../utils/getDrugInteractionIcon";
import getDrugInteractionPhrasesFormatted from "../../../../utils/getDrugInteractionPhrasesFormatted";
import getInnerHtmlInsideDiv from "../../../../utils/getInnerHtmlInsideDiv";

interface ProductAlertsProps {
  // contraindicationAlerts: ContraindicationAlertType[];
  // excipientAlerts?: ExcipientAlertType[];
  // drugInteractionAlerts: DrugInteractionAlertType[];
  alerts: AlertsType;
}

const ProductAlerts = ({ alerts }: ProductAlertsProps) => {
  const { t } = useTranslation();

  const {
    componentsAllergy,
    componentsIntolerance,
    contraindications,
    drugInteraction,
    excipientAllergy,
    excipientIntolerance,
  } = alerts;

  return (
    <CardSimple title={t("common.alerts")}>
      <HorizontalScrollView gap={8}>
        {componentsAllergy?.map((alert, index) => {
          return (
            <AlertCard
              withoutBorderIcon={true}
              icon={<img alt="Icon" src={allergyComponentIcon} />}
              subtitle={t("common.allergy")}
              title={alert.agentName}
              topIcon={<IoAlert />}
              key={`component-allergy-alert-index-${index}`}
              anchor={`component-allergy-alert-${index}`}
              color="red"
            />
          );
        })}
        {getDrugInteractionPhrasesFormatted(drugInteraction, t).map(
          (alert, index) => {
            return (
              <AlertCard
                icon={getDrugInteractionsIcon(alert.design)}
                subtitle={t("common.drug_interaction")}
                title={alert.drugName}
                topIcon={<IoAlert />}
                key={`drug-interaction-alert-index-${index}`}
                anchor={`drug-interaction-alert-${index}`}
                color="red"
              />
            );
          }
        )}
        {componentsIntolerance?.map((alert, index) => {
          return (
            <AlertCard
              withoutBorderIcon={true}
              icon={<img alt="Icon" src={intoleranceComponentIcon} />}
              subtitle={t("common.intolerance")}
              title={alert.agentName}
              topIcon={<IoAlert />}
              key={`component-intolerance-alert-index-${index}`}
              anchor={`component-intolerance-alert-${index}`}
              color="orange"
            />
          );
        })}
        {excipientAllergy?.map((alert, index) => {
          return (
            <AlertCard
              withoutBorderIcon={true}
              icon={<img alt="Icon" src={allergyExcipientIcon} />}
              subtitle={t("common.allergy")}
              title={alert.excipientName}
              topIcon={<IoAlert />}
              key={`excipient-allergy-alert-index-${index}`}
              anchor={`excipient-allergy-alert-${index}`}
              color="orange"
            />
          );
        })}
        {getContraindicationPhrasesFormatted(contraindications, t).map(
          (alert, index) => {
            return (
              <AlertCard
                icon={getSymptomsSvg(alert.categoryId, "white")}
                subtitle={t("common.contraindication")}
                title={alert.name}
                topIcon={<IoAlert />}
                key={`contraindication-alert-index-${index}`}
                anchor={`contraindication-alert-${index}`}
                color="orange"
              />
            );
          }
        )}
        {excipientIntolerance?.map((alert, index) => {
          return (
            <AlertCard
              withoutBorderIcon={true}
              icon={<img alt="Icon" src={intoleranceExcipientIcon} />}
              subtitle={t("common.intolerance")}
              title={alert.excipientName}
              topIcon={<IoAlert />}
              key={`excipient-intolerance-alert-index-${index}`}
              anchor={`excipient-intolerance-alert-${index}`}
              color="yellow"
            />
          );
        })}
      </HorizontalScrollView>

      {componentsAllergy?.map((alert, index) => {
        return (
          <StandardTooltip
            color={"red"}
            anchor={`component-allergy-alert-${index}`}
            text={alert.agentName}
            key={index}
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(
                    t("hovers.you_are_allergic_to", { name: alert.agentName })
                  ),
                }}
              ></div>
            }
            clickable
          />
        );
      })}

      {componentsIntolerance?.map((alert, index) => {
        return (
          <StandardTooltip
            color={"orange"}
            anchor={`component-intolerance-alert-${index}`}
            text={alert.agentName}
            key={index}
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(
                    t("hovers.you_are_intolerant_to", { name: alert.agentName })
                  ),
                }}
              ></div>
            }
            clickable
          />
        );
      })}

      {excipientAllergy?.map((alert, index) => {
        return (
          <StandardTooltip
            color={"orange"}
            anchor={`excipient-allergy-alert-${index}`}
            text={alert.excipientName}
            key={index}
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(
                    t("hovers.you_are_allergic_to_excipient", {
                      name: alert.excipientName,
                    })
                  ),
                }}
              ></div>
            }
            clickable
          />
        );
      })}

      {excipientIntolerance?.map((alert, index) => {
        return (
          <StandardTooltip
            color={"yellow"}
            anchor={`excipient-intolerance-alert-${index}`}
            text={alert.excipientName}
            key={index}
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(
                    t("hovers.you_are_intolerant_to_excipient", {
                      name: alert.excipientName,
                    })
                  ),
                }}
              ></div>
            }
            clickable
          />
        );
      })}

      {getDrugInteractionPhrasesFormatted(drugInteraction, t).length > 0 &&
        getDrugInteractionPhrasesFormatted(drugInteraction, t).map(
          (alert, index) => {
            return (
              <StandardTooltip
                color={"red"}
                anchor={`drug-interaction-alert-${index}`}
                text={alert.phrase}
                key={index}
                children={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getInnerHtmlInsideDiv(alert.phrase),
                    }}
                  ></div>
                }
                clickable
              />
            );
          }
        )}
      {getContraindicationPhrasesFormatted(contraindications, t).length > 0 &&
        getContraindicationPhrasesFormatted(contraindications, t).map(
          (alert, index) => {
            const phrase = t("hovers.nutrient_contraindicated")
              .replace("{{ nutrientName }}", alert.nutrientName)
              .replace("{{ symptomName }}", alert.name);

            return (
              <StandardTooltip
                color={"orange"}
                anchor={`contraindication-alert-${index}`}
                text={phrase}
                key={index}
                children={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getInnerHtmlInsideDiv(phrase),
                    }}
                  ></div>
                }
                clickable
              />
            );
          }
        )}
    </CardSimple>
  );
};

export default ProductAlerts;
