import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router";
import { GlobalContext } from "./context/global";
import { SetupAnswerContext } from "./context/setupAnswer";
import { useViewport } from "./hooks/Viewport/useViewport";
import FavoriteFoods from "./screens/FavoriteFoods";
import { Home } from "./screens/Home";
import SkeletonHomePage from "./screens/Home/components/skeleton";
import { LoadingScreen } from "./screens/Loading";
import NutrientDetails from "./screens/NutrientDetails";
import NutrientDetailsMobile from "./screens/NutrientDetailsMobile";
import NutritionalNeeds from "./screens/NutritionalNeeds";
import ProductDetails from "./screens/ProductDetails";
import ProductDetailsMobile from "./screens/ProductDetailsMobile";
import Products from "./screens/Products";
import ProductsMobile from "./screens/ProductsMobile";
import { RecommendedPacks } from "./screens/RecommendedPacks";
import { RecommendedPacksMobile } from "./screens/RecommendedPacksMobile";
import ValidateBasket from "./screens/ValidateBasket";

function Router() {
  const { isUnderMobile } = useViewport();
  const { processingAnswer, waitingAnimation } = useContext(GlobalContext);
  const { t, i18n } = useTranslation();
  const { language } = useContext(SetupAnswerContext);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  if (waitingAnimation) {
    return <LoadingScreen />;
  } else if (processingAnswer && !waitingAnimation) {
    return <SkeletonHomePage />;
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/products"
        element={isUnderMobile ? <ProductsMobile /> : <Products />}
      />
      <Route path="/nutritional-needs" element={<NutritionalNeeds />} />
      <Route
        path="/recommended-packs"
        element={
          isUnderMobile ? <RecommendedPacksMobile /> : <RecommendedPacks />
        }
      />
      <Route path="/favorite-foods" element={<FavoriteFoods />} />

      <Route
        path="/product-details"
        element={isUnderMobile ? <ProductDetailsMobile /> : <ProductDetails />}
      />
      <Route
        path="/nutrient-details"
        element={
          isUnderMobile ? <NutrientDetailsMobile /> : <NutrientDetails />
        }
      />
      <Route
        path="/recommendation/product-details"
        element={<ProductDetails />}
      />

      <Route path="/validate-basket" element={<ValidateBasket />} />
    </Routes>
  );
}

export default Router;
