import styled from "styled-components";

export const Container = styled.button<{
  color?: "green" | "blue" | "red";
  width?: string;
  disabled: boolean;
}>`
  width: ${({ width }) => (width ? width : `100%`)};
  height: 51px;
  border-radius: 30px;
  background-color: ${({ theme, color }) => {
    switch (color) {
      case "green":
        return theme.colors.selected;
      case "blue":
        return theme.colors.midBlue;
      case "red":
        return theme.colors.lightRed;
      default:
        return theme.colors.selected;
    }
  }};
  padding: 8px;
  justify-content: center;
  align-items: center;
  padding-left: 28px;
  padding-right: 28px;

  ${({ disabled, theme }) =>
    disabled &&
    `
    cursor: default;
    background-color: ${theme.colors.grayBgButton};
  `}

  :hover {
    filter: brightness(0.8);
    ${({ disabled }) =>
      disabled &&
      `
    filter: none;
  `}
  }

  > label {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    ${({ disabled, theme }) =>
      disabled &&
      `
    cursor: default;
    color: ${theme.colors.grayLight};
  `}
  }
`;
