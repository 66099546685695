import Aesthetics from "../assets/symptoms/symptoms-icon/Aesthetics.svg";
import Allergies from "../assets/symptoms/symptoms-icon/Allergies.svg";
import Antiaging from "../assets/symptoms/symptoms-icon/Anti-aging.svg";
import Anti_inflammatory from "../assets/symptoms/symptoms-icon/Anti-inflammatory.svg";
import Biomarker from "../assets/symptoms/symptoms-icon/Biomarker.svg";
import Bone_and_joint from "../assets/symptoms/symptoms-icon/Bone-and-joint.svg";
import Brain_Health from "../assets/symptoms/symptoms-icon/Brain-Health.svg";
import Cardiovascular from "../assets/symptoms/symptoms-icon/Cardiovascular.svg";
import Disease_Condition from "../assets/symptoms/symptoms-icon/Disease-Condition.svg";
import Eating_Patterns from "../assets/symptoms/symptoms-icon/Eating-Patterns.svg";
import Emotional from "../assets/symptoms/symptoms-icon/Emotional.svg";
import Energy_Metabolism_and_Stimulation from "../assets/symptoms/symptoms-icon/Energy-Metabolism.svg";
import Eye_Health from "../assets/symptoms/symptoms-icon/Eye-Health.svg";
import General_Health from "../assets/symptoms/symptoms-icon/General-Health.svg";
import Gut_Digestion from "../assets/symptoms/symptoms-icon/Gut-Digestion.svg";
import Hormones from "../assets/symptoms/symptoms-icon/Hormones.svg";
import Kidney_Bladder from "../assets/symptoms/symptoms-icon/Kidney-Bladder.svg";
import Libido_and_Sexual_Health from "../assets/symptoms/symptoms-icon/Libido-and-Sexual-Health.svg";
import Liver_Health from "../assets/symptoms/symptoms-icon/Liver-Health.svg";
import Mens_Health from "../assets/symptoms/symptoms-icon/Mens-Health.svg";
import Mood from "../assets/symptoms/symptoms-icon/Mood.svg";
import Muscle_Gain from "../assets/symptoms/symptoms-icon/Muscle-Gain.svg";
import Nervous_System from "../assets/symptoms/symptoms-icon/Nervous-System.svg";
import Sleep_Quality from "../assets/symptoms/symptoms-icon/Sleep-Quality.svg";
import Womens_Health from "../assets/symptoms/symptoms-icon/Women-Health.svg";
import { HeartIcon } from "../components/Icons/HeartIcon";

export const getSymptomsIcon = (category: number) => {
  let variants = {
    1: Aesthetics,
    2: Anti_inflammatory,
    3: Antiaging,
    4: Biomarker,
    5: Biomarker,
    6: Bone_and_joint,
    7: Cardiovascular,
    8: Brain_Health,
    10: Disease_Condition,
    11: Eating_Patterns,
    12: Emotional,
    13: Energy_Metabolism_and_Stimulation,
    14: Eye_Health,
    15: Energy_Metabolism_and_Stimulation,
    16: General_Health,
    17: Gut_Digestion,
    18: Hormones,
    19: Kidney_Bladder,
    20: Libido_and_Sexual_Health,
    21: Liver_Health,
    22: Mens_Health,
    23: Mood,
    24: Muscle_Gain,
    25: Nervous_System,
    26: Biomarker,
    27: Sleep_Quality,
    28: Womens_Health,
    29: Nervous_System,
    30: Allergies,
    31: Biomarker,
  };

  return (
    (
      <div
        style={{
          width: "auto",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={variants[category as keyof typeof variants]}
          alt=""
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    ) || <HeartIcon />
  );
};
