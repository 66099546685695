import Molecule from "../assets/drug-interactions/2.svg";
import Heart from "../assets/drug-interactions/5.svg";
import Drug from "../assets/drug-interactions/6-7.svg";
import Flask from "../assets/drug-interactions/flask.svg";

export const getDrugInteractionsIcon = (design: number) => {
  const variants = {
    1: Heart,
    2: Molecule,
    3: Molecule,
    4: Heart,
    5: Heart,
    6: Drug,
    7: Drug,
    8: Flask,
  };

  return (
    <img
      alt="Drug Interaction Icon"
      src={variants[design as keyof typeof variants]}
    />
  );
};
