import styled from "styled-components";
import aqueous from "../assets/agent-form-icons/aqueous.svg";
import dry from "../assets/agent-form-icons/dry.svg";
import others from "../assets/nutrient-category/99-others.svg";

const iconVariants = {
  1: dry,
  2: aqueous,
  99: others,
};

export const getAgentFormIcon = (id: number, size?: number) => {
  return (
    <Image
      size={size || 30}
      src={iconVariants[id as keyof typeof iconVariants] || iconVariants[99]}
      alt=""
    />
  );
};

const Image = styled.img<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
