import React from "react";
import RoundedProgress from "../../../../../../components/RoundedProgress";
import {
  CompositionField,
  SymptomsCollapse,
  SymptomsCollapseTitle,
  SymptomsProgressContainer,
} from "./style";

type SymptomItemProps = {
  symptom: {
    name: string;
    score: number;
  };
};

const SymptomItem: React.FC<SymptomItemProps> = ({ symptom }) => {
  const { name, score } = symptom;

  return (
    <SymptomsCollapse>
      <SymptomsCollapseTitle>{name}</SymptomsCollapseTitle>
      <SymptomsProgressContainer>
        <RoundedProgress size={55} progress={Math.round(score)}>
          <CompositionField>
            <label>{`${Math.round(score)}%`}</label>
          </CompositionField>
        </RoundedProgress>
      </SymptomsProgressContainer>
    </SymptomsCollapse>
  );
};

export default SymptomItem;
