import { ChildrenType } from "react-tooltip";
import { StyledTooltip } from "./style";

interface TooltipProps {
  text: string;
  anchor?: string;
  color: "orange" | "red" | "yellow" | "green" | "gray" | "blue" | "gold";
  place?: "top" | "left" | "right" | "bottom";
  clickable?: boolean;
  children?: ChildrenType;
}

const StandardTooltip = ({
  text,
  color,
  anchor,
  place = "top",
  clickable = false,
  children,
}: TooltipProps) => {
  if (clickable) {
    return (
      <StyledTooltip
        color={color}
        anchorId={anchor}
        content={text}
        place={place}
        noArrow
        events={["hover", "click"]}
        style={{
          // width: "min-content",
          // width: "fit-content",
          // minWidth: "auto",
          maxWidth: 210,
          zIndex: 999,
        }}
        children={children ? children : null}
      />
    );
  }

  return (
    <StyledTooltip
      color={color}
      anchorId={anchor}
      content={text}
      place={place}
      noArrow
      style={{
        // width: "min-content",
        // width: "fit-content",
        // minWidth: "auto",
        maxWidth: 225,
        zIndex: 999,
      }}
      children={children ? children : null}
    />
  );
};

export default StandardTooltip;
