import React from "react";
import { useTranslation } from "react-i18next";
import { NutrientFieldOfActionType } from "../../../../api/types";
import { Title } from "../../style";
import HealthCategoriesCard from "./components/HealthCategoriesCard";
import { Container, HealthCategoriesContainer } from "./style";

type NutrientFieldsOfActionProps = {
  fieldsOfAction: NutrientFieldOfActionType[];
};

const NutrientFieldsOfAction: React.FC<NutrientFieldsOfActionProps> = ({
  fieldsOfAction,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t("screens.nutrientDetail.support_your_following")}</Title>
      <HealthCategoriesContainer>
        {fieldsOfAction.map((field) => (
          <HealthCategoriesCard fieldsOfAction={field} />
        ))}
      </HealthCategoriesContainer>
    </Container>
  );
};

export default NutrientFieldsOfAction;
