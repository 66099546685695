import React, { useContext } from "react";
import { GlobalContext } from "../../context/global";
import { SidebarContext } from "../../context/sidebar";
import { useViewport } from "../../hooks/Viewport/useViewport";
import Header from "../Header";
import HeaderMobile from "../HeaderMobile";
import { Sidebar } from "../Sidebar/NavSidebar";
import { Container, Content, Vertical } from "./style";

interface PageProps {
  screen: string;
  header?: () => JSX.Element;
  children: React.ReactNode;
  hideHeader?: boolean;
  logo?: JSX.Element;
  onClick?: () => void;
  shouldRenderMenuIcon?: boolean;
  removePadding?: boolean;
}

const Page = ({
  screen,
  children,
  header,
  hideHeader = false,
  logo,
  onClick,
  shouldRenderMenuIcon = true,
  removePadding = false,
}: PageProps) => {
  const { isUnderTablet } = useViewport();
  const { sidebarOpen } = useContext(SidebarContext);
  const { amountOfStudies } = useContext(GlobalContext);

  return (
    <Container>
      {isUnderTablet ? (
        <Vertical isUnderTablet={isUnderTablet} sidebarOpen={sidebarOpen}>
          <HeaderMobile
            shouldRenderMenuIcon={shouldRenderMenuIcon}
            headerChildren={header}
            logo={logo}
          />
          <Content removePadding={removePadding}>{children}</Content>
        </Vertical>
      ) : (
        <>
          <Sidebar screen={screen} />
          <Vertical isUnderTablet={isUnderTablet} sidebarOpen={sidebarOpen}>
            {!hideHeader && <Header headerChildren={header} />}
            <Content>{children}</Content>
          </Vertical>
        </>
      )}
    </Container>
  );
};

export default Page;
