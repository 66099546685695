import styled from "styled-components";

export const Container = styled.div.attrs(
  (props: { viewMore: boolean; index: number }) => props
)`
  margin-top: 11px;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #e6e7ec;
  background-color: white;
  align-items: center;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    > svg {
      width: 22px;
      height: 22px;
      transform: rotate(45deg);
      margin-left: 8px;
    }
    > label {
      margin-left: 10px;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.black};
      font-weight: bold;
    }
  }
  > label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    margin-right: 8px;
  }

  transition: opacity 1s ease-out;
  opacity: 1;
  height: auto;
  ${({ theme }) => theme.shadow}
  ${({ viewMore, index }) =>
    !viewMore &&
    index > 2 &&
    `
    opacity: 0;
    height: 0;
	border: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
    `}
`;
