import { NutrientContent, NutrientHeader, NutrientName } from "./style";

import {
  Bar,
  Progress,
} from "../../../../../../../../components/ProgressCard/style";
import { getNutrientCategoryIcon } from "../../../../../../../../utils/getNutrientCategoryIcon";

type NutrientItemProps = {
  name: string;
  score: number;
  iconId: number;
  isContraindication?: boolean;
};

const NutrientItem = ({
  name,
  score,
  iconId,
  isContraindication = false,
}: NutrientItemProps) => {
  return (
    <NutrientContent>
      <NutrientHeader>
        <NutrientName>{name}</NutrientName>
        {getNutrientCategoryIcon(iconId)}
      </NutrientHeader>
      <Progress>
        <Bar
          progress={Math.min(score, 100)}
          isContraindication={isContraindication}
        />
      </Progress>
    </NutrientContent>
  );
};

export default NutrientItem;
