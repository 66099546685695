import { createRef, useContext, useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ProductProps } from "../../../../api/types";
import { GlobalContext } from "../../../../context/global";
import { ShoppingCartContext } from "../../../../context/shoppingCart";

import imgSliderMale01 from "../../assets/img-slider-male-01.png";
import imgSliderMale02 from "../../assets/img-slider-male-02.png";
import imgSliderMale03 from "../../assets/img-slider-male-03.png";

import imgSliderFemale01 from "../../assets/img-slider-female-01.png";
import imgSliderFemale02 from "../../assets/img-slider-female-02.png";
import imgSliderFemale03 from "../../assets/img-slider-female-03.png";

import HorizontalScrollView from "../HorizontalScroll";
import HoverButton from "../HoverButton";
import InfoComum from "../InfoComum/bannerCard";
import {
  BackgroundGradientBanner,
  BannerWrapper,
  CardsContainer,
  CircularProgressContainer,
  ContainerCardsPacks,
  ContainerPackProductsLength,
  ContainerSlider,
  HeaderPacks,
  PackProductCount,
  SeeAllLink,
  TitleBanner,
  TitleHeaderPacks,
  TitlePackProductCount,
} from "./style";

interface SliderPacksMobileProps {
  selectedPack: string
  gender: string
}

export function SliderPacksMobile({ selectedPack, gender }: SliderPacksMobileProps) {
  const [essentialPrice, setEssentialPrice] = useState(0);
  const [optimalPrice, setOptimalPrice] = useState(0);
  const [premiumPrice, setPremiumPrice] = useState(0);
  const [packName, setPackName] = useState("Essential Pack");
  const [imgSlider, setImgSlider] = useState("");
  const [packPercentage, setPackPercentage] = useState(0);
  const [packProducts, setPackProducts] = useState<ProductProps[]>([]);
  const { addPack, isPackAlreadyAdded } = useContext(ShoppingCartContext);

  const sliderRef = createRef<HTMLDivElement>();
  const { t } = useTranslation();
  const [isInCart, setIsInCart] = useState(false);
  const navigate = useNavigate();

  const {
    essentialPack,
    optimalPack,
    premiumPack,
    essentialPackScore,
    optimalPackScore,
    premiumPackScore,
  } = useContext(GlobalContext);

  const addPackInShoppingCart = () => {
    addPack(packProducts);
  };

  const getPackPrice = () => {
    if (selectedPack === "essential") {
      return essentialPrice;
    }
    if (selectedPack === "optimal") {
      return optimalPrice;
    }
    if (selectedPack === "premium") {
      return premiumPrice;
    }
  };

  useEffect(() => {
    setPackProducts(essentialPack);

    setEssentialPrice(
      parseFloat(
        essentialPack
          .map((product) => product.price)
          .reduce((acc, cur) => acc + cur, 0)
          .toFixed(2)
      )
    );
    setOptimalPrice(
      parseFloat(
        optimalPack
          .map((product) => product.price)
          .reduce((acc, cur) => acc + cur, 0)
          .toFixed(2)
      )
    );
    setPremiumPrice(
      parseFloat(
        premiumPack
          .map((product) => product.price)
          .reduce((acc, cur) => acc + cur, 0)
          .toFixed(2)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiumPack]);

  useEffect(() => {
    if (selectedPack === "essential") {
      setPackName(t("screens.recommendedPack.essential_pack"));
      setPackProducts(essentialPack);
      setPackPercentage(essentialPackScore);
      setImgSlider(gender === "male" ? imgSliderMale01 : imgSliderFemale01);
    }
    if (selectedPack === "optimal") {
      setPackName(t("screens.recommendedPack.optimal_pack"));
      setPackProducts(optimalPack);
      setPackPercentage(optimalPackScore);
      setImgSlider(gender === "male" ? imgSliderMale02 : imgSliderFemale02);
    }
    if (selectedPack === "premium") {
      setPackName(t("screens.recommendedPack.premium_pack"));
      setPackProducts(premiumPack);
      setPackPercentage(premiumPackScore);
      setImgSlider(gender === "male" ? imgSliderMale03 : imgSliderFemale03);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPack]);

  useEffect(() => {
    const isInCart = () => isPackAlreadyAdded(packProducts);
    setIsInCart((state) => (state = isInCart()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packProducts, []]);

  const { setStillProcessing } = useContext(GlobalContext);

  return (
    <ContainerSlider>
      <BannerWrapper img={imgSlider}>
        <BackgroundGradientBanner />
        <TitleBanner
          dangerouslySetInnerHTML={{
            __html: t("screens.home.banner.pack_supports", {
              packName: packName,
              packPercentage: packPercentage,
            }),
          }}
        ></TitleBanner>

        <CircularProgressContainer>
          <CircularProgressbar
            strokeWidth={3}
            value={packPercentage}
            text={`${packPercentage}%`}
            styles={{
              path: {
                stroke: `#21E7C5`,
              },
              trail: {
                stroke: `#00000090`,
              },
              text: {
                fill: "white",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "39px",
              },
            }}
          />
        </CircularProgressContainer>

        <ContainerPackProductsLength>
          <PackProductCount>{packProducts.length}</PackProductCount>
          <TitlePackProductCount>
            {t("screens.home.banner.products")}
          </TitlePackProductCount>
        </ContainerPackProductsLength>
      </BannerWrapper>

      <ContainerCardsPacks>
        <HeaderPacks>
          <TitleHeaderPacks>
            {t("screens.home.banner.recommended_packs")}
          </TitleHeaderPacks>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <SeeAllLink onClick={() => navigate("/recommended-packs")}>
              See All
            </SeeAllLink>
          </div>
        </HeaderPacks>

        <HorizontalScrollView ref={sliderRef} size={180} padding={14}>
          <CardsContainer>
            {packProducts.map((product, index) => (
              <InfoComum
                key={index}
                amountProducts={packProducts.length}
                product={product}
              />
            ))}
          </CardsContainer>
        </HorizontalScrollView>

        <HoverButton
          width={254}
          height={48}
          sizeFont={16}
          margin={24}
          cursorNotAllowed={isInCart}
          isActive={isInCart}
          price={getPackPrice() ?? 0}
          currency={premiumPack[0].currency}
          label={
            isInCart
              ? t("screens.recommendedPack.in_cart")
              : t("screens.recommendedPack.add_to_cart")
          }
          onClick={addPackInShoppingCart}
        />
      </ContainerCardsPacks>
    </ContainerSlider>
  );
}
