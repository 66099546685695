import React, { useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { NutrientDietInformationType } from "../../../../../api/types";
import forkAndKnifeImg from "../../../../../assets/banners/fork-and-knife.svg";
import {
  CarouselButton,
  DietInformation,
  DietInformationCarouselButtons,
  DietInformationCarouselContainer,
  DietInformationContainer,
  DietInformationImage,
  DietInformationProgressIcon,
  DietInformationTitle,
  DietInformationTitleContainer,
} from "./style";

type DietInformationCarouselProps = {
  nutrientDietInformation: NutrientDietInformationType[];
};

const DietInformationCarousel: React.FC<DietInformationCarouselProps> = ({
  nutrientDietInformation,
}) => {
  const [selectedValue, setSelectedValue] = useState(0);

  const handleChangeBanner = (index: number) => {
    setSelectedValue(index);
  };

  const getIcon = (type: string, iconUrl?: string) => {
    switch (type) {
      case "nutrition":
        return forkAndKnifeImg;
      case "health risk":
        return `https://storage.googleapis.com/cdn-base.reload.co/${iconUrl}`;
    }
  };

  return (
   // <DietInformationCarouselContainer>
   <DietInformationContainer>
   {nutrientDietInformation
     // .slice(0)
     // .reverse()
        .map((information, index) => {
          return (
            <DietInformation
              score={information.score}
              backgroundType={information.type}
              // isSelected={index === selectedValue}
              key={index}
            >
              <DietInformationProgressIcon>
                <CircularProgressbarWithChildren
                  value={information.score}
                  strokeWidth={4}
                  styles={buildStyles({
                    trailColor: "transparent",
                    pathColor: "white",
                  })}
                >
                  <DietInformationImage
                    src={getIcon(information.type, information.iconUrl)}
                  />
                </CircularProgressbarWithChildren>
              </DietInformationProgressIcon>
              <DietInformationTitleContainer>
                 <DietInformationTitle>{information.text}</DietInformationTitle>
              </DietInformationTitleContainer>
              {/* <DietInformationCarouselButtons>
                {[...Array(nutrientDietInformation.length)].map(
                  (elem, index) => (
                    <CarouselButton
                      key={index}
                      actual={selectedValue}
                      value={index}
                      onClick={() => handleChangeBanner(index)}
                    />
                  )
                )}
              </DietInformationCarouselButtons> */}
            </DietInformation>
          );
        })}
    </DietInformationContainer>
  );
};

export default DietInformationCarousel;
