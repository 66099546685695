import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiArrowSmLeft } from "react-icons/hi";
import { TbTrash } from "react-icons/tb";
import { useLocation } from "react-router";
import { FilterContext } from "../../../context/filter";
import { Fieldset } from "./Fieldset";
import { InputRangePrice } from "./InputRangePrice";
import { BackDrop, FilterSidebarContainer } from "./styles";

interface FilterSidebarProps {
  isFilterSidebarOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export function FilterSidebar({
  onClose,
  isFilterSidebarOpen,
}: FilterSidebarProps) {
  const {
    avatarSymptoms,
    categorySymptoms,
    healthGoals,
    handleToggleAvatarSymptomIndex,
    handleToggleCategorySymptomIndex,
    handleToggleHealthGoalIndex,
    applyFilter,
    clearFilters,
    avatarSymptomsIndexSelected,
    categorySymptomsIndexSelected,
    healthGoalsIndexSelected,
    setAvatarSymptomsIndexsSelected,
  } = useContext(FilterContext);

  const { t } = useTranslation();

  const { pathname } = useLocation();

  /**
   * Inserido mas ainda não utilizado
   */
  const [isProductPage, setIsProductPage] = useState(false);
  const [isNutritionalNeedsPage, setIsNutritionalNeedsPage] = useState(false);

  useEffect(() => {
    setIsProductPage(pathname.includes("products"));
    setIsNutritionalNeedsPage(pathname.includes("nutritional-needs"));
  }, [pathname]);
  // console.log("Is Nutritional: ", isNutritionalNeedsPage);
  // console.log("Is Product: ", isProductPage);

  return (
    <>
      <FilterSidebarContainer isSidebarOpen={isFilterSidebarOpen}>
        <div className="sidebar-title">
          <HiArrowSmLeft size={25} onClick={onClose} className="arrow-left" />
          <h3>{t("components.filterSidebar.find_the_best")}</h3>
          <span>{t("components.filterSidebar.select_below")}</span>
        </div>
        <div className="div-form">
          <Fieldset
            legend={t("components.filterSidebar.your_symptoms")}
            inputName="symptoms"
            listOfIndexSelected={avatarSymptomsIndexSelected}
            labels={avatarSymptoms.map((symptom) => symptom.name)}
            onClick={handleToggleAvatarSymptomIndex}
          />

          <Fieldset
            legend={t("components.filterSidebar.symptoms_category")}
            inputName="conditions médicales"
            listOfIndexSelected={categorySymptomsIndexSelected}
            labels={categorySymptoms.map((symptom) => symptom.name)}
            onClick={handleToggleCategorySymptomIndex}
          />

          <Fieldset
            legend={t("components.filterSidebar.your_health_goals")}
            inputName="health goals"
            listOfIndexSelected={healthGoalsIndexSelected}
            labels={Object.keys(healthGoals).map((_, index) =>
              t(`healthGoals.${Object.keys(healthGoals)[index]}`)
            )}
            onClick={handleToggleHealthGoalIndex}
          />

          {isProductPage && (
            <InputRangePrice legend={t("components.filterSidebar.price")} />
          )}

          <div className="btn-container">
            <button onClick={clearFilters} className="btn-reinitialiser">
              <TbTrash size={20} />
              {t("components.filterSidebar.clear_filters")}
            </button>

            {/* <button className="btn-apliquer" onClick={applyFilter}>
              {t("components.filterSidebar.apply")}
            </button> */}
          </div>
        </div>
      </FilterSidebarContainer>

      <BackDrop isSidebarOpen={isFilterSidebarOpen} onClick={onClose} />
    </>
  );
}
