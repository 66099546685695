import beauty from "../assets/health-goals/beauty.svg";
import boost from "../assets/health-goals/boost.svg";
import cognition from "../assets/health-goals/cognition.svg";
import digestion from "../assets/health-goals/digestion.svg";
import energy from "../assets/health-goals/energy.svg";
import immunity from "../assets/health-goals/immunity.svg";
import longevity from "../assets/health-goals/longevity.svg";
import mood from "../assets/health-goals/mood.svg";
import sleep from "../assets/health-goals/sleep.svg";
import weight from "../assets/health-goals/weight_loss.svg";
import { HeartIcon } from "../components/Icons/HeartIcon";

export const getHealthGoalsIcon = (category: string, size = 80) => {
  let association_object = {
    beauty,
    boost,
    cognition,
    digestion,
    energy,
    immunity,
    longevity,
    weight,
    mood,
    sleep,
  };

  let found = undefined;

  let prohibitedWords = ["health", "and", "&"];
  if (category) {
    let words = category.toLowerCase().trim().split(" ");
    for (const key in association_object) {
      for (let word of words) {
        if (
          key.toLowerCase().trim().split(" ").indexOf(word) > -1 &&
          !prohibitedWords.includes(word)
        ) {
          found = (
            <img
              // style={{ width: `${size}%`, height: `${size}%` }}
              src={association_object[key as keyof typeof association_object]}
              alt=""
            />
          );
          break;
        }
      }
    }
  }

  return found ? found : <HeartIcon />;
};
