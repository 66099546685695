import React, { createContext, useEffect, useState } from "react";
import { decodeToken } from "react-jwt";
import { useSearchParams } from "react-router-dom";
import { SetupAnswerModal } from "../components/Modal/SetupAnswer";
import redirectToQuiz from "../utils/redirectToQuiz";
interface SetupAnswerContextInterface {
  modalOpen: boolean;
  handleCloseModal: () => void;
  handleOpenModal: () => void;
  answerId: number | undefined;
  pharmacyId: number | undefined;
  language: string;
  token: string | null | undefined;
  setAnswerId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPharmacyId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

export const SetupAnswerContext = createContext<SetupAnswerContextInterface>(
  {} as SetupAnswerContextInterface
);

const SetupAnswerProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const [urlToken, setUrlToken] = useState(
    !!searchParams.get("token")
      ? searchParams.get("token")
      : localStorage.getItem("token") ?? undefined
  );

  // const { decodedToken, isExpired } = useJwt(urlToken || "");

  const [answerId, setAnswerId] = useState<number | undefined>(
    !!searchParams.get("answer")
      ? parseInt(searchParams.get("answer")!, 10)
      : undefined
  );
  const [pharmacyId, setPharmacyId] = useState<number | undefined>(
    !!searchParams.get("pharmacy")
      ? parseInt(searchParams.get("pharmacy")!, 10)
      : undefined
  );
  const [language, setLanguage] = useState(
    searchParams.get("language") ?? "en"
  );

  useEffect(() => {
    if (process.env.REACT_APP_MODE === "production") {
      if (!urlToken) {
        redirectToQuiz();
        return;
      }

      localStorage.setItem("token", urlToken);

      const decoded = decodeToken(urlToken);

      console.log("decodedToken: ", decoded);

      // @ts-ignore
      const _answerId = decoded.answer;
      // @ts-ignore
      const _pharmacyId = decoded.pharmacy;
      // @ts-ignore
      const _language = decoded.language ?? "en";

      setAnswerId(_answerId);
      setPharmacyId(_pharmacyId);
      setLanguage(_language ?? "en");
    } else {
      const _answerId = answerId;
      const _pharmacyId = pharmacyId;
      const _language = language;

      setAnswerId(_answerId ?? 1291); // Todo trocar
      setPharmacyId(_pharmacyId ?? 4);
      setLanguage(_language ?? "en");
    }
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleKeyDown = (e: any) => {
    // console.log("Handle Key Down: ", e.keyCode);

    if (e.keyCode === 192) {
      if (!modalOpen) {
        handleOpenModal();
      }
    }

    if (e.keyCode === 27) {
      if (modalOpen) {
        handleCloseModal();
      }
    }
  };

  const isNotProduction = () => {
    return (
      process.env.NODE_ENV !== "production" ||
      process.env.REACT_APP_MODE === "homologation" ||
      process.env.REACT_APP_MODE === "development"
    );
  };

  return (
    <SetupAnswerContext.Provider
      value={{
        modalOpen,
        handleCloseModal,
        handleOpenModal,
        answerId,
        language,
        pharmacyId,
        setAnswerId,
        setLanguage,
        setPharmacyId,
        token: urlToken,
      }}
    >
      {isNotProduction() ? (
        <>
          <div
            tabIndex={0}
            style={{ width: "100%", height: "100%" }}
            onKeyDown={handleKeyDown}
          >
            {children}
          </div>
          <SetupAnswerModal />
        </>
      ) : (
        <>{children}</>
      )}
    </SetupAnswerContext.Provider>
  );
};
export { SetupAnswerProvider };
