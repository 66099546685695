import styled from "styled-components";

export const Progress = styled.div`
  margin-top: 1rem;
  height: 15px;
  width: 100%;
  border-radius: 50px;
  background-color: white;
`;

export const Bar = styled.div<{ progress: number }>`
  background: ${({ progress }) =>
    progress < 0
      ? `linear-gradient(269.62deg, #EDD79F 18.05%, rgba(255, 164, 113, 0.97) 103.31%)`
      : `linear-gradient(269.62deg, #2effaf 18.05%, #00aaff 103.31%)`};
  height: 100%;
  width: ${({ progress }) => progress}%;
  border-radius: inherit;

  transition: width 500ms ease-out;
`;
