import { AlertsType } from "../api/types";

function handleImportance(alerts: AlertsType) {
  if (
    alerts.drugInteraction.length > 0 ||
    alerts.componentsAllergy.length > 0
  ) {
    return "hasAvoid";
  }
  if (
    alerts.contraindications.length > 0 ||
    alerts.excipientAllergy.length > 0 ||
    alerts.componentsIntolerance.length > 0
  ) {
    return "hasContraindications";
  }
  if (alerts.excipientIntolerance.length > 0) {
    return "hasExcipientAlerts";
  }
}

export default handleImportance;
