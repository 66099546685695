import React from "react";
import { Bar, Progress } from "./style";

// import { Container } from './styles';

type ProgressBarProps = {
  progress: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <Progress>
      <Bar progress={progress} />
    </Progress>
  );
};

export default ProgressBar;
