import styled from "styled-components";

export const Container = styled.div`
  /* width: 100%; */
  // padding: 2rem 0;
  // display: flex;
  // flex-direction: column;
  // margin: 0 16px;

  margin-top: 24px;

  //   border: 2px solid red;
`;

export const Spacing = styled.div`
  height: 26px;
`;

export const HalfSpacing = styled.div`
  height: 12px;
`;

export const SymptomScrollButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;
