import { createRef } from "react";
import { ProductProps, SymptomType } from "../../../../api/types";
import CardSimple from "../../../../components/Cards/Simple";
import ProgressCard from "../../../../components/ProgressCard";
import { getHealthGoalsIcon } from "../../../../utils/getHealthGoalsIcon";
import { HealthGoalsContainer } from "../../../ProductDetails/style";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import Symptom from "../../../../components/Cards/Sympthon";
import HorizontalScrollView from "../../../../components/HorizontalScroll";
import ScrollButtons from "../../../../components/ScrollButtons";
import { getSymptomsSvg } from "../../../../utils/getSymptomSvg";
import { SymptomNutrientsModal } from "../../../ProductDetails/components/SymptomNutrientsModal";
import { SymptomScrollButtonContainer, YourBenefitsContainer } from "./styles";

interface YourBenefitsProps {
  product: ProductProps;
}

export function YourBenefits({ product }: YourBenefitsProps) {
  const sliderRef = createRef<HTMLDivElement>();
  const { t } = useTranslation();

  const [openSymptomModal, setOpenSymptomModal] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState<
    SymptomType | undefined
  >();

  const handleOpenSymptomModal = (symptom: SymptomType) => {
    setSelectedSymptom(symptom);
    setOpenSymptomModal(true);
  };

  const handleCloseSymptomModal = () => {
    setSelectedSymptom(undefined);
    setOpenSymptomModal(false);
  };

  console.log("product pack", product)

  return (
    <YourBenefitsContainer>
      {product.healthGoals.filter((elem) => elem.score !== 0).length > 0 && (
        <CardSimple title={t("screens.recommendedPack.health_goals")}>
          <HealthGoalsContainer>
            {product.healthGoals.map((healthGoal, index) => {
              return (
                <ProgressCard
                  key={index}
                  title={healthGoal.name}
                  icon={getHealthGoalsIcon(healthGoal.name, 40)}
                  progress={healthGoal.score}
                />
              );
            })}
          </HealthGoalsContainer>
        </CardSimple>
      )}

      {product.symptoms.length > 0 && (
        <>
          <CardSimple title={t("screens.recommendedPack.symptoms")}>
            <SymptomScrollButtonContainer>
              <ScrollButtons ref={sliderRef} length={174} />
            </SymptomScrollButtonContainer>
            <HorizontalScrollView ref={sliderRef} size={180}>
              {product.symptoms.map((symptom, index) => {
                return (
                  <Symptom
                    //   warning={index % 2 === 1}
                    key={index}
                    icon={getSymptomsSvg(symptom.categoryId, "white")}
                    progress={symptom.score}
                    title={symptom.name}
                    onClick={() => handleOpenSymptomModal(symptom)}
                  />
                );
              })}
            </HorizontalScrollView>
          </CardSimple>

          <SymptomNutrientsModal
            isOpen={openSymptomModal}
            symptom={selectedSymptom}
            onClose={handleCloseSymptomModal}
          />
        </>
      )}


    </YourBenefitsContainer>
  );
}
