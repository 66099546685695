import {
  ChangeEvent,
  InputHTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoClose } from "react-icons/io5";
import { FilterContext } from "../../../../context/filter";
import { GlobalContext } from "../../../../context/global";
import { formatterCurrencyFilter } from "../../../../utils/getCurrencyString";
import { useDebounce } from "../../../../utils/hooks/useDebounce";
import { FieldsetContainer } from "./styles";

interface FieldsetProps extends InputHTMLAttributes<HTMLInputElement> {
  legend: string;
}

export function InputRangePrice({ legend }: FieldsetProps) {
  const [isCheckFormOpen, setIsCheckFormOpen] = useState(false);

  const { highestPrice: max, lowestPrice: min } = useContext(GlobalContext);

  const {
    handleSetMaxPriceFilter,
    handleSetMinPriceFilter,
    minPriceFilter,
    maxPriceFilter,
    minVal,
    setMinVal,
    maxVal,
    setMaxVal,
  } = useContext(FilterContext);

  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  const resetValues = () => {
    setMinVal(min);
    setMaxVal(max);
  };

  const debouncedPriceMinValue = useDebounce(minVal, 300);
  const debouncedPriceMaxValue = useDebounce(maxVal, 300);

  useEffect(() => {
    document.addEventListener("clearFilters", function () {
      resetValues();
    });

    return () => window.removeEventListener("clearFilters", () => null);
  }, []);

  function handleOpenCheckForm() {
    setIsCheckFormOpen(!isCheckFormOpen);
  }

  function handleChangeMinValue(event: ChangeEvent<HTMLInputElement>) {
    const value = Math.min(Number(event.target.value), maxVal - 1);
    setMinVal(value);
    minValRef.current = value;
    // handleSetMinPriceFilter(value);
  }

  function handleChangeMaxValue(event: ChangeEvent<HTMLInputElement>) {
    const value = Math.max(Number(event.target.value), minVal + 1);
    setMaxVal(value);
    maxValRef.current = value;
  }

  useEffect(() => {
    if (debouncedPriceMaxValue !== maxPriceFilter) {
      handleSetMaxPriceFilter(debouncedPriceMaxValue);
    }

    if (debouncedPriceMinValue !== minPriceFilter) {
      handleSetMinPriceFilter(debouncedPriceMinValue);
    }
  }, [debouncedPriceMinValue, debouncedPriceMaxValue]);

  return (
    <FieldsetContainer isCheckFormOpen={isCheckFormOpen}>
      <div className="title">
        <legend onClick={handleOpenCheckForm}>{legend}</legend>
        <IoClose
          size={25}
          fill={"#545976"}
          className="icon-close"
          onClick={handleOpenCheckForm}
        />
      </div>

      <div className="form-check-container">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={handleChangeMinValue}
          className="thumb thumb-left"
        />

        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={handleChangeMaxValue}
          className="thumb thumb-right"
        />

        <div className="slider">
          <div className="slider-track" />
          <div className="start-ball"></div>
          <div className="end-ball"></div>
          <div ref={range} className="slider-range" />
          <div className="slider-left-value">
            {formatterCurrencyFilter.format(minVal)}
          </div>
          <div className="slider-right-value">
            {formatterCurrencyFilter.format(maxVal)}
          </div>
        </div>
      </div>
    </FieldsetContainer>
  );
}
