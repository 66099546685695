import React, { createRef } from "react";
import { useTranslation } from "react-i18next";
import { ProductProps } from "../../../../api/types";
// import BestProductsCard from "../../../../components/Cards/BestProduct";
import InfoProduct from "../../../../components/Cards/InfoProduct";
import HorizontalScrollView from "../../../../components/HorizontalScroll";
import BestProductMobile from "../../components/BestProductMobile";
import { Title } from "../../style";
import { Container } from "./style";

interface BestProductProps {
  betterProduct: ProductProps;
  cheaperProduct: ProductProps;
  allProducts: ProductProps[];
  nutrientName: string;
}

const BestProducts: React.FC<BestProductProps> = ({
  betterProduct,
  cheaperProduct,
  allProducts,
  nutrientName,
}) => {
  const sliderRef = createRef<HTMLDivElement>();
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t("screens.nutrientDetail.better_compatibility")}</Title>
      <BestProductMobile product={betterProduct} />
      <Title>{t("screens.nutrientDetail.best_report")}</Title>
      <BestProductMobile product={cheaperProduct} color="secondary" />
      <div style={{ position: "relative" }}>
        <Title>
          {t("screens.nutrientDetail.best_products_with_nutrient", {
            name: nutrientName,
          })}
        </Title>
        <HorizontalScrollView ref={sliderRef}>
          {allProducts.map((item, index) => (
            <InfoProduct key={index} product={item} />
          ))}
        </HorizontalScrollView>
      </div>
    </Container>
  );
};

export default BestProducts;
