import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";
import { IoChevronForwardSharp, IoClose } from "react-icons/io5";
import { useLocation } from "react-router";
import { NutrientProps } from "../../api/types";
import { ReactComponent as IconFilter } from "../../assets/filter-icon.svg";
import logoMobile from "../../assets/logo-mobile.svg";
import NutrientCard from "../../components/Cards/Nutrient";
import Loading from "../../components/Loading";
import Page from "../../components/Page";
import { BackDrop } from "../../components/Sidebar/FilterSidebar/styles";
import { FilterContext } from "../../context/filter";
import { useViewport } from "../../hooks/Viewport/useViewport";
import { HeaderContainer, HeaderLabel } from "../../styles/commomStyles";
import replaceAccentedCharacters from "../../utils/replaceAccentedCharacters";
import CardNutrientMobile from "./components/CardNutrientMobile";

import {
  Container,
  FilterButton,
  FilterButtonMobile,
  FilterButtonSearchContainer,
  FilteredContainer,
  FilteredContainerMobile,
  FilterTag,
  LoadingContainer,
  SearchContainer,
  SearchContainerMobile,
  SearchInput,
  SearchInputMobile,
  SubHeader,
  SubHeaderMobile,
  Title,
} from "./style";

interface NutritionalNeedsLocation {
  state?: {
    scroll?: boolean;
  };
}

export default function NutritionalNeeds() {
  const {
    avatarSymptoms,
    categorySymptoms,
    categorySymptomsIndexSelected,
    setCategorySymptomsIndexSelected,
    handleOpenSidebar,
    filteredNutrients,
    avatarSymptomsIndexSelected,
    setAvatarSymptomsIndexsSelected,
    healthGoals,
    healthGoalsIndexSelected,
    setHealthGoalsIndexSelected,
    clearFilters,
    loadingFilter,
    nutrientsSearchInput,
    setNutrientsSearchInput,
  } = useContext(FilterContext);

  const { state } = useLocation() as NutritionalNeedsLocation;

  useEffect(() => {
    // if (state && state.scroll) {
    window.scrollTo(0, 0);
    // }
  }, [state]);

  // useEffect(() => {
  //   clearFilters();
  // }, []);

  const { t } = useTranslation();
  const { isUnderTablet } = useViewport();

  const searchInput = useRef<HTMLInputElement>(null);

  const [isOpenedSearch, setIsOpenedSearch] = useState(false);
  const [inResearch, setInResearch] = useState(false);

  function handleClickedSearch() {
    setIsOpenedSearch(true);
    const focus = () => searchInput?.current?.focus();
    focus();
  }

  function handleUnclickedSearch() {
    setIsOpenedSearch(false);

    if (nutrientsSearchInput.length === 0) {
      setInResearch(false);
      return;
    }
    setInResearch(true);
  }

  const RenderHeader = () => (
    <HeaderContainer>
      <HeaderLabel>
        <b>{t("screens.nutritionalNeeds.best_nutrients")}</b>
      </HeaderLabel>
    </HeaderContainer>
  );

  const filterBySearchInput = (nutrient: NutrientProps) => {
    return replaceAccentedCharacters(nutrient.name.toLowerCase()).includes(
      replaceAccentedCharacters(nutrientsSearchInput).toLowerCase()
    );
  };

  const RenderHeaderLogo = () => (
    <HeaderContainer>
      <img src={logoMobile} alt="" />
    </HeaderContainer>
  );

  return (
    <>
      {isUnderTablet ? (
        <Page screen="nutritional-needs" logo={RenderHeaderLogo()}>
          <SubHeaderMobile>
            <Title>
              <label>
                <b>{t("screens.nutritionalNeeds.best_nutrients")} </b>
              </label>
            </Title>

            <FilterButtonSearchContainer>
              <FilterButtonMobile
                onClick={handleOpenSidebar}
                isOpenedSearch={isOpenedSearch}
                inResearch={inResearch}
              >
                {/* <IconFilter className="icon-filter" /> */}
              </FilterButtonMobile>
              <SearchContainerMobile
                isOpenedSearch={isOpenedSearch}
                inResearch={inResearch}
              >
                <AiOutlineSearch onClick={handleClickedSearch} size={50} />
                <SearchInputMobile
                  ref={searchInput}
                  value={nutrientsSearchInput}
                  onClick={handleClickedSearch}
                  onChange={(e) => {
                    setNutrientsSearchInput(e.target.value);
                  }}
                  name="searchInput"
                />
              </SearchContainerMobile>
            </FilterButtonSearchContainer>
          </SubHeaderMobile>

          <FilteredContainerMobile>
            <FilterButton onClick={handleOpenSidebar}>
              <IconFilter className="icon-filter" />
              <span>{t("screens.nutritionalNeeds.filter")}</span>
              <IoChevronForwardSharp size={15} />
            </FilterButton>

            {avatarSymptomsIndexSelected.map((value) => {
              return (
                <FilterTag className="filterTag">
                  <span>{avatarSymptoms[value].name}</span>
                  <IoClose
                    size={15}
                    fill={"#545976"}
                    className="icon-close"
                    onClick={() => {
                      setAvatarSymptomsIndexsSelected((prev) =>
                        prev.filter((elem) => elem !== value)
                      );
                    }}
                  />
                </FilterTag>
              );
            })}

            {categorySymptomsIndexSelected.map((value) => {
              return (
                <FilterTag className="filterTag">
                  <span>{categorySymptoms[value].name}</span>
                  <IoClose
                    size={15}
                    fill={"#545976"}
                    className="icon-close"
                    onClick={() => {
                      setCategorySymptomsIndexSelected((prev) =>
                        prev.filter((elem) => elem !== value)
                      );
                    }}
                  />
                </FilterTag>
              );
            })}

            {healthGoalsIndexSelected.map((value) => {
              return (
                <FilterTag className="filterTag">
                  <span>
                    {t(`healthGoals.${Object.keys(healthGoals)[value]}`)}
                  </span>
                  <IoClose
                    size={15}
                    fill={"#545976"}
                    className="icon-close"
                    onClick={() => {
                      setHealthGoalsIndexSelected((prev) =>
                        prev.filter((elem) => elem !== value)
                      );
                    }}
                  />
                </FilterTag>
              );
            })}
          </FilteredContainerMobile>

          {!loadingFilter && filteredNutrients ? (
            <Container>
              {filteredNutrients
                .filter(filterBySearchInput)
                .map((nutrient, key) => (
                  <CardNutrientMobile nutrient={{ ...nutrient }} key={key} />
                ))}
            </Container>
          ) : (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}

          <BackDrop
            isSidebarOpen={isOpenedSearch}
            onClick={handleUnclickedSearch}
          />
        </Page>
      ) : (
        <Page screen="nutritional-needs" header={() => <RenderHeader />}>
          <SubHeader>
            <label>
              <b>{t("screens.nutritionalNeeds.find_the_best")} </b>
            </label>
            <SearchContainer onClick={() => searchInput?.current?.focus()}>
              <AiOutlineSearch />
              <SearchInput
                ref={searchInput}
                value={nutrientsSearchInput}
                onChange={(e) => {
                  setNutrientsSearchInput(e.target.value);
                }}
                placeholder={t("screens.nutritionalNeeds.search_nutrient")}
                name="searchInput"
              />
            </SearchContainer>
          </SubHeader>

          <FilteredContainer>
            <FilterButton onClick={handleOpenSidebar}>
              <IconFilter className="icon-filter" />
              <span>{t("screens.nutritionalNeeds.filter")}</span>
              <IoChevronForwardSharp size={15} />
            </FilterButton>

            {avatarSymptomsIndexSelected.map((value) => {
              return (
                <FilterTag className="filterTag">
                  <span>{avatarSymptoms[value].name}</span>
                  <IoClose
                    size={15}
                    fill={"#545976"}
                    className="icon-close"
                    onClick={() => {
                      setAvatarSymptomsIndexsSelected((prev) =>
                        prev.filter((elem) => elem !== value)
                      );
                    }}
                  />
                </FilterTag>
              );
            })}

            {categorySymptomsIndexSelected.map((value) => {
              return (
                <FilterTag className="filterTag">
                  <span>{categorySymptoms[value].name}</span>
                  <IoClose
                    size={15}
                    fill={"#545976"}
                    className="icon-close"
                    onClick={() => {
                      setCategorySymptomsIndexSelected((prev) =>
                        prev.filter((elem) => elem !== value)
                      );
                    }}
                  />
                </FilterTag>
              );
            })}

            {healthGoalsIndexSelected.map((value) => {
              return (
                <FilterTag className="filterTag">
                  <span>
                    {t(`healthGoals.${Object.keys(healthGoals)[value]}`)}
                  </span>
                  <IoClose
                    size={15}
                    fill={"#545976"}
                    className="icon-close"
                    onClick={() => {
                      setHealthGoalsIndexSelected((prev) =>
                        prev.filter((elem) => elem !== value)
                      );
                    }}
                  />
                </FilterTag>
              );
            })}
          </FilteredContainer>

          {!loadingFilter && filteredNutrients ? (
            <Container>
              {filteredNutrients
                .filter(filterBySearchInput)
                .map((nutrient, key) => (
                  <NutrientCard nutrient={{ ...nutrient }} key={key} />
                ))}
            </Container>
          ) : (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}
        </Page>
      )}
    </>
  );
}
