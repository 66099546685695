import styled from "styled-components";

export const ContainerMobile = styled.div<{
  showAnimation1: boolean;
  showAnimation2: boolean;
}>`
  display: flex;
  flex: auto;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  overflow-y: hidden;
  flex-direction: column;
  position: relative;
  padding-top: 200px;

  // border: 2px solid red;

  .kpsule {
    position: absolute;
    top: 20%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation1 }) => (showAnimation1 ? `1` : `0`)};
    transition: all 1s linear;
  }

  .amazon {
    position: absolute;
    top: 20%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation2 }) => (showAnimation2 ? `1` : `0`)};
    transition: all 1s linear;
    max-width: 300px;
  }

  .animation-1 {
    opacity: ${({ showAnimation1 }) => (showAnimation1 ? `1` : `0`)};
    transition: all 1s linear;

    position: absolute;
    top: 0;
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);

    max-height: 300px;

    // border: 2px solid red;
  }

  .animation-2 {
    opacity: ${({ showAnimation2 }) => (showAnimation2 ? `1` : `0`)};
    transition: all 1s linear;

    position: absolute;
    top: 0;
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);

    max-height: 300px;

    // border: 2px solid red;
  }
`;

export const Container = styled.div<{
  showAnimation1: boolean;
  showAnimation2: boolean;
}>`
  display: flex;
  flex: auto;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  overflow-y: hidden;
  flex-direction: column;
  position: relative;
  padding-top: 55px;

  // border: 2px solid red;

  .kpsule {
    position: absolute;
    top: 20%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation1 }) => (showAnimation1 ? `1` : `0`)};
    transition: all 1s linear;
  }

  .amazon {
    position: absolute;
    top: 20%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation2 }) => (showAnimation2 ? `1` : `0`)};
    transition: all 1s linear;
    max-width: 300px;
  }

  // @media screen and (max-width: 428px) {
  //   height: 100vh;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  .animation-1 {
    // width: 150%;

    opacity: ${({ showAnimation1 }) => (showAnimation1 ? `1` : `0`)};
    transition: all 1s linear;

    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    max-height: 600px;

    // border: 2px solid red;

    // @media screen and (max-width: 428px) {
    //   width: 150%;
    //   top: -250px;
    // }
  }

  .animation-2 {
    // width: 85%;
    // bottom: -85px;
    opacity: ${({ showAnimation2 }) => (showAnimation2 ? `1` : `0`)};
    transition: all 1s linear;

    position: absolute;
    top: 60%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    max-height: 600px;

    // border: 2px solid red;

    // @media screen and (max-width: 428px) {
    //   width: 120%;
    //   top: -150px;
    // }

    // @media screen and (max-width: 375px) {
    //   top: -170px;
    // }
  }
`;

export const Title = styled.label`
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.black};

  position: absolute;
  top: 80px;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    margin-top: 20px;
    /* position: absolute;
    bottom: 35%; */
    bottom: 0px;
    top: initial;
  }
`;

export const ContainerFooter = styled.div<{
  showAnimation1: boolean;
  showAnimation2: boolean;
}>`
  width: 100%;
  height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 23px;
  align-items: center;

  position: relative;

  // border: 2px solid red;

  .kpsule {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation1 }) => (showAnimation1 ? `1` : `0`)};
    transition: all 1s linear;
  }

  .amazon {
    position: absolute;
    top: 58%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: ${({ showAnimation2 }) => (showAnimation2 ? `1` : `0`)};
    transition: all 1s linear;
    max-width: 300px;
  }
`;
