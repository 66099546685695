import styled from "styled-components";
import scrollbar from "../../../../../../styles/scrollbar";

export const ContainerIconMock = styled.div`
  svg {
    width: 45px;
    height: 45px;
  }
`;

export const HeaderComposition = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CompositionField = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 19px;
  line-height: 36px;
`;

export const CompositionName = styled.label`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
`;

export const ExtractTypeName = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grayLight};
`;

export const Dosage = styled.label`
  font-size: 20px;
  width: 80%;
  color: ${({ theme }) => theme.colors.blueLight};
  font-weight: bold;
`;

export const NutrientFillsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
`;

export const CompositionTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  span {
    font-size: 16px;
    color: #545976;
  }
`;

export const NutrientFills = styled.span<{ contentLength: number }>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  ${({ contentLength }) => contentLength > 50 && "font-size: 14px;"}
  color: ${({ theme }) => theme.colors.black};
  margin-top: -4px;
  opacity: 0.5;

  text-align: right;
  width: 200px;
`;

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const HeaderModal = styled.div`
  width: 100%;
  height: 73px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding-right: 20px;
  background-color: #fff;

  -webkit-box-shadow: 0px 3px 18px 1px #dbdbdb;
  box-shadow: 0px 3px 18px 1px #dbdbdb;

  position: relative;
  z-index: 50;

  .icon-close {
    transition: all 0.3s ease-out;
    cursor: pointer;
  }
`;

export const SymptomScrollButtonContainer = styled.div`
  /* position: absolute;
  top: 20px;
  right: 24px; */
  display: flex;
  justify-content: flex-end;
  margin-bottom: -50px;
`;

export const InformationCardContainer = styled.div`
  margin-top: 38px;

  .carousel {
    overflow-x: hidden;
    cursor: grab;
  }

  .inner-carousel {
    display: flex;
    gap: 8px;
    padding: 0 5px;
  }
`;

export const Title = styled.h2`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};

  @media screen and (max-width: 428px) {
    font-size: 18px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 26px;
  background-color: #f5f7fe;
  padding: 20px 15px 15px;
  overflow-y: scroll;
  height: 100vh;

  @media screen and (max-width: 600px) {
    padding: 8px;
    padding-right: 5px;
  }

  ${scrollbar}
`;

export const Footer = styled.div`
  height: 32px;
  width: 100%;

  -webkit-box-shadow: 0px -3px 18px 1px #dbdbdb;
  box-shadow: 0px -3px 18px 1px #dbdbdb;

  position: relative;
  z-index: 50;
`;
