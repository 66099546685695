import styled from "styled-components";
import scrollbar from "../../../styles/scrollbar";

// export const  = styled.div`
// 	width: 274px;
// 	background: #fff;
// 	position: absolute;
// 	/* z-index: 999; */
// `

interface FilterSidebarProps {
  isSidebarOpen: boolean;
  // isSidebarClosed: boolean
  // isCheckFormOpen?: boolean
}

export const FilterSidebarContainer = styled.div<FilterSidebarProps>`
  width: 274px;
  height: 100vh;
  color: ${({ theme }) => theme.colors.gray};
  background: #fff;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;

  ${scrollbar}

  //   border: 2px solid red;

  top: 0;
  left: -450px;
  z-index: 1001;

  transition: all 0.6s ease-out;

  /* left: ${({ isSidebarOpen }) => (isSidebarOpen ? "-450px" : "0")}; */

  ${({ isSidebarOpen }) => isSidebarOpen && `left: 0;`}

  .arrow-left {
    transition: 0.3s;
  }
  .arrow-left:hover {
    filter: brightness(0.3);
  }

  .sidebar-title {
    /* width: 80%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;

    padding: 1.5rem;
    /* margin: 0 auto; */

    svg {
      cursor: pointer;
    }

    h3 {
      font-size: 1.125rem;
    }

    & > span {
      font-size: 1rem;
    }
  }

  .div-form {
    .btn-container {
      margin-top: 50px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1.25rem;

      padding: 1.5rem;

      button {
        width: 223px;
        height: 44px;
        border-radius: 29px;
        color: ${({ theme }) => theme.colors.gray};
        transition: 0.3s;

        &:hover {
          filter: brightness(0.8);
        }
      }

      .btn-reinitialiser {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        background: ${({ theme }) => theme.colors.grayBgButton};
      }

      .btn-apliquer {
        color: #fff;
        background: ${({ theme }) => theme.colors.selected};
      }
    }
  }
`;

export const BackDrop = styled.div<FilterSidebarProps>`
  position: fixed;
  z-index: -1000;
  background: #00000060;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 0;

  transition: opacity 0.6s ease-in-out;

  ${({ isSidebarOpen }) =>
    isSidebarOpen &&
    `
			opacity: 1;
			z-index: 499;
		`}
`;
