import styled from "styled-components";

export type AlertColors = "orange" | "red" | "yellow" | "green" | "gray";

export const getAlertGradient = (color: AlertColors) => {
  switch (color) {
    case "orange":
      return `linear-gradient(228.69deg, #F68E61 24.77%, #FF6737 86.3%)`;
    case "red":
      return `linear-gradient(33.35deg, #D24848 14.59%, #FD8787 79%)`;
    case "yellow":
      return `linear-gradient(0deg, #FF9922 14.67%, rgba(255, 198, 113, 0.97) 90.76%)`;
    case "green":
      return `linear-gradient(33.35deg, #43AF45 14.59%, #79D57B 79%)`;
    default:
      return `linear-gradient(228.69deg, #F68E61 24.77%, #FF6737 86.3%)`;
  }
};

export const Container = styled.div<{
  color: "orange" | "red" | "yellow" | "green" | "gray";
}>`
  padding: 12px 8px;
  border-radius: 8px;
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 136px;
  height: 92px;
  justify-content: space-between;
  background: ${({ color }) => getAlertGradient(color)};
  flex-shrink: 0;

  -webkit-box-shadow: 0px 3px 3px 1px #e3caca;
  box-shadow: 0px 3px 3px 1px #e3caca;
`;

export const Title = styled.label`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.white};
`;

export const SubTitle = styled.label`
  color: ${({ theme }) => theme.colors.white};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

export const Icon = styled.div`
  display: flex;
  height: 32px;
  width: 32px;

  border: 1.5px solid white;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px;
  align-items: flex-start;
  //   margin-bottom: 10px;
  > img {
    fill: ${({ theme }) => theme.colors.white};
    height: 100%;
    width: 20px;
  }
`;

export const WithoutBorderIcon = styled.div`
  display: flex;
  height: 32px;
  width: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  align-items: flex-start;
  > img {
    fill: ${({ theme }) => theme.colors.white};
    height: 100%;
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
`;

export const TopIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  height: 16px;
  width: 16px;
  background: white;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    color: rgba(255, 164, 113, 0.97);
  }
`;
