import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AgentFieldOfActionType,
  CompositionType,
} from "../../../../../api/types";
import { ReactComponent as AlertIcon } from "../../../../../assets/content/alert.svg";
import { ReactComponent as CapsuleIconOrange } from "../../../../../assets/content/capsule-orange.svg";
import { ReactComponent as CapsuleIconRed } from "../../../../../assets/content/capsule-red.svg";
import { ReactComponent as HealthRiskIcon } from "../../../../../assets/content/health-risk.svg";
import { ReactComponent as NutritionalIcon } from "../../../../../assets/content/nutritional.svg";
import { ReactComponent as PillSynergyIcon } from "../../../../../assets/content/pill-synergy.svg";
import { ReactComponent as StarIcon } from "../../../../../assets/content/star-blue.svg";
import RoundedProgress from "../../../../../components/RoundedProgress";
import StandardTooltip from "../../../../../components/Tooltip";
import { getAgentExtractTypeIcon } from "../../../../../utils/getAgentExtractTypeIcon";
import getDrugInteractionPhrasesFormatted from "../../../../../utils/getDrugInteractionPhrasesFormatted";
import getInnerHtmlInsideDiv from "../../../../../utils/getInnerHtmlInsideDiv";
import { getNutrientCategoryIcon } from "../../../../../utils/getNutrientCategoryIcon";
import {
  AlertContainer,
  AlertIconContainer,
  CircleAlert,
  CircleSymptons,
  CompositionField,
  CompositionName,
  Container,
  ContainerCircleSymptons,
  ContainerIconMock,
  ContainerInfos,
  Fills,
  FooterComposition,
  IconContainerLeft,
  IconContainerRight,
  NutrientFills,
} from "./style";
// import { ModalComposition } from "./ModalComposition";

type ComponentCardProps = {
  component: CompositionType;
  viewMore: boolean;
  index: number;
  onComposition: () => void;
};

const ComponentCard = ({
  component,
  index,
  viewMore,
  onComposition,
}: ComponentCardProps) => {
  const [countAlert, setCountAlert] = useState<any>([]);

  const {
    healthRisks,
    nutrition,
    dosageAmountUnit,
    dosageAmountValue,
    fieldsOfAction,
    drugInteractions,
    hasDrugInteraction,
    isInTopTen,
    name,
    score,
    extractType,
    form,
    // nutrientFills,
    // studiesId,
  } = component;

  function handleCountAlert() {
    const alerts = fieldsOfAction.filter(
      (element) => element.isContraindication === true
    );
    setCountAlert([...countAlert, alerts]);
  }

  useEffect(() => {
    handleCountAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleSeeAllRelatedPublications = () => {
  //   setComponentName(name);
  //   setStudiesID(studiesId);
  //   setSuboutcomeIdScoreOrderList(fieldsOfAction.map((elem) => elem.id));
  //   const indexes: number[] = [];
  //   fieldsOfAction.forEach((elem, index) => {
  //     if (elem.isContraindication) {
  //       indexes.push(index);
  //     }
  //   });
  //   setContraindicationSuboutcomeIndex(indexes);
  // };

  const { t } = useTranslation();

  const getIconColor = () => {
    if (component.drugInteractions.some((elem) => elem.action === "Avoid")) {
      return "red";
    }
    if (component.drugInteractions.some((elem) => elem.action === "Lower")) {
      return "yellow";
    }
    if (component.drugInteractions.some((elem) => elem.action === "Favor")) {
      return "green";
    }
    return "gray";
  };

  const getIconColorDrug = () => {
    // if (drugInteractions.some((elem) => elem.action === "Avoid")) {
    //   console.log("retornou red");
    //   return <CapsuleIconRed />;
    // }
    if (drugInteractions.some((elem) => elem.action === "Lower")) {
      return <CapsuleIconOrange />;
    }
    if (drugInteractions.some((elem) => elem.action === "Avoid")) {
      return <CapsuleIconRed />;
    }
    if (drugInteractions.some((elem) => elem.action === "Favor")) {
      return <CapsuleIconOrange />;
    }
    return null;
  };

  const getDrugInteractionText = () => {
    const interactionsToAvoid = component.drugInteractions.filter(
      (elem) => elem.action === "Avoid"
    );
    if (interactionsToAvoid.length > 0) {
      const avoid = getDrugInteractionPhrasesFormatted(
        [interactionsToAvoid[0]],
        t
      );

      return avoid[0].phrase;
    }
    const interactionLower = component.drugInteractions.filter(
      (elem) => elem.action === "Lower"
    );
    if (interactionLower.length > 0) {
      const lower = getDrugInteractionPhrasesFormatted(
        [interactionLower[0]],
        t
      );
      return lower[0].phrase;
    }

    const interactionFavor = component.drugInteractions.filter(
      (elem) => elem.action === "Favor"
    );

    if (interactionFavor.length > 0) {
      const favor = getDrugInteractionPhrasesFormatted(
        [interactionFavor[0]],
        t
      );
      return favor[0].phrase;
    }

    return "";
  };

  const getSynergyPhrase = (field: AgentFieldOfActionType) => {
    if (!field.hasSynergy) {
      return "";
    }

    const { phrase, activeAgentName, passiveAgentName } = field.synergies[0];

    return phrase
      .replace(activeAgentName, `<strong>${activeAgentName}</strong>`)
      .replace(passiveAgentName, `<strong>${passiveAgentName}</strong>`);
  };

  function handleContraindicationAlert() {
    const contraindications = fieldsOfAction.filter(
      (element) => element.isContraindication
    );
    if (contraindications.length > 1) {
      return t("hovers.agent_has_alerts");
    } else {
      return t("hovers.agent_has_alert");
    }
  }

  function handleDrugInterationAlert() {
    const drugInteractionsAlerts = drugInteractions.filter(
      (element) => element
    );
    if (drugInteractionsAlerts.length > 1) {
      return t("hovers.agent_has_drug_interations");
    } else {
      return t("hovers.agent_has_drug_interation");
    }
  }

  return (
    <>
      <Container viewMore={viewMore} index={index}>
        <Fills>
          <IconContainerLeft>
            {isInTopTen && (
              <AlertContainer id={`is-in-top-ten-${component.id}`}>
                <StarIcon />
              </AlertContainer>
            )}
            {isInTopTen && (
              <StandardTooltip
                anchor={`is-in-top-ten-${component.id}`}
                color="blue"
                text={t("hovers.nutrient_is_recommended")}
                place="right"
              />
            )}

            {healthRisks.length > 0 && (
              <AlertContainer id={`health-risk-${component.id}`}>
                <HealthRiskIcon />
              </AlertContainer>
            )}

            {healthRisks?.length > 0 && (
              <StandardTooltip
                anchor={`health-risk-${component.id}`}
                color="blue"
                text={t("hovers.agent_has_healthRisk_alert")}
                place="right"
              />
            )}

            {fieldsOfAction.some((element) => element.synergies.length > 0) && (
              <AlertContainer id={`synergy-${component.id}`}>
                <PillSynergyIcon />
              </AlertContainer>
            )}

            {fieldsOfAction.some((element) => element.synergies.length > 0) && (
              <StandardTooltip
                anchor={`synergy-${component.id}`}
                color="blue"
                text={t("hovers.agent_has_synergy_alert")}
                place="right"
              />
            )}

            {nutrition.length > 0 && (
              <AlertContainer id={`nutrition-${component.id}`}>
                <NutritionalIcon />
              </AlertContainer>
            )}

            {nutrition?.length > 0 && (
              <StandardTooltip
                anchor={`nutrition-${component.id}`}
                color="blue"
                text={t("hovers.agent_has_nutrition_deficient_alert")}
                place="right"
              />
            )}
          </IconContainerLeft>

          <ContainerInfos>
            <RoundedProgress
              size={70}
              progress={component.score}
              grayForeground
              thickness={6}
            >
              <ContainerIconMock>
                {!!component.extractTypeId
                  ? getAgentExtractTypeIcon(component.extractTypeId)
                  : getNutrientCategoryIcon(component.categoryId)}
              </ContainerIconMock>
            </RoundedProgress>
            <CompositionField>
              <label>{`${parseFloat(
                dosageAmountValue.toFixed(2)
              )} ${dosageAmountUnit}`}</label>
            </CompositionField>
            <CompositionName>
              {`${name}`}
              {extractType ? (
                <label>{` - ${extractType}`}</label>
              ) : component.categoryId === 26 ? (
                <label>{` - ${t("common.plant_extract")}`}</label>
              ) : (
                ``
              )}
            </CompositionName>
            <NutrientFills
              contentLength={
                t("screens.productDetail.fills_need_day", {
                  nutrientFills: Math.round(score),
                }).length
              }
            >
              {t("screens.productDetail.fills_need_day", {
                nutrientFills: Math.round(score),
              })}
            </NutrientFills>
          </ContainerInfos>

          <IconContainerRight>
            {(component.allergies.length > 0 ||
              component.intolerances.length > 0) && (
              <AlertContainer id={`allergy-alert-${component.id}`}>
                <AlertIcon />
              </AlertContainer>
            )}

            {(component.allergies.length > 0 ||
              component.intolerances.length > 0) && (
              <StandardTooltip
                anchor={`allergy-alert-${component.id}`}
                color={component.allergies.length > 0 ? "red" : "orange"}
                text={t("hovers.agent_has_alert")}
                place="right"
              />
            )}

            {fieldsOfAction.some((element) => element.isContraindication) && (
              <AlertContainer id={`contraindication-alert-${component.id}`}>
                <AlertIcon />

                {fieldsOfAction.filter((element) => element.isContraindication)
                  .length > 1 && (
                  <CircleAlert value={countAlert.length + 1}>
                    <label>{countAlert.length + 1}</label>
                  </CircleAlert>
                )}
              </AlertContainer>
            )}

            {fieldsOfAction.some((element) => element.isContraindication) && (
              <StandardTooltip
                anchor={`contraindication-alert-${component.id}`}
                color="orange"
                text={handleContraindicationAlert()}
                place="right"
              />
            )}

            {hasDrugInteraction && (
              <AlertIconContainer
                // color={getIconColor()}
                id={`drug-interaction-${component.id}`}
              >
                {getIconColorDrug()}
              </AlertIconContainer>
            )}

            {hasDrugInteraction && (
              <StandardTooltip
                anchor={`drug-interaction-${component.id}`}
                color={getIconColor()}
                text={handleDrugInterationAlert()}
                place="right"
              />
            )}
          </IconContainerRight>
        </Fills>
        <FooterComposition>
          <ContainerCircleSymptons>
            <CircleSymptons value={fieldsOfAction?.length}>
              <label>{fieldsOfAction?.length}</label>
            </CircleSymptons>
            <label>{t("screens.productDetail.symptoms")}</label>
          </ContainerCircleSymptons>

          <label onClick={onComposition}>
            {t("screens.productDetail.see_more")}
          </label>
        </FooterComposition>
      </Container>
      {component?.fieldsOfAction.map((field, index) => {
        if (!field.hasSynergy) return null;
        return (
          <StandardTooltip
            anchor={`${component.id}-synergy-${field.id}-${field.categoryId}`}
            color="blue"
            key={index}
            text={field.synergies[0].phrase}
            place="top"
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(getSynergyPhrase(field)),
                }}
              ></div>
            }
          />
        );
      })}

      {component?.fieldsOfAction.map((field, index) => {
        if (!field.isContraindication) return null;
        return (
          <StandardTooltip
            anchor={`${component.id}-contraindication-${field.id}-${field.categoryId}`}
            color="orange"
            text={t("hovers.nutrient_contraindicated", {
              nutrientName: component.name,
              symptomName: field.name,
            })}
            key={index}
            place="top"
            children={
              <div
                dangerouslySetInnerHTML={{
                  __html: getInnerHtmlInsideDiv(
                    t("hovers.nutrient_contraindicated", {
                      nutrientName: component.name,
                      symptomName: field.name,
                    })
                  ),
                }}
              ></div>
            }
          />
        );
      })}
    </>
  );
};

export default ComponentCard;
