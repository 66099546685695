import styled from "styled-components";
import scrollbar from "../../../../styles/scrollbar";

export const Container = styled.div`
  flex-direction: column;
  padding: 32px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Title = styled.h1`
  margin-bottom: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};
  //   border: 2px solid red;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 330px;
  overflow-y: scroll;
  flex-direction: column;
  ${scrollbar}
  padding-right: 16px;
`;
