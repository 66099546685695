import { Tab, TabList, Tabs } from "react-tabs";
import styled from "styled-components";

interface TabsContainerProps {
  backgroundColor?: string;
}

export const TabsContainer = styled(Tabs)<TabsContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.white}; */
`;

export const TabListContent = styled(TabList)<TabsContainerProps>`
  width: 100%;
  margin: 0 auto;
  /* background: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.white}; */

  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  position: relative;
`;

export const UnorderedTab = styled(Tab)<{ selected: boolean }>`
  width: 100%;
  font-size: 24px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.selected : theme.colors.black};
  font-weight: bold;
  cursor: pointer;
  transition: color 360ms;

  // border: 2px solid black;
`;

export const FlagContainer = styled.button<{
  selected: boolean;
  isDisabled: boolean;
}>`
  width: 100%;
  height: 34px;
  font-size: 16px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.black};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.selected : "transparent"};
  border: ${({ theme, selected }) =>
    selected ? "none" : `1.5px solid ${theme.colors.grayLight}`};
  border-radius: 24px;
  position: relative;
  cursor: pointer;

  ${({ theme, isDisabled }) =>
    isDisabled &&
    `
    color: ${theme.colors.grayLight};
    background: ${theme.colors.grayBgButton};
  `}

  // border: 2px solid green;

  /* @media screen and (max-width: 320px) {
    width: 140px;
  } */

  span {
    font-weight: 700;
  }

  > label {
    cursor: pointer;
  }
`;

export const Flag = styled.div<{ show: boolean }>`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 5px;
  position: absolute;
  margin-top: 12px;
  left: 0;
  right: 0;
  transition: opacity 360ms;
  ${({ show }) => (show ? `opacity: 1` : `opacity: 0`)};

  background-color: ${({ theme }) => theme.colors.selected};
`;

export const LearnMore = styled.span`
  width: 100px;
  height: 30px;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.selected};
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;
