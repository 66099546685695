import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Container, Content, Header } from "./style";

const SkeletonBannerCard: React.FC = () => {
  return (
    <Container>
      <Header>
        <Skeleton
          baseColor="#E1E2E8"
          highlightColor="#00000010"
          width="50px"
          height="35px"
          style={{ borderRadius: "4px", opacity: 0.3 }}
        />
        <Skeleton
          baseColor="#E1E2E8"
          highlightColor="#00000010"
          width="35px"
          height="35px"
          style={{ borderRadius: "4px", opacity: 0.3, marginLeft: "12px" }}
        />
        <Skeleton
          baseColor="#E1E2E8"
          highlightColor="#00000010"
          width="35px"
          height="35px"
          style={{ borderRadius: "4px", opacity: 0.3, marginLeft: "12px" }}
        />
      </Header>
      <Skeleton
        baseColor="#CCCED6"
        highlightColor="#00000010"
        width="73px"
        height="73px"
        style={{ borderRadius: "73px" }}
      />
      <Content>
        <Skeleton
          baseColor="#E1E2E8"
          highlightColor="#00000010"
          width="101px"
          height="11px"
          style={{ borderRadius: "25px", marginTop: "36px" }}
        />
        <Skeleton
          baseColor="#E1E2E8"
          highlightColor="#00000010"
          width="101px"
          height="11px"
          style={{ borderRadius: "25px", marginTop: "12px" }}
        />
        <Skeleton
          baseColor="#E1E2E8"
          highlightColor="#00000010"
          width="101px"
          height="11px"
          style={{ borderRadius: "25px", marginTop: "12px" }}
        />
      </Content>
    </Container>
  );
};
export default SkeletonBannerCard;
