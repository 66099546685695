import styled from "styled-components";

export const HoverButtonContainer = styled.button<{ isAdded: boolean, cursorNotAllowed: boolean, width?: number, height?: number, sizeFont?: number }>`
  width: ${({width}) => width ? `${width}px` : "250px"};
  ${({height}) => height && `height: ${height}px`};
  /* height: ${({height}) => height ? `${height}px` : "250px"}; */
  display: flex;
  justify-content: center;
  background-color: ${({ isAdded, theme }) => !isAdded && `${theme.colors.selected}`};
  align-items: center;
  padding: 0.8rem;
  border-radius: 80px;
  cursor: ${({cursorNotAllowed}) => cursorNotAllowed ? "not-allowed" : "pointer"};
  position: relative;

  > svg {
    // border: 2px solid red;
    margin-left: 6px;
    margin-bottom: 1px;
    fill: white;
    position: absolute;
    right: 12px;
  }

  > label {
    cursor: pointer;
    font-size: ${({sizeFont}) => sizeFont ? sizeFont : 18}px;
    color: ${({ theme }) => theme.colors.white};
    cursor: ${({cursorNotAllowed}) => cursorNotAllowed ? "not-allowed" : "pointer"};
  }

  &:hover {
    background: ${({ isAdded }) =>
      isAdded
        ? ``
        : `linear-gradient(180.32deg, #2effaf 15.34%, #00aaff 98.15%)`};
  }

  ${({ isAdded, theme }) => isAdded && `background: ${theme.colors.midBlue}`}
`;
