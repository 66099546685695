import React, { createRef } from "react";
import { useTranslation } from "react-i18next";
import { ProductProps } from "../../../../api/types";
import BestProductsCard from "../../../../components/Cards/BestProduct";
import InfoProduct from "../../../../components/Cards/InfoProduct";
import HorizontalScrollView from "../../../../components/HorizontalScroll";
import ScrollButtons from "../../../../components/ScrollButtons";
import { Title } from "../../style";
import {
  Container,
  HalfSpacing,
  Spacing,
  SymptomScrollButtonContainer,
} from "./style";

interface BestProductProps {
  betterProduct?: ProductProps;
  cheaperProduct?: ProductProps;
  allProducts: ProductProps[];
  nutrientName: string;
}

const BestProducts: React.FC<BestProductProps> = ({
  betterProduct,
  cheaperProduct,
  allProducts,
  nutrientName,
}) => {
  const sliderRef = createRef<HTMLDivElement>();
  const { t } = useTranslation();

  return (
    <Container>
      <HalfSpacing />
      {betterProduct && (
        <>
          <Title>{t("screens.nutrientDetail.better_compatibility")}</Title>
          <Spacing />
          <BestProductsCard product={betterProduct} />
        </>
      )}
      <Spacing />
      {cheaperProduct &&
        betterProduct &&
        cheaperProduct.id !== betterProduct.id && (
          <>
            <Spacing />
            <Title>{t("screens.nutrientDetail.best_report")}</Title>
            <Spacing />
            <BestProductsCard product={cheaperProduct} color="secondary" />
            <Spacing />
          </>
        )}
      <Spacing />
      <div style={{ position: "relative" }}>
        <Title>
          {t("screens.nutrientDetail.best_products_with_nutrient", {
            name: nutrientName,
          })}
        </Title>
        <SymptomScrollButtonContainer>
          <ScrollButtons ref={sliderRef} length={174} />
        </SymptomScrollButtonContainer>
        <Spacing />
        <HorizontalScrollView ref={sliderRef}>
          {allProducts.map((item, index) => (
            <InfoProduct key={index} product={item} />
          ))}
        </HorizontalScrollView>
      </div>
    </Container>
  );
};

export default BestProducts;
