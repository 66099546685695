import React, { useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { NutrientDietInformationType } from "../../../../../api/types";
import forkAndKnifeImg from "../../../../../assets/banners/fork-and-knife.svg";
import {
  DietInformation,
  DietInformationContainer,
  DietInformationImage,
  DietInformationProgressIconSimplesCard,
  DietInformationTitle,
  DietInformationTitleContainerSimpleCard,
} from "./style";

type DietInformationCarouselProps = {
  nutrientDietInformation: NutrientDietInformationType[];
};

const DietInformationSimpleCard: React.FC<DietInformationCarouselProps> = ({
  nutrientDietInformation,
}) => {
  const [selectedValue, setSelectedValue] = useState(0);
  const [modalInformation, setModalInformation] = useState(false);

  const handleChangeBanner = (index: number) => {
    setSelectedValue(index);
  };

  const getIcon = (type: string, iconUrl?: string) => {
    switch (type) {
      case "nutrition":
        return forkAndKnifeImg;
      case "health risk":
        return `https://storage.googleapis.com/cdn-base.reload.co/${iconUrl}`;
    }
  };

  function handleModalInformation() {
    setModalInformation(!modalInformation);
  }

  return (
    // <DietInformationCarouselContainer>
    <DietInformationContainer onClick={handleModalInformation}>
      {nutrientDietInformation
        // .slice(0)
        // .reverse()
        .map((information, index) => {
          return (
            <DietInformation
              onClick={() => handleChangeBanner(index)}
              modalInformation={modalInformation}
              information={information.text}
              score={information.score}
              backgroundType={information.type}
              isSelected={index === selectedValue}
              key={index}
            >
              <DietInformationProgressIconSimplesCard>
                <CircularProgressbarWithChildren
                  value={information.score}
                  strokeWidth={4}
                  styles={buildStyles({
                    trailColor: "transparent",
                    pathColor: "white",
                  })}
                >
                  <DietInformationImage
                    src={getIcon(information.type, information.iconUrl)}
                  />
                </CircularProgressbarWithChildren>
              </DietInformationProgressIconSimplesCard>

              <DietInformationTitleContainerSimpleCard
                information={information.text}
                isSelected={index === selectedValue}
                modalInformation={modalInformation}
              >
                <DietInformationTitle>{information.text}</DietInformationTitle>
              </DietInformationTitleContainerSimpleCard>

              {/* <DietInformationCarouselButtons>
                {[...Array(nutrientDietInformation.length)].map(
                  (elem, index) => (
                    <CarouselButton
                      key={index}
                      actual={selectedValue}
                      value={index}
                      onClick={() => handleChangeBanner(index)}
                    />
                  )
                )}
              </DietInformationCarouselButtons> */}
            </DietInformation>
          );
        })}
    </DietInformationContainer>
  );
};

export default DietInformationSimpleCard;
