import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";
import Modal from "react-modal";
import { SymptomType } from "../../../../api/types";
import NutrientItem from "../../TabsMobile/FieldsOfActionMobile/components/HealthCategoriesCard/components/NutrientBar.tsx";
import { Container, Content, IconContainer, Title } from "./style";

interface SymptomNutrientsModalProps {
  symptom?: SymptomType;
  isOpen: boolean;
  onClose: () => void;
}

const SymptomNutrientsModalMobile = ({
  isOpen,
  onClose,
  symptom,
}: SymptomNutrientsModalProps) => {
  const { t } = useTranslation();

  const customStyles: Modal.Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "469px",
      width: "95vw",
      minHeight: "305px",
      maxHeight: "469px",
      border: "none",
      borderRadius: "8px",
      boxShadow: "0px 6px 8px 0px rgba(0, 0, 0, 0.3)",
      padding: "0",
      overflow: "hidden",
      zIndex: 999,
      position: "absolute",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <Container>
        <Title>{symptom?.name}</Title>
        <IconContainer onClick={onClose}>
          <IoCloseSharp size={22} />
        </IconContainer>
        <Content>
          {symptom?.nutrients.map((nutrient, index) => (
            <NutrientItem
              key={index}
              iconId={nutrient.subcategoryId}
              name={nutrient.name}
              score={nutrient.score}
              isContraindication={nutrient.isContraindication}
            />
          ))}
        </Content>
      </Container>
    </Modal>
  );
};

export { SymptomNutrientsModalMobile };
