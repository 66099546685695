import React, { createRef } from "react";
import { Container, Content, Title } from "./style";
import HorizontalScrollView from "../../HorizontalScroll";

const CardSimple: React.FC<{ title: string; children: React.ReactNode, hiddenWidth?: boolean, noBackground?: boolean, noPaddingLeftRight?: boolean }> = ({
	children,
	title,
	hiddenWidth,
	noBackground,
	noPaddingLeftRight,
}) => {

	return (
		<Container noPaddingLeftRight={noPaddingLeftRight} noBackground={noBackground} hiddenWidth={hiddenWidth}>
			<Title>{title}</Title>
			<Content>{children}</Content>
		</Container>
	);
};

export default CardSimple;
