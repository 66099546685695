import { useTheme } from "styled-components";
import { LoadingComponent } from "./style";

interface LoadingProps {
  color?: string;
}

const Loading = ({ color }: LoadingProps) => {
  const theme = useTheme();

  return (
    <LoadingComponent color={color ?? theme.colors.black}>
      <div className="loader"></div>
    </LoadingComponent>
  );
};

export default Loading;
