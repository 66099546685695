import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { HoverButtonContainer } from "./style";

interface HoverButtonProps {
  cursorNotAllowed?: boolean;
  isActive: boolean;
  onClick: () => void;
  label: string;
  width?: number;
  height?: number;
  sizeFont?: number;
}

const HoverButton = ({ cursorNotAllowed, isActive, onClick, label, width, height, sizeFont }: HoverButtonProps) => {
  return (
    <HoverButtonContainer 
      width={width}
      height={height}
      sizeFont={sizeFont}
      cursorNotAllowed={cursorNotAllowed!}
      onClick={onClick} 
      isAdded={isActive}
      >
      <label>{label}</label>
      {isActive && <IoCheckmarkCircleOutline size={22} color="white" />}
    </HoverButtonContainer>
  );
};

export default HoverButton;
