import styled from "styled-components";
import bark from "../assets/extract-type-icons/bark.svg";
import berry from "../assets/extract-type-icons/berry.svg";
import brand_formula from "../assets/extract-type-icons/brand_formula.svg";
import bud from "../assets/extract-type-icons/bud.svg";
import bulb from "../assets/extract-type-icons/bulb.svg";
import essential_oil from "../assets/extract-type-icons/essential_oil.svg";
import flower from "../assets/extract-type-icons/flower.svg";
import fruiting_body from "../assets/extract-type-icons/fruiting_body.svg";
import juice from "../assets/extract-type-icons/juice.svg";
import leaf from "../assets/extract-type-icons/leaf.svg";
import mycelium from "../assets/extract-type-icons/mycelium.svg";
import phytosome from "../assets/extract-type-icons/phytosome.svg";
import plant_compound from "../assets/extract-type-icons/plant_compound.svg";
import rhizome from "../assets/extract-type-icons/rhizome.svg";
import root from "../assets/extract-type-icons/root.svg";
import seaweed from "../assets/extract-type-icons/seaweed.svg";
import seed from "../assets/extract-type-icons/seed.svg";
import seed_oil from "../assets/extract-type-icons/seed_oil.svg";
import others from "../assets/nutrient-category/99-others.svg";

const iconVariants = {
  1: root,
  2: leaf,
  3: bud,
  4: flower,
  5: fruiting_body,
  7: rhizome,
  8: seaweed,
  9: juice,
  10: seed,
  11: essential_oil,
  12: seed_oil,
  13: plant_compound,
  14: brand_formula,
  15: berry,
  16: bark,
  17: phytosome,
  19: mycelium,
  20: bulb,
  99: others,
};

export const getAgentExtractTypeIcon = (id: number, size?: number) => {
  return (
    <Image
      size={size || 30}
      src={iconVariants[id as keyof typeof iconVariants] || iconVariants[99]}
      alt=""
    />
  );
};

const Image = styled.img<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
