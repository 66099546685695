import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoAlertOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { ProductProps } from "../../../../api/types";
import { ReactComponent as StarIcon } from "../../../../assets/content/star.svg";
import fallbackImage from "../../../../assets/jpg/fallbackImage.jpg";
import StandardTooltip from "../../../../components/Tooltip";
import { ShoppingCartContext } from "../../../../context/shoppingCart";
import { getCurrencyFormat } from "../../../../utils/getCurrencyString";
import { getImageCompletePath } from "../../../../utils/getImageCompletePath";
import { AddProductButton } from "../../../ProductsMobile/components/CardProductsFilteredMobile/InfoComumMobile/InfoProductMobile/style";
import {
  AlertContainer,
  BestProductCard,
  BestProductContent,
  BestProductFooter,
  BestProductImage,
  BestProductInformation,
  CompatibilityLabel,
  IconsContainer,
  ImageContainer,
  ProductName,
  StarContainer,
  SubTitleContainer,
  TitileContainer,
} from "./style";

interface BestProductsCardProps {
  product: ProductProps;
  color?: "primary" | "secondary";
}

const BestProductMobile: React.FC<BestProductsCardProps> = ({
  product,
  color = "primary",
}) => {
  const {
    brand,
    isInPack,
    hasPrecaution,
    hasSynergy,
    price,
    currency,
    score,
    name,
    imageUrl,
  } = product;

  const { t } = useTranslation();
  const navigation = useNavigate();

  const { addProduct, isProductAlreadyAdded, handleOpenRemoveProductModal } =
    useContext(ShoppingCartContext);

  const addToCart = () => {
    if (isProductAlreadyAdded(product)) {
      handleOpenRemoveProductModal(product);
    } else {
      addProduct(product, 1);
    }
  };

  const handleNavigate = () => {
    navigation("/product-details", {
      state: product,
      // replace: true,
    });
  };

  return (
    <BestProductCard color={color}>
      <BestProductContent>
        <BestProductInformation onClick={handleNavigate}>
          <TitileContainer>
            <ProductName>{name}</ProductName>

            <SubTitleContainer>
              <IconsContainer>
                {isInPack && (
                  <StarContainer id={`product-is-in-top-ten-${product.id}`}>
                    <StarIcon />
                  </StarContainer>
                )}
                {isInPack && (
                  <StandardTooltip
                    anchor={`product-is-in-top-ten-${product.id}`}
                    color="blue"
                    text={t("hovers.product_is_recommended")}
                    place="bottom"
                    clickable
                  />
                )}
                {hasPrecaution && (
                  <AlertContainer id={`product-has-alert-${product.id}`}>
                    <IoAlertOutline color="white" />
                  </AlertContainer>
                )}
                {hasPrecaution && (
                  <StandardTooltip
                    anchor={`product-has-alert-${product.id}`}
                    color="orange"
                    text={t("hovers.product_has_alert")}
                    place="bottom"
                    clickable
                  />
                )}
              </IconsContainer>
              <CompatibilityLabel value={Math.round(score)}>
                {t("screens.nutrientDetail.your_compatibility")}{" "}
                <label>{`${Math.round(score)}/100`}</label>
              </CompatibilityLabel>
            </SubTitleContainer>
          </TitileContainer>

          <ImageContainer>
            <BestProductImage
              src={getImageCompletePath("product", imageUrl)}
              onError={(e) => (e.currentTarget.src = fallbackImage)}
            />
          </ImageContainer>
        </BestProductInformation>

        <BestProductFooter>
          <div
            onClick={() => {
              navigation("/product-details", {
                state: product,
                // replace: true,
              });
            }}
          >
            <label>{t("screens.home.tabs.bestProducts.learn_more")}</label>
          </div>
          <div>
            <AddProductButton
              width={172}
              onClick={addToCart}
              added={isProductAlreadyAdded(product)}
            >
              <label>
                {isProductAlreadyAdded(product)
                  ? t("screens.home.tabs.bestProducts.in_cart")
                  : `${getCurrencyFormat(currency, price)}`}
              </label>

              {isProductAlreadyAdded(product) ? (
                <IoCheckmarkCircleOutline color="white" size={22} />
              ) : (
                <IoIosAddCircleOutline size={22} />
              )}
            </AddProductButton>
          </div>
        </BestProductFooter>
      </BestProductContent>
    </BestProductCard>
  );
};

export default BestProductMobile;
