import styled from "styled-components";

interface FieldsetProps {
  isCheckFormOpen?: boolean;
}

export const FieldsetContainer = styled.fieldset<FieldsetProps>`
  width: 274px;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .form-check-container {
    width: 100%;
    margin-top: 1rem;
    transition: visibility 0.3s, opacity 0.3s linear;
    height: ${({ isCheckFormOpen }) => (isCheckFormOpen ? "auto" : "0")};
    overflow: hidden;
    opacity: ${({ isCheckFormOpen }) => (isCheckFormOpen ? "1" : "0")};
    visibility: ${({ isCheckFormOpen }) =>
      isCheckFormOpen ? "visible" : "hidden"};

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 2px solid ${({ theme }) => theme.colors.grayLightBorder};

    ${({ isCheckFormOpen, theme }) =>
      isCheckFormOpen
        ? `border-bottom: 2px solid transparent`
        : `border-bottom: 2px solid ${theme.colors.grayLightBorder}`};

    // border: 2px solid orange;

    .icon-close {
      transition: all 0.3s ease-out;
      cursor: pointer;
      transform: ${({ isCheckFormOpen }) =>
        isCheckFormOpen ? "rotate(0)" : "rotate(45deg)"};
    }
  }

  legend {
    font-size: 1rem;
    align-items: center;
    /* margin-bottom: 24px; */
    /* margin-top: 24px; */
    cursor: pointer;
  }

  label {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.grayLight};
    display: grid;
    grid-template-columns: 1em auto;
    gap: 1em;
    margin-top: 1em;
    cursor: pointer;
  }

  .slider {
    position: absolute;
    width: 200px;
  }

  .slider-track,
  .slider-range,
  .slider-left-value,
  .slider-right-value {
    position: absolute;
    top: 0;
  }

  .slider-track,
  .slider-range {
    border-radius: 3px;
    height: 2px;
  }

  /* .slider-range {
        right: 40px;
      } */

  .start-ball {
    content: "";
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    top: 1px;
    left: 0;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.gray};
  }

  .end-ball {
    content: "";
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    top: 1px;
    right: 0;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.gray};
  }
  .slider-track {
    background-color: ${({ theme }) => theme.colors.gray};
    width: 100%;
    z-index: 1;
  }

  .slider-range {
    background-color: ${({ theme }) => theme.colors.gray};
    z-index: 2;
  }

  .slider-left-value,
  .slider-right-value {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 18px;
    /* letter-spacing: -1px; */
    margin: 30px 0px 20px;
  }

  .slider-left-value {
    left: 0px;
  }

  .slider-right-value {
    right: 0px;
  }

  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
  }

  .thumb-left {
    z-index: 3;
  }

  .thumb-right {
    width: 202px;
    z-index: 4;
  }

  .thumb::-webkit-slider-thumb {
    background-color: ${({ theme }) => theme.colors.selected};
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  .thumb::-moz-range-thumb {
    background-color: ${({ theme }) => theme.colors.selected};
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
`;
